import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AgendaRu from 'components/Agenda/AgendaRu';
import AgendaUa from 'components/Agenda/AgendaUa';

const TermsOfUse = (props) => {
  const agenda = ((language) => {
    switch (language) {
      case 'ua':
      case 'uk':
        return <AgendaUa />;
      case 'ru':
      default:
        return <AgendaRu />;
    }
  })(props.language);
  return (
    <Dialog
      open={props.openState}
      onClose={props.close}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <FormattedMessage id="termsOfUse.title" />
      </DialogTitle>
      <DialogContent>{agenda}</DialogContent>
      <DialogActions>
        <Button onClick={props.disagree}>
          <FormattedMessage id="termsOfUse.disagree" />
        </Button>
        <Button onClick={props.agree}>
          <FormattedMessage id="termsOfUse.agree" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(TermsOfUse);
