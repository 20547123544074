import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { userActions } from '../../../actions';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import RegisterForm from './RegisterForm';

import registerPageStyle from '../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';

// import connect from "react-redux";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: '',
      },
      submitted: false,
      checked: [],
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  handleSubmit(data) {
    this.setState({
      submitted: true,
      user: {
        email: data.email.trim(),
      },
    });
    data.groupid = 2;
    const trimed = Object.keys(data).reduce((obj, key) => {
      let value = data[key];
      if (typeof value === 'string') value = value.trim();
      obj[key] = value;
      return obj;
    }, {});
    const { dispatch } = this.props;
    dispatch(userActions.register(trimed));
  }

  render() {
    const { registering, classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={7} lg={6}>
            <Card className={classes.cardSignup}>
              <CardBody>
                <GridContainer justify="center">
                  {!registering ? (
                    <GridItem xs={12} sm={10} md={10}>
                      <h2 className={classes.cardTitle}>
                        <FormattedMessage id="registration.title" />
                      </h2>
                      <RegisterForm
                        classes={classes}
                        onSubmit={this.handleSubmit}
                        handleToggle={this.handleToggle}
                      />
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 20,
                        }}
                      >
                        <NavLink to="/auth/register/partner">
                          <FormattedMessage id="registration.form.asPartner" />
                        </NavLink>
                      </div>
                    </GridItem>
                  ) : (
                    <GridItem xs={12} sm={10} md={10}>
                      <h3
                        className={classes.cardTitle}
                        style={{ marginBottom: 20 }}
                      >
                        <FormattedMessage id="registration.success.title" />
                      </h3>
                      <p align="center">
                        <FormattedMessage
                          id="registration.success.message"
                          values={{ email: this.state.user.email }}
                        />
                      </p>
                      <div align="center">
                        <NavLink to="/auth/login">
                          <Button color="primary" round>
                            <FormattedMessage id="layout.menu.login" />
                          </Button>
                        </NavLink>
                      </div>
                    </GridItem>
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { registering, checkMailUrl } = state.registration;
  return {
    registering,
    checkMailUrl,
  };
}

export default connect(mapStateToProps)(
  withStyles(registerPageStyle)(RegisterPage)
);
