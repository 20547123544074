import Square from '../../classes/Square';
import Layer from '../../classes/Layer';
import Cursor from '../../classes/Cursor';
import Marker from './Marker';

export class SizeLayer extends Layer {
  constructor(size) {
    super();
    this.selectedSize = size;
    this.cursor = new Cursor({ width: 1, height: 1 });
    this.selected = new Square(
      undefined,
      undefined,
      'rgba(255, 255, 255, 0.5)'
    );
    this.markers = Array.from({ length: 4 }, () => new Marker());
    this.addElement(this.selected, ...this.markers, this.cursor);
  }

  setSize({ width = 100, height = 100 } = {}) {
    this.width = width;
    this.height = height;
    this.setSelected(this.selectedSize);
  }

  setSelected({ x = 0.1, y = 0.1 } = {}) {
    this.selectedSize = { x, y };
    this.selected.width = this.width * x;
    this.selected.height = this.height * y;
    this.move({
      x: (this.width - this.selected.width) / 2,
      y: (this.height - this.selected.height) / 2,
    });
  }

  getSelected() {
    return this.selectedSize;
  }

  alignMarkers() {
    const bounds = this.selected.getBounds();
    const boundsArray = [
      { x: bounds.left, y: bounds.top },
      { x: bounds.left, y: bounds.bottom },
      { x: bounds.right, y: bounds.top },
      { x: bounds.right, y: bounds.bottom },
    ];
    this.markers.forEach((el, idx) => el.setPosition(boundsArray[idx]));
  }

  move(position = { x: 0, y: 0 }) {
    this.selected.setPosition(position);
    this.alignMarkers();
  }

  resize(position = { x: 0, y: 0 }) {
    const square = this.selected;
    const bounds = square.getBounds();
    if (position.x < bounds.right - square.width / 2) {
      square.width -= position.x - square.x;
      square.x = position.x;
    }
    if (position.x > bounds.left + square.width / 2) {
      square.width += position.x - square.x - square.width;
    }
    if (position.y < bounds.bottom - square.height / 2) {
      square.height -= position.y - square.y;
      square.y = position.y;
    }
    if (position.y > bounds.top + square.height / 2) {
      square.height += position.y - square.y - square.height;
    }
    this.selectedSize = {
      x: ((1 / this.width) * this.selected.width).toFixed(2),
      y: ((1 / this.height) * this.selected.height).toFixed(2),
    };
    this.alignMarkers();
  }

  init(ctx = undefined) {
    if (!ctx) throw 'No canvas';
    this.cursor.init(ctx);
    let moveEnabled = false;
    let resizeEnabled = false;
    let cursorOffset = null;
    const events = {
      mousedown: (e) => {
        const canvasPos = this.cursor.getPosition(e);
        const squareBounds = this.selected.getBounds();
        const squareCollisiion = this.cursor.checkCollision(this.selected);
        const markerCollision = this.markers
          .map((el) => this.cursor.checkCollision(el))
          .some((i) => i);
        if (squareCollisiion) {
          moveEnabled = true;
          cursorOffset = {
            x: canvasPos.x - squareBounds.left,
            y: canvasPos.y - squareBounds.top,
          };
        }
        if (markerCollision) {
          moveEnabled = false;
          resizeEnabled = true;
        }
      },
      mouseup: () => {
        moveEnabled = false;
        resizeEnabled = false;
        cursorOffset = null;
      },
      mouseout: () => {
        moveEnabled = false;
        resizeEnabled = false;
        cursorOffset = null;
      },
      mousemove: (e) => {
        this.markers.forEach(
          (el) => (el.hover = this.cursor.checkCollision(el))
        );
        const canvasPos = this.cursor.getPosition(e);
        if (resizeEnabled && this.visible) this.resize(canvasPos);
        if (moveEnabled && this.visible) {
          this.move({
            x: canvasPos.x - cursorOffset.x,
            y: canvasPos.y - cursorOffset.y,
          });
        }
      },
    };
    Object.keys(events).forEach((e) =>
      ctx.canvas.addEventListener(e, events[e])
    );
  }
}

export default SizeLayer;
