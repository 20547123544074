import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import { history } from 'helpers';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import Switch from '@material-ui/core/Switch';
import Table from 'components/Table/Table.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import Group from '@material-ui/icons/Group';
import Close from '@material-ui/icons/Close';
import Settings from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';

import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';

import RemoveAlert from 'components/RemoveAlert';
import NoservicePlaceholder from './NoservicePlaceholder';
import EmptyPlaceholder from './EmptyPlaceholder';

import { viewersActions } from 'actions';
import LoaderAnimation from 'components/Loader';

class ViewersList extends React.Component {
  state = { confirm: null };

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(viewersActions.getViewers());
  }

  addViewer = () => history.push('/viewers/add');

  editViewer = (id) => history.push(`/viewers/edit/${id}`);

  toggleViwer = (id, state) => {
    this.props.dispatch(viewersActions.toggleViewer(id, state));
  };

  removeViewer = (id) => {
    this.setState({
      confirm: (confirmation) => {
        if (confirmation) {
          this.props.dispatch(viewersActions.removeViewers(id));
        }
        this.setState({ confirm: null });
      },
    });
  };

  render() {
    const { viewers, viewersServiceActive, classes, intl, pageLoader } =
      this.props;

    if (pageLoader) return <LoaderAnimation />;

    if (!viewersServiceActive)
      return <NoservicePlaceholder classes={classes} />;

    if (!viewers.length)
      return <EmptyPlaceholder classes={classes} addViewer={this.addViewer} />;

    const mediaQueryUpXs = isWidthUp('sm', this.props.width);
    const tableData = viewers.map((viewer, idx) => [
      viewer.email,
      <Switch
        key={idx}
        checked={!viewer.UserBlocked}
        onChange={() => this.toggleViwer(viewer.id, !viewer.UserBlocked)}
      />,
      [
        {
          color: 'info',
          icon: Settings,
          action: () => this.editViewer(viewer.id),
          tooltipMessage: intl.formatMessage({
            id: 'tooltip.message.settings',
          }),
        },
        {
          color: 'danger',
          icon: Close,
          action: () => this.removeViewer(viewer.id),
          tooltipMessage: intl.formatMessage({ id: 'tooltip.message.delete' }),
        },
      ].map((prop, key) => {
        return (
          <Tooltip title={prop.tooltipMessage} key={key}>
            <Button
              color={prop.color}
              className={classes.actionButton}
              onClick={prop.action}
            >
              <prop.icon className={classes.icon} />
            </Button>
          </Tooltip>
        );
      }),
    ]);

    return (
      <Fragment>
        <Card>
          <CardHeader color="info" icon>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <CardIcon color="info">
                  <Group />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  <FormattedMessage id="viewers.list.table.title" />
                </h4>
              </GridItem>
              <GridItem xs={12} sm={6} className={classes.right}>
                <Button
                  color="primary"
                  size="sm"
                  fullWidth={!mediaQueryUpXs}
                  onClick={this.addViewer}
                  style={{
                    marginTop: mediaQueryUpXs ? 15 : 30,
                  }}
                >
                  <FormattedMessage id="viewers.list.add" />
                </Button>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                intl.formatMessage({ id: 'viewers.list.table.mail' }),
                intl.formatMessage({ id: 'viewers.list.table.status' }),
                intl.formatMessage({ id: 'viewers.list.table.actions' }),
              ]}
              tableData={tableData}
              customCellClasses={[classes.center, classes.right]}
              customClassesForCells={[1, 2]}
              customHeadCellClasses={[classes.center, classes.right]}
              customHeadClassesForCells={[1, 2]}
            />
          </CardBody>
        </Card>
        <RemoveAlert
          confirm={this.state.confirm}
          title={intl.formatMessage({
            id: 'viewers.list.remove.title',
          })}
          description={intl.formatMessage({
            id: 'viewers.list.remove.description',
          })}
          successTitle={intl.formatMessage({
            id: 'viewers.list.remove.success',
          })}
          confirmBtnText={intl.formatMessage({
            id: 'viewers.list.remove.confirm',
          })}
          cancelBtnText={intl.formatMessage({
            id: 'viewers.list.remove.cancel',
          })}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  viewers: state.viewers.contacts,
  // viewers: [],
  viewersServiceActive: true,
  pageLoader: state.loader.pageLoader,
});
export default injectIntl(
  connect(mapStateToProps)(
    withWidth()(withStyles(extendedTablesStyle)(ViewersList))
  )
);
