import { connect } from 'react-redux';
import { Parser } from 'html-to-react';
import Card from 'components/Card/Card';
import React, { Component } from 'react';
import { invoiceActions } from 'actions';
import Table from 'components/Table/Table.jsx';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import { paidAllInvoicesStyles } from './utils';
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';

const htmlToReactParser = new Parser();

class PaidAllInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.props.dispatch(invoiceActions.getInvoiceItems('unpaid'));
  }

  getDataTable() {
    const { intl } = this.props;
    const dataTable = [];
    let totalSum = 0;
    let currency = {
      prefix: '',
      suffix: '',
    };
    this.props.invoices.map((item) => {
      if (item.type === 'Invoice') return;
      const title = {
        color: 'warning',
        data: [
          `${this.props.intl.formatMessage({ id: 'invoice.title' })} # ${
            item.id
          }`,
          '',
          '',
        ],
      };
      dataTable.push(title);

      const description = [
        htmlToReactParser.parse(
          `<pre>${item.invoiceitems[0].description}</pre>`
        ),
        '',
        `${item.currencyprefix} ${item.total} ${item.currencysuffix}`,
      ];
      dataTable.push(description);
      currency = { prefix: item.currencyprefix, suffix: item.currencysuffix };
      totalSum += +item.total;
    });

    dataTable.push({
      total: {
        title: intl.formatMessage({ id: 'order.form.table.amount' }),
      },
      amount: (
        <span>
          {currency.prefix} {totalSum.toFixed(2)} {currency.suffix}
        </span>
      ),
    });
    return dataTable;
  }

  handleSubmit = () => {
    const data = this.props.invoices.map((item) => item.id);
    this.props.dispatch(invoiceActions.createInvoiceOfInvoices(data));
  };

  render() {
    const { intl } = this.props;
    const dataTable = this.getDataTable();
    return (
      <GridContainer justify="center">
        <GridItem xs={12} md={8}>
          <Card>
            <CardBody>
              <Table
                hover
                tableHead={[
                  intl.formatMessage({
                    id: 'invoice.history.table.description',
                  }),
                  '',
                  intl.formatMessage({ id: 'payment.success.table.amount' }),
                ]}
                tableData={dataTable}
              />
              <div style={{ textAlign: 'center' }}>
                <Button color="rose" onClick={this.handleSubmit}>
                  <FormattedMessage id="payment.form.pay.all" />
                </Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { invoice } = state;
  return {
    invoices: Object.keys(invoice.unpaid).length ? invoice.unpaid : [],
  };
}

export default injectIntl(
  connect(mapStateToProps)(withStyles(paidAllInvoicesStyles)(PaidAllInvoices))
);
