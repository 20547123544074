import axios from 'axios';

export const pushRulesService = {
  getPushRules,
  getOnePushRule,
  addPushRule,
  editPushRule,
  removePushRules,
};

function getPushRules() {
  return axios.get(`/push-rules`);
}

function getOnePushRule(zoneIndex) {
  return axios.get(`/push-rules/${zoneIndex}`);
}

function addPushRule(data) {
  return axios.post(`/push-rules`, data);
}

function editPushRule(zoneIndex, data) {
  return axios.put(`/push-rules/${zoneIndex}`, data);
}

function removePushRules(serviceId, zoneIndex) {
  return axios.delete(`/push-rules/${serviceId}.${zoneIndex}`);
}
