import {
  normalizeAuthForms,
  handleAutocomplete,
} from '../../Channels/Form/formsNormalization';
import React from 'react';
import validate from './validate';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import Email from '@material-ui/icons/Email';
import { Field, reduxForm } from 'redux-form';
import Tooltip from '@material-ui/core/Tooltip';
import { injectIntl, FormattedMessage } from 'react-intl';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import RenderTextField from 'components/CustomInput/RenderTextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';

class AuthForm extends React.Component {
  state = {
    showPasswordAsText: false,
    showVisibilityIcon: false,
  };

  onShowPassword = () => {
    this.setState((prevState) => {
      return { showPasswordAsText: !prevState.showPasswordAsText };
    });
  };

  changePasswordFieldType = () => {
    return this.state.showPasswordAsText ? 'text' : 'password';
  };

  displayVisibilityPasswordIcon = () => {
    const { classes } = this.props;
    return !this.state.showPasswordAsText ? (
      <VisibilityIcon className={classes.inputAdornmentIcon}>
        lock_outline
      </VisibilityIcon>
    ) : (
      <VisibilityOffIcon className={classes.inputAdornmentIcon}>
        lock_outline
      </VisibilityOffIcon>
    );
  };

  handleShowPasswordChange = ({ target }) => {
    const { value } = target;
    if (value.length > 2) {
      this.setState({ showVisibilityIcon: true });
    } else {
      this.setState({ showVisibilityIcon: false });
    }
  };

  changeToolTipTitle = () => {
    return this.state.showPasswordAsText
      ? { id: 'auth.form.hide.password' }
      : { id: 'auth.form.show.password' };
  };

  render() {
    const { handleSubmit, classes, intl, loader } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="username"
          placeholder={intl.formatMessage({ id: 'form.email' })}
          component={RenderTextField}
          formControlProps={{
            fullWidth: true,
          }}
          autoFocus
          autoComplete="off"
          normalize={(value) => normalizeAuthForms(value)}
          onChange={handleAutocomplete}
          startAdornment={
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Email className={classes.inputAdornmentIcon} />
            </InputAdornment>
          }
        />
        <Field
          name="password"
          component={RenderTextField}
          type={this.changePasswordFieldType()}
          onChange={this.handleShowPasswordChange}
          normalize={(value) => normalizeAuthForms(value)}
          placeholder={intl.formatMessage({ id: 'form.password' })}
          formControlProps={{
            fullWidth: true,
          }}
          startAdornment={
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
            </InputAdornment>
          }
          endAdornment={
            this.state.showVisibilityIcon && (
              <Tooltip
                title={intl.formatMessage(this.changeToolTipTitle())}
                aria-label="Show password. Warning: this will display your password"
                placement="top"
              >
                <InputAdornment
                  position="end"
                  style={{ cursor: 'pointer', marginRight: '7px' }}
                  onClick={this.onShowPassword}
                >
                  {this.displayVisibilityPasswordIcon()}
                </InputAdornment>
              </Tooltip>
            )
          }
        />
        <div className={classes.center} style={{ marginTop: 20 }}>
          <Button loading={loader} round color="primary" type="submit">
            <FormattedMessage id="login.form.submit" />
          </Button>
        </div>
      </form>
    );
  }
}

export default injectIntl(
  reduxForm({
    form: 'AuthForm',
    validate,
    touchOnBlur: false,
  })(
    connect((state) => ({
      loader: state.loader.authLoginForm,
    }))(AuthForm)
  )
);
