import React from 'react';

import CustomInput from './CustomInput';

const CustomInputWrapper = ({
  input,
  label,
  formControlProps,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <CustomInput
      labelText={label}
      formControlProps={formControlProps}
      error={!!(touched && error)}
      helpText={touched && error ? error : undefined}
      inputProps={{
        ...custom,
        ...input,
      }}
    />
  );
};

export default CustomInputWrapper;
