import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import footerStyle from 'assets/jss/material-dashboard-pro-react/components/footerStyle';
import paymentImg from 'assets/img/payment_methods.png';

function Footer({ ...props }) {
  const { classes, fluid, white } = props;
  const container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  // var anchor =
  //   classes.a +
  //   cx({
  //     [" " + classes.whiteColor]: white
  //   });
  // var block = cx({
  //   [classes.block]: true,
  //   [classes.whiteColor]: white
  // });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          {/* <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
                <a href="/" className={block}>
                  <FormattedMessage id="layout.menu.homepage" />
                </a>
              </ListItem>
          </List> */}
          <img src={paymentImg} style={{ width: 100, marginTop: 10 }} alt="" />
        </div>
        <p className={classes.right}>
          &copy; {1900 + new Date().getYear()}{' '}
          {/* <a href="/" className={anchor}> */}
          IPTeam <FormattedMessage id="layout.copyright" />
          {/* </a> */}
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  // rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
