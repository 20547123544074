import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Button from 'components/CustomButtons/Button.jsx';
import AddIcon from '@material-ui/icons/Add';
import { NavLink } from 'react-router-dom';

const EmptyPlaceholder = (props) => (
  <div className={props.classes.center}>
    <h3>
      <FormattedMessage id="channels.list.empty" />
    </h3>
    <NavLink to="/channels/add">
      <Button color="primary">
        <AddIcon className={props.classes.icons} />
        <FormattedMessage id="channels.list.add" />
      </Button>
    </NavLink>
  </div>
);

export default injectIntl(EmptyPlaceholder);
