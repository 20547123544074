import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import GeoAutocomplete from 'components/GeoAutocomplete';
import formatGeoData from 'helpers/formatGeoData';
import Grid from '@material-ui/core/Grid';
import Button from 'components/CustomButtons/ButtonWithLoader.js';

class RegionsAdd extends React.Component {
  state = { place: null, edit: false };

  /* componentWillUpdate(nextProps, nextState, nextContext) {
    if (Object.keys(nextProps.edit).length && !this.state.edit) {
      this.input.value = nextProps.edit.country ;
      this.setState({ edit: true } );
    }
  }; */
  onChange = (place) => {
    this.setState({ place });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const data = this.state.place;
    if (data) {
      const formatted = {
        id: data.place_id,
        [this.props.lang]: formatGeoData(data),
      };
      this.props.onChange(formatted);
      this.setState({ place: null });
      this.input.value = '';
    }
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <Grid container spacing={16}>
          <Grid item xs={8} sm={9} md={10}>
            <GeoAutocomplete
              lang={this.props.lang}
              onChange={this.onChange}
              inputRef={(node) => (this.input = node)}
            />
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <Button
              loading={this.props.loader}
              type="submit"
              color="rose"
              fullWidth
              block
              size="sm"
              disabled={!this.state.place}
            >
              <FormattedMessage id="regions.add.submit" />
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default connect((state) => ({
  loader: state.loader.regionsAddForm,
}))(RegionsAdd);
