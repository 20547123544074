export async function retry(
  func,
  {
    validator,
    defaultValue,
    errorMsg = 'Attempts exceeded',
    times = 7,
    delayAfter = 0,
  }
) {
  while (times > 0) {
    try {
      const result = await func();
      if (typeof validator !== 'function' || validator(result)) {
        return result;
      }
    } finally {
      --times;
      await sleep(delayAfter);
    }
  }
  if (defaultValue) return defaultValue;

  throw new Error(errorMsg);
}

const sleep = async (timeout) =>
  new Promise((resolve) => setTimeout(resolve, timeout));
