import React from 'react';
import styles from '../styles';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withStyles } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from 'components/CustomButtons/ButtonWithLoader';

const UpdateOrderAddons = (props) => {
  const { loader, wizard, classes, isXsDown, selectedTariff, btnSubmitAction } =
    props;

  const isAnyAddonTouched = () => {
    return props.channels.edit.product.configoptions.configoption.some((a) => {
      const [propPath, propName] = a.optionName.split('.');
      return props.dataForm.data[propPath][propName] !== Boolean(a.value);
    });
  };

  return !wizard &&
    selectedTariff.configoptions &&
    selectedTariff.configoptions.configoption.length ? (
    <TableRow>
      <TableCell
        colSpan={3}
        className={classes.right}
        padding="none"
        style={{ borderBottom: 0 }}
      >
        <div style={{ margin: '25px 0 10px' }}>
          <Button
            color="info"
            round
            onClick={btnSubmitAction}
            loading={isAnyAddonTouched() && loader}
            disabled={!isAnyAddonTouched()}
            size={isXsDown ? 'sm' : null}
          >
            <FormattedMessage id="upgrade.configoption" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  ) : null;
};

const mapStateToProps = (state) => ({
  channels: state.channels,
  loader: state.loader.orderFormAdd,
  dataForm: getFormValues('EditChannel')(state),
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(
    withStyles(styles)(UpdateOrderAddons)
  )
);
