export const orderConstant = {
  GET_REQUEST: 'GET_ORDER_REQUEST',
  GET_SUCCESS: 'GET_ORDER_SUCCESS',
  SET_REVIEW: 'SET_ORDER_REVIEW',
  GET_FAILURE: 'GET_ORDER_FAILURE',

  CREATE_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_FAILURE: 'CREATE_ORDER_FAILURE',

  PRE_CALCULATE_SUCCESS: 'PRE_CALCULATE_SUCCESS',
  PRE_CALCULATE_FAILURE: 'PRE_CALCULATE_FAILURE',

  CLEAR_EDIT: 'CLEAR_ORDER',
};
