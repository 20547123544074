import React from 'react';
import { connect } from 'react-redux';
import Lock from '@material-ui/icons/Lock';
import { Field, reduxForm } from 'redux-form';
import Icon from '@material-ui/core/Icon/Icon';
import { passwordValidate } from './validate';
import Button from 'components/CustomButtons/Button.jsx';
import { injectIntl, FormattedMessage } from 'react-intl';
import RenderTextField from 'components/CustomInput/RenderTextField';
import { normalizeAuthForms } from '../../Channels/Form/formsNormalization';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';

let RestoreForm = (props) => {
  const { handleSubmit, classes, intl } = props;

  return (
    <form onSubmit={handleSubmit}>
      <p align="center">
        <FormattedMessage id="restore.password.newPassword.message" />
      </p>
      <Field
        name="password2"
        type="password"
        component={RenderTextField}
        autoFocus
        normalize={(value) => normalizeAuthForms(value)}
        formControlProps={{ fullWidth: true }}
        placeholder={`${intl.formatMessage({ id: 'form.password' })}*`}
        startAdornment={
          <InputAdornment position="start" className={classes.inputAdornment}>
            <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
          </InputAdornment>
        }
      />

      <Field
        name="rePassword"
        type="password"
        component={RenderTextField}
        formControlProps={{ fullWidth: true }}
        normalize={(value) => normalizeAuthForms(value)}
        placeholder={`${intl.formatMessage({ id: 'form.confirmpassword' })}*`}
        startAdornment={
          <InputAdornment position="start" className={classes.inputAdornment}>
            <Lock className={classes.inputAdornmentIcon}>lock_outline</Lock>
          </InputAdornment>
        }
      />
      <div className={classes.center} style={{ marginTop: 20 }}>
        <Button round color="primary" type="submit">
          <FormattedMessage id="form.submit" />
        </Button>
      </div>
    </form>
  );
};

RestoreForm = reduxForm({
  form: 'RestoreNewPasswordForm',
  validate: passwordValidate,
  touchOnBlur: false,
})(RestoreForm);

RestoreForm = injectIntl(
  connect((state) => ({
    initialValues: {
      ...state.authentication.recoveryPassword.recovery,
      type: 'recovery',
    },
  }))(RestoreForm)
);

export default RestoreForm;
