import React from 'react';

import { injectIntl } from 'react-intl';

import ReactTable from 'react-table';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';

function TransferTable(props) {
  const { data, intl, currencies } = props;

  const tableTranslate = {
    previousText: intl.formatMessage({ id: 'layout.table.previousText' }),
    nextText: intl.formatMessage({ id: 'layout.table.nextText' }),
    loadingText: intl.formatMessage({ id: 'layout.table.loadingText' }),
    noDataText: intl.formatMessage({ id: 'layout.table.noDataText' }),
    pageText: intl.formatMessage({ id: 'layout.table.pageText' }),
    ofText: intl.formatMessage({ id: 'layout.table.ofText' }),
    rowsText: intl.formatMessage({ id: 'layout.table.rowsText' }),
  };

  const rowData = data.map((i) => {
    const currency = currencies.filter((c) => c.id === i.currency)[0];
    return {
      code: i.code,
      amount: `${i.amount.toFixed(2)} ${currency ? currency.suffix : ''}`,
      transfer_at: i.transfer_at,
    };
  });

  return (
    <Card>
      <CardBody>
        <ReactTable
          data={rowData}
          {...tableTranslate}
          filterable
          columns={[
            {
              Header: intl.formatMessage({ id: 'transfer.table.code' }),
              accessor: 'code',
              filterable: false,
            },
            {
              Header: intl.formatMessage({ id: 'transfer.table.amount' }),
              accessor: 'amount',
              filterable: false,
            },
            {
              Header: intl.formatMessage({ id: 'transfer.table.transfer_at' }),
              accessor: 'transfer_at',
              filterable: false,
            },
          ]}
          defaultPageSize={10}
          showPaginationBottom
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
}

export default injectIntl(withStyles(extendedTablesStyle)(TransferTable));
