export default (values, props) => {
  const errors = {};

  /* if (!values.username) {
    errors.username = 'Required'
  }else if (values.username.length > 15) {
    errors.username = 'Must be 15 characters or less'
  } */

  const requiredFields = ['username', 'password'];
  requiredFields.forEach((field) => {
    if (!values[field] || !values[field].trim()) {
      errors[field] = props.intl.formatMessage({ id: 'validation.required' });
    }
  });

  if (
    values.username &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username.trim())
  ) {
    errors.username = props.intl.formatMessage({ id: 'validation.email' }); // Invalid email address
  }

  if (values.password && values.password.trim().length < 6) {
    errors.password = props.intl.formatMessage(
      { id: 'validation.password' },
      { count: 6 }
    ); // Password min length 6 charts
  }
  return errors;
};
