const ENV = process.env;
export const baseReactAPIUrl = `${ENV.REACT_APP_PROTOCOL}://${ENV.REACT_APP_API_URL}`;

export const baseReactMACROSCOPUrl = `${ENV.REACT_APP_PROTOCOL}://${ENV.REACT_APP_MACROSCOP_URL}`;

export const baseReactWebsocketUrl = `${ENV.REACT_APP_WEBSOCKET_PROTOCOL}://${ENV.REACT_APP_MACROSCOP_URL}`;

export const winManualsX64Url =
  'https://drive.google.com/file/d/1g6ScQjZHuoVI83xbRkEVnCT6G956Bk3f/view?usp=sharing';

export const winManualsX86Url =
  'https://drive.google.com/file/d/1gb1Q5YEnr-EnfYETAytVpaofbKXPi8-_/view?usp=sharing';
