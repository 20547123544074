import React from 'react';
import { get, merge } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getFormValues } from 'redux-form';
import { getFormData } from '../../../helpers';
import RefillForm from 'containers/Refill/RefillForm';
import withStyles from '@material-ui/core/styles/withStyles';
import { channelsActions, invoiceActions, orderActions } from 'actions';
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  ...customSelectStyle,
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: '',
      productName: '',
      configOptions: [],
      orderReview: {
        totalAmount: '',
        channelid: 'c5d39cd6-c456-491a-a98d-2d0119def19f',
        pid: 2,
        billingcycle: 'monthly',
        addons: '',
        customfields: {
          14: 'c5d39cd6-c456-491a-a98d-2d0119def19f',
        },
        configoptions: {
          2: 2,
          3: 3,
          5: 5,
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      Object.keys(this.props.channels.edit.orderReview).length &&
      this.state.orderReview.totalAmount !==
        prevProps.channels.edit.orderReview.totalAmount
    ) {
      this.setState({ orderReview: this.props.channels.edit.orderReview });
    }
  }

  updateChannel = () => {
    const { dispatch, channels, dataForm } = this.props;
    const params = getFormData(this.props);
    params.data.testMode = true;
    if (get(dataForm, 'data.ArchiveSettings.DeviceArchiveEnabled') === true) {
      merge(params, {
        data: {
          ArchiveSettings: {
            DeviceArchiveAutoSynchronizationEnabled: true,
          },
        },
      });
    }
    if (params.data.Id) {
      dispatch(
        channelsActions.updateChannel(merge(channels.edit, dataForm, params)),
        'update'
      );
    }
  };

  paid = (promocode = '', checkout) => {
    if (checkout) {
      this.updateChannel();
    }
    const modelId = this.props.channels.edit.data.ConnectionSettings.ModelId;
    const data = { ...this.state.orderReview, promocode, checkout, modelId };
    this.props.dispatch(orderActions.addOrder(data));
  };

  startTest = () => {
    const { dispatch } = this.props;
    dispatch(invoiceActions.startTest(this.state.orderReview));
  };

  render() {
    const { user, channels, currentStep } = this.props;
    return (
      currentStep > 1 && (
        <RefillForm
          paid={this.paid}
          userCredit={user.credit}
          startTest={this.startTest}
          updateChannel={this.updateChannel}
          orderReview={this.state.orderReview}
          channelName={channels.edit.data.Name}
        />
      )
    );
  }
}

const mapStateToProps = (state) => {
  const { authentication, invoice, channels, eWallet, orderReview } = state;
  return {
    user: authentication.user,
    invoice,
    channels,
    eWallet,
    orderReview,
    deviceManufactures: state.deviceManufactures,
    dataForm: getFormValues('EditChannel')(state),
    cameraConfigValues: getFormValues('CameraConfigForm')(state),
    videoStreamValues: getFormValues('VideoStreamTestForm')(state),
    deviceSettingsValues: getFormValues('DeviceSettingsForm')(state),
    partnerSelectedValues: getFormValues('PartnerSelectedForm')(state),
  };
};

export default injectIntl(connect(mapStateToProps)(withStyles(style)(Step3)));
