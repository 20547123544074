import React from 'react';
import { FormattedMessage } from 'react-intl';

export const WelcomePlaceholder = () => (
  <div style={{ textAlign: 'center' }}>
    <h2>
      <FormattedMessage id="searchpartner.welcome.title" />
    </h2>
    <p>
      <FormattedMessage id="searchpartner.welcome.description" />
    </p>
  </div>
);

export const AreaToLargePlaceholder = () => (
  <div style={{ textAlign: 'center' }}>
    <h2>
      <FormattedMessage id="searchpartner.areaToLarge.title" />
    </h2>
    <p>
      <FormattedMessage id="searchpartner.areaToLarge.description" />
    </p>
  </div>
);

export const NoResultsPlaceholder = ({ region }) => (
  <div style={{ textAlign: 'center' }}>
    <h2>
      <FormattedMessage id="searchpartner.list.search.empty.title" />
    </h2>
    <p>
      <FormattedMessage id="searchpartner.list.search.empty.current" />:{' '}
      {region}
      <br />
      <FormattedMessage id="searchpartner.list.search.empty.description" />
    </p>
  </div>
);

export const EmptyPlaceholder = () => (
  <div style={{ textAlign: 'center' }}>
    <h2>
      <FormattedMessage id="searchpartner.list.empty" />
    </h2>
  </div>
);
