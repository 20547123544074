import React from 'react';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const CheckboxWithLabelAndError = ({
  input,
  label,
  classes,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={Boolean(touched && error)}>
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          {...input}
          onChange={(event) => input.onChange(event.target.checked)}
          value={String(input.value)}
          checked={input.value}
          {...custom}
        >
          {children}
        </Checkbox>
      }
    />
    {Boolean(touched && error) && (
      <FormHelperText className={classes.checkboxError}>{error}</FormHelperText>
    )}
  </FormControl>
);

export default CheckboxWithLabelAndError;
