export class Canvas {
  constructor(selector = undefined) {
    if (!selector) throw 'No selector';
    const container =
      typeof selector === 'string'
        ? document.querySelector(selector)
        : selector;
    const canvas = document.createElement('canvas');
    container.appendChild(canvas);
    this.ctx = canvas.getContext('2d');
    this.elements = [];
  }

  addElement(...elements) {
    this.elements.push(...elements);
  }

  setSize(size = { width: 100, height: 100 }) {
    this.width = this.ctx.canvas.width = size.width;
    this.height = this.ctx.canvas.height = size.height;
  }

  resize() {
    const { canvas } = this.ctx;
    const { width, height } = canvas.parentElement.getBoundingClientRect();
    this.setSize({ width, height });
  }

  draw() {
    const cw = this.width;
    const ch = this.height;
    this.ctx.clearRect(0, 0, cw, ch);
    this.ctx.fillStyle = '#FFF';
    this.ctx.fillRect(0, 0, cw, ch);
    this.elements.forEach((el) => el.draw(this.ctx));
  }

  render() {
    this.draw();
    requestAnimationFrame(this.render.bind(this));
  }

  init() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
    this.render();
    return this;
  }
}

export default Canvas;
