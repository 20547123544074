import { pushRulesService } from '../services';
import { pushRulesConstants } from '../constants';
import { alertActions } from 'actions/alert.actions';
import { loaderActions } from 'actions/loader.actions';
import { history } from '../helpers';

export const pushRulesActions = {
  getPushRules,
  addPushRule,
  getOnePushRule,
  editPushRule,
  removeRule,
};

function getPushRules() {
  return (dispatch) => {
    dispatch(loaderActions.show('pageLoader'));
    pushRulesService
      .getPushRules()
      .then(
        (response) => {
          dispatch({
            type: pushRulesConstants.GET_PUSH_RULES_SUCCESS,
            data: response.data.data,
          });
        },
        (error) => {
          dispatch(alertActions.error(error.message));
        }
      )
      .finally(() => {
        dispatch(loaderActions.hide('pageLoader'));
      });
  };
}

function getOnePushRule(zoneIndex) {
  return (dispatch) => {
    dispatch(loaderActions.show('pageLoader'));
    return pushRulesService
      .getOnePushRule(zoneIndex)
      .then(
        (response) => {
          dispatch({
            type: pushRulesConstants.GET_ONE_PUSH_RULES_SUCCESS,
            data: response.data.data,
          });
        },
        (error) => {
          dispatch(alertActions.error(error.message));
        }
      )
      .finally(() => {
        dispatch(loaderActions.hide('pageLoader'));
      });
  };
}

function addPushRule(params) {
  return (dispatch) => {
    dispatch(loaderActions.show('pageLoader'));
    pushRulesService
      .addPushRule(params)
      .then(
        (response) => {
          dispatch({
            type: pushRulesConstants.ADD_PUSH_RULES_SUCCESS,
            data: response.data.data,
          });
          history.push('/push');
        },
        (error) => {
          dispatch(alertActions.error(error.message));
        }
      )
      .finally(() => {
        dispatch(loaderActions.hide('pageLoader'));
      });
  };
}

function editPushRule(editIndex, params) {
  return (dispatch) => {
    dispatch(loaderActions.show('pageLoader'));
    pushRulesService
      .editPushRule(editIndex, params)
      .then(
        (response) => {
          dispatch({
            type: pushRulesConstants.EDIT_PUSH_RULES_SUCCESS,
            data: response.data.data,
          });
          history.push('/push');
        },
        (error) => {
          dispatch(alertActions.error(error.message));
        }
      )
      .finally(() => {
        dispatch(loaderActions.hide('pageLoader'));
      });
  };
}

function removeRule(serviceId, index) {
  return (dispatch) => {
    dispatch(loaderActions.show('viewerRemove'));
    pushRulesService
      .removePushRules(serviceId, index)
      .then(
        (response) => {
          dispatch({
            type: pushRulesConstants.REMOVE_PUSH_RULES_SUCCESS,
            data: response.data.data,
          });
        },
        (error) => {
          dispatch(alertActions.error(error.message));
        }
      )
      .finally(() => {
        dispatch(loaderActions.hide('pageLoader'));
      });
  };
}
