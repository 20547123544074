import { history } from 'helpers';
import { connect } from 'react-redux';
import Card from 'components/Card/Card';
import InvoiceTable from './InvoiceTable';
import { NavLink } from 'react-router-dom';
import UnpaidInvoices from './UnpaidInvoices';
import SortingInvoice from './SortingInvoice';
import LoaderAnimation from 'components/Loader';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import GridItem from 'components/Grid/GridItem';
import RemoveAlert from 'components/RemoveAlert';
import React, { Component, Fragment } from 'react';
import CardHeader from 'components/Card/CardHeader';
import Assignment from '@material-ui/icons/Assignment';
import { invoiceActions, loaderActions } from 'actions';
import GridContainer from 'components/Grid/GridContainer';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import { invoiceListStyles, setNavLinkColor } from './utils';

class InvoiceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      nPerPage: 10,
      data: [],
      filter: 'All',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loaderActions.show('pageLoader'));
    dispatch(invoiceActions.getInvoiceItems('all'));
  }

  handleButtonRefillBalance = () => {
    history.push('/refill');
  };

  handleButtonPaidAll = () => {
    history.push('/invoice/paidall');
  };

  handleChange(event) {
    this.setState({ filter: event.target.value });
  }

  handleCancelInvoice(id) {
    this.setState({
      confirm: (confirmation) => {
        if (confirmation) {
          this.cancelInvoice(id);
        }
        this.setState({ confirm: null });
      },
    });
  }

  cancelInvoice(id) {
    const { dispatch } = this.props;
    dispatch(
      invoiceActions.cancelInvoice(id, () => {
        dispatch(loaderActions.show('pageLoader'));
        dispatch(invoiceActions.getInvoiceItems('all'));
      })
    );
  }

  render() {
    const { classes, intl, invoice, currency, loader } = this.props;
    const data = [];

    if (invoice.all.length) {
      const cancelInvoice = (id) => (e) => {
        e.preventDefault();
        this.handleCancelInvoice(id);
      };
      invoice.all.forEach((item) => {
        if (this.state.filter === 'All' || this.state.filter === item.status) {
          item.typeDescription = intl.formatMessage({ id: item.type });
          item.datepaid =
            item.datepaid === '0000-00-00 00:00:00' ? '' : item.datepaid;
          item.amount = `${item.currencyprefix} ${item.total} ${item.currencysuffix}`;
          const href = `invoice/${item.id}`;
          item.statusIntl = (
            <Fragment>
              <NavLink style={setNavLinkColor(item.status)} to={href}>
                {intl.formatMessage({ id: item.status })}
              </NavLink>
              {item.type === 'Upgrade' && item.status === 'Unpaid' && (
                <Fragment>
                  <span> / </span>
                  <a href="#" onClick={cancelInvoice(item.id)}>
                    {intl.formatMessage({
                      id: 'invoice.table.remove.cancel',
                    })}
                  </a>
                </Fragment>
              )}
            </Fragment>
          );
          data.push(item);
        }
      });
    }

    if (loader) return <LoaderAnimation />;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="invoice.history.title" />
                <br />
                <small>
                  <FormattedMessage id="invoice.history.description" />
                </small>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem md={3}>
                  <GridContainer>
                    <GridItem md={12}>
                      <UnpaidInvoices
                        invoiceData={invoice.all}
                        classes={classes}
                        currency={currency}
                        paidAll={this.handleButtonPaidAll}
                        refillBalance={this.handleButtonRefillBalance}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={12}>
                      <SortingInvoice
                        intl={intl}
                        handleChange={this.handleChange}
                        selectedValue={this.state.filter}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem md={9}>
                  <InvoiceTable data={data} />
                  <RemoveAlert
                    confirm={this.state.confirm}
                    title={intl.formatMessage({
                      id: 'invoice.table.remove.title',
                    })}
                    successTitle={intl.formatMessage({
                      id: 'invoice.table.remove.success',
                    })}
                    confirmBtnText={intl.formatMessage({
                      id: 'invoice.table.remove.confirm',
                    })}
                    cancelBtnText={intl.formatMessage({
                      id: 'invoice.table.remove.cancel',
                    })}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const {
    invoice,
    system: { currencies },
    authentication: { user },
  } = state;
  const currency = currencies.find((item) => item.id === user.currency);
  const loader = state.loader.pageLoader;
  return {
    invoice,
    currency,
    loader,
  };
}

export default injectIntl(
  connect(mapStateToProps)(withStyles(invoiceListStyles)(InvoiceHistory))
);
