import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { history } from '../../../helpers';
import { pushRulesConstants } from '../../../constants';

// material-ui components
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AlarmIcon from '@material-ui/icons/AlarmAdd';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import LoaderAnimation from 'components/Loader';

import customSelectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import customStyles from './customStyle';

import withStyles from '@material-ui/core/styles/withStyles';

import { checkAll } from 'helpers/validationFields.js';
import validateConfig from './validate';
import CustomInput from 'components/CustomInput/CustomInput';

import {
  channelsActions,
  loaderActions,
  viewersActions,
  pushRulesActions,
} from 'actions';
import { FormHelperText } from '@material-ui/core';

const pageStyle = { ...customSelectStyles, ...customStyles };

function PushAdd(props) {
  const {
    classes,
    intl,
    channels,
    viewers,
    user,
    loader,
    pushRules: { edit },
    pageLoader,
  } = props;

  useEffect(() => {
    const { dispatch, match } = props;
    dispatch(loaderActions.show('pageLoader'));
    dispatch(channelsActions.getChannels());
    dispatch(viewersActions.getViewers());
    if (match.params && match.params.zoneIndex) {
      dispatch(pushRulesActions.getOnePushRule(match.params.zoneIndex));
    }

    return () => {
      props.dispatch({
        type: pushRulesConstants.CLEAN_PUSH_RULES_EDIT,
        data: [],
      });
    };
  }, []);

  useEffect(() => {
    if (
      props.match.params &&
      props.match.params.zoneIndex &&
      !editMode &&
      dataPushRules !== edit &&
      channels.length
    ) {
      setEditMode(props.match.params.zoneIndex);
      setZones(edit.serviceId);
      setPushRule(edit);
    }
  });

  const [dataPushRules, setPushRule] = useState(edit);
  const [validator, setValidateConfig] = useState(validateConfig);
  const [zones, setZonesSelect] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    const newPushRule = { ...dataPushRules };
    if (typeof newPushRule[name] === 'object') {
      newPushRule[name] = [...value];
    } else {
      newPushRule[name] = String(value);
    }
    setPushRule(newPushRule);
    if (name === 'serviceId') setZones(value);
  };

  const setZones = (value) => {
    const { channel } = channels.find((item) => item.id === value);
    const zones = [
      ...channel.data.AnalyzeSettings.MotionDetectorSettings.Zones,
    ];
    zones.shift();
    setZonesSelect(zones);
  };

  const checkZones = (item) =>
    item.channel.data.AnalyzeSettings.MotionDetectorSettings.Zones.length > 1;

  const handleSubmitButton = (event) => {
    event.preventDefault();
    const validation = checkAll(validator, dataPushRules, intl);
    setValidateConfig(validation);
    // TODO validate
    let hasError = false;
    Object.values(validation).forEach((field) => {
      if (field.error) {
        hasError = true;
      }
    });
    if (!hasError) {
      if (!editMode) {
        props.dispatch(pushRulesActions.addPushRule(dataPushRules));
      } else {
        props.dispatch(pushRulesActions.editPushRule(editMode, dataPushRules));
      }
    }
  };

  const pushRule = { ...dataPushRules };

  if (pageLoader) return <LoaderAnimation />;

  const serviceError = validator.serviceId.errorMessage;
  const zonesError = validator.zoneIds.errorMessage;
  const clientsError = validator.clientIds.errorMessage;

  return (
    <form onSubmit={handleSubmitButton}>
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <AlarmIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            <FormattedMessage
              id={
                editMode
                  ? 'push.receiving.title.edit'
                  : 'push.receiving.title.add'
              }
            />
            <br />
            <small>
              <FormattedMessage
                id={
                  editMode
                    ? 'push.form.description.edit'
                    : 'push.form.description.add'
                }
              />
            </small>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer alignItems="center" justify="center" direction="row">
            <GridItem lg={8}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={5} lg={6}>
                  <FormControl
                    fullWidth
                    className={
                      serviceError
                        ? classes.formError
                        : classes.selectFormControl
                    }
                    error={serviceError}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={
                        serviceError
                          ? classes.selectLabelError
                          : classes.selectLabel
                      }
                    >
                      <FormattedMessage id="push.form.channel" />
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={pushRule.serviceId}
                      onChange={handleChange}
                      inputProps={{
                        name: 'serviceId',
                        id: 'channel-select',
                        // onBlur: handleValidate("serviceId")
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        <FormattedMessage id="push.form.select.channel" />
                      </MenuItem>
                      {channels
                        .filter((item) => item.status === 'Active')
                        .map((item) => (
                          <MenuItem
                            disabled={!checkZones(item)}
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {item.channel.name}
                            {!checkZones(item)
                              ? ` [ ${intl.formatMessage({
                                  id: 'push.form.not.zones',
                                })} ]`
                              : ''}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{serviceError || ''}</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={5} lg={6}>
                  <CustomInput
                    labelText={intl.formatMessage({
                      id: 'push.form.text.notification',
                    })}
                    id="float"
                    name="textNotification"
                    value={pushRule.textNotification}
                    onChange={handleChange}
                    // style={{ paddingTop: "6px" }}
                    // onBlur={handleValidate('textNotification')}
                    error={validator.textNotification.error}
                    helpText={validator.textNotification.errorMessage}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.TextNotification,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={5} lg={6}>
                  <FormControl
                    fullWidth
                    className={
                      zonesError ? classes.formError : classes.selectFormControl
                    }
                    error={zonesError}
                  >
                    <InputLabel
                      htmlFor="users-select"
                      className={
                        zonesError
                          ? classes.selectLabelError
                          : classes.selectLabel
                      }
                    >
                      <FormattedMessage id="push.form.zones" />
                    </InputLabel>
                    <Select
                      multiple
                      value={pushRule.zoneIds || []}
                      onChange={handleChange}
                      MenuProps={{
                        className: classes.selectMenu,
                        classes: { paper: classes.selectPaper },
                      }}
                      classes={{ select: classes.select }}
                      inputProps={{
                        name: 'zoneIds',
                        id: 'multiple-select',
                        disabled: !pushRule.serviceId,
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        <FormattedMessage id="push.form.select.zones" />
                      </MenuItem>
                      {zones.map((item, index) => (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={item.Id}
                        >
                          {item.Name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{zonesError || ''}</FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={5} lg={6}>
                  <FormControl
                    fullWidth
                    className={
                      clientsError
                        ? classes.formError
                        : classes.selectFormControl
                    }
                    error={clientsError}
                  >
                    <InputLabel
                      htmlFor="users-select"
                      className={
                        clientsError
                          ? classes.selectLabelError
                          : classes.selectLabel
                      }
                    >
                      <FormattedMessage id="push.form.users" />
                    </InputLabel>
                    <Select
                      multiple
                      value={pushRule.clientIds || []}
                      onChange={handleChange}
                      MenuProps={{
                        className: classes.selectMenu,
                        classes: { paper: classes.selectPaper },
                      }}
                      classes={{ select: classes.select }}
                      inputProps={{
                        name: 'clientIds',
                        id: 'multiple-select',
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        <FormattedMessage id="push.form.choice.users" />
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple,
                        }}
                        value={user.macroscopUserId}
                      >
                        {user.email}
                      </MenuItem>
                      {viewers.map((item, index) => (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={item.macroscopUserId}
                        >
                          {item.email}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{zonesError || ''}</FormHelperText>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer alignItems="flex-start" justify="flex-end">
                <GridItem>
                  <Button
                    className={classes.PushButtonStyle}
                    type="button"
                    onClick={() => history.push('/push')}
                  >
                    <FormattedMessage id="push.form.cancel" />
                  </Button>
                  <Button
                    className={classes.PushButtonStyle}
                    loading={loader}
                    type="submit"
                    color="rose"
                  >
                    <FormattedMessage
                      id={editMode ? 'push.form.save' : 'push.form.submit'}
                    />
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </form>
  );
}

const mapStateToProps = (state) => ({
  channels: state.channels.all,
  pushRules: state.pushRules,
  viewers: state.viewers.contacts,
  user: state.authentication.user,
  loader: state.loader.pushAddForm,
  pageLoader: state.loader.pageLoader,
});

export default injectIntl(
  connect(mapStateToProps)(withStyles(pageStyle)(PushAdd))
);
