import {
  portFix,
  fillPorts,
  isTestPortBtn,
  getBrandNameByModelId,
} from 'helpers';
import {
  reset,
  isValid,
  reduxForm,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form';
import validate from '../validate';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { channelsActions } from 'actions';
import { get, merge, isEqual } from 'lodash';
import { getPortsFields } from './getPortsFields';
import DevicePortsModal from '../devicePortsModal';
import CameraConfigComponent from './CameraConfigComponent';

class CameraConfigForm extends Component {
  state = {
    submitted: false,
    portsModal: false,
    btnModalOpend: false,
  };

  constructor(props) {
    super(props);
    props.innerRef(this);
  }

  componentDidUpdate(prevProps, prevState) {
    this.handlePortsAfterUpdate(prevProps);
    this.handleAdditionalPortsUpdate(prevProps, prevState);
    this.handlePortsTestUpdate(prevProps, prevState);
  }

  componentDidMount = () => {
    const { wizard, change, editChannel, deviceManufactures } = this.props;
    if (!wizard && Object.keys(deviceManufactures).length) {
      const modelId = get(editChannel, 'data.ConnectionSettings.ModelId');
      change('Brand', getBrandNameByModelId(modelId, deviceManufactures));
    }
  };

  componentWillUnmount = () => {
    this.props.dispatch(channelsActions.clear());
    this.props.dispatch(reset('CameraConfigForm'));
  };

  handlePortsAfterUpdate(prevProps) {
    const { change, cameraConfigValues } = this.props;
    if (
      get(cameraConfigValues, 'data.ConnectionSettings.Hostname') !==
      get(prevProps.cameraConfigValues, 'data.ConnectionSettings.Hostname')
    ) {
      const hostName = get(
        cameraConfigValues,
        'data.ConnectionSettings.Hostname'
      );
      if (hostName) {
        change('data.ConnectionSettings.Hostname', hostName.split(':')[0]);
      }
    }
  }

  testPort = () => {
    const { dispatch, deviceManufactures, cameraConfigValues, change, touch } =
      this.props;
    const hostname = get(
      cameraConfigValues,
      'data.ConnectionSettings.Hostname'
    );
    if (!hostname) {
      touch('data.ConnectionSettings.Hostname');
      return;
    }
    const portsToTest = fillPorts(
      deviceManufactures,
      cameraConfigValues,
      change
    );
    dispatch(channelsActions.reachablePort(hostname.trim(), portsToTest));
    this.setState({ btnModalOpend: false });
  };

  onSubmit = () => {
    this.testPort();
    this.setState({ submitted: true });
  };

  portsModalOpen = () => this.setState({ portsModal: true });

  portsModalClose = () => this.setState({ portsModal: false });

  handleBrandAndModelUpdate = (prevProps) => {
    const { cameraConfigValues, change, deviceManufactures } = this.props;
    if (Object.keys(deviceManufactures).length) {
      if (
        !isEqual(cameraConfigValues, prevProps.cameraConfigValues) ||
        !get(cameraConfigValues, 'Brand')
      ) {
        const modelId = get(
          cameraConfigValues,
          'data.ConnectionSettings.ModelId'
        );
        if (modelId && !get(cameraConfigValues, 'Brand')) {
          change('Brand', getBrandNameByModelId(modelId, deviceManufactures));
        }
      }
    }
  };

  handleAdditionalPortsUpdate = (prevProps, prevState) => {
    if (
      this.state.btnModalOpend !== prevState.btnModalOpend &&
      Object.values(this.props.portReachable)[0] ===
        Object.values(prevProps.portReachable)[0] &&
      !get(this.props.formError, 'isPortsTested')
    ) {
      this.setState({ portsModal: false, btnModalOpend: true });
    }
    if (
      !this.state.btnModalOpend &&
      Object.values(this.props.portReachable)[0] &&
      Object.values(this.props.portReachable)[0] !==
        Object.values(prevProps.portReachable)[0] &&
      !get(this.props.formError, 'isPortsTested')
    ) {
      this.setState({ portsModal: false, btnModalOpend: true });
    }
  };

  handlePortsTestUpdate = (prevProps, prevState) => {
    if (
      prevState.submitted &&
      !isEqual(this.props.portReachable, prevProps.portReachable)
    ) {
      this.props.change('isPortsTested', this.props.portReachable);
      this.props.handleSubmit(() => {})();
      this.setState({ submitted: false });
    }
  };

  isValid = () => {
    this.props.handleSubmit(() => {})();
    return this.props.formValid;
  };

  handleTestPortsBtnDisabled = () => {
    return (
      get(this.props.cameraConfigErrors, 'customParam.Port') ||
      get(
        this.props.cameraConfigErrors,
        'data.ConnectionSettings.ExternalNetworkPorts[1].PortValue'
      )
    );
  };

  handleAdditionalPortBtnDisabled = () => {
    return (
      get(this.props.cameraConfigErrors, 'isPortsTested') &&
      !get(this.props.cameraConfigErrors, 'customParam.Port')
    );
  };

  render() {
    const {
      intl,
      change,
      handleSubmit,
      portReachable,
      cameraConfigValues,
      deviceManufactures,
      portReachableLoader,
    } = this.props;
    const isPortTested = isTestPortBtn(cameraConfigValues);
    const portsFields = getPortsFields(
      intl,
      deviceManufactures,
      portReachable,
      cameraConfigValues
    );
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Grid container spacing={32}>
            <CameraConfigComponent
              change={change}
              onSubmit={this.onSubmit}
              portsModalOpen={this.portsModalOpen}
              handleTestPortsBtnDisabled={this.handleTestPortsBtnDisabled}
            />
            <DevicePortsModal
              openState={this.state.portsModal}
              close={this.portsModalClose}
              ports={portsFields}
              testPorts={this.testPort}
              isTestPortBtn={isPortTested}
              loader={portReachableLoader}
              handleTestPortsBtnDisabled={this.handleAdditionalPortBtnDisabled}
            />
          </Grid>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  editChannel: state.channels.edit,
  portReachable: state.channels.portReachable,
  deviceManufactures: state.deviceManufactures,
  formValid: isValid('CameraConfigForm')(state),
  portReachableLoader: state.loader.channelsTestPort,
  cameraConfigValues: getFormValues('CameraConfigForm')(state),
  cameraConfigErrors: getFormSyncErrors('CameraConfigForm')(state),
  initialValues: merge(
    {},
    state.channels.edit,
    getFormValues('EditChannel')(state)
  ),
});

export default injectIntl(
  connect((state) => mapStateToProps(portFix(state)))(
    reduxForm({
      validate,
      form: 'CameraConfigForm',
      enableReinitialize: true,
    })(CameraConfigForm)
  )
);
