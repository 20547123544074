import React from 'react';

import { injectIntl } from 'react-intl';

import ReactTable from 'react-table';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button.jsx';
import Close from '@material-ui/icons/Close';

import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';

function TransferTable(props) {
  const { data, intl, classes, remove, currencies } = props;

  const tableTranslate = {
    previousText: intl.formatMessage({ id: 'layout.table.previousText' }),
    nextText: intl.formatMessage({ id: 'layout.table.nextText' }),
    loadingText: intl.formatMessage({ id: 'layout.table.loadingText' }),
    noDataText: intl.formatMessage({ id: 'layout.table.noDataText' }),
    pageText: intl.formatMessage({ id: 'layout.table.pageText' }),
    ofText: intl.formatMessage({ id: 'layout.table.ofText' }),
    rowsText: intl.formatMessage({ id: 'layout.table.rowsText' }),
  };

  const rowsData = data.map((i) => {
    const currency = currencies.filter((c) => c.id === i.currency)[0];
    const row = {
      code: i.code,
      email_to: i.email_to,
      amount: `${i.amount.toFixed(2)} ${currency ? currency.suffix : ''}`,
      created_at: i.created_at,
      transfer_at: i.transfer_at,
      status: intl.formatMessage({ id: `transfer.${i.status}` }),
      actions:
        i.status === 'new' ? (
          <div align="right">
            <Button
              color="danger"
              className={classes.actionButton}
              onClick={() => remove(i.id)}
            >
              <Close className={classes.icon} />
            </Button>
          </div>
        ) : null,
    };

    return row;
  });

  return (
    <Card>
      <CardBody>
        <ReactTable
          data={rowsData}
          {...tableTranslate}
          filterable
          columns={[
            {
              Header: intl.formatMessage({ id: 'transfer.table.code' }),
              accessor: 'code',
              filterable: false,
            },
            {
              Header: intl.formatMessage({ id: 'transfer.table.email_to' }),
              accessor: 'email_to',
              filterable: false,
            },
            {
              Header: intl.formatMessage({ id: 'transfer.table.amount' }),
              accessor: 'amount',
              filterable: false,
            },
            {
              Header: intl.formatMessage({ id: 'transfer.table.create_at' }),
              accessor: 'created_at',
              filterable: false,
            },
            {
              Header: intl.formatMessage({ id: 'transfer.table.transfer_at' }),
              accessor: 'transfer_at',
              filterable: false,
            },
            {
              Header: intl.formatMessage({ id: 'transfer.table.status' }),
              accessor: 'status',
              sortable: false,
              filterable: false,
            },
            {
              Header: intl.formatMessage({ id: 'regions.table.actions' }),
              accessor: 'actions',
              sortable: false,
              filterable: false,
            },
          ]}
          defaultPageSize={10}
          showPaginationBottom
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
}

export default injectIntl(withStyles(extendedTablesStyle)(TransferTable));
