import { transferService } from 'services';
import { transferConstants } from 'constants/transfer.constants';
import { alertActions } from 'actions/alert.actions';
import { reset } from 'redux-form';
import { userActions } from 'actions/user.actions';

export const transferActions = {
  getTransfers,
  addTransfer,
  deleteTransfer,
  approveTransfer,
  getTransfersAbonent,
};

function getTransfers() {
  return (dispatch) => {
    transferService.getTransfers().then(
      ({ data }) => {
        dispatch({ type: transferConstants.GET_SUCCESS, data: data.data });
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function failure(error) {
    return { type: transferConstants.GET_FAILURE, error };
  }
}

function addTransfer(params) {
  return (dispatch) => {
    dispatch({ type: 'SHOW_LOADER', place: 'addTransfer' });
    transferService.addTransfer(params).then(
      ({ data }) => {
        dispatch({ type: 'HIDE_LOADER', place: 'addTransfer' });
        dispatch(reset('addTransfer')); // clear form auth
        dispatch({ type: transferConstants.ADD_SUCCESS, data: data.data });
      },
      (error) => {
        dispatch({ type: 'HIDE_LOADER', place: 'addTransfer' });
        dispatch(failure(error.message));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function failure(error) {
    return { type: transferConstants.ADD_FAILURE, error };
  }
}

function deleteTransfer(id) {
  return (dispatch) => {
    transferService.deleteTransfer(id).then(
      () => {
        dispatch({ type: transferConstants.DELETE_SUCCESS, data: { id } });
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function failure(error) {
    return { type: transferConstants.DELETE_FAILURE, error };
  }
}

function approveTransfer(code) {
  return (dispatch) => {
    dispatch({ type: 'SHOW_LOADER', place: 'getTransfer' });
    transferService.approveTransfer(code).then(
      ({ data }) => {
        dispatch({ type: 'HIDE_LOADER', place: 'getTransfer' });
        dispatch(reset('getTransfer')); // clear form auth
        dispatch(userActions.getBalance());
        dispatch({ type: transferConstants.APPROVE_SUCCESS, data: data.data });
      },
      (error) => {
        dispatch({ type: 'HIDE_LOADER', place: 'getTransfer' });
        dispatch(alertActions.error(error.message));
      }
    );
  };
}

function getTransfersAbonent() {
  return (dispatch) => {
    transferService.getTransfersAbonent().then(
      ({ data }) => {
        dispatch({ type: transferConstants.APPROVE_SUCCESS, data: data.data });
      },
      (error) => {
        dispatch(alertActions.error(error.message));
      }
    );
  };
}
