import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// import SyntaxHighlighter from 'react-syntax-highlighter/prism';
// import { prism } from 'react-syntax-highlighter/styles/prism';
// @material-ui/icons
// core components
import Snackbars from 'components/Snackbar/Snackbar.jsx';
import { alertActions } from '../../actions';

class Messenger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bc: false,
    };
  }

  componentWillUpdate(nextProps) {
    if (nextProps.alert.message && nextProps !== this.props)
      this.showNotification('bc');
  }

  componentWillUnmount() {}

  showNotification(place) {
    const { dispatch } = this.props;
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      // use this to make the notification autoclose
      setTimeout(
        function () {
          x[place] = false;
          this.setState(x);
          dispatch(alertActions.clear());
        }.bind(this),
        6000
      );
    }
  }

  render() {
    const { alert, intl } = this.props;
    return (
      <div>
        {/* <Button
          color="primary"
          onClick={() => this.showNotification("bc")}>
          Top Left
        </Button> */}
        <Snackbars
          place="tc"
          color={alert.color}
          // icon={AddAlert}
          message={
            alert.message ? intl.formatMessage({ id: alert.message }) : ''
          }
          open={this.state.bc}
          closeNotification={() => this.setState({ bc: false })}
          close
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

export default injectIntl(connect(mapStateToProps)(Messenger));
