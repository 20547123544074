const emailRegexp = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
const phoneRegexp = new RegExp(/^\+?\d[\d()\s-]{4,14}\d$/);

const profileValidate = (values, { intl }) => {
  const errors = {};

  const requiredFields = ['firstname', 'country', 'email'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = intl.formatMessage({ id: 'validation.required' });
    }
  });

  const nameFields = ['firstname', 'lastname'];
  nameFields.forEach((field) => {
    if (values[field] && values[field].trim().length > 30) {
      errors[field] = intl.formatMessage(
        {
          id: `validation.${field}`,
        },
        { count: 30 }
      );
    }
  });

  if (values.email && !emailRegexp.test(values.email.trim())) {
    errors.email = intl.formatMessage({
      id: 'validation.email',
    });
  }

  if (values.phone && !phoneRegexp.test(values.phone)) {
    errors.phone = intl.formatMessage({ id: 'validation.phone' });
  }

  if (values.avatar && values.avatar.size > 200000) {
    errors.avatar = intl.formatMessage({
      id: 'profile.form.partnerdata.form.validation.avatar',
    });
  }

  return errors;
};

const passwordValidate = (values, { intl }) => {
  const errors = {};
  const len = { min: 6, max: 128 };
  const requiredFields = ['password', 'rePassword', 'password2'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = intl.formatMessage({ id: 'validation.required' });
    }
    if (
      values[field] &&
      (values[field].trim().length < len.min ||
        values[field].trim().length > len.max)
    ) {
      errors[field] = intl.formatMessage({ id: 'validation.password2' }, len);
    }
  });
  if (values.password2 !== values.rePassword) {
    errors.rePassword = intl.formatMessage({
      id: 'validation.confirmpassword',
    });
  }
  return errors;
};
const deleteValidate = (values, { intl }) => {
  const errors = {};
  const len = { min: 6, max: 128 };
  const requiredFields = ['agreeToDelete', 'agreeToBalanceLoss'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = intl.formatMessage({ id: 'validation.agreeToTerms' });
    }
  });
  if (!values.password) {
    errors.password = intl.formatMessage({ id: 'validation.required' });
  }
  if (
    values.password &&
    (values.password.trim().length < len.min ||
      values.password.trim().length > len.max)
  ) {
    errors.password = intl.formatMessage({ id: 'validation.password2' }, len);
  }
  return errors;
};

export { profileValidate, passwordValidate, deleteValidate };
