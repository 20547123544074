import React, { Fragment } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from '@material-ui/core/styles/withStyles';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

class RemoveAlert extends React.Component {
  state = { success: false };

  showSuccess = () => this.setState({ success: true });

  hideSuccess = () => this.setState({ success: false });

  onConfirm = () => {
    this.props.confirm(true);
    this.showSuccess();
  };

  render = () => {
    const {
      classes,
      confirm,
      title,
      description,
      successTitle,
      confirmBtnText,
      cancelBtnText,
    } = this.props;
    return (
      <Fragment>
        <SweetAlert
          warning
          show={Boolean(confirm)}
          title={title}
          onConfirm={this.onConfirm}
          onCancel={() => confirm(false)}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
          cancelBtnCssClass={`${classes.button} ${classes.danger}`}
          confirmBtnText={confirmBtnText}
          cancelBtnText={cancelBtnText}
          showCancel
        >
          {description}
        </SweetAlert>
        <SweetAlert
          show={this.state.success}
          success
          title={successTitle}
          onConfirm={this.hideSuccess}
          onCancel={this.hideSuccess}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        />
      </Fragment>
    );
  };
}

export default withStyles(sweetAlertStyle)(RemoveAlert);
