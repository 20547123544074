import chunk from 'lodash/chunk';

export const string2Bin = (str) => str.split('').map((i) => i.charCodeAt(0));
export const bin2String = (array) =>
  array.map((byte) => String.fromCharCode(parseInt(byte, 2))).join('');

const fillZero = (str, length) => {
  if (str.length >= length) return str;
  return (
    Array.from({ length: length - str.length })
      .fill(0)
      .join('') + str
  );
};

export const getMapFromString = (str) => {
  const parsed = string2Bin(atob(str));
  const formatted = parsed.map((i) => fillZero(i.toString(2), 8));
  return chunk(formatted, 5).map((row) =>
    row.reduce((arr, el) => {
      return [...arr, ...el.split('').reverse()];
    }, [])
  );
};

export const getStringFromMap = (map) =>
  btoa(bin2String(chunk(map.flat(), 8).map((i) => i.reverse().join(''))));
