import axios from 'axios';

export const transferService = {
  getTransfers,
  addTransfer,
  deleteTransfer,
  approveTransfer,
  getTransfersAbonent,
};

/**
 * Get all transfers
 * @returns {AxiosPromise<any>}
 */
function getTransfers() {
  return axios.get(`/transfer`);
}

/**
 * Add transfer
 * @returns {AxiosPromise<any>}
 */
function addTransfer(params) {
  return axios.post(`/transfer`, params);
}

/**
 * Delete transfer
 * @returns {AxiosPromise<any>}
 */
function deleteTransfer(id) {
  return axios.delete(`/transfer/${id}`);
}

/**
 * Approve transfer
 * @returns {AxiosPromise<any>}
 */
function approveTransfer(data) {
  return axios.put(`/transfer/`, data);
}

/**
 * Approve transfer
 * @returns {AxiosPromise<any>}
 */
function getTransfersAbonent() {
  return axios.get(`/transfer/abonent`);
}
