import React from 'react';
import { styles } from './styles';
import { injectIntl } from 'react-intl';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import withWidth from '@material-ui/core/withWidth';
import { appStoreUrl, winManuals } from './manuals';
import withStyles from '@material-ui/core/styles/withStyles';

const Manual = (props) => {
  const {
    installationSection,
    setupCameraSection,
    utilizationCameraSection,
    settingsCameraSection,
    other,
  } = props.images;
  const { headers, boldSections, paragraphs } = props;
  return (
    <GridContainer
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <GridItem xs={12} md={8} style={{ fontSize: '1.2em' }}>
        <GridItem style={{ marginTop: '1rem' }}>
          <p style={{ marginBottom: '1rem' }}>{paragraphs[0]}</p>
          <p>
            <b>{boldSections[0]} </b>
            {paragraphs[1]}
            <b>{boldSections[1]} </b>
            {paragraphs[2]} <b> {boldSections[2]} </b>
            {paragraphs[3]}
          </p>
        </GridItem>
        <GridItem>
          <h2 className={props.classes.h2FontWeight}>{headers[0]}</h2>
          <div
            className={props.classes.appStoreIcon}
            onClick={winManuals(appStoreUrl)}
          />
          <p>{paragraphs[4]}</p>
          <GridItem
            container
            justify="center"
            alignItems="center"
            style={{ margin: '20px' }}
          >
            <img
              className={props.classes.imageStyles}
              src={installationSection.installation}
            />
          </GridItem>
          <p>{paragraphs[5]}</p>
        </GridItem>
        <GridItem>
          <h2 className={props.classes.h2FontWeight}>{headers[1]}</h2>
          <p>{paragraphs[6]}</p>
          <ul>
            <li>
              <p>{paragraphs[7]}</p>
              <GridItem
                container
                justify="center"
                alignItems="center"
                style={{ margin: '20px' }}
              >
                <img
                  className={props.classes.imageStyles}
                  src={setupCameraSection.firstSetup}
                />
              </GridItem>
            </li>
            <li>
              <p>{paragraphs[8]}</p>
              <GridItem
                container
                justify="center"
                alignItems="center"
                style={{ margin: '20px' }}
              >
                <img
                  className={props.classes.imageStyles}
                  src={setupCameraSection.secondSetup}
                />
              </GridItem>
            </li>
            <li>
              <p>{paragraphs[9]}</p>
              <GridItem
                container
                justify="center"
                alignItems="center"
                style={{ margin: '20px' }}
              >
                <img
                  className={props.classes.imageStyles}
                  src={setupCameraSection.thirdSetup}
                />
              </GridItem>
              <GridItem
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
                style={{ margin: '20px' }}
              >
                <p>
                  <b>{boldSections[3]}</b> IPTeamCloud
                </p>
                <p>
                  <b>{boldSections[4]}</b> gw.ipteamcloud.com
                </p>
                <p>
                  <b>{boldSections[5]}</b> {paragraphs[10]}
                </p>
                <p>{paragraphs[11]}</p>
                <GridItem
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <p>
                    <b>{boldSections[6]}</b> {paragraphs[12]}
                  </p>
                  <p>
                    <b>{boldSections[7]}</b> {paragraphs[13]}
                  </p>
                  <p>
                    <b>{boldSections[8]}</b> {paragraphs[14]}
                  </p>
                </GridItem>
                <p>
                  <b>{boldSections[9]}</b> {paragraphs[15]}
                </p>
                <p>
                  <b>{boldSections[10]}</b> {paragraphs[16]}
                </p>
                <GridItem
                  container
                  justify="center"
                  alignItems="center"
                  className={props.classes.savingMargin}
                >
                  <p>
                    {paragraphs[17]} <b>{boldSections[11]}</b>
                  </p>
                </GridItem>
                <p>{paragraphs[18]}</p>
                <GridItem container justify="center" alignItems="center">
                  <img
                    className={props.classes.imageStyles}
                    src={setupCameraSection.fourthSetup}
                  />
                </GridItem>
              </GridItem>
            </li>
          </ul>
        </GridItem>
        <GridItem>
          <h2 className={props.classes.h2FontWeight}>{headers[2]}</h2>
          <div>
            <p>{paragraphs[19]}</p>
            <GridItem
              container
              justify="center"
              alignItems="center"
              style={{ margin: '20px' }}
            >
              <img
                className={props.classes.imageStyles}
                src={utilizationCameraSection.use1Manual}
              />
            </GridItem>
          </div>
          <div>
            <p>{paragraphs[20]}</p>
            <GridItem
              container
              justify="center"
              alignItems="center"
              style={{ margin: '20px' }}
            >
              <img
                className={props.classes.imageStyles}
                src={utilizationCameraSection.use2Manual}
              />
            </GridItem>
          </div>
          <div>
            <p>{paragraphs[21]}</p>
            <GridItem
              container
              justify="center"
              alignItems="center"
              style={{ margin: '20px' }}
            >
              <img
                className={props.classes.imageStyles}
                src={utilizationCameraSection.use3Manual}
              />
            </GridItem>
          </div>
          <div>
            <p>{paragraphs[22]}</p>
            <GridItem
              container
              justify="center"
              alignItems="center"
              style={{ margin: '20px' }}
            >
              <img
                className={props.classes.verticalImageStyles}
                src={utilizationCameraSection.use4Manual}
              />
            </GridItem>
          </div>
          <div>
            <p>
              {paragraphs[23]}
              <img className={props.classes.icons} src={other.qualitySwitch} />:
            </p>
            <GridItem
              container
              justify="center"
              alignItems="center"
              style={{ margin: '20px' }}
            >
              <img
                className={props.classes.verticalImageStyles}
                src={utilizationCameraSection.use5Manual}
              />
            </GridItem>
          </div>
          <p>{paragraphs[24]}</p>
          <div>
            <p>{paragraphs[25]}</p>
            <GridItem
              container
              justify="center"
              alignItems="center"
              style={{ margin: '20px' }}
            >
              <img
                className={props.classes.imageStyles}
                src={utilizationCameraSection.use6Manual}
              />
            </GridItem>
          </div>
          <div>
            <p>
              {paragraphs[26]}
              <img className={props.classes.icons} src={other.playbacks} />
              {paragraphs[27]}
            </p>
            <GridItem
              container
              justify="center"
              alignItems="center"
              style={{ margin: '20px' }}
            >
              <img
                className={props.classes.imageStyles}
                src={utilizationCameraSection.use7Manual}
              />
            </GridItem>
          </div>
          <div>
            <p>
              {paragraphs[28]}
              <img className={props.classes.icons} src={other.notifications} />
              {paragraphs[29]}
            </p>
            <GridItem
              container
              justify="center"
              alignItems="center"
              style={{ margin: '20px' }}
            >
              <img
                className={props.classes.imageStyles}
                src={utilizationCameraSection.use8Manual}
              />
            </GridItem>
          </div>
        </GridItem>
        <GridItem>
          <h2 className={props.classes.h2FontWeight}>{headers[3]}</h2>
          <p>
            {paragraphs[30]}
            <img
              className={props.classes.icons}
              src={settingsCameraSection.settingsManual}
            />
            {paragraphs[31]}
          </p>
          <GridItem
            container
            justify="center"
            alignItems="center"
            style={{ margin: '20px' }}
          >
            <img
              className={props.classes.imageStyles}
              src={settingsCameraSection.settings1Manual}
            />
          </GridItem>
          <p>{paragraphs[32]}</p>
          <GridItem container justify="center" alignItems="center">
            <img
              className={props.classes.imageStyles}
              src={settingsCameraSection.settings2Manual}
            />
          </GridItem>
          <p>{paragraphs[33]}</p>
          <GridItem
            container
            justify="space-around"
            alignItems="center"
            style={{ margin: '20px' }}
          >
            <GridItem style={{ marginBottom: '20px' }}>
              <img
                className={props.classes.imageStyles}
                src={settingsCameraSection.settings6Manual}
              />
            </GridItem>
            <GridItem style={{ marginBottom: '20px' }}>
              <img
                className={props.classes.imageStyles}
                src={settingsCameraSection.settings3Manual}
              />
            </GridItem>
            <GridItem style={{ marginBottom: '20px' }}>
              <img
                className={props.classes.imageStyles}
                src={settingsCameraSection.settings4Manual}
              />
            </GridItem>
            <GridItem style={{ marginBottom: '20px' }}>
              <img
                className={props.classes.imageStyles}
                src={settingsCameraSection.settings5Manual}
              />
            </GridItem>
          </GridItem>
          <p>{paragraphs[34]}</p>
          <GridItem
            container
            justify="space-evenly"
            alignItems="center"
            style={{ margin: '20px' }}
          >
            <img
              className={props.classes.imageStyles}
              src={settingsCameraSection.settings7Manual}
            />
            <img
              className={props.classes.imageStyles}
              src={settingsCameraSection.settings8Manual}
            />
          </GridItem>
        </GridItem>
      </GridItem>
    </GridContainer>
  );
};

export default injectIntl(withWidth()(withStyles(styles)(Manual)));
