// Array.from({ length: 10 }).map(() => {
//   const channels = Array.from({
//     length: faker.random.number({ min: 1, max: 5 })
//   }).map(() => ({
//     channelid: faker.random.uuid(),
//     Name: faker.random.word(),
//     ModelId: modelIds[faker.random.number({ min: 0, max: modelIds.length - 1 })],
//     date: faker.date.past().getTime(),
//     approved: faker.random.boolean()
//   }));
//   return {
//     id: faker.random.uuid(),
//     fullname: faker.name.findName(),
//     profilephoto: faker.random.boolean() ? faker.image.avatar() : null,
//     channels,
//   };
// });

const testData = [
  {
    id: '7efdfe67-fa6f-4fa4-81ac-a78432e4da17',
    fullname: 'Жанна Блинова',
    profilephoto: null,
    channels: [
      {
        channelid: '278217bd-e122-493b-b2e1-32a3758ad50d',
        Name: 'красота',
        ModelId: '6ad9a618-faf4-4e63-b55c-b03cdc1751dd',
        date: 1551292447669,
        approved: true,
      },
      {
        channelid: 'd2c129aa-5b82-428f-af91-bd579402cecf',
        Name: 'back-end',
        ModelId: 'ea6ba82f-fbc1-4e8f-8cca-05b92f79e83b',
        date: 1524702140524,
        approved: true,
      },
      {
        channelid: '86b2bcad-cb05-48f2-851f-597120f5a3a8',
        Name: 'withdrawal',
        ModelId: 'd40c4d87-650a-4fc8-b980-c14296e52cbe',
        date: 1550476843421,
        approved: true,
      },
    ],
  },
  {
    id: '5acfd3a6-efe3-440c-9a9b-69e12d3fadc6',
    fullname: 'Александра Коновалова',
    profilephoto: null,
    channels: [
      {
        channelid: 'f5403db7-bdb9-4832-a026-3f33cd3b9405',
        Name: 'encryption',
        ModelId: 'aed2a65e-70e2-4e88-8f42-7e95bf8a4298',
        date: 1548113936854,
        approved: true,
      },
      {
        channelid: '806c0852-bde6-4d83-abec-c5ce58ebc391',
        Name: 'Communications',
        ModelId: 'db3673c2-76b9-4260-a950-e211a03a02ab',
        date: 1532297221544,
        approved: true,
      },
    ],
  },
  {
    id: 'e3488db3-29f7-4577-bb1e-ff3b9887aad3',
    fullname: 'Жанна Савельева',
    profilephoto: null,
    channels: [
      {
        channelid: 'b81f601e-23fb-42ea-af76-d327fb40e34f',
        Name: 'Курганская область',
        ModelId: '286bcb7f-6a7f-4d60-a91e-54e766bb14f5',
        date: 1550208458094,
        approved: true,
      },
      {
        channelid: '73fda88f-ac13-40db-8f96-ad86cf8fcd63',
        Name: 'Administrator',
        ModelId: 'bf05b186-f808-429c-aba7-d52cd454db9d',
        date: 1541304671772,
        approved: true,
      },
      {
        channelid: 'efe98609-f662-4c5a-8fbb-babff6b23481',
        Name: 'Executive',
        ModelId: '80e21fa5-5e33-408e-8db7-b83bd2491eed',
        date: 1552495173086,
        approved: true,
      },
      {
        channelid: '5fee3abe-3eb5-4d3d-9356-14645a57317f',
        Name: 'Focused',
        ModelId: '37d98740-d9d4-4dcc-92d3-53bd70be4a8a',
        date: 1546188832916,
        approved: true,
      },
    ],
  },
  {
    id: '81d916e5-4f80-45bd-b329-f47837f03f59',
    fullname: 'Алевтина Савина',
    profilephoto:
      'https://s3.amazonaws.com/uifaces/faces/twitter/anjhero/128.jpg',
    channels: [
      {
        channelid: 'd85d9696-fe32-4f0f-9054-6b249319684d',
        Name: 'Visionary',
        ModelId: 'd7c56525-fefb-4e10-8f95-ab13252794a2',
        date: 1534095044825,
        approved: true,
      },
      {
        channelid: '613e02cc-79b7-4516-9a9d-e3f5682b9e8e',
        Name: 'Чеченская Республика',
        ModelId: '9ff1e649-b244-4acf-a712-65da0a7b91d1',
        date: 1539496055807,
        approved: true,
      },
      {
        channelid: 'bfcf834a-e0a9-4721-9957-5f6e9824d947',
        Name: 'neural',
        ModelId: '74930e46-7a9b-4e32-b1f8-2ba8c3ea7d7f',
        date: 1539264365769,
        approved: true,
      },
      {
        channelid: '52165c7d-3af9-4469-937b-bea4a9cce261',
        Name: 'quantify',
        ModelId: '47b2c08d-6d65-4302-a882-58d18720701d',
        date: 1523830111152,
        approved: true,
      },
    ],
  },
  {
    id: 'dfd59c60-97bd-4bbf-8ced-11f223a12977',
    fullname: 'Екатерина Соловьева',
    profilephoto:
      'https://s3.amazonaws.com/uifaces/faces/twitter/ryuchi311/128.jpg',
    channels: [
      {
        channelid: '1e92f9b4-aca7-428b-8fd9-2bca339cc39a',
        Name: 'Ботинок',
        ModelId: '8ea78648-c0b8-4a23-a1d0-35915e651fca',
        date: 1550038451648,
        approved: true,
      },
      {
        channelid: 'a68d6ff4-5c21-4829-9f1b-c003c045a5b7',
        Name: 'Литва',
        ModelId: '2a559d7d-948b-43a2-8a69-b3e093f45990',
        date: 1535361573053,
        approved: true,
      },
      {
        channelid: 'a0013749-7877-477a-a332-b0033399c8ba',
        Name: 'Стальной',
        ModelId: '04808d1e-8a38-46fe-886b-5e556628b390',
        date: 1534410131232,
        approved: true,
      },
    ],
  },
  {
    id: 'd668a12c-7c17-4324-a0c9-2754108d54f4',
    fullname: 'Екатерина Зайцева',
    profilephoto: null,
    channels: [
      {
        channelid: '4678708f-2f88-4872-aa72-846fcc71a73e',
        Name: 'primary',
        ModelId: '6ad9a618-faf4-4e63-b55c-b03cdc1751dd',
        date: 1539284230259,
        approved: true,
      },
      {
        channelid: '8c8c7537-af26-4472-ac73-0f2454cee1b9',
        Name: '24/7',
        ModelId: 'e47ff3f9-f220-4a16-bce4-637a1f4cf8ac',
        date: 1551304098044,
        approved: true,
      },
      {
        channelid: '25f05ba7-573c-4054-a0b8-4f7dfe17d826',
        Name: 'extranet',
        ModelId: 'f149b57c-424e-4490-a4ec-d645d52b646c',
        date: 1549294228910,
        approved: true,
      },
      {
        channelid: '85f8314f-ad10-4117-b934-dcebf2cc6675',
        Name: 'Executive',
        ModelId: 'fc1fc3ac-8808-4f7c-92fd-f46a9f9fbb5f',
        date: 1552979866431,
        approved: true,
      },
      {
        channelid: 'dd1a10a3-7834-43e0-b546-406c017ff9a7',
        Name: 'District',
        ModelId: '2e8ee912-12f6-4cf3-8145-1dcc47d78d38',
        date: 1541956832122,
        approved: true,
      },
    ],
  },
  {
    id: '773bc9ba-7543-4857-8318-5b9ca5605a75',
    fullname: 'Василий Крюков',
    profilephoto:
      'https://s3.amazonaws.com/uifaces/faces/twitter/edhenderson/128.jpg',
    channels: [
      {
        channelid: '8cb4d85c-c942-48c4-ad72-e6698989660a',
        Name: 'invoice',
        ModelId: 'd40c4d87-650a-4fc8-b980-c14296e52cbe',
        date: 1532132713711,
        approved: false,
      },
      {
        channelid: '100c0050-30cd-4c31-b404-ae8eb715768b',
        Name: 'Хлопковый',
        ModelId: '9887bfbc-b44a-4b2f-b986-fe288444b7a8',
        date: 1543908204999,
        approved: true,
      },
    ],
  },
  {
    id: '98d23f75-4462-4c27-8351-85328c084333',
    fullname: 'Антон Овчинников',
    profilephoto: null,
    channels: [
      {
        channelid: '2025449c-c79e-4529-9cc6-a822f57db751',
        Name: 'radical',
        ModelId: '24e5e5f5-81a1-44de-8ea9-a9841fb86812',
        date: 1534415280949,
        approved: true,
      },
      {
        channelid: '5861ef59-d42e-4a28-a4dd-8e9114b798f5',
        Name: 'open-source',
        ModelId: '8f8f5c67-91af-4ae9-8362-87f60207b327',
        date: 1543721039974,
        approved: false,
      },
    ],
  },
  {
    id: 'd602179e-7795-423e-8874-42a628fed334',
    fullname: 'Анатолий Гущин',
    profilephoto: null,
    channels: [
      {
        channelid: '389346ab-1833-42ac-9b46-04518ae48ea2',
        Name: 'markets',
        ModelId: 'f186d3d5-56f1-4f44-a2a2-8d4a981394f2',
        date: 1537778999060,
        approved: true,
      },
    ],
  },
  {
    id: 'e060eaa7-899b-457b-874e-f2d8b1d121c5',
    fullname: 'Оксана Тихонова',
    profilephoto:
      'https://s3.amazonaws.com/uifaces/faces/twitter/dicesales/128.jpg',
    channels: [
      {
        channelid: 'dd4cc709-b6f7-4a97-9901-848c5cb65aa7',
        Name: 'cultivate',
        ModelId: '7f7cfce0-88c8-4a47-aad5-d62a98f177fb',
        date: 1522526860386,
        approved: true,
      },
      {
        channelid: 'd5e39eb6-eb05-4ef4-b2ee-e9a7639e1152',
        Name: 'back-end',
        ModelId: 'c33df6e7-da65-4bad-a6a8-5a433db952df',
        date: 1525025013379,
        approved: true,
      },
    ],
  },
];

export default testData;
