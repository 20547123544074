export const langCodes = {
  ru: 'ru',
  uk: 'uk',
  ua: 'uk',
  en: 'en',
  russian: 'ru',
  ukrainian: 'uk',
  ukraine: 'uk',
  english: 'en',
};
export const transformLangCodes = (shortLang) => {
  switch (shortLang) {
    case 'ru':
      return 'russian';
    case 'en':
      return 'english';
    case 'uk':
      return 'ukraine';
    default:
      return 'ukraine';
  }
};

export const getLangCode = (lang) =>
  lang
    ? langCodes[lang]
      ? langCodes[lang]
      : langCodes.russian
    : langCodes.russian;

export const browserLocale = (
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'ru-RU'
).split(/[_-]+/)[0];
