import { partnerService } from 'services';
import { alertActions, loaderActions } from '.';
import { partnerConstants, userConstants } from '../constants';

export const partnerActions = {
  getStatus,
  verificationRequest,
  verificationReopen,
  getRegions,
  addRegion,
  deleteRegion,
  editRegion,
  getPartner,
  clearPartner,
  getPartners,
  getSelectedPartner,
  getPartnersAbonents,
  confirmConnected,
  setRating,
};

function getStatus() {
  return (dispatch) => {
    dispatch({ type: 'SHOW_LOADER', place: 'partnerAuthorization' });

    partnerService.getStatus().then(
      (response) => {
        dispatch({ type: 'HIDE_LOADER', place: 'partnerAuthorization' });
        dispatch({
          type: partnerConstants.GET_STATUS_SUCCESS,
          data: response.data,
        });
      },
      (error) => {
        dispatch({
          type: partnerConstants.GET_STATUS_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}

function verificationRequest() {
  return (dispatch) => {
    dispatch({ type: 'SHOW_LOADER', place: 'partnerAuthorization' });
    partnerService.verificationRequest().then(
      (response) => {
        dispatch({ type: 'HIDE_LOADER', place: 'partnerAuthorization' });
        dispatch({
          type: partnerConstants.REQUEST_VERIFICATION_SUCCESS,
          data: response.data,
        });
      },
      (error) => {
        dispatch({
          type: partnerConstants.REQUEST_VERIFICATION_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}

function verificationReopen(ticketid) {
  return (dispatch) => {
    dispatch({ type: 'SHOW_LOADER', place: 'partnerAuthorization' });
    partnerService.verificationReopen(ticketid).then(
      (response) => {
        dispatch({ type: 'HIDE_LOADER', place: 'partnerAuthorization' });
        dispatch({
          type: partnerConstants.REQUEST_VERIFICATION_SUCCESS,
          data: response.data,
        });
      },
      (error) => {
        dispatch({
          type: partnerConstants.REQUEST_VERIFICATION_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}

function getRegions() {
  return (dispatch) => {
    partnerService.getRegions().then(
      (response) => {
        dispatch({
          type: partnerConstants.REQUEST_GET_REGIONS_SUCCESS,
          data: response.data,
        });
        dispatch(loaderActions.hide('pageLoader'));
      },
      (error) => {
        dispatch({
          type: partnerConstants.REQUEST_GET_REGIONS_FAILURE,
          error: error.toString(),
        });
        dispatch(loaderActions.hide('pageLoader'));
      }
    );
  };
}

function addRegion(data) {
  return (dispatch) => {
    dispatch(loaderActions.show('regionsAddForm'));
    partnerService.addRegion(data).then(
      (response) => {
        dispatch({
          type: partnerConstants.REQUEST_ADD_REGIONS_SUCCESS,
          data: response.data,
        });
        dispatch(loaderActions.hide('regionsAddForm'));
      },
      (error) => {
        dispatch({
          type: partnerConstants.REQUEST_ADD_REGIONS_FAILURE,
          error: error.toString(),
        });
        dispatch(alertActions.error(error.message));
        dispatch(loaderActions.hide('regionsAddForm'));
      }
    );
  };
}

function editRegion(data) {
  return (dispatch) => {
    partnerService.editRegion(data).then(
      (response) => {
        dispatch({
          type: partnerConstants.REQUEST_EDIT_REGIONS_SUCCESS,
          data: response.data,
        });
      },
      (error) => {
        dispatch({
          type: partnerConstants.REQUEST_EDIT_REGIONS_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}

function deleteRegion(regionId) {
  return (dispatch) => {
    partnerService.deleteRegion(regionId).then(
      () => {
        dispatch({
          type: partnerConstants.REQUEST_DELETE_REGIONS_SUCCESS,
          data: { regionId },
        });
      },
      (error) => {
        dispatch({
          type: partnerConstants.REQUEST_DELETE_REGIONS_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}

function getSelectedPartner(partnerId) {
  return (dispatch) => {
    dispatch(loaderActions.show('getPartner'));
    partnerService.getPartner(partnerId).then(
      (response) => {
        dispatch({
          type: partnerConstants.GET_SELECTED_PARTNER_SUCCESS,
          data: response.data,
        });
        dispatch(loaderActions.hide('getPartner'));
      },
      (error) => {
        // dispatch({ type: partnerConstants.GET_PARTNER_FAILURE, error: error.toString() });
        dispatch({ type: partnerConstants.GET_PARTNER_FAILURE, error });
        dispatch(loaderActions.hide('getPartner'));
      }
    );
  };
}

function getPartner(partnerId) {
  return (dispatch) => {
    dispatch(loaderActions.show('getPartner'));
    partnerService.getPartner(partnerId).then(
      (response) => {
        dispatch({
          type: partnerConstants.GET_PARTNER_SUCCESS,
          data: response.data,
        });
        dispatch(loaderActions.hide('getPartner'));
      },
      (error) => {
        // dispatch({ type: partnerConstants.GET_PARTNER_FAILURE, error: error.toString() });
        dispatch({ type: partnerConstants.GET_PARTNER_FAILURE, error });
        dispatch(loaderActions.hide('getPartner'));
      }
    );
  };
}

function clearPartner() {
  return (dispatch) => dispatch({ type: partnerConstants.CLEAR_PARTNER });
}

const getPartnersParamsInitial = {
  place: null,
};

function getPartners({ place } = getPartnersParamsInitial) {
  return (dispatch) => {
    // dispatch(loaderActions.show("getPartners"));
    partnerService.getPartners({ place }).then(
      (response) => {
        dispatch({
          type: partnerConstants.GET_PARTNERS_SUCCESS,
          data: response.data.partners || [],
        });
        // dispatch(loaderActions.hide("getPartners"));
        dispatch(loaderActions.hide('pageLoader'));
      },
      (error) => {
        dispatch({
          type: partnerConstants.GET_PARTNERS_FAILURE,
          error: error.toString(),
        });
        // dispatch(loaderActions.hide("getPartners"));
        dispatch(loaderActions.hide('pageLoader'));
      }
    );
  };
}

function getPartnersAbonents() {
  return (dispatch) => {
    dispatch(loaderActions.show('getPartnersAbonents'));
    partnerService.getPartnersAbonents().then(
      (response) => {
        dispatch({
          type: partnerConstants.GET_PARTNERS_ABONENTS_SUCCESS,
          data: response.data.abonents || [],
        });
        dispatch(loaderActions.hide('getPartnersAbonents'));
      },
      (error) => {
        dispatch({
          type: partnerConstants.GET_PARTNERS_ABONENTS_FAILURE,
          error: error.toString(),
        });
        dispatch(loaderActions.hide('getPartnersAbonents'));
      }
    );
  };
}

function setRating(affiliateChannelId, rating) {
  return (dispatch) => {
    partnerService.setRating(affiliateChannelId, rating).then(
      () => {
        dispatch({
          type: partnerConstants.SET_RATING_SUCCESS,
          data: { id: affiliateChannelId, rating },
        });
        dispatch({ type: userConstants.ALL_NOTIFICATION_SUCCESS });
      },
      (error) => {
        dispatch({
          type: partnerConstants.SET_RATING_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}

function confirmConnected(confirmId, status) {
  return (dispatch) => {
    dispatch(loaderActions.show(`approveChannel.${confirmId}`));
    partnerService.confirmConnected({ confirmId, status }).then(
      (response) => {
        dispatch({
          type: partnerConstants.GET_PARTNERS_CONFIRM_SUCCESS,
          data: response.data.abonents || [],
        });
        const channel = response.data.abonents.reduce(
          (accumulator, abonent) =>
            abonent.channels.find((channel) => channel.confirmid === confirmId),
          {}
        );
        if (channel && channel.approved) {
          const user = JSON.parse(localStorage.getItem('user'));
          user.stats.activeAbonents = user.stats.activeAbonents + 1;
          localStorage.setItem('user', JSON.stringify(user));
          dispatch({ type: userConstants.REFRESH_SUCCESS, user });
        }
        dispatch({ type: userConstants.ALL_NOTIFICATION_SUCCESS });
        dispatch(loaderActions.hide(`approveChannel.${confirmId}`));
      },
      (error) => {
        dispatch({
          type: partnerConstants.GET_PARTNERS_CONFIRM_FAILURE,
          error: error.toString(),
        });
        dispatch(loaderActions.hide(`approveChannel.${confirmId}`));
      }
    );
  };
}
