import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import MacroscopCanvas from './macroscop/MacroscopCanvas';
import withStyles from '@material-ui/core/styles/withStyles';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  canvasWrapper: {
    margin: 'auto',
    width: '100%',
  },
  canvasContainer: {
    background: '#e7e7e7',
    color: '#9c9c9c',
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '75%',
    borderRadius: '0 0 6px 6px',
    overflow: 'hidden',
    '& canvas': {
      display: 'none',
    },
    '& canvas, & .placeholder': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '& .placeholder': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  icon: {
    fontSize: '5rem',
  },
  title: {
    fontSize: '2rem',
    margin: 0,
  },
};

export class ZonesCanvas extends Component {
  state = { loading: true, error: false };

  mounted = false;

  componentDidMount() {
    this.mounted = true;
    this.canvas = new MacroscopCanvas({
      selector: this.container,
      image: this.props.image,
    }).init();
    this.canvas.image.onload = () => {
      this.canvas.ctx.canvas.style.display = 'block';
      if (this.mounted) {
        this.setState({
          loading: false,
          error: false,
        });
      }
    };
    this.canvas.image.onerror = () => {
      this.canvas.ctx.canvas.style.display = 'none';
      if (this.mounted) {
        this.setState({
          loading: false,
          error: true,
        });
      }
    };
    if (this.props.data) this.canvas.setData(this.props.data);
    const canvasActions = {
      setCursorSize: (size) => this.canvas.zoneLayer.setCursorSize(size),
      fillGrid: () => this.canvas.zoneLayer.grid.fill(),
      clearGrid: () => this.canvas.zoneLayer.grid.clear(),
      setView: (view) => this.setView(view),
      setData: (data) => this.canvas.setData(data),
      getData: () => this.canvas.getData(),
      reset: () => {
        this.canvas.sizeLayer.setSelected();
        this.canvas.zoneLayer.grid.clear();
      },
    };
    if (this.props.getActions) this.props.getActions(canvasActions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && this.props.data !== prevProps.data) {
      this.canvas.setData(this.props.data);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setView(view) {
    const { canvas } = this;
    switch (view) {
      case 'size':
        canvas.sizeLayer.visible = true;
        canvas.zoneLayer.visible = false;
        break;
      case 'zone':
      case 'grid':
      default:
        canvas.sizeLayer.visible = false;
        canvas.zoneLayer.visible = true;
        break;
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, error } = this.state;
    return (
      <div className={classes.canvasWrapper}>
        <div
          className={classes.canvasContainer}
          ref={(node) => (this.container = node)}
        >
          {loading && (
            <div className="placeholder">
              <div align="center">
                <CircularProgress size="3rem" thickness={2.5} color="inherit" />
                <h3 className={classes.title}>
                  <FormattedMessage id="channels.video.status.loading" />
                </h3>
              </div>
            </div>
          )}
          {error && (
            <div className="placeholder">
              <div align="center">
                <VideocamOffIcon className={classes.icon} />
                <h3 className={classes.title}>
                  <FormattedMessage id="channels.video.status.error" />
                </h3>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(styles)(ZonesCanvas));
