import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem';
import lockScreenPageStyle from 'assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.jsx';

import { userActions } from 'actions';

class LockScreenPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      token: '',
      email: '',
      emailErrorMessage: '',
      changedEmail: false,
    };

    if (
      props.match &&
      props.match.params &&
      props.match.params.verificationId
    ) {
      props.dispatch(
        userActions.verifyEmail(props.match.params.verificationId)
      );
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.validate(value, name);
    this.setState({ [name]: value });
  };

  handleSubmit() {
    const { token } = this.state;
    this.props.dispatch(userActions.verifyEmail(token));
  }

  validate(value, name) {
    if (name === 'email') {
      const emailRegexp = new RegExp(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
      );

      if (this.state.changedEmail && value && !emailRegexp.test(value)) {
        this.setState({
          emailErrorMessage: this.props.intl.formatMessage({
            id: 'validation.email',
          }),
        });
        return false;
      }
      this.setState({ emailErrorMessage: '' });
      return true;
    }
  }

  resendEmail() {
    const { email } = this.state;
    if (!this.validate(email, 'email')) {
      return false;
    }
    this.props.dispatch(userActions.resendEmail(email));
  }

  render() {
    const { classes, intl } = this.props;
    return (
      <GridContainer className={classes.container} justify="center">
        <GridItem xs={12} sm={6} md={5}>
          <Card profile className={classes[this.state.cardAnimaton]}>
            {this.props.match &&
            (this.props.match.params.token ||
              this.props.confirmEmail ||
              this.props.match.params.verificationId) ? (
              <Fragment>
                <CardBody profile>
                  <h5 className={classes.cardTitle}>
                    {!this.props.confirmEmail ? (
                      <FormattedMessage id="confirm.loading" />
                    ) : (
                      <div>
                        <FormattedMessage id={this.props.confirmTextMessage} />
                        {this.props.errorConfirm ? (
                          <CustomInput
                            error={!!this.state.emailErrorMessage}
                            onBlur={() => this.setState({ changedEmail: true })}
                            labelText={intl.formatMessage({
                              id: 'confirm.form.email',
                            })}
                            id="company-disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: 'text',
                              onChange: this.handleChange,
                              name: 'email',
                            }}
                            helpText={this.state.emailErrorMessage}
                          />
                        ) : null}
                      </div>
                    )}
                  </h5>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  {!this.props.errorConfirm ? (
                    <NavLink to="/auth/login">
                      <Button color="rose" round>
                        <FormattedMessage id="confirm.enterSite" />
                      </Button>
                    </NavLink>
                  ) : (
                    <Button color="rose" onClick={this.resendEmail} round>
                      <FormattedMessage id="confirm.message.error.resend" />
                    </Button>
                  )}
                </CardFooter>
              </Fragment>
            ) : (
              <Fragment>
                <CardBody profile>
                  <h4 className={classes.cardTitle}>
                    <FormattedMessage id="confirm.title" />
                  </h4>
                  <CustomInput
                    labelText={intl.formatMessage({ id: 'confirm.form.token' })}
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      onChange: this.handleChange,
                      name: 'token',
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="rose" round onClick={this.handleSubmit}>
                    <FormattedMessage id="confirm.form.submit" />
                  </Button>
                </CardFooter>
              </Fragment>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

LockScreenPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { confirmEmail, confirmTextMessage, errorConfirm } = state.registration;
  return {
    confirmEmail,
    confirmTextMessage,
    errorConfirm,
  };
}

export default injectIntl(
  connect(mapStateToProps)(withStyles(lockScreenPageStyle)(LockScreenPage))
);
