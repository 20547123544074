import React from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';

const CameraCircularProgress = (props) => {
  const { message } = props;
  return (
    <div className={message.id > 1 ? props.classes.container : ''}>
      <div className={`${props.classes.filler} ${props.classes.info}`}>
        <div align="center">
          <CircularProgress size="3rem" thickness={2.5} color="inherit" />
          <h3 className={props.classes.title}>
            <FormattedMessage id={message.data} />
          </h3>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(withStyles(styles)(CameraCircularProgress));
