import React from 'react';
import { merge } from 'lodash';
import { connect } from 'react-redux';
import Card from 'components/Card/Card';
import Radio from '@material-ui/core/Radio';
import { sortingInvoiceStyles } from './utils';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import GridItem from 'components/Grid/GridItem';
import CardHeader from 'components/Card/CardHeader';
import FilterList from '@material-ui/icons/FilterList';
import GridContainer from 'components/Grid/GridContainer';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import styles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

const SortingInvoice = (props) => {
  const { classes, handleChange, selectedValue, intl } = props;

  const radioButtons = [
    {
      label: intl.formatMessage({ id: 'All' }),
      value: 'All',
    },
    {
      label: intl.formatMessage({ id: 'Paid' }),
      value: 'Paid',
    },
    {
      label: intl.formatMessage({ id: 'Unpaid' }),
      value: 'Unpaid',
    },
    {
      label: intl.formatMessage({ id: 'Cancelled' }),
      value: 'Cancelled',
    },
  ];

  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="info">
          <FilterList />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          <FormattedMessage id="invoice.sorting" />
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          {radioButtons.map((item, key) => {
            return (
              <GridItem sm={12} key={key}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedValue === item.value}
                      onChange={handleChange}
                      value={item.value}
                      name="radio button demo"
                      aria-label="A"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label={item.label}
                />
              </GridItem>
            );
          })}
        </GridContainer>
      </CardBody>
    </Card>
  );
};

function mapStateToProps(state) {
  const { invoice } = state;
  return {
    invoice,
  };
}

export default injectIntl(
  connect(mapStateToProps)(
    withStyles(merge(styles, sortingInvoiceStyles))(SortingInvoice)
  )
);
