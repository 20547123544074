import { history } from 'helpers';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import Card from 'components/Card/Card.jsx';
import Table from 'components/Table/Table.jsx';
import GridOn from '@material-ui/icons/GridOn';
import LoaderAnimation from 'components/Loader';
import Tooltip from '@material-ui/core/Tooltip';
import EmptyPlaceholder from './EmptyPlaceholder';
import Settings from '@material-ui/icons/Settings';
import CardBody from 'components/Card/CardBody.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import { loaderActions, channelsActions } from 'actions';
import Button from 'components/CustomButtons/Button.jsx';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';

class ZonesList extends React.Component {
  state = { confirm: null, modalIsOpen: false, selectedCameraId: null };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loaderActions.show('pageLoader'));
    dispatch(channelsActions.getChannels());
  }

  editZone = (id) => history.push(`/zones/edit/${id}`);

  closeSweetAlert = () => this.setState({ modalIsOpen: false });

  updatePushNotifications = (id) => history.push(`/order/edit/${id}`);

  verifyPushZones = (camera) => {
    if (!camera) return;
    if (Number(camera.configoptions.configoption[4].value) === 0) {
      this.setState({ selectedCameraId: camera.id, modalIsOpen: true });
    } else {
      this.editZone(camera.id);
    }
  };

  render() {
    const { channels, loader, classes, intl } = this.props;

    if (loader) return <LoaderAnimation />;

    if (!channels.all.length) return <EmptyPlaceholder classes={classes} />;

    if (this.state.modalIsOpen) {
      return (
        <SweetAlert
          show={this.state.modalIsOpen}
          warning
          showCancel
          title=""
          onConfirm={() =>
            this.updatePushNotifications(this.state.selectedCameraId)
          }
          onCancel={this.closeSweetAlert}
          confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
          cancelBtnCssClass={`${this.props.classes.button} ${this.props.classes.danger}`}
          confirmBtnText={this.props.intl.formatMessage({
            id: 'push.list.table.zones.btn.tariff',
          })}
          cancelBtnText={this.props.intl.formatMessage({
            id: 'tariff.change.btn.cancel',
          })}
        >
          <FormattedMessage id="push.list.table.zones.disabled" />
        </SweetAlert>
      );
    }

    const tableData = channels.all.map((item) => [
      item.channel.name,
      <Tooltip
        title={intl.formatMessage({ id: 'tooltip.message.settings' })}
        key={item.id}
      >
        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => this.verifyPushZones(item)}
        >
          <Settings className={classes.icon} />
        </Button>
      </Tooltip>,
    ]);

    return (
      <Fragment>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <GridOn />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="channels.list.table.title" />
            </h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                intl.formatMessage({ id: 'channels.list.table.name' }),
                intl.formatMessage({ id: 'channels.list.table.actions' }),
              ]}
              tableData={tableData}
              customCellClasses={[classes.right]}
              customClassesForCells={[1]}
              customHeadCellClasses={[classes.right]}
              customHeadClassesForCells={[1]}
            />
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  channels: state.channels,
  loader: state.loader.pageLoader,
});
export default injectIntl(
  connect(mapStateToProps)(withStyles(extendedTablesStyle)(ZonesList))
);
