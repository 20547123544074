export const ewalletConstant = {
  GET_DATA_REQUEST: 'EWALLET_GET_DATA_REQUEST',
  GET_DATA_SUCCESS: 'EWALLET_GET_DATA_SUCCESS',
  GET_DATA_FAILURE: 'EWALLET_GET_DATA_FAILURE',

  GET_PAY_ORDER_REQUEST: 'EWALLET_GET_PAY_ORDER_REQUEST',
  GET_PAY_ORDER_SUCCESS: 'EWALLET_GET_PAY_ORDER_SUCCESS',
  GET_PAY_ORDER_FAILURE: 'EWALLET_GET_PAY_ORDER_FAILURE',

  WITHDRAW_REQUEST: 'EWALLET_WITHDRAW_REQUEST',
  WITHDRAW_SUCCESS: 'EWALLET_WITHDRAW_SUCCESS',
  WITHDRAW_FAILURE: 'EWALLET_WITHDRAW_FAILURE',
};
