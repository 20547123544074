import React from 'react';
import { Field } from 'redux-form';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'components/ReduxInputs';
import { normalizeCameraName } from '../formsNormalization';

const CameraNameComponent = (props) => {
  const { intl } = props;
  return (
    <Grid item xs={12} sm={12}>
      <Field
        component={TextField}
        fullWidth
        label={intl.formatMessage({ id: 'channels.form.title' })}
        name="data.Name"
        normalize={(value, previousValue) =>
          normalizeCameraName(value, previousValue, 30)
        }
      />
    </Grid>
  );
};

export default injectIntl(CameraNameComponent);
