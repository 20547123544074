import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { history } from '../helpers';
import { userService } from 'services';
import { baseReactAPIUrl } from '../constants';
import indexRoutes from '../routes/index.jsx';
import { systemActions } from 'actions/system.actions';
import { Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute';
import Messager from '../components/Messenger/Messenger.js';

function axiosAuthorize() {
  const token = JSON.parse(localStorage.getItem('token'));
  if (token)
    axios.defaults.headers.common.Authorization = `${token.tokenType} ${token.accessToken}`;
}
axiosAuthorize();

function axiosSetup() {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        userService.logout();
        document.location.reload(true);
      }
      return Promise.reject(error.response.data);
    }
  );
  axios.defaults.baseURL = baseReactAPIUrl;
}
axiosSetup();

class App extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(systemActions.getCurrencies());
  }

  componentDidUpdate() {
    if (this.props.authentication.user) {
      axiosAuthorize();
    }
  }

  render() {
    return (
      <div>
        <Router history={history}>
          <Switch>
            {indexRoutes.map((prop, index) => {
              return prop.private ? (
                <PrivateRoute
                  path={prop.path}
                  component={prop.component}
                  key={index}
                />
              ) : (
                <Route
                  path={prop.path}
                  component={prop.component}
                  key={index}
                />
              );
            })}
          </Switch>
        </Router>
        <Messager />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, authentication, loader } = state;
  return {
    alert,
    authentication,
    loader: loader.app,
  };
}

export default connect(mapStateToProps)(App);
