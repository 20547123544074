import axios from 'axios';

export const systemService = {
  getCurrencies,
};

/**
 * Get all available currencies
 * @returns {AxiosPromise<any>}
 */
function getCurrencies() {
  return axios.get(`/system/getcurrencies`);
}
