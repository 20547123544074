import React from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

const CameraStatusError = (props) => {
  return (
    <div className={props.classes.container}>
      <div className={`${props.classes.filler} ${props.classes.info}`}>
        <div align="center">
          <VideocamOffIcon className={props.classes.icon} />
          <h3 className={props.classes.title}>
            <FormattedMessage id={props.status} />
          </h3>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(withStyles(styles)(CameraStatusError));
