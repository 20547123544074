import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';

import RenderTextField from 'components/CustomInput/RenderTextField';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import Group from '@material-ui/icons/Group';

import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import {
  channelsActions,
  loaderActions,
  tariffActions,
  viewersActions,
} from 'actions';
import LoaderAnimation from 'components/Loader';

import ChannelsFields from '../ChannelsFields';
import validate from './validate';

class ViewersAdd extends React.Component {
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(loaderActions.show('pageLoader'));
    dispatch(channelsActions.getChannels());
    dispatch(tariffActions.getTariffs());
  }

  onSubmit = (values) => {
    const { dispatch } = this.props;
    dispatch(viewersActions.addViewers(values));
  };

  disabled = () => {
    // this.props.channels.filter(item => item.)
  };

  render() {
    const {
      classes,
      handleSubmit,
      intl,
      pageLoader,
      channels,
      loader,
      formValues,
    } = this.props;

    if (pageLoader) return <LoaderAnimation />;

    const buttonDisabled = () => {
      let state = true;
      if (!channels.length) {
        return state;
      }
      if (formValues && formValues.channels) {
        const keys = Object.keys(formValues.channels);
        for (let i = 0; i < keys.length; i++) {
          if (
            formValues.channels[keys[i]].ChannelsRealtimeAllowed ||
            formValues.channels[keys[i]].ChannelsArchiveAllowed
          ) {
            state = false;
          }
        }
      }

      return state;
    };

    const notContent = () => {
      let viewersServiceActive = false;
      if (channels.length) {
        channels.map((channel) => {
          const configoptions = channel.configoptions.configoption;
          for (let i = 0; i < configoptions.length; i++) {
            if (
              configoptions[i].optionName === 'CustomRows.Viewers' &&
              configoptions[i].value > 0
            ) {
              viewersServiceActive = true;
              break;
            }
          }
        });
      }
      return viewersServiceActive;
    };

    return (
      <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Group />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="layout.menu.viewers.add" />
            </h4>
          </CardHeader>
          <CardBody>
            {notContent() ? (
              <div>
                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="email"
                      component={RenderTextField}
                      label={intl.formatMessage({ id: 'form.email' })}
                      formControlProps={{ fullWidth: true }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="password2"
                      type="password"
                      component={RenderTextField}
                      label={intl.formatMessage({ id: 'form.password' })}
                      formControlProps={{ fullWidth: true }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <Field
                      name="confirmpassword"
                      type="password"
                      component={RenderTextField}
                      label={intl.formatMessage({ id: 'form.confirmpassword' })}
                      formControlProps={{ fullWidth: true }}
                    />
                  </GridItem>
                </GridContainer>

                <ChannelsFields
                  channels={channels}
                  disabledParams={buttonDisabled()}
                />
                <div style={{ textAlign: 'right', margin: '20px 0 0' }}>
                  <Button
                    loading={loader}
                    type="submit"
                    color="rose"
                    disabled={buttonDisabled()}
                  >
                    <FormattedMessage id="viewers.add.form.submit" />
                  </Button>
                </div>
              </div>
            ) : (
              <GridContainer justify="center" alignItems="center">
                <GridItem xs={12} sm={6}>
                  <h3>
                    <FormattedMessage id="viewers.add.empty" />
                  </h3>
                </GridItem>
              </GridContainer>
            )}
          </CardBody>
        </Card>
      </form>
    );
  }
}

// import { channels } from "../testData.js";

// const channelsHandler = (channels = [], tariffs = []) => {
//   if (channels.length) {
//     return channels.map(item => {
//       // const disabled = {};
//       let viewersServiceActive = false;
//       item.configoptions.configoption.map(item => {
//         if (item.optionName === "CustomRows.Viewers") {
//           viewersServiceActive = !!item.value;
//         }
//       });

//       return {
//         id: item.channel.data.Id,
//         name: item.channel.data.Name,
//         enabled: item.status === "Active",
//         viewersServiceActive
//       };
//     });
//   }
//   return [];
// };

const mapStateToProps = (state) => ({
  channels: state.channels.all,
  loader: state.loader.viewerAddForm,
  pageLoader: state.loader.pageLoader,
  formValues: getFormValues('ViewersAdd')(state),
});
export default injectIntl(
  connect(mapStateToProps)(
    withStyles(regularFormsStyle)(
      reduxForm({
        form: 'ViewersAdd',
        validate,
        touchOnBlur: false,
      })(ViewersAdd)
    )
  )
);
