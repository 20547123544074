import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, injectIntl } from 'react-intl';
import PartnerProfile from 'containers/SearchPartner/PartnerProfile';

const PartnerCard = (props) => {
  const {
    change,
    channels,
    clearPartner,
    partnerSelected,
    partnerHandleCheck,
  } = props;
  return Object.keys(channels.partner).length ? (
    <Grid item xs={8} sm={8} md={8} lg={7}>
      {!channels.partner.partnercode ? (
        <Paper>
          <div style={{ textAlign: 'center', padding: 20 }}>
            <h3 style={{ margin: 0 }}>
              <FormattedMessage id="channels.form.partner.notfound" />
            </h3>
          </div>
        </Paper>
      ) : (
        <PartnerProfile
          change={change}
          data={channels.partner}
          check={partnerSelected}
          partnerClear={clearPartner}
          partnerHandleCheck={partnerHandleCheck}
          wide
        />
      )}
    </Grid>
  ) : null;
};

const mapStateToProps = (state) => ({
  channels: state.channels,
});
export default injectIntl(
  connect((state) => mapStateToProps(state))(PartnerCard)
);
