import {
  normalizeAuthForms,
  handleAutocomplete,
} from '../../Channels/Form/formsNormalization';
import React from 'react';
import { connect } from 'react-redux';
import Email from '@material-ui/icons/Email';
import { emailVaildation } from './validate';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import RenderTextField from 'components/CustomInput/RenderTextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';

const RestoreForm = (props) => {
  const { handleSubmit, classes, intl, loader } = props;

  return (
    <form onSubmit={handleSubmit}>
      <p align="center">
        <FormattedMessage id="restore.password.interEmailForStartRecovery.message" />
      </p>
      <Field
        name="email"
        autoFocus
        autoComplete="off"
        component={RenderTextField}
        onChange={handleAutocomplete}
        normalize={(value) => normalizeAuthForms(value)}
        placeholder={intl.formatMessage({ id: 'form.email' })}
        formControlProps={{
          fullWidth: true,
        }}
        startAdornment={
          <InputAdornment position="start" className={classes.inputAdornment}>
            <Email className={classes.inputAdornmentIcon} />
          </InputAdornment>
        }
      />
      <div className={classes.center} style={{ marginTop: 20 }}>
        <Button loading={loader} round color="primary" type="submit">
          <FormattedMessage id="restorePassword.form.submit" />
        </Button>
      </div>
    </form>
  );
};

export default injectIntl(
  reduxForm({
    form: 'RestoreForm',
    validate: emailVaildation,
    touchOnBlur: false,
    // asyncValidate
  })(
    connect((state) => ({
      loader: state.loader.authRecoveryForm,
    }))(RestoreForm)
  )
);
