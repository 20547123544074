import {
  basicNormalizer,
  handleAutocomplete,
} from '../../Channels/Form/formsNormalization';
import validate from './validate';
import { connect } from 'react-redux';
import { userActions } from 'actions';
import React, { Fragment } from 'react';
import TermsOfUse from './TermsOfUse.js';
import Lock from '@material-ui/icons/Lock';
import Email from '@material-ui/icons/Email';
import { Field, reduxForm } from 'redux-form';
import Person from '@material-ui/icons/Person';
import Icon from '@material-ui/core/Icon/Icon';
import Public from '@material-ui/icons/Public';
import Language from '@material-ui/icons/Language';
import ReCaptcha from 'components/ReCaptcha/ReCaptcha';
import { injectIntl, FormattedMessage } from 'react-intl';
import countriesListCodes from 'i18n-iso-countries/codes.json';
import Button from 'components/CustomButtons/ButtonWithLoader';
import RenderTextField from 'components/CustomInput/RenderTextField';
import RenderSelectField from 'components/CustomSelect/RenderSelectField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import CheckboxWithLabelAndError from 'components/CustomCheckbox/CustomCheckbox';

class RegisterForm extends React.Component {
  state = { modalOpen: false };

  componentDidMount() {
    this.props.change('language', this.props.language);
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ modalOpen: true });
  };

  closeModal = () => this.setState({ modalOpen: false });

  disagreeWithTermsModal = () => {
    this.props.change('agreeToTerms', false);
    this.closeModal();
  };

  agreeWithTermsModal = () => {
    this.props.change('agreeToTerms', true);
    this.closeModal();
  };

  changeLang = (e) => {
    this.props.dispatch(userActions.changeLang(e.target.value));
  };

  render() {
    const { handleSubmit, classes, intl, loader } = this.props;

    const getCountryList = countriesListCodes
      .map((i) => ({
        value: i[0],
        label: intl.formatMessage({ id: `country.${i[0]}` }),
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));

    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        <Field
          name="firstname"
          placeholder={`${intl.formatMessage({ id: 'form.firstname' })}*`}
          component={RenderTextField}
          formControlProps={{ fullWidth: true }}
          autoFocus
          autoComplete="off"
          onChange={handleAutocomplete}
          normalize={(value, previousValue) =>
            basicNormalizer(value, previousValue, 30)
          }
          startAdornment={
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Person className={classes.inputAdornmentIcon} />
            </InputAdornment>
          }
        />

        <Field
          name="email"
          placeholder={`${intl.formatMessage({ id: 'form.email' })}*`}
          component={RenderTextField}
          formControlProps={{ fullWidth: true }}
          startAdornment={
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Email className={classes.inputAdornmentIcon} />
            </InputAdornment>
          }
        />

        <Field
          name="password"
          type="password"
          placeholder={`${intl.formatMessage({ id: 'form.password' })}*`}
          component={RenderTextField}
          formControlProps={{ fullWidth: true }}
          startAdornment={
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
            </InputAdornment>
          }
        />

        <Field
          name="confirmpassword"
          type="password"
          placeholder={`${intl.formatMessage({ id: 'form.confirmpassword' })}*`}
          component={RenderTextField}
          formControlProps={{ fullWidth: true }}
          startAdornment={
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Lock className={classes.inputAdornmentIcon}>lock_outline</Lock>
            </InputAdornment>
          }
        />

        <Field
          name="country"
          component={RenderSelectField}
          native
          placeholder={`${intl.formatMessage({ id: 'form.country' })}*`}
          formControlProps={{
            fullWidth: true,
            style: { margin: '0 0 17px 0', paddingTop: 27 },
          }}
          inputProps={{ style: { textTransform: 'none' } }}
          displayEmpty
          startAdornment={
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Public className={classes.inputAdornmentIcon} />
            </InputAdornment>
          }
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'form.country.select' })}
          </option>
          {getCountryList.map((el, idx) => (
            <option key={idx} value={el.value}>
              {el.label}
            </option>
          ))}
        </Field>

        <Field
          name="language"
          component={RenderSelectField}
          native
          placeholder={`${intl.formatMessage({ id: 'form.language' })}*`}
          formControlProps={{
            fullWidth: true,
            style: { margin: '0 0 17px 0', paddingTop: 27 },
          }}
          inputProps={{ style: { textTransform: 'none' } }}
          displayEmpty
          onChange={this.changeLang}
          startAdornment={
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Language className={classes.inputAdornmentIcon} />
            </InputAdornment>
          }
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'language.select' })}
          </option>
          <option value="ru">
            {intl.formatMessage({ id: 'language.ru' })}
          </option>
          <option value="uk">
            {intl.formatMessage({ id: 'language.ua' })}
          </option>
          <option value="en">
            {intl.formatMessage({ id: 'language.en' })}
          </option>
        </Field>

        <Field
          color="primary"
          name="agreeToTerms"
          className={classes.checkbox}
          classes={{
            checked: classes.checked,
            checkboxError: classes.checkboxAgreeError,
          }}
          label={
            <Fragment>
              <FormattedMessage id="form.agreeToTerms.text" />{' '}
              <a href="#" onClick={this.openModal}>
                <FormattedMessage id="form.agreeToTerms.link" />
              </a>
            </Fragment>
          }
          component={CheckboxWithLabelAndError}
        />
        <Field name="recaptcha" component={ReCaptcha} />

        <div className={classes.center} style={{ marginTop: 20 }}>
          <Button loading={loader} round color="primary" type="submit">
            <FormattedMessage id="registration.form.submit" />
          </Button>
        </div>

        <TermsOfUse
          openState={this.state.modalOpen}
          close={this.closeModal}
          agree={this.agreeWithTermsModal}
          disagree={this.disagreeWithTermsModal}
          language={this.props.language}
        />
      </form>
    );
  }
}

export default injectIntl(
  reduxForm({
    form: 'RegisterForm',
    validate,
    // initialValues: { language: "ru" },
    touchOnBlur: false,
    // asyncValidate
  })(
    connect((state) => ({
      loader: state.loader.authRegForm,
      language: state.authentication.language,
    }))(RegisterForm)
  )
);
