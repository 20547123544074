import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import People from '@material-ui/icons/People';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

const styles = {
  ...dashboardStyle,
  cardBody: {
    textAlign: 'right',
  },
  cardCategory: {
    ...dashboardStyle.cardCategory,
    paddingTop: 0,
  },
};

const Statistics = (props) => {
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} lg={4}>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <People />
            </CardIcon>
            <CardBody className={props.classes.cardBody}>
              <h3 className={props.classes.cardTitle}>{props.active || 0}</h3>
              <p className={props.classes.cardCategory}>
                <FormattedMessage id="layout.setupfee.active" />
              </p>
            </CardBody>
          </CardHeader>
        </Card>
      </GridItem>
      {
        // TODO: Temporary unused partner information
        /* <GridItem xs={12} sm={6} lg={4}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <LocalAtm />
            </CardIcon>
            <CardBody className={props.classes.cardBody}>
              <h3 className={props.classes.cardTitle}>{props.paid || 0}</h3>
              <p className={props.classes.cardCategory}>
                <FormattedMessage id="layout.setupfee.paid" />
              </p>
            </CardBody>
          </CardHeader>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} lg={4}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Star />
            </CardIcon>
            <CardBody className={props.classes.cardBody}>
              <h3 className={props.classes.cardTitle}>
                {props.avaliable || 0}
              </h3>
              <p className={props.classes.cardCategory}>
                <FormattedMessage id="layout.setupfee.avaliable" />
              </p>
            </CardBody>
          </CardHeader>
        </Card>
      </GridItem> */
      }
    </GridContainer>
  );
};

export default injectIntl(connect()(withStyles(styles)(Statistics)));
