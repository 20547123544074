import React from 'react';
import Pagination from 'components/Pagination';
import Profile from './Profile.js';

class List extends React.Component {
  state = { page: 1, data: [] };

  itemsOnPage = 5;

  componentDidMount() {
    this.setPage(1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) this.setPage(1);
  }

  setPage = (page) => {
    const data = this.props.data.slice(
      (page - 1) * this.itemsOnPage,
      page * this.itemsOnPage
    );
    this.setState({ page, data });
  };

  render() {
    const total = Math.ceil(this.props.data.length / this.itemsOnPage);
    return (
      <div>
        {this.state.data.map((i) => (
          <Profile key={i.id} data={i} new={this.props.new} />
        ))}
        {total > 1 && (
          <Pagination
            style={{ marginTop: 30 }}
            current={this.state.page}
            total={total}
            visible="5"
            change={this.setPage}
          />
        )}
      </div>
    );
  }
}

export default List;
