import Layer from '../../classes/Layer';
import Grid from './Grid';
import GridCursor from './GridCursor';

export class ZoneLayer extends Layer {
  constructor({ size = undefined, grid = undefined } = {}) {
    super();
    this.grid = new Grid({ size, grid });
    this.cursor = new GridCursor();
    this.cursorScale = 1;
    this.markGridValue = 1;
    this.addElement(this.cursor, this.grid);
  }

  setSize({ width = 100, height = 100 } = {}) {
    this.width = width;
    this.height = height;
    this.setCursorSize();
  }

  setCursorSize(scale = this.cursorScale) {
    this.cursorScale = scale;
    const squareSize = {
      width: this.width / this.grid.size.x,
      height: this.height / this.grid.size.y,
    };
    const cursorSize = {
      width: squareSize.width * this.cursorScale,
      height: squareSize.height * this.cursorScale,
    };
    this.cursor.setSize(cursorSize);
    this.cursor.setGrid(squareSize);
  }

  markGrid() {
    this.grid.grid.flat().forEach((el) => {
      if (this.cursor.checkCollision(el.square)) {
        el.filled = this.markGridValue;
      }
    });
  }

  init(ctx = undefined) {
    if (!ctx) throw 'No canvas';
    this.cursor.init(ctx);
    let drawEnabled;
    const events = {
      mousedown: (e) => {
        this.markGridValue = e.which === 3 ? 0 : 1;
        drawEnabled = true;
        if (this.visible) {
          this.markGrid();
        }
      },
      mouseup: () => (drawEnabled = false),
      mouseout: () => (drawEnabled = false),
      mousemove: () => drawEnabled && this.visible && this.markGrid(),
      contextmenu: (e) => e.preventDefault(),
    };
    Object.keys(events).forEach((e) =>
      ctx.canvas.addEventListener(e, events[e])
    );
  }
}

export default ZoneLayer;
