import { systemService } from 'services';
import { systemConstants } from '../constants';
// import { alertActions } from "actions";

export const systemActions = {
  getCurrencies,
};

function getCurrencies() {
  return (dispatch) => {
    systemService.getCurrencies().then(
      (response) => {
        dispatch({
          type: systemConstants.GET_CURRENCIES_SUCCESS,
          data: response.data.currencies.currency,
        });
      },
      (error) => {
        dispatch({
          type: systemConstants.GET_CURRENCIES_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}
