import { partnerConstants } from '../constants';

const initialState = [];

export function places(state = initialState, action) {
  switch (action.type) {
    case partnerConstants.REQUEST_GET_REGIONS_SUCCESS:
      return [...action.data];
    case partnerConstants.REQUEST_ADD_REGIONS_SUCCESS:
      return [...state, action.data];
    case partnerConstants.REQUEST_DELETE_REGIONS_SUCCESS:
      return state.filter((i) => action.data.regionId !== i._id);
    default:
      return state;
  }
}
