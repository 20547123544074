export class Circle {
  constructor(position, size, color) {
    this.setPosition(position);
    this.setSize(size);
    this.setColor(color);
    this.setPath();
  }

  setPath() {
    this.path = new Path2D();
    this.path.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
  }

  setPosition({ x = 0, y = 0 } = {}) {
    this.x = x;
    this.y = y;
  }

  setSize(radius = 50) {
    this.radius = radius;
  }

  setColor(color = 'rgba(100, 150, 200, 0.5)') {
    this.fillStyle = color;
  }

  getBounds() {
    return {
      top: this.y - this.radius,
      left: this.x - this.radius,
      right: this.x + this.radius,
      bottom: this.y + this.radius,
    };
  }

  draw(ctx = undefined) {
    if (!ctx) throw 'No canvas';
    this.setPath();
    ctx.fillStyle = this.fillStyle;
    ctx.fill(this.path);
  }
}

export default Circle;
