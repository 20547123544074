export const transferConstants = {
  GET_REQUEST: 'GET_TRANSFER_REQUEST',
  GET_SUCCESS: 'GET_TRANSFER_SUCCESS',
  GET_FAILURE: 'GET_TRANSFER_FAILURE',

  ADD_REQUEST: 'ADD_TRANSFER_REQUEST',
  ADD_SUCCESS: 'ADD_TRANSFER_SUCCESS',
  ADD_FAILURE: 'ADD_TRANSFER_FAILURE',

  DELETE_REQUEST: 'DELETE_TRANSFER_REQUEST',
  DELETE_SUCCESS: 'DELETE_TRANSFER_SUCCESS',
  DELETE_FAILURE: 'DELETE_TRANSFER_FAILURE',

  APPROVE_SUCCESS: 'APPROVE_TRANSFER_SUCCESS',
};
