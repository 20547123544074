import React from 'react';
import { get, merge, set, uniqBy } from 'lodash';
import { baseReactMACROSCOPUrl } from '../constants';

export const initialOptions = {
  AltStreamSupported: true,
  DeviceArchiveSupported: false,
  PtzSupported: false,
  SoundReceivingSupported: false,
  SoundTransmittingSupported: false,
};

export const getVideoParams = (user) => {
  return {
    serverURL: baseReactMACROSCOPUrl,
    login: user.email,
    password: user.macroscopPassword,
    width: 1920,
    height: 1080,
  };
};

export const getAltStream = (videoStreamValues) => {
  return get(
    videoStreamValues,
    'data.ConnectionSettings.AltVideoStreamEnabled'
  );
};

export const getBrandModels = (deviceManufactures, formValues) => {
  const brand = get(formValues, 'Brand');
  if (brand) {
    const models = deviceManufactures.find((device) => device.Name === brand);
    if (models) return models.DeviceModels;
  }
  return [];
};

export const getDefaultPort = (deviceManufactures, formValues) => {
  const modelId = get(formValues, 'data.ConnectionSettings.ModelId');
  if (modelId) {
    const device = getBrandModels(deviceManufactures, formValues).find(
      (e) => e.Id === modelId
    );
    if (device) return device.DefaultExternalNetworkPorts;
  }
  return [];
};

export const getSupportedSettings = (deviceManufactures, formValues, args) => {
  const modelId = args || get(formValues, 'data.ConnectionSettings.ModelId');
  if (modelId) {
    const params = getBrandModels(deviceManufactures, formValues).find(
      (e) => e.Id === modelId
    );
    return params || { ...initialOptions };
  }
  return { ...initialOptions };
};

export const getStatusOption = (
  editChannel,
  orderOptionsEnabled,
  supportedSettings
) => {
  if (
    Object.keys(editChannel).length &&
    editChannel.product.status === 'Pending'
  ) {
    return { ...initialOptions };
  }
  const options = supportedSettings;
  if (Object.keys(orderOptionsEnabled).length) {
    return { ...options, ...orderOptionsEnabled };
  }
  return options;
};

export const getChanelStatus = (channel, type) => {
  if (channel && channel.Id) {
    const stream = channel.StreamsStates.find((item) => item.Type === type);
    return stream.State;
  }
  return null;
};

export const mainStreamStatusChannel = (videoStreamValues) => {
  return getChanelStatus(
    videoStreamValues ? videoStreamValues.channel : {},
    'MainVideo'
  );
};

export const altStreamStatusChannel = (videoStreamValues) => {
  return get(videoStreamValues, 'data.ConnectionSettings.AltVideoStreamEnabled')
    ? getChanelStatus(
        videoStreamValues ? videoStreamValues.channel : {},
        'AlternativeVideo'
      )
    : null;
};

export const getChannelId = (wizard, videoStreamValues, channel) => {
  return wizard
    ? videoStreamValues
      ? get(videoStreamValues, 'channel.Id')
      : ''
    : get(channel, 'data.Id');
};

export const portFix = (state) => {
  const newState = merge({}, state);
  const hostname = get(
    newState,
    'channels.edit.data.ConnectionSettings.Hostname'
  );
  if (hostname) {
    const hostnameArr = hostname.split(':');
    if (hostnameArr[1]) {
      set(
        newState,
        'channels.edit.data.ConnectionSettings.Hostname',
        hostnameArr[0]
      );
      set(newState, 'channels.edit.customParam.Port', hostnameArr[1]);
    }
  }
  return newState;
};

export const isVideoTestedBtnDisabled = (formError, touch) => {
  const isVideoTestedBtnDisabled =
    get(formError, 'data.Name') ||
    get(formError, 'data.ConnectionSettings.ModelId') ||
    get(formError, 'data.ConnectionSettings.Login') ||
    get(formError, 'data.ConnectionSettings.Password') ||
    get(formError, 'data.ConnectionSettings.Hostname') ||
    get(formError, 'data.ConnectionSettings.MainVideoStreamFormat') ||
    get(formError, 'data.ConnectionSettings.AltVideoStreamFormat');
  if (isVideoTestedBtnDisabled) {
    touch('data.Name');
    touch('data.ConnectionSettings.ModelId');
    touch('data.ConnectionSettings.Login');
    touch('data.ConnectionSettings.Password');
    touch('data.ConnectionSettings.Hostname');
    touch('data.ConnectionSettings.MainVideoStreamFormat');
    touch('data.ConnectionSettings.AltVideoStreamFormat');
  }
};

export const getVideoFormats = (stream, deviceManufactures, formValues) => {
  const modelId = get(formValues, 'data.ConnectionSettings.ModelId');
  if (modelId) {
    const device = getBrandModels(deviceManufactures, formValues).find(
      (e) => e.Id === modelId
    );
    if (device) return device[stream].filter((item) => item !== 'MJPEG');
  }
  return [];
};

export const setVideoFormats = (deviceVideoFormat, formValues, change) => {
  if (
    deviceVideoFormat.main.length &&
    !get(formValues, 'data.ConnectionSettings.MainVideoStreamFormat') &&
    deviceVideoFormat.main.find((i) => i === 'H264')
  ) {
    change('data.ConnectionSettings.MainVideoStreamFormat', 'H264');
  }
};

export const isTestPortBtn = (formValue) => {
  return get(formValue, 'data.ConnectionSettings.Hostname');
};

export const getModelId = (formValue) => {
  return get(formValue, 'data.ConnectionSettings.ModelId');
};

export const getBrandNameByModelId = (modelId, deviceManufactures) => {
  if (!deviceManufactures.length || !modelId) return;
  const brand = deviceManufactures.find((brand) =>
    brand.DeviceModels.some((model) => model.Id === modelId)
  );
  return brand.Name;
};

export const isCameraSupportsAltStream = (
  brandName,
  modelId,
  deviceManufactures
) => {
  if (!deviceManufactures.length) return;
  const cameraBrand = deviceManufactures.find((item) =>
    item.Name.toLowerCase() === brandName.toLowerCase() ? item : null
  );
  if (cameraBrand) {
    return cameraBrand.DeviceModels.find((model) => model.Id === modelId)
      .AltStreamSupported;
  }
  return false;
};

export const fillPorts = (deviceManufactures, formValues, change) => {
  let httpPort = get(formValues, 'customParam.Port');
  if (!httpPort) {
    httpPort = 80;
    change('customParam.Port', 80);
  }
  const defaultPorts = getDefaultPort(deviceManufactures, formValues);
  const formPorts = get(
    formValues,
    'data.ConnectionSettings.ExternalNetworkPorts'
  );
  const ports = defaultPorts.map((port, index) => {
    if (!formPorts || !formPorts[index] || !formPorts[index].PortValue) {
      change(
        `data.ConnectionSettings.ExternalNetworkPorts[${index}].PortValue`,
        port.PortKey
      );
      return port.PortKey;
    }
    return formPorts[index].PortValue;
  });
  return uniqBy([httpPort, ...ports], Number);
};

export const getFormData = (props) => {
  const {
    channels,
    videoStreamValues,
    deviceManufactures,
    cameraConfigValues,
    deviceSettingsValues,
    partnerSelectedValues,
  } = props;
  // Activate camera archive flash drive storage
  /* const cameraParams = getModelParams(
    deviceManufactures,
    cameraConfigValues.data.ConnectionSettings.ModelId
  ); */
  const formData = merge(
    {},
    cameraConfigValues,
    deviceSettingsValues,
    videoStreamValues,
    partnerSelectedValues,
    {
      data: {
        ArchiveSettings: {
          ArchivingDisabled: false,
          // DeviceArchiveEnabled: cameraParams.DeviceArchiveSupported
        },
      },
    }
  );
  const params = { ...channels.edit, ...formData };
  if (
    params.customParam.Port &&
    !params.data.ConnectionSettings.Hostname.includes(':')
  ) {
    params.data.ConnectionSettings.Hostname = `${params.data.ConnectionSettings.Hostname.trim()}:${
      params.customParam.Port
    }`;
  }
  const ports = params.data.ConnectionSettings.ExternalNetworkPorts;
  if (ports) {
    const defaultPorts = getDefaultPort(deviceManufactures, formData);
    if (defaultPorts.length > 0) {
      ports.forEach((port, index) => {
        port.PortKey = defaultPorts[index].PortKey;
        port.IsExternallyDefined = false;
      });
    } else {
      params.data.ConnectionSettings.ExternalNetworkPorts = [];
    }
  }
  return params;
};

export const getVideoImageUrl = (
  channelId,
  videoType,
  streamType,
  videoParams,
  fps = 25
) => {
  const { serverURL, login, password, width, height } = videoParams;
  return `${serverURL}/${videoType}?channelId=${channelId}&resolutionx=${width}&resolutiony=${height}&fps=${fps}&is_ajax=true&whoami=webclient&withcontenttype=true&login=${login}&password=${password}&usertype=Internal&streamtype=${streamType}`;
};

export const intlOptionVideoFormat = (intl, deviceVideoFormat) => {
  return deviceVideoFormat.alt.length
    ? intl.formatMessage({
        id: 'channels.form.codec.select',
      })
    : intl.formatMessage({
        id: 'channels.form.model.select',
      });
};

export const getVideoFormatsOption = (deviceVideoFormat) => {
  return deviceVideoFormat.main.map((item) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));
};

export const isPortsValid = (portsValues) => {
  if (!portsValues) return;
  if (portsValues.length === 1) {
    return portsValues[0] === true;
  }
  if (portsValues.length === 2) {
    return portsValues[0] === true && portsValues[1] === true;
  }
  if (portsValues.length === 3) {
    return (
      portsValues[0] === true &&
      portsValues[1] === true &&
      portsValues[2] === true
    );
  }
  return false;
};

export const displayImageStream = (wizard, videoStreamValues, portsValues) => {
  return (
    (!wizard &&
      get(
        videoStreamValues,
        'data.ConnectionSettings.AltVideoStreamEnabled'
      )) ||
    (wizard && getAltStream(videoStreamValues) && isPortsValid(portsValues))
  );
};

export const notSupportedAltVideoStreamList = {
  ONVIF: 'ONVIF',
  DAHUA: '651bb7c7-24c4-400d-97d0-59b20e381f8c',
};
