import { appStore } from './manuals';

export const styles = {
  imageContainer: {},
  icons: {
    maxWidth: '100%',
    verticalAlign: 'middle',
    padding: '5px',
    height: '3rem',
  },
  imageStyles: {
    display: 'block',
    maxWidth: '100%',
    height: '540px',
    objectFit: 'cover',
  },
  verticalImageStyles: {
    display: 'block',
    maxWidth: '650px',
    objectFit: 'cover',
  },
  h2FontWeight: {
    fontWeight: 400,
  },
  savingMargin: {
    marginBottom: '20px',
  },
  appStoreIcon: {
    display: 'block',
    height: '90px',
    cursor: 'pointer',
    marginBottom: '20px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${appStore})`,
  },
};
