import { deviceManufacturesConstant } from '../constants';
import { deviceManufacturesService } from '../services';

export const deviceManufacturesActions = {
  getDeviceManufactures,
};

function getDeviceManufactures() {
  return (dispatch) => {
    deviceManufacturesService.getDeviceManufactures().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  // function request(user) {
  //   return { type: deviceManufacturesConstant.GET_DEVICES_REQUEST, prams };
  // }
  function success(data) {
    return { type: deviceManufacturesConstant.GET_DEVICES_SUCCESS, data };
  }
  function failure(error) {
    return { type: deviceManufacturesConstant.GET_DEVICES_FAILURE, error };
  }
}
