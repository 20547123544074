import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';

export default {
  ...sweetAlertStyle,
  title: {
    '@media (min-width: 768px)': {
      fontSize: 16,
    },
  },
  price: {
    fontSize: 18,
  },
  total: {
    fontSize: 22,
    fontWeight: 600,
  },
  tableResponsive: {
    minHeight: '0.1%',
  },
  col1: {
    '@media (max-width: 1620px)': {
      width: '65%',
    },
    '@media (max-width: 1400px)': {
      width: '60%',
    },
    '@media (max-width: 1150px)': {
      width: '55%',
    },
    width: '70%',
  },
  col2: {
    '@media (max-width: 1620px)': {
      width: '15%',
    },
    '@media (max-width: 1400px)': {
      width: '15%',
    },
    '@media (max-width: 1150px)': {
      width: '17%',
    },
    width: '15%',
  },
  col3: {
    '@media (max-width: 1620px)': {
      width: '20%',
    },
    '@media (max-width: 1400px)': {
      width: '25%',
    },
    '@media (max-width: 1150px)': {
      width: '28%',
    },
    width: '15%',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
};
