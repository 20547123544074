const emailRegexp = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
const phoneRegexp = new RegExp(/^\+?\d{10,12}$/);
const nickRegexp = new RegExp(/^[a-zA-Z0-9_]+$/);

const profileValidate = (values, { intl }) => {
  const errors = {};

  const requiredFields = [
    'firstname',
    'lastname',
    'nickname',
    'email',
    'country',
    'city',
    'phonenumber',
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = intl.formatMessage({ id: 'validation.required' });
    }
  });

  const nameFields = ['firstname', 'lastname', 'nickname'];
  nameFields.forEach((field) => {
    if (values[field] && values[field].trim().length > 30) {
      errors[field] = intl.formatMessage(
        { id: `validation.${field}` },
        { count: 30 }
      );
    }
  });

  if (values.nickname && !nickRegexp.test(values.nickname.trim())) {
    errors.nickname = intl.formatMessage({ id: `validation.nickname.lang` });
  }

  if (values.email && !emailRegexp.test(values.email.trim())) {
    errors.email = intl.formatMessage(
      {
        id: 'validation.email',
      },
      { count: 30 }
    );
  }

  if (values.phonenumber && !phoneRegexp.test(values.phonenumber.trim())) {
    errors.phonenumber = intl.formatMessage({ id: 'validation.phone' });
  }

  if (values.password && values.password.trim().length < 6) {
    errors.password = intl.formatMessage(
      {
        id: 'validation.password',
      },
      { count: 6 }
    );
  }

  if (values.avatar && values.avatar.size > 200000) {
    errors.avatar = intl.formatMessage({
      id: 'profile.form.partnerdata.form.validation.avatar',
    });
  }

  const socialPhone = ['viber', 'whatsapp'];
  socialPhone.forEach((field) => {
    if (values[field] && !phoneRegexp.test(values[field].trim())) {
      errors[field] = intl.formatMessage({ id: 'validation.phone' });
    }
  });

  const socialLogin = ['telegram', 'skype'];
  socialLogin.forEach((field) => {
    if (values[field] && values[field].trim().length > 30) {
      errors[field] = intl.formatMessage(
        { id: 'validation.length' },
        { count: 30 }
      );
    }
  });

  return errors;
};

const passwordValidate = (values, { intl }) => {
  const errors = {};
  const len = { min: 6, max: 128 };
  const requiredFields = ['oldPassword', 'reOldPassword', 'newPassword'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = intl.formatMessage({ id: 'validation.required' });
    }
    if (
      values[field] &&
      (values[field].trim().length < len.min ||
        values[field].trim().length > len.max)
    ) {
      errors[field] = intl.formatMessage({ id: 'validation.password2' }, len);
    }
  });
  if (values.oldPassword !== values.reOldPassword) {
    errors.reOldPassword = intl.formatMessage({
      id: 'validation.confirmpassword',
    });
  }
  return errors;
};
const deleteValidate = (values, { intl }) => {
  const errors = {};
  const len = { min: 6, max: 128 };
  const requiredFields = ['agreeToDelete', 'agreeToBalanceLoss'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = intl.formatMessage({ id: 'validation.agreeToTerms' });
    }
  });
  if (!values.password) {
    errors.password = intl.formatMessage({ id: 'validation.required' });
  }
  if (
    values.password &&
    (values.password.trim().length < len.min ||
      values.password.trim().length > len.max)
  ) {
    errors.password = intl.formatMessage({ id: 'validation.password2' }, len);
  }
  return errors;
};

export { profileValidate, passwordValidate, deleteValidate };
