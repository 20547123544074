import React from 'react';
import styles from './styles';
import CountUp from 'react-countup';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { history } from '../../helpers/history.js';
import Button from 'components/CustomButtons/Button.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import Notification from './HeaderLinksElements/Notification';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';

class HeaderLinks extends React.Component {
  state = {
    currency: {},
    previousBalance: 0,
  };

  componentDidMount() {
    this.setState({
      previousBalance: Number(this.props.auth.user.credit),
    });
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.currencies.length &&
      Object.keys(nextProps.auth.user).length &&
      !Object.keys(nextState.currency).length
    ) {
      nextProps.currencies.map((item) => {
        if (item.id === nextProps.auth.user.currency) {
          this.setState({ currency: item });
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      Number(this.props.auth.user.credit) !== Number(prevProps.auth.user.credit)
    ) {
      const storageUser = JSON.parse(localStorage.getItem('user'));
      storageUser.credit = this.props.auth.user.credit;
      localStorage.setItem('user', JSON.stringify(storageUser));
    }
  }

  getBalanceMessage = (user) => {
    return user.groupid === 1 ? 'layout.partner.balance' : 'layout.balance';
  };

  render() {
    const { classes, auth } = this.props;
    const { user } = auth;

    return Object.keys(this.state.currency).length ? (
      <div className={classes.container}>
        <Notification classes={classes} stats={user.stats} />
        <Button
          className={classes.button}
          color="transparent"
          onClick={() => history.push('/refill')}
        >
          <AccountBalanceWallet className={classes.icon} />
          <span className={classes.text}>
            <FormattedMessage id={this.getBalanceMessage(user)} />:{' '}
            {this.state.currency.prefix}
            <CountUp
              start={this.state.previousBalance}
              end={Number(user.credit)}
              duration={3}
            />{' '}
            {this.state.currency.suffix}
          </span>
        </Button>
      </div>
    ) : (
      ''
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authentication,
  currencies: state.system.currencies,
});
export default connect(mapStateToProps)(withStyles(styles)(HeaderLinks));
