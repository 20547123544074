import { handleResponse } from '../helpers';
import axios from 'axios';

export const ewalletService = {
  getFormRefill,
  createBill,
  getPayOrder,
  createPaidFromBalance,
  addFounds,
  withdrawRequest,
};

function getFormRefill() {
  return axios.get(`/ewallet/refill/getForm`);
}

function getPayOrder(id) {
  return axios.get(`/ewallet/getPayOrder/${id}`);
}

function addFounds(amount, description) {
  return axios
    .post(`/ewallet/addfound`, { amount, description })
    .then((response) => {
      return response.data;
    });
}

function createBill(amount, description, currency) {
  return axios
    .post('/invoice', { amount, description, currency })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleResponse(error.response));
}

function createPaidFromBalance(params) {
  return axios.post('/order/createpaidfrombalance', params);
}

function withdrawRequest(params) {
  return axios.post('/ewallet/withdraw', params);
}
