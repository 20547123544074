import { ewalletConstant } from '../constants';
import { ewalletService } from '../services';
import { alertActions } from './alert.actions';
import { userActions } from 'actions/user.actions';
import { loaderActions } from '.';

export const ewalletActions = {
  refillBalance,
  createBill,
  getPayOrder,
  createPaidFromBalance,
  withdraw,
};

function refillBalance(id) {
  return (dispatch) => {
    ewalletService.getFormRefill(id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  // function request(id) {
  //   return { type: ewalletConstant.GET_DATA_REQUEST, id };
  // }
  function success(data) {
    return { type: ewalletConstant.GET_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: ewalletConstant.GET_DATA_SUCCESS, error };
  }
}

function createBill(amount, description, currency) {
  return (dispatch) => {
    dispatch(loaderActions.show('refillBalanceForm'));
    ewalletService.addFounds(amount, description, currency).then(
      (data) => {
        dispatch({ type: ewalletConstant.GET_DATA_SUCCESS, data });
        dispatch(loaderActions.hide('refillBalanceForm'));
      },
      (error) => {
        dispatch({
          type: ewalletConstant.GET_DATA_FAILURE,
          error: error.toString(),
        });
        dispatch(alertActions.error(error.toString()));
        dispatch(loaderActions.hide('refillBalanceForm'));
      }
    );
  };
}

function getPayOrder(id) {
  return (dispatch) => {
    ewalletService.getPayOrder(id).then(
      (data) => {
        dispatch(success(data.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  // function request(user) {
  //   return { type: ewalletConstant.GET_PAY_ORDER_REQUEST, user };
  // }

  function success(data) {
    return { type: ewalletConstant.GET_PAY_ORDER_SUCCESS, data };
  }

  function failure(error) {
    return { type: ewalletConstant.GET_PAY_ORDER_FAILURE, error };
  }
}

function createPaidFromBalance(id) {
  return (dispatch) => {
    ewalletService.createPaidFromBalance(id).then(
      (data) => {
        dispatch({ type: ewalletConstant.GET_PAY_ORDER_SUCCESS, data });
        dispatch(userActions.getBalance());
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function failure(error) {
    return { type: ewalletConstant.GET_PAY_ORDER_FAILURE, error };
  }
}

function withdraw(sum) {
  return (dispatch) => {
    dispatch(loaderActions.show('withdrawRequest'));
    ewalletService.withdrawRequest(sum).then(
      () => {
        // dispatch({ type: ewalletConstant.GET_PAY_ORDER_SUCCESS, data });
        dispatch(loaderActions.hide('withdrawRequest'));
        dispatch({ type: ewalletConstant.WITHDRAW_SUCCESS });
      },
      (error) => {
        dispatch(loaderActions.hide('withdrawRequest'));
        dispatch({ type: ewalletConstant.WITHDRAW_FAILURE });
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  // function failure(error) {
  //   return { type: ewalletConstant.GET_PAY_ORDER_FAILURE, error };
  // }
}
