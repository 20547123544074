import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';

export const invoiceListStyles = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

export const invoiceTransactionStyles = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

export const paidAllInvoicesStyles = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

export const sortingInvoiceStyles = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

export const unpaidInvoiceStyles = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

const navLinksColors = {
  paid: '#779500',
  unpaid: '#cc0000',
  cancelled: '#888',
};

export const setNavLinkColor = (invoiceStatus) => {
  const { paid, unpaid, cancelled } = navLinksColors;
  const status = invoiceStatus.toLowerCase();
  return status === 'paid'
    ? { color: paid }
    : status === 'unpaid'
    ? { color: unpaid }
    : status === 'cancelled'
    ? { color: cancelled }
    : {};
};
