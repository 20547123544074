export class CanvasImage {
  constructor(url, size, position) {
    if (!url) throw 'No url';
    this.image = new Image();
    this.image.src = url;
    this.image.onload = () => {
      if (!size && !(this.width && this.height)) this.setSize();
      this.onload();
    };
    this.image.onerror = () => {
      this.onerror();
    };
    this.setPosition(position);
    this.setSize(size);
  }

  onload = () => {};

  onerror = () => {};

  setPosition({ x = 0, y = 0 } = {}) {
    this.x = x;
    this.y = y;
  }

  setSize({ width = null, height = null } = {}) {
    this.width = width || this.image.width;
    this.height = height || this.image.height;
  }

  draw(ctx = undefined) {
    if (!ctx) throw 'No canvas';
    try {
      ctx.drawImage(this.image, this.x, this.y, this.width, this.height);
    } catch (e) {
      // console.error(e);
    }
  }
}

export default CanvasImage;
