import React from 'react';
import { connect } from 'react-redux';
import OrderForm from 'containers/Order/OrderForm';
import GridItem from 'components/Grid/GridItem.jsx';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  choiche: {
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '20px',
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendForm: false,
      tariffState: '',
      isValidate: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  sendState = () => {
    this.setState({ sendForm: true });
    return this.state;
  };

  sendFormSate = (value) => {
    this.setState({ sendForm: value });
  };

  updateValidate = (value) => {
    this.setState({ isValidate: value });
  };

  handleSubmit = () => {};

  isValidated() {
    return this.state.isValidate();
  }

  render() {
    const { classes, channels, currentStep } = this.props;

    const channelName = channels.edit.data.Name;
    return (
      currentStep > 0 && (
        <div>
          <h4 className={classes.infoText}>
            <FormattedMessage
              id="wizard.step2.description"
              values={{ channelName }}
            />
          </h4>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} lg={10}>
              <OrderForm
                wizard
                wizardState={this.state}
                currentStep={currentStep}
                sendForm={this.state.sendForm}
                handleSubmit={this.handleSimple}
                sendFormSate={this.sendFormSate}
                updateValidate={this.updateValidate}
              />
            </GridItem>
          </GridContainer>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  const { channels } = state;
  return {
    channels,
  };
};

export default injectIntl(connect(mapStateToProps)(withStyles(style)(Step2)));
