import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SwitchUI from '@material-ui/core/Switch';

const Switch = ({ input, label, disabled }) =>
  label ? (
    <FormControlLabel
      control={
        <SwitchUI
          disabled={disabled}
          checked={!!input.value}
          onChange={input.onChange}
        />
      }
      label={label}
    />
  ) : (
    <SwitchUI
      disabled={disabled}
      checked={!!input.value}
      onChange={input.onChange}
    />
  );

export default Switch;
