import { authHeader } from '../helpers';
import { userService } from './user.service';
import { baseReactAPIUrl } from '../constants';
// import axios from "axios";

const serverUrl = baseReactAPIUrl;

export const channelsService = {
  getChannels,
  getOneChannel,
  addChannel,
  updateChannel,
  deleteChannel,
  getStatusCam,
  reachablePort,
  changePriority,
  toggleChannel,
  getStatistic,
};

function toggleChannel(params) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ serviceid: params.id }),
  };

  return fetch(
    `${serverUrl}/channels/toggle/${params.channel.data.Id}`,
    requestOptions
  ).then(handleResponse);
}

function changePriority(channels) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify(channels),
  };

  return fetch(`${serverUrl}/channels/changePriority`, requestOptions).then(
    handleResponse
  );
}

function getChannels() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${serverUrl}/channels`, requestOptions).then(handleResponse);
}

function getStatusCam(Id) {
  /* return axios
    .get(`/channels/getStatusCam/${Id}`)
    .catch(error => handleResponse(error.response)); */

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${serverUrl}/channels/getStatusCam/${Id}`, requestOptions).then(
    handleResponse
  );
}

function getOneChannel(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${serverUrl}/channels/edit/${id}`, requestOptions).then(
    handleResponse
  );
}

function addChannel(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${serverUrl}/channels`, requestOptions)
    .then(handleResponse)
    .catch((err) => {
      throw Error(err);
    });
}

function updateChannel(channel, query) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify(channel.data),
  };
  const url = new URL(`${serverUrl}/channels/${channel.data.Id}`);

  if (query) {
    Object.keys(query).forEach((key) =>
      url.searchParams.append(key, query[key])
    );
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteChannel(Id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  return fetch(`${serverUrl}/channels/delete/${Id}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  // return response.data;
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        document.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function reachablePort(host, port) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(
    `${serverUrl}/channels/port-reachable?host=${host}&port=${port}`,
    requestOptions
  ).then(handleResponse);
}

function getStatistic(channelId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(
    `${serverUrl}/channels/statistic?channelId=${channelId}`,
    requestOptions
  ).then(handleResponse);
}
