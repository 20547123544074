import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Danger from 'components/Typography/Danger.jsx';
import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';

import { userActions } from 'actions';
import RestorePasswordForm from './RestorePasswordForm';
import RestorePasswordNewPasswordForm from './RestorePasswordNewPasswordForm';

class RestorePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendRequest: false,
    };

    if (!this.state.sendRequest && this.props.location.search) {
      const codeArr = this.props.location.search.split('=');
      if (codeArr[0] === '?key') {
        this.props.dispatch(
          userActions.validateRecoveryCode({
            pwresetkey: codeArr[1],
            type: 'validation',
          })
        );
      }
      this.setState({ sendRequest: true });
    } else {
      this.props.dispatch(userActions.clearRecovery());
    }

    // reset login status
    this.props.dispatch(userActions.logout());
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitNewPassword = this.handleSubmitNewPassword.bind(this);
  }

  handleSubmit(values) {
    const { dispatch } = this.props;
    dispatch(userActions.restorePassword(values));
  }

  handleSubmitNewPassword(values) {
    const { dispatch } = this.props;
    dispatch(userActions.validateRecoveryCode(values));
  }

  render() {
    const { classes, recoveryPassword } = this.props;

    let content;
    if (this.props.location.search) {
      if (recoveryPassword.recovery.result === 'success') {
        content = (
          <RestorePasswordNewPasswordForm
            classes={classes}
            onSubmit={this.handleSubmitNewPassword}
          />
        );
      } else if (recoveryPassword.recovery.result === 'error') {
        content = (
          <div>
            <Danger>
              <p align="center">
                <FormattedMessage id={recoveryPassword.recovery.message} />
              </p>
            </Danger>
            <NavLink to="/auth/recover" className={classes.navLink}>
              <FormattedMessage id="login.passwordRecovery" />
            </NavLink>
          </div>
        );
      } else {
        // else
      }
    } else if (recoveryPassword.recovery.result === 'success') {
      content = (
        <p align="center">
          <FormattedMessage id="restore.password.success.message" />
        </p>
      );
    } else if (recoveryPassword.sendEmail.result === 'success') {
      content = (
        <p align="center">
          <FormattedMessage
            id="send.email.restore.password.success.message"
            values={{ email: recoveryPassword.sendEmail.email }}
          />
        </p>
      );
    } else if (recoveryPassword.sendEmail.result === 'error') {
      content = (
        <Fragment>
          <Danger>
            <p align="center">
              <FormattedMessage id={recoveryPassword.sendEmail.message} />
            </p>
          </Danger>
          <RestorePasswordForm classes={classes} onSubmit={this.handleSubmit} />
        </Fragment>
      );
    } else {
      content = (
        <RestorePasswordForm classes={classes} onSubmit={this.handleSubmit} />
      );
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={8} lg={6}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>
                <FormattedMessage id="restorePassword.title" />
              </h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={10} md={10}>
                    {content}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

RestorePasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {
    authentication: { recoveryPassword },
  } = state;
  return {
    recoveryPassword,
  };
}

export default injectIntl(
  connect(mapStateToProps)(withStyles(registerPageStyle)(RestorePasswordPage))
);
