import { get } from 'lodash';
import { v4 as uuid } from 'uuid';
import { baseReactWebsocketUrl } from '../constants';

const mainDisplayParameters = {
  fps: 15,
  width: 1920,
  height: 1080,
};

const altDisplayParameters = {
  fps: 15,
  width: 640,
  height: 480,
};

export class SocketStreamService {
  socket = null;

  interval = null;

  onMessageAttempts = 0;

  constructor() {
    this.socket = new WebSocket(`${baseReactWebsocketUrl}/webapi/ws`);
    this.firstRequestType = uuid();
    this.secondRequestType = uuid();
    this.thirdRequestType = uuid();
  }

  onOpen = (cameraId, user, streamType) => {
    this.socket.onopen = () => {
      if (this.socket.readyState === this.socket.OPEN) {
        this.socket.send(
          JSON.stringify({
            requestId: this.firstRequestType,
            commandType: 0,
            parameters: {
              userName: user.name,
              userType: 0,
              password: user.password,
            },
          })
        );
        this.socket.send(
          JSON.stringify({
            requestId: this.secondRequestType,
            commandType: 1,
            parameters: {
              cameraId,
              displayParameters:
                streamType === 'MainVideo'
                  ? mainDisplayParameters
                  : altDisplayParameters,
              videoStreamParameters: {
                streamType: streamType === 'MainVideo' ? 'main' : 'alternative',
                streamFormat: 'mjpeg',
              },
              soundStreamParameters: {
                isSoundOn: false,
              },
            },
          })
        );
        this.interval = setInterval(() => {
          this.socket.send(
            JSON.stringify({
              requestId: this.thirdRequestType,
              commandType: 3,
              parameters: {},
            })
          );
        }, 30 * 1000);
      }
    };
  };

  onMessage = (img, imageVisible, handleLoad, handleError) => {
    this.socket.onmessage = (event) => {
      if (event.data.size < 100) {
        this.onMessageAttempts++;
        if (this.onMessageAttempts === 4) {
          this.socket.close();
          clearInterval(this.interval);
          handleError();
        }
      }
      if (img.current) {
        if (event.data.size > 1000) {
          this.onMessageAttempts = 0;
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64 = reader.result.substr(reader.result.indexOf(',') + 1);
            const raw = atob(base64);
            const rawJpeg = raw.substr(raw.indexOf('ÿØÿà'));
            if (Object.keys(img).length) {
              if (!imageVisible.visible) {
                const base64Image = await new Promise((resolve) => {
                  const image = btoa(rawJpeg);
                  if (image) {
                    resolve(image);
                  }
                });
                await new Promise((resolve) => {
                  resolve(handleLoad());
                });
                img.current.src = `data:image/jpeg;base64,${base64Image}`;
              }
              if (imageVisible.visible) {
                const base64Image = btoa(rawJpeg);
                if (base64Image && get(img, 'current')) {
                  img.current.src = `data:image/jpeg;base64,${base64Image}`;
                }
              }
              if (!imageVisible.visible) {
                img.current.style = 'visibility: visible;';
                imageVisible.visible = true;
              }
            }
          };
          reader.readAsDataURL(event.data);
        }
      }
    };
  };

  onClose = () => {
    this.socket.onclose = (event) => {
      if (event.wasClean) {
        clearInterval(this.interval);
      } else {
        clearInterval(this.interval);
      }
    };
  };

  onError = (handleError) => {
    this.socket.onerror = (event) => {
      if (event.isTrusted) {
        handleError();
      }
      clearInterval(this.interval);
    };
  };

  close = () => {
    this.socket.close();
    clearInterval(this.interval);
  };
}
