import React from 'react';
import {
  getAltStream,
  displayImageStream,
  intlOptionVideoFormat,
  getVideoFormatsOption,
  notSupportedAltVideoStreamList,
} from 'helpers';
import { merge, get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { channelsActions } from 'actions';
import { Field, getFormValues, isValid } from 'redux-form';
import { Switch, TextField } from 'components/ReduxInputs';

let altStreamBtnActive = false;

const AlternativeVideoStreamControls = (props) => {
  const {
    intl,
    wizard,
    active,
    tested,
    portReachable,
    deviceVideoFormat,
    videoStreamValues,
    activateVideoLoading,
  } = props;

  const isAltStreamSwitchBtnDisabled = () => {
    const {
      wizard,
      cameraConfigFormValid,
      cameraConfigValues,
      altStreamDisabled,
    } = props;
    if (
      !cameraConfigFormValid ||
      (cameraConfigValues.Brand &&
        (cameraConfigValues.Brand === notSupportedAltVideoStreamList.ONVIF ||
          (cameraConfigValues.data.ConnectionSettings &&
            cameraConfigValues.data.ConnectionSettings.ModelId ===
              notSupportedAltVideoStreamList.DAHUA)))
    )
      return true;
    if (
      !wizard &&
      get(
        cameraConfigValues,
        'data.ConnectionSettings.AltVideoStreamFormat'
      ) === 'NotSelected'
    ) {
      return true;
    }
    if (tested && get(props.videoStreamValues, 'isVideoTested')) {
      return false;
    }
    if (altStreamDisabled) {
      return true;
    }
    if (active.videoStreamError) {
      return false;
    }
    if (!cameraConfigFormValid) {
      return true;
    }
    if (get(props.videoStreamValues, 'isVideoTested')) {
      altStreamBtnActive = false;
      return false;
    }
    if (tested && !get(props.videoStreamValues, 'isVideoTested')) {
      return true;
    }
    if (!tested && active.mainVideoStreamActive) {
      altStreamBtnActive = true;
      return true;
    }
    if (!tested && portReachable && !altStreamBtnActive) {
      return false;
    }
    return true;
  };

  const activateAltVideoStreamFormat = (change, videoStreamValues) => {
    if (
      !get(videoStreamValues, 'data.ConnectionSettings.AltVideoStreamFormat')
    ) {
      change('data.ConnectionSettings.AltVideoStreamFormat', 'H264');
    }
  };

  const getCameraDataToUpdate = (cameraConfigValues, newValue, name) => {
    const defaultCameraData = {
      data: {
        Id: get(cameraConfigValues, 'data.Id'),
        Name: get(cameraConfigValues, 'data.Name'),
        ConnectionSettings: {
          ModelId: get(cameraConfigValues, 'data.ConnectionSettings.ModelId'),
          AltVideoStreamFormat: 'H264',
        },
      },
    };
    const propList = name.split('.');
    const connSettPropLvl2 = propList.pop();
    const connSettPropLvl1 = propList.pop();
    return merge(defaultCameraData, {
      data: {
        [connSettPropLvl1]: {
          [connSettPropLvl2]: newValue,
        },
      },
    });
  };

  const updateAlternativeStream = (event, newValue, previousValue, name) => {
    const { change, dispatch, cameraConfigValues, videoStreamValues } = props;
    activateAltVideoStreamFormat(change, videoStreamValues);
    if (!tested) {
      return;
    }

    const cameraDataToUpdate = getCameraDataToUpdate(
      cameraConfigValues,
      newValue,
      name
    );
    if (newValue) {
      activateVideoLoading({
        mainVideoStreamActive: false,
        altVideoStreamActive: true,
        videoStreamError: false,
      });
    }
    dispatch(
      channelsActions.addAlternativeChannelTest(
        cameraDataToUpdate,
        newValue,
        change,
        () =>
          activateVideoLoading({
            mainVideoStreamActive: false,
            altVideoStreamActive: false,
            videoStreamError: false,
          })
      )
    );
  };

  const portsValues = Object.values(portReachable);

  return (
    <Grid
      item
      xs={12}
      sm={6}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {displayImageStream(wizard, videoStreamValues, portsValues) && (
        <Grid item xs={12} sm={12}>
          <Field
            component={TextField}
            fullWidth
            select
            SelectProps={{ native: true }}
            disabled={!getAltStream(videoStreamValues)}
            label={intl.formatMessage({
              id: 'channels.form.codec.title',
            })}
            name="data.ConnectionSettings.AltVideoStreamFormat"
          >
            <option disabled value="" />
            <option disabled>
              {intlOptionVideoFormat(intl, deviceVideoFormat)}
            </option>
            {getVideoFormatsOption(deviceVideoFormat)}
          </Field>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={6}
        style={{ marginTop: 'auto', padding: '0 0 1.8rem 0' }}
      >
        <Field
          component={Switch}
          disabled={isAltStreamSwitchBtnDisabled()}
          onChange={updateAlternativeStream}
          label={intl.formatMessage({ id: 'channels.form.altVideo' })}
          name="data.ConnectionSettings.AltVideoStreamEnabled"
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  portReachable: state.channels.portReachable,
  cameraConfigFormValid: isValid('CameraConfigForm')(state),
  cameraConfigValues: getFormValues('CameraConfigForm')(state),
  videoStreamValues: getFormValues('VideoStreamTestForm')(state),
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(AlternativeVideoStreamControls)
);
