import { ewalletConstant } from '../constants';

const initialState = {
  invoice: {},
  redirectUrl: '',
  withdrawRequest: false,
};

export function eWallet(state = initialState, action) {
  switch (action.type) {
    case ewalletConstant.GET_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case ewalletConstant.GET_PAY_ORDER_SUCCESS:
      return {
        ...state,
        payOrder: action.data,
      };
    case ewalletConstant.WITHDRAW_SUCCESS:
      return {
        ...state,
        withdrawRequest: true,
      };
    default:
      return state;
  }
}
