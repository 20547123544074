import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import { history } from 'helpers/history';

const customStyle = {
  textCenter: { textAlign: 'center' },
  header: { margin: '15px 0' },
  lead: { fontSize: 18 },
};

const IsAddFoundExists = ({ classes, invoice, cancelInvoice, loader }) => {
  const invoiceUrl = invoice ? `/invoice/${invoice.id}` : null;
  const invoiceLink = invoiceUrl ? (
    <NavLink to={invoiceUrl}>#{invoice.id}</NavLink>
  ) : null;

  return (
    <div className={classes.textCenter}>
      <div className={classes.lead}>
        <FormattedMessage
          id="payment.form.invoiceIsExists"
          values={{ invoice: invoiceLink }}
        />
        <br />
        <FormattedMessage id="payment.form.summ" />:
      </div>
      <h2 className={classes.header}>
        {invoice.total} {invoice.currencysuffix}
      </h2>
      {invoiceUrl && (
        <Button round color="rose" onClick={() => history.push(invoiceUrl)}>
          <FormattedMessage id="payment.form.pay" />
        </Button>
      )}
      {invoiceUrl && (
        <Button
          round
          color="rose"
          onClick={() => cancelInvoice(invoice.id)}
          loading={loader}
        >
          <FormattedMessage id="payment.form.cancel" />
        </Button>
      )}
    </div>
  );
};

export default withStyles(customStyle)(IsAddFoundExists);
