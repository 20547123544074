import { orderService } from 'services';
import { alertActions } from 'actions/alert.actions';
import { orderConstant } from 'constants/order.constants';
import { history } from 'helpers';
import { invoiceConstants } from 'constants/invoice.constants';
import { loaderActions } from '.';
import { userConstants } from '../constants';

export const orderActions = {
  reviewOrder,
  updateOrder,
  addOrder,
  getOrder,
  clear,
  createPaidFromBalance,
};

function clear() {
  return (dispatch) => {
    dispatch({ type: orderConstant.CLEAR_EDIT });
  };
}

function reviewOrder(data) {
  return (dispatch) => {
    dispatch({ type: orderConstant.SET_REVIEW, data });
  };
}

function updateOrder(serviceid, data) {
  return (dispatch) => {
    // dispatch(success(data));
    dispatch(loaderActions.show('orderFormAdd'));
    orderService.updateOrder(serviceid, data).then(
      ({ data }) => {
        dispatch(success(data));
        dispatch(alertActions.success('alert.order.updated'));
        dispatch(loaderActions.hide('orderFormAdd'));
        if (data.invoiceid) {
          history.push(`/invoice/${data.invoiceid}`);
        }
        // history.push("/channels");
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(
          alertActions.error(
            error.status === 500 ? 'order.error.update' : error.message
          )
        );
        dispatch(loaderActions.hide('orderFormPromocode'));
        dispatch(loaderActions.hide('orderFormAdd'));
      }
    );
  };

  function success(data) {
    return { type: invoiceConstants.CREATE_SUCCESS, data };
  }

  function failure(error) {
    return { type: invoiceConstants.CREATE_FAILURE, error };
  }
}

function addOrder(params) {
  return (dispatch) => {
    dispatch({ type: orderConstant.SET_REVIEW, data: params });
    dispatch(loaderActions.show('orderFormAdd'));
    orderService.addOrder(params).then(
      (data) => {
        dispatch(loaderActions.hide('orderFormAdd'));
        if (params.checkout) {
          dispatch({ type: orderConstant.CREATE_SUCCESS, data: data.data });
          dispatch({
            type: userConstants.UPDATE_USER_BALANCE,
            data: params.totalAmount,
          });
          if (params.pid === 1 || params.pid === 5) {
            history.push('/channels/list');
          } else {
            history.push(`/invoice/${data.invoiceid}`);
          }
        } else {
          dispatch({
            type: orderConstant.PRE_CALCULATE_SUCCESS,
            data: data.cartdata,
          });
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loaderActions.hide('orderFormAdd'));
      }
    );
  };

  // function request(user) {
  //   return { type: orderConstant.CREATE_REQUEST, user };
  // }

  // function success(data) {
  //   return { type: orderConstant.CREATE_SUCCESS, data };
  // }

  function failure(error) {
    return { type: orderConstant.CREATE_FAILURE, error };
  }
}

function getOrder(id) {
  return (dispatch) => {
    // dispatch(request({ username }));

    orderService.getOrder(id).then(
      (data) => {
        dispatch({ type: orderConstant.GET_SUCCESS, data: data.data });
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  // function request(user) {
  //   return { type: orderConstant.GET_REQUEST, user };
  // }

  // function success(order) {
  //   return { type: orderConstant.GET_SUCCESS, order };
  // }

  function failure(error) {
    return { type: orderConstant.GET_FAILURE, error };
  }
}

function createPaidFromBalance(params) {
  return (dispatch) => {
    // dispatch(request({ username }));

    orderService.createPaidFromBalance(params).then(
      (data) => {
        dispatch({ type: orderConstant.GET_SUCCESS, data: data.data });
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  // function request(user) {
  //   return { type: orderConstant.GET_REQUEST, user };
  // }

  // function success(order) {
  //   return { type: orderConstant.GET_SUCCESS, order };
  // }

  function failure(error) {
    return { type: orderConstant.GET_FAILURE, error };
  }
}
