import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { getFormValues } from 'redux-form';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity';
import LockIcon from '@material-ui/icons/Lock';
import BlockIcon from '@material-ui/icons/Block';

// core components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';

import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';

import { connect } from 'react-redux';
import { userActions } from 'actions';
import UserProfileForm from './UserProfileForm';
import UserPasswordForm from './UserPasswordForm';
import UserDeleteForm from './UserDeleteForm';
import PropTypes from 'prop-types';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.handleUserForm = this.handleUserForm.bind(this);
    this.handlePasswordForm = this.handlePasswordForm.bind(this);
    this.handleDeleteForm = this.handleDeleteForm.bind(this);
  }

  componentWillMount() {
    const { dispatch, authentication } = this.props;
    dispatch(userActions.refreshToken(authentication.user.email));
  }

  handleUserForm(values) {
    delete values.token;
    const { dispatch } = this.props;
    dispatch(userActions.update(values));
    // dispatch(userActions.refreshToken());
  }

  handlePasswordForm(values) {
    const { dispatch, authentication } = this.props;
    dispatch(
      userActions.changePassword({
        ...values,
        macroscopUserId: authentication.user.macroscopUserId,
      })
    );
  }

  handleDeleteForm(values) {
    const { dispatch } = this.props;
    const id = this.props.authentication.user._id;
    dispatch(userActions.remove(id, values.password));
  }

  render() {
    const { classes, currencies, formValues } = this.props;

    return (
      <Fragment>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <PermIdentity />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="profile.form.userdata.title" />
            </h4>
          </CardHeader>
          <CardBody>
            <UserProfileForm
              onSubmit={this.handleUserForm}
              currencies={currencies}
              classes={classes}
              formValues={formValues}
            />
          </CardBody>
        </Card>
        <Clearfix />
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <LockIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="profile.form.password.title" />
            </h4>
          </CardHeader>
          <CardBody>
            <UserPasswordForm
              onSubmit={this.handlePasswordForm}
              classes={classes}
            />
          </CardBody>
        </Card>
        <Clearfix />
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <BlockIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="profile.form.delete.title" />
            </h4>
          </CardHeader>
          <CardBody>
            <UserDeleteForm
              onSubmit={this.handleDeleteForm}
              classes={classes}
            />
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    authentication,
    system: { currencies },
  } = state;

  return {
    authentication,
    currencies,
    formValues: getFormValues('UserProfileForm')(state),
  };
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  withStyles(userProfileStyles)(UserProfile)
);
