import {
  channelsConstant,
  orderConstant,
  partnerConstants,
} from '../constants';
// import Immutable from 'seamless-immutable';
import merge from 'lodash/merge';
import { prepareUserDataFromResponse } from '../helpers/userNormalizer';

const initialState = {
  all: [],
  edit: {
    data: {
      // ConnectionSettings: {
      //   MainVideoStreamFormat: "H264",
      //   AltVideoStreamEnabled: false
      // }
    },
    order: {},
    orderReview: {},
    cartData: {},
    customParam: {},
    product: {},
    testMode: {},
  },
  partner: {},
  portReachable: false,
  creatingCamera: false,
  channelSessionFailure: false,
};

export function channels(state = merge({}, initialState), action) {
  switch (action.type) {
    case channelsConstant.CHANNEL_SESSION_FAILURE: {
      return {
        ...state,
        channelSessionFailure: action.data,
      };
    }
    case channelsConstant.CREATING_CAMERA: {
      return {
        ...state,
        creatingCamera: action.data,
      };
    }
    case channelsConstant.ADD_CHANNELS: {
      const all = state.all.concat([action.channel]);
      return { ...state, ...all };
    }
    case channelsConstant.GET_SUCCESS:
      return { ...state, all: action.data };

    case channelsConstant.TOGGLE_ON_OFF_CHANNEL: {
      const allCh = state.all.map((item) => {
        if (item.channel.channelid === action.data.Id) {
          item.channel.data.Disabled = !item.channel.data.Disabled;
        }
        return item;
      });

      return {
        ...state,
        all: allCh,
      };
    }
    // case channelsConstant.DELETE_REQUEST:
    case channelsConstant.DELETE_SUCCESS:
      return {
        ...state,
        all: state.all.filter((item) => item.id !== action.data.Id),
      };

    case channelsConstant.ADD_SUCCESS:
    case channelsConstant.UPDATE_SUCCESS:
      return {
        ...state,
        edit: merge({}, action.data),
      };
    case channelsConstant.ADD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case channelsConstant.GET_ONE_SUCCESS:
      return {
        ...state,
        edit: merge({}, initialState.edit, {
          data: action.data.channel.data,
          product: action.data,
        }),
      };
    case orderConstant.CREATE_SUCCESS:
      return {
        ...state,
        edit: merge({}, state.edit, {
          order: action.data,
        }),
      };
    case orderConstant.PRE_CALCULATE_SUCCESS:
      return {
        ...state,
        edit: merge({}, state.edit, {
          cartData: action.data,
        }),
      };
    case orderConstant.SET_REVIEW:
      return {
        ...state,
        edit: merge({}, state.edit, {
          orderReview: action.data,
        }),
      };
    case channelsConstant.CLEAR_EDIT:
      return {
        ...state,
        edit: merge({}, initialState.edit),
        partner: {},
        portReachable: false,
        creatingCamera: false,
      };
    case channelsConstant.GET_STATUS_SUCCESS:
      return {
        ...state,
        edit: merge({}, state.edit, {
          testMode: action.data,
        }),
      };
    case partnerConstants.GET_PARTNER_SUCCESS:
      return {
        ...state,
        partner: prepareUserDataFromResponse(action.data),
      };
    case partnerConstants.GET_SELECTED_PARTNER_SUCCESS:
      return {
        ...state,
        partner: merge({}, prepareUserDataFromResponse(action.data), {
          partnerSelected: true,
        }),
      };
    case partnerConstants.GET_PARTNER_FAILURE:
      return {
        ...state,
        partner: action.error,
      };
    case partnerConstants.CLEAR_PARTNER:
      return {
        ...state,
        partner: {},
      };
    case channelsConstant.REACHABLE_SUCCESS:
      return { ...state, portReachable: action.data.data };

    case channelsConstant.CHANGE_PRIORITY:
      return {
        ...state,
        all: action.data,
      };

    default:
      return state;
  }
}
