import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from 'components/CustomButtons/ButtonWithLoader.js';

const SubmitEditChannel = (props) => {
  const onClick = () => props.submitEditChannel();
  return (
    <React.Fragment>
      {!props.wizard && (
        <div align="right" style={{ marginTop: 20 }}>
          <Button
            loading={props.formLoader}
            onClick={onClick}
            type="submit"
            color="danger"
          >
            <FormattedMessage id="channels.form.submitEditChannel" />
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(SubmitEditChannel);
