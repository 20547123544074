import axios from 'axios';

export const partnerService = {
  getStatus,
  verificationRequest,
  verificationReopen,
  getRegions,
  addRegion,
  editRegion,
  deleteRegion,
  getPartner,
  getPartners,
  getPartnersAbonents,
  confirmConnected,
  setRating,
};

/**
 * Get all available currencies
 * @returns {AxiosPromise<any>}
 */
function getStatus() {
  return axios.get(`/partner/verification`);
}

/**
 * Get all available currencies
 * @returns {AxiosPromise<any>}
 */
function verificationRequest() {
  return axios.post(`/partner/verification`);
}

/**
 * Get all available currencies
 * @returns {AxiosPromise<any>}
 */
function verificationReopen(ticketid) {
  return axios.patch(`/partner/verification`, { ticketid });
}

/**
 * Get all regions
 * @returns {AxiosPromise<any>}
 */
function getRegions() {
  return axios.get(`/partner/region`);
}

/**
 * Get add region
 * @returns {AxiosPromise<any>}
 */
function addRegion(data) {
  return axios.post(`/partner/region`, data);
}

/**
 * Get add region
 * @returns {AxiosPromise<any>}
 */
function editRegion(id, data) {
  return axios.patch(`/partner/region/${id}`, data);
}

/**
 * Get delete region
 * @returns {AxiosPromise<any>}
 */
function deleteRegion(regionId) {
  return axios.delete(`/partner/region/${regionId}`);
}

/**
 * Get one partner
 * @returns {AxiosPromise<any>}
 */
function getPartner(partnerId) {
  return axios.get(`/partner/${partnerId}`);
}

/**
 * Get all partners
 * @returns {AxiosPromise<any>}
 */
function getPartners({ place }) {
  return axios.get(`/partner`, { params: place ? { place } : {} });
}

function getPartnersAbonents() {
  return axios.get(`/partner/getpartnersabonents`);
}

function confirmConnected(data) {
  return axios.post(`/partner/confirmconnected`, data);
}

function setRating(affiliateChannelId, rating) {
  return axios.post(`/partner/setrating`, { affiliateChannelId, rating });
}
