import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from '@material-ui/core/styles/withStyles';
import { channelsConstant } from 'constants/cannels.constants';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

const ChannelSessionFailure = (props) => {
  const handleClose = () => {
    props.dispatch({
      type: channelsConstant.CHANNEL_SESSION_FAILURE,
      data: false,
    });
    document.location.reload(true);
  };

  const { intl, classes, channelSessionFailure } = props;
  return (
    <SweetAlert
      show={!!channelSessionFailure}
      warning
      title={intl.formatMessage({ id: 'channel.session.failure' })}
      confirmBtnText={intl.formatMessage({ id: 'subscribers.confirm.ok' })}
      confirmBtnCssClass={`${classes.button} ${classes.success}`}
      onConfirm={handleClose}
    />
  );
};

const mapStateToProps = (state) => ({
  channelSessionFailure: state.channels.channelSessionFailure,
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(
    withStyles(sweetAlertStyle)(ChannelSessionFailure)
  )
);
