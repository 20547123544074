import React from 'react';
import styles from '../styles';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withStyles } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from 'components/CustomButtons/ButtonWithLoader';

const billingCycleBlackList = ['', 'free account'];

const UpdateOrderBilling = (props) => {
  const { loader, wizard, classes, isXsDown, productId, btnSubmitAction } =
    props;

  const isTariffOrBillingCycleTouched = () => {
    return (
      props.channels.edit.product.billingcycle.toLowerCase() !==
        props.dataForm.billingCycle ||
      props.channels.edit.product.pid !== productId
    );
  };

  const isTariffDisabled = (item) =>
    props.tariff.hideTestProduct && (item.pid === 1 || item.pid === 5);

  const currentTariffIsIllegal = () => {
    const currentTariff = props.tariff.products.find(
      (e) => e.pid === productId
    );
    if (currentTariff) return isTariffDisabled(currentTariff);
    return true;
  };

  return !wizard ? (
    <TableRow>
      <TableCell colSpan={3} className={classes.right} padding="none">
        <div style={{ margin: '25px 0' }}>
          <Button
            color="info"
            round
            onClick={btnSubmitAction}
            loading={isTariffOrBillingCycleTouched() && loader}
            disabled={
              !isTariffOrBillingCycleTouched() ||
              (isTariffOrBillingCycleTouched() &&
                (currentTariffIsIllegal() ||
                  billingCycleBlackList.includes(props.dataForm.billingCycle)))
            }
            size={isXsDown ? 'sm' : null}
          >
            <FormattedMessage id="upgrade.product" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  ) : null;
};

const mapStateToProps = (state) => ({
  tariff: state.tariff,
  channels: state.channels,
  loader: state.loader.orderFormAdd,
  dataForm: getFormValues('EditChannel')(state),
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(
    withStyles(styles)(UpdateOrderBilling)
  )
);
