import { partnerConstants, userConstants } from '../constants';
import { prepareUserDataFromResponse } from '../helpers/userNormalizer';

const initialState = {
  loggedIn: false,
  user: {},
  recoveryPassword: {
    sendEmail: {
      result: false,
      email: '',
    },
    recovery: {
      result: false,
      email: '',
      pwresetkey: '',
    },
  },
  notConfirmEmail: false,
  language: 'ru',
};

initialState.user = prepareUserDataFromResponse(
  JSON.parse(localStorage.getItem('user'))
);

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user,
      };
    case userConstants.REFRESH_SUCCESS:
    case userConstants.LOGIN_SUCCESS:
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        user: prepareUserDataFromResponse(action.user),
      };
    case userConstants.GET_BALANCE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data,
        },
      };
    case userConstants.UPDATE_USER_BALANCE:
      return {
        ...state,
        user: {
          ...state.user,
          credit:
            state.user.credit - action.data >= 0
              ? state.user.credit - action.data
              : state.user.credit,
        },
      };

    case userConstants.LOGIN_FAILURE:
    case userConstants.LOGOUT:
      return {
        ...initialState,
        language: state.language,
      };
    case userConstants.LOGIN_NOT_CONFIRM:
      return {
        ...state,
        notConfirmEmail: action.data,
        loggingIn: false,
      };
    case userConstants.VALIDATE_CODE_RECOVERY_SUCCESS:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          recovery: action.data,
        },
      };
    case userConstants.SEND_EMAIL_RECOVERY_FAILURE:
    case userConstants.SEND_EMAIL_RECOVERY_SUCCESS:
      return {
        ...state,
        recoveryPassword: {
          ...state.recoveryPassword,
          sendEmail: action.data,
        },
      };
    case userConstants.CLEAR_RECOVERY:
      return {
        ...initialState,
      };

    case userConstants.CHANGE_LANG:
      return {
        ...state,
        language: action.language,
      };

    case partnerConstants.SET_RATING_SUCCESS: {
      const setRating = state.user.setRating.map((item) => {
        if (item.id === action.data.id) {
          item.rating = action.data.rating;
        }
        return item;
      });
      return {
        ...state,
        user: {
          ...state.user,
          setRating,
        },
      };
    }
    case userConstants.ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          stats: {
            ...state.user.stats,
            allnatification: --state.user.stats,
          },
        },
      };

    default:
      return state;
  }
}
