import React from 'react';
import OrderSum from './OrderSum';
import { injectIntl } from 'react-intl';
import OrderAddons from './OrderAddons';
import TableBody from '@material-ui/core/TableBody';
import SelectedTariffRows from './SelectedTariffRows';
import UpdateOrderAddons from '../UpdateOrder/UpdateOrderAddons';
import UpdateOrderBilling from '../UpdateOrder/UpdateOrderBilling';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const OrderContainer = (props) => {
  const {
    width,
    wizard,
    currency,
    modelParams,
    tariffState,
    selectedTariff,
    btnSubmitAction,
  } = props;

  const isXsDown = isWidthDown('xs', width);

  return (
    <TableBody>
      <SelectedTariffRows
        currency={currency}
        tariffState={tariffState}
        selectedTariff={selectedTariff}
      />
      <UpdateOrderBilling
        wizard={wizard}
        isXsDown={isXsDown}
        productId={selectedTariff.pid}
        btnSubmitAction={btnSubmitAction}
      />
      <OrderAddons
        wizard={wizard}
        currency={currency}
        modelParams={modelParams}
        selectedTariff={selectedTariff}
      />
      <OrderSum currency={currency} selectedTariff={selectedTariff} />
      <UpdateOrderAddons
        wizard={wizard}
        isXsDown={isXsDown}
        selectedTariff={selectedTariff}
        btnSubmitAction={btnSubmitAction}
      />
    </TableBody>
  );
};

export default injectIntl(withWidth()(OrderContainer));
