import React from 'react';
import { connect } from 'react-redux';
import Tooltip from 'components/Tooltip';
import Info from '@material-ui/icons/Info';
import { profileValidate } from './validate.js';
import GridItem from 'components/Grid/GridItem.jsx';
import AvatarUpload from 'components/AvatarUpload/';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import { injectIntl, FormattedMessage } from 'react-intl';
import PhoneInput from 'components/PhoneInput/PhoneInputMui';
import { Field, reduxForm, getFormValues } from 'redux-form';
import GridContainer from 'components/Grid/GridContainer.jsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import countriesListCodes from 'i18n-iso-countries/codes.json';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import RenderTextField from 'components/CustomInput/RenderTextField';
import RenderSelectField from 'components/CustomSelect/RenderSelectField';
import {
  normalizeCameraName,
  normalizeCameraLogin,
} from 'containers/Channels/Form/formsNormalization';

let UserProfileForm = (props) => {
  const { classes, handleSubmit, intl, loader, user } = props;

  const profilephoto = user ? user.profilephoto : null;

  const getCountryList = countriesListCodes
    .map((i) => ({
      value: i[0],
      label: intl.formatMessage({ id: `country.${i[0]}` }),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const makeTooltip = (text) => (
    <Tooltip title={text}>
      <InputAdornment position="end">
        <Info style={{ fontSize: 14, color: '#999' }} />
      </InputAdornment>
    </Tooltip>
  );

  const isPartnerAuthorized = () => {
    if (props.verification) {
      return props.verification.status === 'Closed';
    }
    return false;
  };
  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem xs={12} sm={4} md={3} lg={2}>
          <Field
            name="avatar"
            type="file"
            image={profilephoto}
            component={AvatarUpload}
            addButtonProps={{
              color: 'rose',
              round: true,
              text: intl.formatMessage({ id: 'profile.form.avatar.upload' }),
            }}
            changeButtonProps={{
              color: 'rose',
              round: true,
              disabled: Boolean(profilephoto),
              text: intl.formatMessage({ id: 'profile.form.avatar.change' }),
            }}
            removeButtonProps={{
              color: 'danger',
              round: true,
              text: intl.formatMessage({ id: 'profile.form.avatar.cancel' }),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={8} md={9} lg={10}>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Field
                name="firstname"
                component={RenderTextField}
                label={`${intl.formatMessage({ id: 'form.firstname' })}*`}
                formControlProps={{ fullWidth: true }}
                endAdornment={makeTooltip(
                  intl.formatMessage({
                    id: 'profile.form.partnerdata.description.name',
                  })
                )}
                disabled={isPartnerAuthorized()}
                normalize={(value, previousValue) =>
                  normalizeCameraLogin(value, previousValue, 24)
                }
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="lastname"
                component={RenderTextField}
                label={`${intl.formatMessage({ id: 'form.lastname' })}*`}
                formControlProps={{ fullWidth: true }}
                endAdornment={makeTooltip(
                  intl.formatMessage({
                    id: 'profile.form.partnerdata.description.name',
                  })
                )}
                disabled={isPartnerAuthorized()}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="nickname"
                component={RenderTextField}
                label={`${intl.formatMessage({ id: 'form.nickname' })}*`}
                formControlProps={{ fullWidth: true }}
                endAdornment={makeTooltip(
                  intl.formatMessage({
                    id: 'profile.form.partnerdata.description.nickname',
                  })
                )}
                disabled={isPartnerAuthorized()}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="email"
                component={RenderTextField}
                label={`${intl.formatMessage({ id: 'form.email' })}*`}
                formControlProps={{ fullWidth: true }}
                disabled
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                inputProps={{
                  disabled: isPartnerAuthorized(),
                }}
                name="phonenumber"
                component={PhoneInput}
                country={props.formValues && props.formValues.country}
                label={`${intl.formatMessage({ id: 'form.phone' })}*`}
                formControlProps={{ fullWidth: true }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="country"
                component={RenderSelectField}
                native
                label={`${intl.formatMessage({ id: 'form.country' })}*`}
                formControlProps={{ fullWidth: true }}
                inputProps={{ style: { textTransform: 'none' } }}
                disabled={isPartnerAuthorized()}
              >
                <option disabled value="" />
                <option disabled value="">
                  {intl.formatMessage({ id: 'form.country.select' })}
                </option>
                {getCountryList.map((el, idx) => (
                  <option key={idx} value={el.value}>
                    {el.label}
                  </option>
                ))}
              </Field>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="city"
                component={RenderTextField}
                label={`${intl.formatMessage({ id: 'form.city' })}*`}
                formControlProps={{ fullWidth: true }}
                normalize={(value, previousValue) =>
                  normalizeCameraName(value, previousValue, 30)
                }
                disabled={isPartnerAuthorized()}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="language"
                component={RenderSelectField}
                native
                label={intl.formatMessage({ id: 'form.language' })}
                formControlProps={{ fullWidth: true }}
                inputProps={{ style: { textTransform: 'none' } }}
                // displayEmpty
              >
                <option disabled value="" />
                <option disabled value="">
                  {intl.formatMessage({ id: 'language.select' })}
                </option>
                <option value="russian">
                  {intl.formatMessage({ id: 'language.ru' })}
                </option>
                <option value="ukraine">
                  {intl.formatMessage({ id: 'language.ua' })}
                </option>
                <option value="english">
                  {intl.formatMessage({ id: 'language.en' })}
                </option>
              </Field>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="telegram"
                component={RenderTextField}
                label="Telegram"
                formControlProps={{ fullWidth: true }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="viber"
                component={PhoneInput}
                country={props.formValues && props.formValues.country}
                label="Viber"
                formControlProps={{ fullWidth: true }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="skype"
                component={RenderTextField}
                label="Skype"
                formControlProps={{ fullWidth: true }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="whatsapp"
                component={PhoneInput}
                country={props.formValues && props.formValues.country}
                label="WhatsApp"
                formControlProps={{ fullWidth: true }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <Button
        loading={loader}
        type="submit"
        color="rose"
        className={classes.updateProfileButton}
      >
        <FormattedMessage id="profile.form.userdata.submit" />
      </Button>
      <Clearfix />
    </form>
  );
};

UserProfileForm = reduxForm({
  form: 'UserProfileForm',
  validate: profileValidate,
  touchOnBlur: false,
})(UserProfileForm);

UserProfileForm = injectIntl(
  connect((state) => ({
    user: state.authentication.user,
    initialValues: {
      ...state.authentication.user,
    },
    loader: state.loader.userProfileForm,
    verification: state.partner.verification,
    formValues: getFormValues('UserProfileForm')(state),
  }))(UserProfileForm)
);

export default UserProfileForm;
