import React from 'react';
import TextFieldUI from '@material-ui/core/TextField';

const TextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextFieldUI
    label={label}
    error={Boolean(touched && invalid)}
    helperText={touched && error}
    {...input}
    {...custom}
    autoComplete="off"
  />
);

export default TextField;
