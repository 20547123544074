import { get } from 'lodash';
import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Field,
  isValid,
  reduxForm,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form';
import { styles } from '../styles';
import validate from '../validate.js';
import { connect } from 'react-redux';
import { partnerActions } from 'actions';
import PartnerCard from '../PartnerCard';
import { TextField } from 'components/ReduxInputs';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from 'components/CustomButtons/ButtonWithLoader.js';

class PartnerSelected extends Component {
  constructor(props) {
    super(props);
    props.innerRef(this);
  }

  state = {
    partnerSelected: false,
  };

  componentWillMount() {
    this.clearPartner();
  }

  isValid = () => {
    this.props.handleSubmit(() => {})();
    return this.props.formValid;
  };

  partnerHandleCheck = (check) => {
    this.setState({ partnerSelected: check });
  };

  getPartner = () => {
    const { dispatch, formValues, formError, touch } = this.props;
    if (get(formError, 'data.customParam.installer')) {
      touch('data.customParam.installer');
      return;
    }
    const partnerId = get(formValues, 'data.customParam.installer');
    if (partnerId) {
      dispatch(partnerActions.getPartner(partnerId));
    }
  };

  selectPartner = () => {
    this.setState({ partnerSelected: true });
  };

  clearPartner = () => {
    const { dispatch, change } = this.props;
    this.setState({ partnerSelected: false });
    change('data.customParam.installer', '');
    dispatch(partnerActions.clearPartner());
  };

  handlePartnerEdit = (e) => {
    const { dispatch, channels } = this.props;
    const partnerCard = channels.partner;
    if (Object.keys(partnerCard).length && !e.target.value) {
      dispatch(partnerActions.clearPartner());
    }
  };

  onSubmit = () => {
    this.props.handleSubmit(() => {})();
  };

  render() {
    const {
      intl,
      change,
      channels,
      formValues,
      handleSubmit,
      loaderGetPartner,
    } = this.props;
    const partnerCard = (
      <PartnerCard
        change={change}
        partnerClear={this.clearPartner}
        partnerHandleCheck={this.partnerHandleCheck}
        partnerSelected={this.state.partnerSelected}
      />
    );
    return (
      <React.Fragment>
        <h3 style={{ margin: '20px 0 10px' }}>
          <FormattedMessage id="channels.form.group.other" />
        </h3>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Grid container alignItems="center" spacing={16}>
            {!this.state.partnerSelected &&
              !get(channels, 'partner.partnerSelected') && (
                <Grid item xs={12}>
                  <Field
                    style={{ paddingTop: '0.4rem' }}
                    component={TextField}
                    fullWidth
                    label={intl.formatMessage({
                      id: 'channels.form.installer',
                    })}
                    name="data.customParam.installer"
                    onChange={this.handlePartnerEdit}
                  />
                  {get(formValues, 'data.customParam.installer') && (
                    <Button
                      style={styles.btnPaddingWithMargin}
                      loading={loaderGetPartner}
                      onClick={this.getPartner}
                      color="success"
                      size="sm"
                      type="submit"
                    >
                      <FormattedMessage id="channels.form.partner.get" />
                    </Button>
                  )}
                  {Boolean(
                    Object.keys(channels.partner).length &&
                      channels.partner.partnercode &&
                      !channels.partner.partnerSelected
                  ) && (
                    <Fragment>
                      <Button
                        style={styles.btnPaddingWithMarginLeft}
                        color="success"
                        size="sm"
                        onClick={this.selectPartner}
                      >
                        <FormattedMessage id="channels.form.partner.select" />
                      </Button>
                      <Button
                        style={styles.btnPaddingWithMargin}
                        simple
                        color="danger"
                        size="sm"
                        onClick={this.clearPartner}
                      >
                        <FormattedMessage id="channels.form.partner.cancel" />
                      </Button>
                    </Fragment>
                  )}
                </Grid>
              )}
            {partnerCard}
          </Grid>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  channels: state.channels,
  loaderGetPartner: state.loader.getPartner,
  formValues: getFormValues('PartnerSelectedForm')(state),
  formError: getFormSyncErrors('PartnerSelectedForm')(state),
  formValid: isValid('PartnerSelectedForm')(state),
});
export default injectIntl(
  connect((state) => mapStateToProps(state))(
    reduxForm({
      form: 'PartnerSelectedForm',
      touchOnBlur: false,
      enableReinitialize: true,
      validate,
    })(PartnerSelected)
  )
);
