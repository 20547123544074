import React from 'react';
import ReactTable from 'react-table';
import { injectIntl } from 'react-intl';
import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';

class RegionsTable extends React.Component {
  render() {
    const { data, classes, intl, lang, remove, noEdit, ...other } = this.props;
    const tableTranslate = {
      previousText: intl.formatMessage({ id: 'layout.table.previousText' }),
      nextText: intl.formatMessage({ id: 'layout.table.nextText' }),
      loadingText: intl.formatMessage({ id: 'layout.table.loadingText' }),
      noDataText: intl.formatMessage({ id: 'layout.table.noDataText' }),
      pageText: intl.formatMessage({ id: 'layout.table.pageText' }),
      ofText: intl.formatMessage({ id: 'layout.table.ofText' }),
      rowsText: intl.formatMessage({ id: 'layout.table.rowsText' }),
    };
    const rowsData = data
      ? data.map((i) => {
          const row = {
            country: i[lang].country,
            region: i[lang].region,
            city: i[lang].city,
            district: i[lang].district,
            microdistrict: i[lang].microdistrict,
          };
          if (!noEdit && Object.keys(data).length > 1) {
            row.actions = (
              <div align="right">
                <Button
                  color="danger"
                  className={classes.actionButton}
                  onClick={() => remove(i._id)}
                >
                  <Close className={classes.icon} />
                </Button>
              </div>
            );
          }
          return row;
        })
      : [];
    const columns = [
      {
        Header: intl.formatMessage({ id: 'regions.table.country' }),
        accessor: 'country',
      },
      {
        Header: intl.formatMessage({ id: 'regions.table.region' }),
        accessor: 'region',
      },
      {
        Header: intl.formatMessage({ id: 'regions.table.city' }),
        accessor: 'city',
      },
      {
        Header: intl.formatMessage({ id: 'regions.table.district' }),
        accessor: 'district',
      },
      {
        Header: intl.formatMessage({ id: 'regions.table.microdistrict' }),
        accessor: 'microdistrict',
      },
    ];
    if (!noEdit) {
      columns.push({
        Header: intl.formatMessage({ id: 'regions.table.actions' }),
        accessor: 'actions',
        sortable: false,
        filterable: false,
      });
    }
    return (
      <ReactTable
        data={rowsData}
        {...tableTranslate}
        columns={columns}
        sortable
        defaultPageSize={10}
        showPaginationBottom
        className="-striped -highlight"
        {...other}
      />
    );
  }
}

export default injectIntl(withStyles(extendedTablesStyle)(RegionsTable));
