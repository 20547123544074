import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/Button.jsx';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Primary from 'components/Typography/Primary.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import PropTypes from 'prop-types';

const btnStyle = {
  margin: 0,
  paddingTop: '0.625rem',
  paddingBottom: '0.625rem',
};

const textStyle = {
  fontSize: 12,
  fontWeight: 400,
};

function ButtonGoBack({ ...props }) {
  const { classes, history } = props;
  const onClick = () => {
    const { pathname } = history.location;
    if (
      !['/invoice/paidall', '/invoice/transaction'].includes(pathname) &&
      /\/invoice\//.test(pathname)
    ) {
      history.push('/invoices');
    } else {
      history.goBack();
    }
  };
  return (
    <Button style={btnStyle} color="transparent" onClick={onClick}>
      <Primary>
        <span style={textStyle}>
          <KeyboardArrowLeft className={classes.icons} />
          <FormattedMessage id="layout.back" />
        </span>
      </Primary>
    </Button>
  );
}

ButtonGoBack.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withRouter(withStyles(buttonsStyle)(ButtonGoBack));
