import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import defaultImage from 'assets/img/avatar_placeholder.png';

const styles = {
  avatarContainer: {
    width: '100%',
    maxWidth: '100% !important',
    paddingBottom: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
};

const Avatar = (props) => (
  <div
    className={`${props.classes.avatarContainer} thumbnail ${
      props.avatar && 'img-circle'
    }`}
    style={{ backgroundImage: `url(${props.image || defaultImage})` }}
  />
);
export default withStyles(styles)(Avatar);
