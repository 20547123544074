import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';

import { userActions } from 'actions';
import AuthForm from './AuthForm';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      submitted: false,
      cardAnimaton: 'cardHidden',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      100
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit({ username, password }) {
    this.setState({ submitted: true });
    const { dispatch } = this.props;

    if (username.trim() && password.trim()) {
      dispatch(userActions.login(username.trim(), password.trim()));
    }
  }

  resendEmail() {
    const { email } = this.props.notConfirmEmail;

    this.props.dispatch(userActions.resendEmail(email));
  }

  render() {
    const { classes, notConfirmEmail } = this.props;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={6} lg={4}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>
                <FormattedMessage id="login.title" />
              </h2>
              <CardBody>
                <GridContainer justify="center">
                  {!notConfirmEmail ? (
                    <GridItem xs={12} sm={10} md={10}>
                      <AuthForm
                        classes={classes}
                        onSubmit={this.handleSubmit}
                      />
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 20,
                        }}
                      >
                        <NavLink to="/auth/recover" className={classes.navLink}>
                          <FormattedMessage id="login.passwordRecovery" />
                        </NavLink>
                      </div>
                    </GridItem>
                  ) : (
                    <GridItem xs={12} sm={10} md={10}>
                      <FormattedMessage id={notConfirmEmail.message} />
                      <br />
                      <Button color="rose" onClick={this.resendEmail} round>
                        <FormattedMessage id="confirm.message.error.resend" />
                      </Button>
                    </GridItem>
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { loggingIn, notConfirmEmail } = state.authentication;
  return {
    loggingIn,
    notConfirmEmail,
  };
}

export default connect(mapStateToProps)(
  withStyles(registerPageStyle)(LoginPage)
);
