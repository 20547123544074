import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import countrieslist from 'countries-list';

import { userActions } from '../../../actions';

import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import RegisterForm from './RegisterForm';
import Button from 'components/CustomButtons/Button.jsx';

import registerPageStyle from '../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: '' };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    const email = data.email.trim();
    this.setState({ email });
    data.groupid = 1;
    const trimed = Object.keys(data).reduce((obj, key) => {
      let value = data[key];
      if (typeof value === 'string') value = value.trim();
      obj[key] = value;
      return obj;
    }, {});
    const { dispatch } = this.props;
    const countryCode = countrieslist.countries[trimed.country].phone;
    dispatch(
      userActions.register({
        ...trimed,
        phone: trimed.phone.slice(countryCode.length + 1),
      })
    );
  }

  render() {
    const { registering, classes } = this.props;
    const { email } = this.state;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={7} lg={6}>
            <Card className={classes.cardSignup}>
              <CardBody>
                <GridContainer justify="center">
                  {!registering ? (
                    <GridItem xs={12} sm={10} md={10}>
                      <h2 className={classes.cardTitle}>
                        <FormattedMessage id="registration.partner.title" />
                      </h2>
                      <RegisterForm
                        classes={classes}
                        onSubmit={this.handleSubmit}
                      />
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 20,
                        }}
                      >
                        <NavLink to="/auth/register">
                          <FormattedMessage id="registration.form.asClient" />
                        </NavLink>
                      </div>
                    </GridItem>
                  ) : (
                    <GridItem xs={12} sm={10} md={10}>
                      <h3
                        className={classes.cardTitle}
                        style={{ marginBottom: 20 }}
                      >
                        <FormattedMessage id="registration.success.title" />
                      </h3>
                      <p align="center">
                        <FormattedMessage
                          id="registration.success.message"
                          values={{ email }}
                        />
                      </p>
                      <div align="center">
                        <NavLink to="/auth/login">
                          <Button color="primary" round>
                            <FormattedMessage id="layout.menu.login" />
                          </Button>
                        </NavLink>
                      </div>
                    </GridItem>
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering, checkMailUrl } = state.registration;
  return {
    registering,
    checkMailUrl,
  };
}

export default connect(mapStateToProps)(
  withStyles(registerPageStyle)(RegisterPage)
);
