import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Videocam from '@material-ui/icons/Videocam';
import Star from '@material-ui/icons/Star';
import LocalAtm from '@material-ui/icons/LocalAtm';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

const styles = {
  ...dashboardStyle,
  cardBody: {
    textAlign: 'right',
  },
  cardCategory: {
    ...dashboardStyle.cardCategory,
    paddingTop: 0,
  },
};

const matchWidth = (param, width) => {
  const ww = window.innerWidth;
  switch (param) {
    case 'up':
      return ww > width;
    case 'down':
      return ww < width;
    case 'equal':
      return ww === width;
    default:
      return ww;
  }
};

const PartnerStatistics = (props) => {
  const lgBreakpoint = matchWidth('up', 1600);
  return (
    <GridContainer>
      <GridItem xs={12} sm={6} lg={lgBreakpoint ? 3 : 6}>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <Videocam />
            </CardIcon>
            <CardBody className={props.classes.cardBody}>
              <h3 className={props.classes.cardTitle}>
                {props.data.cams ||
                  props.intl.formatMessage({
                    id: 'profile.partner.statistics.nodata',
                  })}
              </h3>
              <p className={props.classes.cardCategory}>
                <FormattedMessage id="profile.partner.statistics.cams" />
              </p>
            </CardBody>
          </CardHeader>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} lg={lgBreakpoint ? 3 : 6}>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <Star />
            </CardIcon>
            <CardBody className={props.classes.cardBody}>
              <h3 className={props.classes.cardTitle}>
                {props.data.rating ||
                  props.intl.formatMessage({
                    id: 'profile.partner.statistics.nodata',
                  })}
              </h3>
              <p className={props.classes.cardCategory}>
                <FormattedMessage id="profile.partner.statistics.rating" />
              </p>
            </CardBody>
          </CardHeader>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} lg={lgBreakpoint ? 3 : 6}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <LocalAtm />
            </CardIcon>
            <CardBody className={props.classes.cardBody}>
              <h3 className={props.classes.cardTitle}>
                {(props.data.profit && props.data.profit.toFixed(2)) ||
                  props.intl.formatMessage({
                    id: 'profile.partner.statistics.nodata',
                  })}
              </h3>
              <p className={props.classes.cardCategory}>
                <FormattedMessage id="profile.partner.statistics.profit" />
              </p>
            </CardBody>
          </CardHeader>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} lg={lgBreakpoint ? 3 : 6}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <LocalAtm />
            </CardIcon>
            <CardBody className={props.classes.cardBody}>
              <h3 className={props.classes.cardTitle}>
                {props.data.id ||
                  props.intl.formatMessage({
                    id: 'profile.partner.statistics.nodata',
                  })}
              </h3>
              <p className={props.classes.cardCategory}>
                <FormattedMessage id="profile.partner.statistics.id" />
              </p>
            </CardBody>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default injectIntl(connect()(withStyles(styles)(PartnerStatistics)));
