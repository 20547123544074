import { userConstants } from '../constants';

const initialState = {
  registering: false,
  checkMailUrl: '',
  confirmEmail: '',
  errorConfirm: false,
  confirmTextMessage: '',
};

export function registration(state = initialState, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return {
        loader: true,
        registering: false,
        checkMailUrl: '',
      };
    case userConstants.REGISTER_SUCCESS:
    case userConstants.RESEND_SUCCESS:
      return {
        loader: false,
        registering: true,
        confirmEmail: false,
        errorConfirm: false,
        checkMailUrl: action.data.checkMailUrl,
      };
    case userConstants.REGISTER_FAILURE:
      return {
        loader: false,
        registering: false,
        checkMailUrl: '',
      };
    case userConstants.CONFIRM_SUCCESS:
      return {
        ...state,
        confirmEmail: true,
        errorConfirm: false,
        confirmTextMessage: 'confirm.message.success',
      };
    case userConstants.CONFIRM_FAILURE:
      return {
        ...state,
        confirmEmail: true,
        errorConfirm: true,
        confirmTextMessage:
          action.error.status === 409
            ? 'confirm.message.error.email'
            : 'confirm.message.error.send',
      };
    default:
      return state;
  }
}
