import { authHeader } from '../helpers';
import axios from 'axios';
import { baseReactAPIUrl } from '../constants';

const serverUrl = baseReactAPIUrl;

export const orderService = {
  addOrder,
  updateOrder,
  getOrder,
  createPaid,
};

function updateOrder(serviceid, data) {
  return axios.put(`/order/${serviceid}`, data);
  /* const requestOptions = {
    method: "PUT",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify(data)
  };

  return fetch(`${config.serverUrl}/order/${serviceid}`, requestOptions).then(
    handleResponse
  ); */
}

function addOrder(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${serverUrl}/order`, requestOptions).then(handleResponse);
}

function getOrder(Id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${serverUrl}/order/${Id}`, requestOptions).then(handleResponse);
}

function createPaid(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${serverUrl}/order/createpaid`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        document.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
