import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invoiceService } from 'services';
import Button from 'components/CustomButtons/ButtonWithLoader';
import { FormattedMessage, injectIntl } from 'react-intl';

class ApplyInvoiceFromCreditButton extends Component {
  state = {
    loading: false,
  };

  applyInvoiceFromCredit = async () => {
    const { id, user } = this.props;
    this.setState({
      loading: true,
    });
    const invoice = await invoiceService.applyInvoice(id);
    if (invoice.data.result === 'success') {
      const storageUser = JSON.parse(localStorage.getItem('user'));
      storageUser.credit =
        user.credit - invoice.data.amount > 0
          ? user.credit - invoice.data.amount
          : user.credit;
      localStorage.setItem('user', JSON.stringify(storageUser));
      document.location.reload(true);
    }
  };

  render() {
    return (
      <span className="input-group-btn">
        <Button
          style={{ backgroundColor: '#3c763d', padding: '8px 25px' }}
          loading={this.state.loading}
          onClick={this.applyInvoiceFromCredit}
          size="sm"
        >
          <FormattedMessage id="invoice.submitInvoiceButton" />
        </Button>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(ApplyInvoiceFromCreditButton)
);
