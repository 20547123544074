import validate from '../validate';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  fillPorts,
  getFormData,
  getAltStream,
  isPortsValid,
  getStatusOption,
  getVideoFormats,
  setVideoFormats,
  getSupportedSettings,
  isVideoTestedBtnDisabled,
  notSupportedAltVideoStreamList,
} from 'helpers';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isEqual, merge, get } from 'lodash';
import MainVideoStreamControls from './MainVideoStreamControls';
import { alertActions, channelsActions, tariffActions } from 'actions';
import { getModelParams } from 'containers/Order/OrderContainer/helpers';
import { getFormError, getFormValues, isValid, reduxForm } from 'redux-form';
import AlternativeVideoStreamControls from './AlternativeVideoStreamControls';

class VideoStreamControls extends Component {
  state = {
    getTariffs: false,
    altStreamDisabled: false,
    autoSelectAltStream: true,
  };

  constructor(props) {
    super(props);
    props.innerRef(this);
  }

  componentDidMount() {
    const { wizard, channels, change, currentStep } = this.props;
    this.mounted = true;
    if (wizard && currentStep === 1) {
      this.setState({ getTariffs: true });
      this.props.dispatch(
        tariffActions.getTariffs(2, this.props.channels.edit.data.Id, true)
      );
    }
    if (!wizard && Object.keys(channels.edit.data).length) {
      change(
        'data.ConnectionSettings.MainVideoStreamFormat',
        get(channels.edit, 'data.ConnectionSettings.MainVideoStreamFormat')
      );
      if (get(channels.edit, 'data.ConnectionSettings.AltVideoStreamEnabled')) {
        this.setAltVideoStreamEnabled();
      }
    }
  }

  componentWillUpdate(nextProps) {
    this.deactivateTariffsIfAltVideoStreamDisabled(nextProps);
    this.getTariffsOnSecondWizardStep(nextProps);
    this.getTariffsIfAltVideoStreamDisabled(nextProps);
  }

  componentDidUpdate(prevProps) {
    const { wizard, change, channels, deviceManufactures, cameraConfigValues } =
      this.props;
    this.handleVideoTestUpdate(prevProps);
    this.handleVideoFormatsUpdate(prevProps);
    if (
      wizard ||
      (!wizard &&
        get(channels, 'edit.data.ConnectionSettings.AltVideoStreamEnabled'))
    ) {
      const portsValues = Object.values(this.props.portReachable);
      if (
        (isPortsValid(portsValues) && this.state.autoSelectAltStream) ||
        (isPortsValid(portsValues) &&
          get(cameraConfigValues, 'data.ConnectionSettings.ModelId') !==
            get(
              prevProps,
              'cameraConfigValues.data.ConnectionSettings.ModelId'
            ))
      ) {
        if (
          cameraConfigValues.Brand &&
          (cameraConfigValues.Brand === notSupportedAltVideoStreamList.ONVIF ||
            (cameraConfigValues.data.ConnectionSettings &&
              cameraConfigValues.data.ConnectionSettings.ModelId ===
                notSupportedAltVideoStreamList.DAHUA))
        ) {
          return null;
        }
        const cameraParams = getModelParams(
          deviceManufactures,
          cameraConfigValues.data.ConnectionSettings.ModelId
        );
        if (cameraParams) {
          if (!cameraParams.AltStreamSupported) {
            this.setState({
              altStreamDisabled: true,
              autoSelectAltStream: false,
            });
            change('data.ConnectionSettings.AltVideoStreamEnabled', false);
          } else {
            this.setState({
              autoSelectAltStream: false,
              altStreamDisabled: false,
            });
            this.setAltVideoStreamEnabled();
          }
        }
      } else if (
        !isPortsValid(portsValues) &&
        !this.state.autoSelectAltStream
      ) {
        this.setState({ autoSelectAltStream: true });
        change('data.ConnectionSettings.AltVideoStreamEnabled', false);
      }
    }
  }

  setAltVideoStreamEnabled() {
    this.props.change('data.ConnectionSettings.AltVideoStreamEnabled', true);
    this.props.change(
      'data.ConnectionSettings.AltVideoStreamFormat',
      get(
        this.props.channels.edit,
        'data.ConnectionSettings.AltVideoStreamFormat'
      ) || 'H264'
    );
  }

  deactivateTariffsIfAltVideoStreamDisabled = (nextProps) => {
    if (
      this.props.currentStep === 0 &&
      nextProps.currentStep === 1 &&
      get(
        this.props.videoStreamValues,
        'data.ConnectionSettings.AltVideoStreamEnabled'
      )
    ) {
      this.setState({ getTariffs: false });
    }
  };

  getTariffsOnSecondWizardStep = (nextProps) => {
    if (
      nextProps.sendForm &&
      this.props.channels.edit.data.Id &&
      !this.state.getTariffs
    ) {
      this.setState({ getTariffs: true });
      this.props.dispatch(
        tariffActions.getTariffs(null, this.props.channels.edit.data.Id, true)
      );
    }
  };

  getTariffsIfAltVideoStreamDisabled = (nextProps) => {
    if (
      this.state.getTariffs &&
      this.props.currentStep === 0 &&
      nextProps.currentStep === 1 &&
      !get(
        this.props.videoStreamValues,
        'data.ConnectionSettings.AltVideoStreamEnabled'
      )
    ) {
      this.setState({ getTariffs: true });
      this.props.dispatch(
        tariffActions.getTariffs(2, this.props.channels.edit.data.Id, true)
      );
    }
  };

  onUpdateChannelTestSuccess = (callback) => {
    if (this.mounted) {
      callback();
    }
  };

  isValid = () => {
    this.props.handleSubmit(() => {})();
    return this.props.tested;
  };

  handleVideoTestUpdate = (prevProps) => {
    if (!isEqual(this.props.tested, prevProps.tested)) {
      this.props.change('isVideoTested', this.props.tested);
    }
  };

  handleVideoFormatsUpdate = (prevProps) => {
    const { change, videoStreamValues } = this.props;
    if (
      get(this.props, 'cameraConfigValues.data.ConnectionSettings.ModelId') !==
        get(prevProps, 'cameraConfigValues.data.ConnectionSettings.ModelId') ||
      get(
        this.props,
        'cameraConfigValues.data.ConnectionSettings.AltVideoStreamEnabled'
      ) !==
        get(
          prevProps,
          'cameraConfigValues.data.ConnectionSettings.AltVideoStreamEnabled'
        )
    ) {
      const deviceVideoFormat = this.getDeviceVideoFormat();
      setVideoFormats(deviceVideoFormat, videoStreamValues, change);
    }
  };

  getDeviceVideoFormat = () => {
    const { cameraConfigValues, deviceManufactures } = this.props;
    return {
      main: getVideoFormats(
        'MainVideoStreamFormatsSupported',
        deviceManufactures,
        cameraConfigValues
      ),
      alt: getVideoFormats(
        'AltVideoStreamFormatsSupported',
        deviceManufactures,
        cameraConfigValues
      ),
    };
  };

  testVideo = () => {
    const {
      change,
      dispatch,
      deviceManufactures,
      cameraConfigValues,
      activateVideoLoading,
    } = this.props;
    if (!cameraConfigValues) return;
    fillPorts(deviceManufactures, cameraConfigValues, change);
    const params = getFormData(this.props);
    const cameraParams = getModelParams(
      deviceManufactures,
      cameraConfigValues.data.ConnectionSettings.ModelId
    );
    if (!cameraParams.AltStreamSupported) {
      params.data.ConnectionSettings.AltVideoStreamFormat = null;
    }
    params.data.testMode = true;
    if (params.data.ConnectionSettings.AltVideoStreamEnabled) {
      activateVideoLoading({
        mainVideoStreamActive: true,
        altVideoStreamActive: true,
        videoStreamError: false,
      });
    } else {
      activateVideoLoading({
        mainVideoStreamActive: true,
        altVideoStreamActive: false,
        videoStreamError: false,
      });
    }
    if (!params.data.Id) {
      dispatch(
        channelsActions.addChannelTest(
          params,
          params.data.ConnectionSettings.AltVideoStreamEnabled,
          change,
          () =>
            activateVideoLoading({
              mainVideoStreamActive: false,
              altVideoStreamActive: false,
              videoStreamError: false,
            }),
          this.onUpdateChannelTestSuccess
        )
      );
    } else {
      dispatch(channelsActions.updateChannelTest(params));
    }
  };

  isAnyCameraSettingsTouched = () => {
    const { cameraConfigValues, channels } = this.props;
    const channelData = Object.keys(channels.edit.data).length;
    if (!channelData) {
      return false;
    }
    return (
      cameraConfigValues.data.Name !== channels.edit.data.Name ||
      cameraConfigValues.data.ConnectionSettings.ModelId !==
        channels.edit.data.ConnectionSettings.ModelId ||
      cameraConfigValues.data.ConnectionSettings.Password !==
        channels.edit.data.ConnectionSettings.Password ||
      cameraConfigValues.data.ConnectionSettings.Login !==
        channels.edit.data.ConnectionSettings.Login ||
      `${cameraConfigValues.data.ConnectionSettings.Hostname}:${cameraConfigValues.customParam.Port}` !==
        channels.edit.data.ConnectionSettings.Hostname ||
      cameraConfigValues.data.ConnectionSettings.AltVideoStreamEnabled !==
        channels.edit.data.ConnectionSettings.AltVideoStreamEnabled
    );
  };

  testStreamAfterTheVideoLoop = () => {
    const {
      wizard,
      change,
      tested,
      dispatch,
      deviceManufactures,
      cameraConfigValues,
      activateVideoLoading,
    } = this.props;
    if (!wizard || tested) {
      const params = getFormData(this.props);
      const anyAddonsTouched = this.isAnyCameraSettingsTouched();
      const cameraParams = getModelParams(
        deviceManufactures,
        cameraConfigValues.data.ConnectionSettings.ModelId
      );
      if (
        !cameraParams.AltStreamSupported &&
        params.data.ConnectionSettings.AltVideoStreamFormat
      ) {
        delete params.data.ConnectionSettings.AltVideoStreamFormat;
      }
      if (
        !cameraParams.DeviceArchiveSupported &&
        params.data.ArchiveSettings.DeviceArchiveEnabled
      ) {
        params.data.ArchiveSettings.DeviceArchiveEnabled = false;
      }
      if (anyAddonsTouched) {
        dispatch(channelsActions.updateChannelTest(params));
      }

      if (params.data.Id) {
        activateVideoLoading({
          mainVideoStreamActive: true,
          altVideoStreamActive: get(
            params,
            'data.ConnectionSettings.AltVideoStreamEnabled'
          ),
          videoStreamError: false,
        });
        dispatch(
          channelsActions.testVideoAfterTestLoop(
            params.data.Id,
            params.data.ConnectionSettings.AltVideoStreamEnabled,
            change,
            () =>
              activateVideoLoading({
                mainVideoStreamActive: false,
                altVideoStreamActive: false,
                videoStreamError: false,
              })
          )
        );
      }
    }
  };

  testVideoLoop = () => {
    const {
      cameraConfigFormError,
      videoStreamTestFormError,
      deviceSettingsFormError,
      partnerSelectedFormError,
      touch,
    } = this.props;
    const errors = merge(
      cameraConfigFormError,
      videoStreamTestFormError,
      deviceSettingsFormError,
      partnerSelectedFormError
    );
    isVideoTestedBtnDisabled(errors, touch);
    this.setState({ altStreamDisabled: true });
    this.testVideo();
  };

  onSubmit = () => {
    const { dispatch, videoStreamTestFormValid } = this.props;
    if (!videoStreamTestFormValid) return;
    const params = getFormData(this.props);
    params.data.testMode = true;
    if (params.data.Id) {
      dispatch(channelsActions.updateChannel(params, this.props.formAction));
      if (!this.props.wizard) {
        // Todo: update order
      }
    }
    this.props.handleSubmit(() => {})();
  };

  componentWillUnmount = () => {
    this.mounted = false;
    this.props.dispatch(alertActions.clear());
    this.props.dispatch(channelsActions.clear());
  };

  render() {
    const {
      active,
      wizard,
      tested,
      editChannel,
      handleSubmit,
      videoStreamValues,
      cameraConfigValues,
      deviceManufactures,
      orderOptionsEnabled,
      activateVideoLoading,
    } = this.props;
    const isUseAltStream = getAltStream(videoStreamValues);
    const supportedSettings = getSupportedSettings(
      deviceManufactures,
      cameraConfigValues
    );
    const deviceSupportedSettings = getStatusOption(
      editChannel,
      orderOptionsEnabled,
      supportedSettings
    );
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Grid container spacing={16}>
            <MainVideoStreamControls
              wizard={wizard}
              active={active}
              tested={tested}
              testVideoLoop={this.testVideoLoop}
              deviceVideoFormat={this.getDeviceVideoFormat()}
              testStreamAfterTheVideoLoop={this.testStreamAfterTheVideoLoop}
            />
            <AlternativeVideoStreamControls
              wizard={wizard}
              active={active}
              tested={tested}
              change={this.props.change}
              isUseAltStream={isUseAltStream}
              activateVideoLoading={activateVideoLoading}
              deviceVideoFormat={this.getDeviceVideoFormat()}
              altStreamDisabled={this.state.altStreamDisabled}
              deviceSupportedSettings={deviceSupportedSettings}
            />
          </Grid>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  channels: state.channels,
  // editChannel: state.channels.edit,
  deviceManufactures: state.deviceManufactures,
  portReachable: state.channels.portReachable,
  testVideoLoader: state.loader.channelsTestVideo,
  cameraConfigValues: getFormValues('CameraConfigForm')(state),
  cameraConfigFormValid: isValid('CameraConfigForm')(state),
  partnerSelectedValues: getFormValues('PartnerSelectedForm')(state),
  deviceSettingsValues: getFormValues('DeviceSettingsForm')(state),
  videoStreamValues: getFormValues('VideoStreamTestForm')(state),
  cameraConfigFormError: getFormError('VideoStreamTestForm')(state),
  deviceSettingsFormError: getFormError('VideoStreamTestForm')(state),
  videoStreamTestFormError: getFormError('VideoStreamTestForm')(state),
  partnerSelectedFormError: getFormError('VideoStreamTestForm')(state),
  videoStreamTestFormValid: isValid('VideoStreamTest')(state),
  user: state.authentication.user,
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(
    reduxForm({
      form: 'VideoStreamTestForm',
      touchOnBlur: false,
      keepDirtyOnReinitialize: true,
      enableReinitialize: true,
      validate,
      initialValues: {
        channel: {},
        statistic: null,
      },
    })(VideoStreamControls)
  )
);
