import PropTypes from 'prop-types';
import OrderForm from './OrderForm';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Card from 'components/Card/Card';
import GridItem from 'components/Grid/GridItem';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import ShopTwo from '@material-ui/icons/ShopTwo';
import CardHeader from 'components/Card/CardHeader';
import {
  deviceManufacturesActions,
  channelsActions,
  loaderActions,
} from 'actions';

import GridContainer from 'components/Grid/GridContainer';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

class OrderView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      queryStatistic: false,
      NameState: '',
      'ConnectionSettings.ModelIdState': '',
      'ConnectionSettings.HostnameState': '',
      serviceId:
        props.match && props.match.params
          ? this.props.match.params.channelId
          : '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    if (this.props.match && this.props.match.params) {
      dispatch(loaderActions.show('pageLoader'));
      dispatch(
        channelsActions.getOneChannel(this.props.match.params.channelId, false)
      );
    }
    dispatch(deviceManufacturesActions.getDeviceManufactures());
  }

  render() {
    const { classes, channels, loader } = this.props;

    return (
      !loader && (
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <ShopTwo />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="order.title" />
              {` "${channels.edit.data.Name}"`}
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center">
              <GridItem xs={12} sm={11}>
                <OrderForm serviceId={this.state.serviceId} />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      )
    );
  }
}

OrderView.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { channels, tariff } = state;
  const loader = state.loader.pageLoader;
  return { channels, loader, tariff };
}

export default injectIntl(
  connect(mapStateToProps)(withStyles(regularFormsStyle)(OrderView))
);
