const formatGeoData = (data) => {
  const raw = data.address_components.reduce((obj, val) => {
    const name = val.long_name;
    obj.name = data.name;
    val.types.forEach((i) => (obj[i] = name));
    return obj;
  }, {});
  const formatted = {};
  formatted.country = raw.country;
  formatted.region =
    raw.administrative_area_level_1 || raw.administrative_area_level_2 || null;
  formatted.city = raw.locality || raw.administrative_area_level_2 || null;
  formatted.district = raw.sublocality || null;
  formatted.microdistrict = raw.neighborhood || raw.sublocality_level_2 || null;
  return formatted;
};

export default formatGeoData;
