import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { pushRulesActions, channelsActions, viewersActions } from 'actions';
import { history } from 'helpers';

import EmptyPlaceholder from './EmptyPlaceholder';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Table from 'components/Table/Table.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import LoaderAnimation from 'components/Loader';
import RemoveAlert from 'components/RemoveAlert';

import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import AlarmIcon from '@material-ui/icons/Alarm';
import Tooltip from '@material-ui/core/Tooltip';

import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';
import Settings from '@material-ui/icons/Settings';
import Close from '@material-ui/icons/Close';
import { pushRulesConstants } from '../../../constants';

class PushReceiving extends Component {
  state = { confirm: null, scenariosData: [] };

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(pushRulesActions.getPushRules());
    dispatch(channelsActions.getChannels());
    dispatch(viewersActions.getViewers());
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({
      type: pushRulesConstants.CLEAN_PUSH_RULES_SUCCESS,
      data: [],
    });
  }

  addRule = () => history.push('/push/add');

  editPushRule = (index) => history.push(`/push/edit/${index}`);

  removePushRule = (serviceId, index) => {
    this.setState({
      confirm: (confirmation) => {
        if (confirmation) {
          this.props.dispatch(pushRulesActions.removeRule(serviceId, index));
        }
        this.setState({ confirm: null });
      },
    });
  };

  getZoneName = (channelId, zoneId) => {
    const dataChannel = this.props.channels.find(
      (item) => item.channel.data.Id === channelId
    );
    let zone;
    if (dataChannel) {
      const zones =
        dataChannel.channel.data.AnalyzeSettings.MotionDetectorSettings.Zones;
      zone = zones.find((z) => z.Id === zoneId);
    }
    return zone ? zone.Name : '';
  };

  checkExistZones = (data) => {
    const zonesCount = data.reduce((count, item) => {
      return (count +=
        item.channel.data.AnalyzeSettings.MotionDetectorSettings.Zones.length ||
        0);
    }, 0);
    return zonesCount > 1;
  };

  render() {
    const {
      classes,
      intl,
      pageLoader,
      pushRulesList,
      user,
      channels,
      viewers,
    } = this.props;

    const existZones = this.checkExistZones(channels);

    if (pageLoader) return <LoaderAnimation />;

    if (!channels.length) {
      return (
        <EmptyPlaceholder
          classes={classes}
          addRule={() => history.push('/channels/add')}
          message={intl.formatMessage({ id: 'push.empty.add.channel' })}
          btnName={intl.formatMessage({
            id: 'push.empty.btn.name.add.channel',
          })}
        />
      );
    }
    if (!existZones) {
      return (
        <EmptyPlaceholder
          classes={classes}
          addRule={() => history.push('/zones/list')}
          message={intl.formatMessage({ id: 'push.empty.add.zone' })}
          btnName={intl.formatMessage({ id: 'push.empty.btn.name.add.zone' })}
        />
      );
    }
    if (!pushRulesList.length)
      return (
        <EmptyPlaceholder
          classes={classes}
          addRule={this.addRule}
          message={intl.formatMessage({ id: 'push.empty.add.push.rule' })}
          btnName={intl.formatMessage({
            id: 'push.empty.btn.name.add.scenario',
          })}
        />
      );

    const users = [...viewers, user];

    const mediaQueryUpXs = isWidthUp('sm', this.props.width);

    const tableData = pushRulesList.map((rule) => [
      rule.channelName,
      rule.textNotification,
      rule.clientIds
        .map((item) => users.find((el) => el.macroscopUserId === item).email)
        .join(', '),
      rule.zoneIds
        .map((item) => this.getZoneName(rule.channelId, item))
        .join(', '),
      [
        {
          color: 'info',
          icon: Settings,
          action: () => this.editPushRule(rule.index, rule.serviceId),
          tooltipMessage: intl.formatMessage({
            id: 'tooltip.message.settings',
          }),
        },
        {
          color: 'danger',
          icon: Close,
          action: () => this.removePushRule(rule.serviceId, rule.index),
          tooltipMessage: intl.formatMessage({ id: 'tooltip.message.delete' }),
        },
      ].map((prop, key) => {
        return (
          <Tooltip title={prop.tooltipMessage} key={key}>
            <Button
              color={prop.color}
              className={classes.actionButton}
              onClick={prop.action}
            >
              <prop.icon className={classes.icon} />
            </Button>
          </Tooltip>
        );
      }),
    ]);

    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <CardIcon color="info">
                      <AlarmIcon />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      <FormattedMessage id="push.receiving.title" />
                      <br />
                      <small>
                        <FormattedMessage id="push.receiving.description" />
                      </small>
                    </h4>
                  </GridItem>
                  <GridItem xs={12} sm={6} className={classes.right}>
                    <Button
                      color="primary"
                      size="sm"
                      fullWidth={!mediaQueryUpXs}
                      onClick={this.addRule}
                      style={{
                        marginTop: mediaQueryUpXs ? 15 : 30,
                      }}
                    >
                      <FormattedMessage id="push.list.add" />
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <Table
                  tableHead={[
                    intl.formatMessage({ id: 'push.list.table.channel.name' }),
                    intl.formatMessage({ id: 'push.list.table.text' }),
                    intl.formatMessage({ id: 'push.list.table.users.email' }),
                    intl.formatMessage({ id: 'push.list.table.zones.name' }),
                    intl.formatMessage({ id: 'push.list.table.actions' }),
                  ]}
                  tableData={tableData}
                  customCellClasses={[
                    classes.center,
                    classes.center,
                    classes.center,
                    classes.right,
                  ]}
                  customClassesForCells={[1, 2, 3, 4]}
                  customHeadCellClasses={[
                    classes.center,
                    classes.center,
                    classes.center,
                    classes.right,
                  ]}
                  customHeadClassesForCells={[1, 2, 3, 4]}
                />
              </CardBody>
            </Card>
            <Clearfix />
          </GridItem>
        </GridContainer>
        <RemoveAlert
          confirm={this.state.confirm}
          title={intl.formatMessage({ id: 'push.list.remove.title' })}
          description={intl.formatMessage({
            id: 'push.list.remove.description',
          })}
          successTitle={intl.formatMessage({
            id: 'push.list.remove.success',
          })}
          confirmBtnText={intl.formatMessage({
            id: 'push.list.remove.confirm',
          })}
          cancelBtnText={intl.formatMessage({
            id: 'push.list.remove.cancel',
          })}
        />
      </Fragment>
    );
  }
}

export default injectIntl(
  connect((state) => ({
    pageLoader: state.loader.pageLoader,
    pushRulesList: state.pushRules.list,
    channels: state.channels.all,
    viewers: state.viewers.contacts,
    user: state.authentication.user,
  }))(withWidth()(withStyles(extendedTablesStyle)(PushReceiving)))
);

// export default PushReceiving;
