import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Wizard from 'components/Wizard/Wizard.jsx';
import Step1 from './WizardSteps/Step1';
import Step2 from './WizardSteps/Step2';
import Step3 from './WizardSteps/Step3';
import withStyles from '@material-ui/core/styles/withStyles';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { channelsActions, invoiceActions } from 'actions';

const styles = {
  ...regularFormsStyle,
  hidden: {
    display: 'none !important',
  },
};

class WizardView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    props.dispatch(channelsActions.clear());
    props.dispatch(invoiceActions.clearWizard());
    // const { dispatch } = this.props;
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(channelsActions.clear());
    dispatch(invoiceActions.clearWizard());
  }

  render() {
    return (
      <Wizard
        validate
        steps={[
          {
            stepName: this.props.intl.formatMessage({
              id: 'wizard.step1.title',
            }),
            stepComponent: Step1,
            stepId: 'channel',
          },
          {
            stepName: this.props.intl.formatMessage({
              id: 'wizard.step2.title',
            }),
            stepComponent: Step2,
            stepId: 'order',
          },
          {
            stepName: this.props.intl.formatMessage({
              id: 'wizard.step3.title',
            }),
            stepComponent: Step3,
            stepId: 'paid',
          },
        ]}
        title={this.props.intl.formatMessage({ id: 'wizard.title' })}
        subtitle=""
        previousButtonText={this.props.intl.formatMessage({
          id: 'wizard.button.prev',
        })}
        nextButtonText={this.props.intl.formatMessage({
          id: 'wizard.button.next',
        })}
        finishButtonClasses={this.props.classes.hidden}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { deviceManufactures, channels, authentication: user } = state;
  return {
    deviceManufactures,
    channels,
    ...user,
  };
};

export default injectIntl(
  connect(mapStateToProps)(withStyles(styles)(WizardView))
);
