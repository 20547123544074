import React from 'react';
import Button from 'components/CustomButtons/Button.jsx';
import { FormattedMessage } from 'react-intl';

export default class Pagination extends React.Component {
  moveNext = () =>
    this.props.change(
      this.props.current + 1 > this.props.total
        ? this.props.total
        : this.props.current + 1
    );

  movePrev = () =>
    this.props.change(this.props.current - 1 < 1 ? 1 : this.props.current - 1);

  moveStart = () => this.props.change(1);

  moveEnd = () => this.props.change(this.props.total);

  moveTo = (e) => this.props.change(e.currentTarget.dataset.page);

  buttonsArray = () => {
    const { current, total } = this.props;
    let { visible } = this.props;
    if (visible > total) visible = total;
    const middle = Math.floor(visible / 2);
    let offset = middle;
    if (current <= middle) offset = current - 1;
    if (current > total - middle) offset = visible - (total - current + 1);
    return Array.from({ length: visible }, (el, idx) => {
      const page = current - offset + idx;
      return (
        <Button
          simple={page !== current}
          round
          color="rose"
          size="sm"
          onClick={this.moveTo}
          data-page={page}
          key={idx}
        >
          {page}
        </Button>
      );
    });
  };

  render = () => (
    <div align="center" style={this.props.style}>
      <Button simple round color="rose" size="sm" onClick={this.moveStart}>
        &laquo;
      </Button>
      <Button simple round color="rose" size="sm" onClick={this.movePrev}>
        <FormattedMessage id="layout.table.previousText" />
      </Button>

      {this.buttonsArray()}

      <Button simple round color="rose" size="sm" onClick={this.moveNext}>
        <FormattedMessage id="layout.table.nextText" />
      </Button>
      <Button simple round color="rose" size="sm" onClick={this.moveEnd}>
        &raquo;
      </Button>
    </div>
  );
}
