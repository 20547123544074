export const cameraLoadingStatus = {
  id: 1,
  data: 'channels.video.status.loading',
};
export const cameraConnectionStatus = {
  id: 2,
  data: 'channels.video.status.connection',
};
export const cameraCreationStatus = {
  id: 3,
  data: 'channels.video.status.creatingCamera',
};

export const getErrorStatus = ({ statusChannel = '', error = false }) => {
  return `channels.video.status.${error ? 'error' : statusChannel}`;
};
