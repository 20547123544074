import React from 'react';
import nouislider from 'nouislider';

// https://demos.creative-tim.com/material-dashboard-pro-react/#/documentation/sliders

class RangeSlider extends React.Component {
  componentDidMount() {
    nouislider.create(this.slider, this.props);
    this.slider.noUiSlider.set(this.props.value);
    if (this.props.onChange) {
      this.slider.noUiSlider.on('change', this.props.onChange);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.slider.noUiSlider.set(this.props.value);
    }
  }

  componentWillUnmount() {
    this.slider.noUiSlider.off('change');
  }

  render() {
    return (
      <div className="slider-primary" ref={(node) => (this.slider = node)} />
    );
  }
}

export default RangeSlider;
