import React from 'react';
import styles from '../styles';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { totalSum, totalAddonsSum } from './helpers';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';

const OrderSum = (props) => {
  const { dataForm, currency, classes, editChannel, selectedTariff } = props;
  return (
    totalAddonsSum(
      dataForm,
      selectedTariff,
      editChannel,
      dataForm.billingCycle,
      currency.code
    ) > 0 && (
      <React.Fragment>
        <TableRow>
          <TableCell />
          <TableCell className={classes.center}>
            <span className={classes.title}>
              <FormattedMessage id="order.form.table.addons" />
            </span>
          </TableCell>
          <TableCell className={classes.right}>
            <span className={classes.total}>
              {`${currency.prefix || ''}
          ${totalAddonsSum(
            dataForm,
            selectedTariff,
            editChannel,
            dataForm.billingCycle,
            currency.code
          )}
          ${currency.suffix || ''}`}
            </span>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell className={classes.center}>
            <span className={classes.title}>
              <FormattedMessage id="order.form.table.amount" />
            </span>
          </TableCell>
          <TableCell className={classes.right}>
            <span className={classes.total}>
              {`${currency.prefix || ''}
          ${totalSum(
            dataForm,
            selectedTariff,
            dataForm.billingCycle,
            currency.code
          )}
          ${currency.suffix || ''}`}
            </span>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  );
};

const mapStateToProps = (state) => ({
  editChannel: state.channels.edit,
  dataForm: getFormValues('EditChannel')(state),
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(withStyles(styles)(OrderSum))
);
