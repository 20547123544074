import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'actions';
import Grid from '@material-ui/core/Grid';
import FormChannel from 'containers/Channels/Form';
import withStyles from '@material-ui/core/styles/withStyles';

class Step1 extends React.Component {
  state = {
    sendForm: false,
    isValidate: false,
  };

  sendState = () => {
    this.setState({ sendForm: true });
    return this.state;
  };

  sendFormSate = (value) => this.setState({ sendForm: value });

  updateValidate = (value) => {
    return this.setState({ isValidate: value });
  };

  isValidated = () => {
    return this.formChannelRef.isValid();
  };

  componentDidMount = () => this.props.dispatch(userActions.getBalance());

  render = () => (
    <Grid container justify="center">
      <Grid item xs={12} lg={window.innerWidth > 1600 ? 8 : 10}>
        <FormChannel
          wizard
          // ref={this.formChannelRef}
          innerRef={(node) => {
            this.formChannelRef = node;
          }}
          formAction="add"
          sendForm={this.state.sendForm}
          sendFormSate={this.sendFormSate}
          currentStep={this.props.currentStep}
          updateValidate={this.updateValidate}
        />
      </Grid>
    </Grid>
  );
}

export default connect()(withStyles({})(Step1));
