import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';

import RenderTextField from 'components/CustomInput/RenderTextField';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

// import validate from "./validate";
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

const GetTransfer = ({ classes, handleSubmit, intl, onSubmit, loader }) => {
  return (
    <Card>
      <CardHeader>
        <h4 className={classes.cardIconTitle}>
          <FormattedMessage id="transfer.get" />
        </h4>
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <Field
                name="code"
                component={RenderTextField}
                label={intl.formatMessage({ id: 'transfer.form.code' })}
                formControlProps={{ fullWidth: true }}
              />
            </GridItem>
          </GridContainer>
          <div style={{ textAlign: 'right', margin: '20px 0 0' }}>
            <Button loading={loader} type="submit" color="rose">
              <FormattedMessage id="transfer.button.get" />
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  loader: state.loader.getTransfer,
});
export default injectIntl(
  connect(mapStateToProps)(
    withStyles(regularFormsStyle)(
      reduxForm({
        form: 'getTransfer',
        // validate,
        touchOnBlur: false,
      })(GetTransfer)
    )
  )
);
