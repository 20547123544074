import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoaderAnimation = () => (
  <div style={{ margin: 50, textAlign: 'center' }}>
    <CircularProgress size="3rem" thickness={2.5} />
  </div>
);

export default LoaderAnimation;
