import { get } from 'lodash';
import styles from './styles';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import LoaderAnimation from 'components/Loader';
import GridItem from 'components/Grid/GridItem';
import TableRow from '@material-ui/core/TableRow';
import withWidth from '@material-ui/core/withWidth';
import SweetAlert from 'react-bootstrap-sweetalert';
import TableHead from '@material-ui/core/TableHead';
import Warning from 'components/Typography/Warning';
import TableCell from '@material-ui/core/TableCell';
import { getFormValues, reduxForm } from 'redux-form';
import { systemService, tariffService } from 'services';
import GridContainer from 'components/Grid/GridContainer';
import { FormattedMessage, injectIntl } from 'react-intl';
import OrderContainer from './OrderContainer/OrderContainer';
import withStyles from '@material-ui/core/styles/withStyles';
import { getModelParams, totalSum } from './OrderContainer/helpers';
import { alertActions, invoiceActions, orderActions } from 'actions';

const initialState = {
  services: {},
  tariff: {},
  updated: false,
  editChannel: {},
  tariffState: '',
  addons: {},
  configoptions: {},
  modelParams: {},
  promocode: '',
  currency: {},
  modalIsOpen: false,
  products: {},
  promoModalIsOpen: false,
};

class OrderForm extends Component {
  state = initialState;

  componentDidMount = async () => {
    const { dispatch } = this.props;
    this.setState({ initialState });
    dispatch(invoiceActions.clearWizard());
    if (this.props.wizard) this.props.updateValidate(this.isValidated);
    await this.setCurrencyState();
    await this.setProductsState();
  };

  componentDidUpdate(prevProps) {
    this.componentDidUpdateProducts(prevProps);
    this.componentDidUpdateOnSubmit();
    this.componentDidUpdateTariff(prevProps);
    this.componentDidUpdateCameraSettings(prevProps);
  }

  componentDidUpdateOnSubmit = () => {
    const { dispatch, currentStep } = this.props;
    if (this.props.sendForm && currentStep > 1) {
      this.props.sendFormSate(false);
      const order = this.handleSubmit();
      dispatch(orderActions.addOrder(order));
    }
  };

  componentDidUpdateTariff = (prevProps) => {
    if (
      (Object.keys(this.state.products).length > 0 &&
        this.props.dataForm.formTariff !== prevProps.dataForm.formTariff) ||
      (Object.keys(this.state.products).length > 0 &&
        this.props.dataForm.billingCycle !== prevProps.dataForm.billingCycle)
    ) {
      const tariff = this.state.products.products.find(
        (item) => item.pid === this.props.dataForm.formTariff
      );
      if (
        prevProps.dataForm.formTariff === 1 ||
        prevProps.dataForm.formTariff === 5
      ) {
        this.props.change('billingCycle', 'monthly');
      }
      if (tariff) {
        this.setState({
          tariff,
        });
      }
    }
  };

  componentDidUpdateProducts = (prevProps) => {
    if (
      prevProps.tariff.products !== this.props.tariff.products ||
      (this.props.wizard &&
        this.props.dataForm.formTariff !== prevProps.dataForm.formTariff) ||
      (this.props.wizard &&
        this.props.dataForm.billingCycle !== prevProps.dataForm.billingCycle)
    ) {
      const products = get(this.props.tariff, 'products');
      if (products) {
        const tariff = products.find(
          (item) => item.pid === this.props.dataForm.formTariff
        );
        if (tariff) {
          this.setState({
            tariff,
          });
        }
      }
    }
  };

  componentDidUpdateCameraSettings = (prevProps) => {
    if (
      this.props.deviceManufactures.length &&
      Object.keys(this.props.channels.edit).length &&
      Object.keys(this.props.channels.edit.data).length > 1 &&
      (prevProps.channels.edit !== this.state.editChannel ||
        prevProps.tariff !== this.props.tariff) &&
      this.props.tariff.products &&
      this.props.tariff.products.length
    ) {
      // Get camera settings
      // Get camera avaliable params
      this.setState({
        editChannel: this.props.channels.edit,
        modelParams: getModelParams(
          this.props.deviceManufactures,
          this.props.channels.edit.data.ConnectionSettings.ModelId
        ),
      });
    }
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  btnSubmitAction = () => {
    if (
      this.props.channels.edit.product.pid === 5 ||
      this.props.channels.edit.product.pid === 1
    ) {
      this.updateOrder();
    } else if (
      Number.parseFloat(this.state.tariff.pricing.UAH.monthly, 10) <
      Number.parseFloat(
        this.props.tariff.products.filter(
          (item) => item.pid === this.props.channels.edit.product.pid
        )[0].pricing.UAH.monthly,
        10
      )
    ) {
      this.setState({ modalIsOpen: true });
    } else {
      this.updateOrder();
    }
  };

  updateOrder = () => {
    const { dataForm, channels } = this.props;

    const configoptions = {
      configoptionid: 'dropdownoptionid',
    };
    this.state.tariff.configoptions.configoption.map((item) => {
      configoptions[item.id] = {
        optionid: item.options.option[0].id,
        qty: get(dataForm, `data.${item.options.option[0].name}`) ? 1 : 0,
      };
    });

    const configoptions2 = {};
    this.state.tariff.configoptions.configoption.map((item) => {
      if (get(dataForm, `data.${item.options.option[0].name}`)) {
        configoptions2[item.id] = item.options.option[0].id;
      }
    });

    const options = {
      channelid: channels.edit.data.Id,
      totalAmount: totalSum(
        dataForm,
        this.state.tariff,
        dataForm.billingCycle,
        this.state.currency.code
      ),
      channelStatus: channels.edit.product.status,
      modelId: channels.edit.data.ConnectionSettings.ModelId,
      pid: dataForm.formTariff,
      billingcycle: dataForm.billingCycle,
      promocode: this.state.promocode,
      configoptions,
      configoptions2,
    };
    this.props.dispatch(
      orderActions.updateOrder(this.props.serviceId, options)
    );

    this.closeModal();
  };

  isValidated = () => {
    let validate = true;
    if (!Object.keys(this.state.tariff).length) {
      this.setState({ tariffState: 'error' });
      validate = false;
      this.props.dispatch(
        alertActions.error(
          this.props.intl.formatMessage({ id: 'order.form.validation.notarif' })
        )
      );
    }
    return validate;
  };

  handleSubmit = () => {
    const { channels, dataForm } = this.props;

    const configoptions = {};
    if (this.isValidated() || this.props.wizard) {
      if (Object.keys(this.state.tariff).length) {
        this.state.tariff.configoptions.configoption.map((item) => {
          if (get(dataForm, `data.${item.options.option[0].name}`)) {
            configoptions[item.id] = item.options.option[0].id;
          }
        });
      }

      const customfields = {};
      this.state.tariff.customfields.customfield.map((item) => {
        if (item.name === 'channelId') {
          customfields[item.id] = this.props.channels.edit.data.Id;
        }
        if (item.name === 'channelName') {
          customfields[item.id] = this.props.channels.edit.data.Name;
        }
        if (item.name === 'modelId') {
          customfields[item.id] =
            this.props.channels.edit.data.ConnectionSettings.ModelId;
        }
      });

      return {
        totalAmount: totalSum(
          dataForm,
          this.state.tariff,
          dataForm.billingCycle,
          this.state.currency.code
        ),
        channelid: channels.edit.data.Id,
        modelId: channels.edit.data.ConnectionSettings.ModelId,
        pid: this.state.tariff.pid,
        billingcycle: dataForm.billingCycle,
        domain: [
          this.props.channels.edit.data.Name,
          this.props.channels.edit.data.Id,
        ],
        addons: Object.keys(this.state.addons)
          .filter((item) => this.state.addons[item] === true)
          .join(','),
        customfields,
        promocode: this.state.promocode,
        configoptions,
      };
    }
  };

  setCurrencyState = async () => {
    const { data } = await systemService.getCurrencies();
    const currency = data.currencies.currency.find(
      (currency) => currency.id === this.props.user.currency
    );
    if (currency) {
      this.setState({
        currency,
      });
    }
  };

  setProductsState = async () => {
    if (!this.props.wizard) {
      const products = await tariffService.getTariffs(
        this.props.channels.edit.product.gid
      );
      if (get(products, 'products')) {
        const tariff = products.products.find(
          (item) => item.pid === this.props.channels.edit.product.pid
        );
        this.setState({
          tariff,
          products,
        });
      }
    }
  };

  render() {
    const { intl, wizard, classes, handleSubmit } = this.props;

    if (
      !wizard &&
      (!Object.keys(this.state.tariff).length ||
        !Object.keys(this.state.currency).length ||
        !Object.keys(this.state.products).length)
    ) {
      return <LoaderAnimation />;
    }
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <GridContainer justify="center" alignItems="center" direction="row">
            <GridItem xs={12} md={9}>
              <Warning>
                <FormattedMessage id="order.form.warning.tariff" />
              </Warning>
            </GridItem>
          </GridContainer>
          <div
            className={classes.tableResponsive}
            style={{ overflowX: 'none' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <FormattedMessage id="order.form.table.service" />
                  </TableCell>
                  <TableCell className={classes.right}>
                    <FormattedMessage id="order.form.table.cost" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <OrderContainer
                wizard={wizard}
                currency={this.state.currency}
                selectedTariff={this.state.tariff}
                tariffState={this.state.tariffState}
                modelParams={this.state.modelParams}
                btnSubmitAction={this.btnSubmitAction}
              />
            </Table>
          </div>
        </form>
        <SweetAlert
          show={this.state.modalIsOpen}
          warning
          showCancel
          title={intl.formatMessage({ id: 'tariff.change.message.title' })}
          onConfirm={this.updateOrder}
          onCancel={this.closeModal}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
          cancelBtnCssClass={`${classes.button} ${classes.danger}`}
          confirmBtnText={intl.formatMessage({
            id: 'tariff.change.btn.confirm',
          })}
          cancelBtnText={intl.formatMessage({
            id: 'tariff.change.btn.cancel',
          })}
        >
          <FormattedMessage id="tariff.change.message.text" />
        </SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    deviceManufactures,
    channels,
    tariff,
    order,
    authentication: user,
    loader,
  } = state;
  let dataForm = getFormValues('EditChannel')(state);
  if (!dataForm) dataForm = {};
  return {
    deviceManufactures,
    channels,
    tariff,
    order,
    dataForm,
    loader: loader.orderFormAdd,
    ...user,
  };
};

const OrderFormChannel = reduxForm({
  form: 'EditChannel',
  touchOnBlur: false,
  enableReinitialize: true,
})(OrderForm);

const getEditChannelInitialValues = (state) => {
  return {
    data: {
      ArchiveSettings: {
        DeviceArchiveEnabled:
          get(
            state.channels,
            'edit.product.configoptions.configoption[5].value'
          ) > 0 || false,
      },
      ConnectionSettings: {
        SoundReceivingEnabled:
          get(
            state.channels,
            'edit.product.configoptions.configoption[0].value'
          ) > 0 || false,
        SoundTransmittingEnabled:
          get(
            state.channels,
            'edit.product.configoptions.configoption[1].value'
          ) > 0 || false,
        PtzEnabled:
          get(
            state.channels,
            'edit.product.configoptions.configoption[2].value'
          ) > 0 || false,
      },
      CustomRows: {
        Viewers:
          get(
            state.channels,
            'edit.product.configoptions.configoption[3].value'
          ) > 0 || false,
        PushMessages:
          get(
            state.channels,
            'edit.product.configoptions.configoption[4].value'
          ) > 0 || false,
      },
    },
    billingCycle: get(state.channels, 'edit.product.billingcycle')
      ? state.channels.edit.product.billingcycle.toLocaleLowerCase()
      : 'monthly',
    formTariff: get(state.channels, 'edit.product.pid')
      ? state.channels.edit.product.pid
      : 'Choose a tariff',
  };
};

const ConnectedOrderFormChannel = injectIntl(
  connect((state) => ({
    loader: state.loader.userDeleteForm,
    channels: state.channels,
    initialValues: getEditChannelInitialValues(state),
  }))(OrderFormChannel)
);
export default injectIntl(
  connect(mapStateToProps)(
    withWidth()(withStyles(styles)(ConnectedOrderFormChannel))
  )
);
