export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  UPDATE_USER_BALANCE: 'UPDATE_USER_BALANCE',
  LOGIN_NOT_CONFIRM: 'USERS_LOGIN_NOT_CONFIRM_EMAIL',

  REFRESH_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_FAILURE: 'REFRESH_TOKEN_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_INFO_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_INFO_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_INFO_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',

  GET_BALANCE_REQUEST: 'USERS_GET_BALANCE_REQUEST',
  GET_BALANCE_SUCCESS: 'USERS_GET_BALANCE_SUCCESS',
  GET_BALANCE_FAILURE: 'USERS_GET_BALANCE_FAILURE',

  CONFIRM_REQUEST: 'CONFIRM_EMAIL_REQUEST',
  CONFIRM_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_FAILURE: 'CONFIRM_EMAIL_FAILURE',

  SEND_EMAIL_RECOVERY_SUCCESS: 'SEND_EMAIL_RECOVERY_PASSWORD_SUCCESS',
  SEND_EMAIL_RECOVERY_FAILURE: 'SEND_EMAIL_RECOVERY_PASSWORD_FAILURE',

  VALIDATE_CODE_RECOVERY_SUCCESS: 'VALIDATE_CODE_RECOVERY_PASSWORD_SUCCESS',
  VALIDATE_CODE_RECOVERY_FAILURE: 'VALIDATE_CODE_RECOVERY_PASSWORD_FAILURE',

  CLEAR_RECOVERY: 'CLEAR_RECOVERY',
  RESEND_SUCCESS: 'RESEND_SUCCESS',

  RESTORE_PASSWORD_SUCCESS: 'RESTORE_PASSWORD_SUCCESS',

  CHANGE_LANG: 'CHANGE_LANG',
  ALL_NOTIFICATION_SUCCESS: 'ALL_NOTIFICATION_SUCCESS',
};
