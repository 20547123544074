import { retry, getResolution, resolveResolution, failure } from '../helpers';
import { tariffService, channelsService } from 'services';

import { alertActions } from 'actions/alert.actions';
import { loaderActions } from 'actions/loader.actions';
import { tariffConstants } from 'constants/tariff.constants';

export const tariffActions = {
  getTariffs,
};

async function retryGetStatistic(channelId) {
  const func = async () => channelsService.getStatistic(channelId);
  const validator = (value) => {
    const mainResolution = getResolution(value, 'MainStream');
    const altResolution = getResolution(value, 'AlternativeStream');
    return altResolution > 0 || mainResolution > 0;
  };
  return retry(func, {
    validator,
    errorMsg: 'Wrong camera settings, please, try later',
    delayAfter: 2000,
  });
}

function getTariffs(groupId, channelId, wizard) {
  return async (dispatch) => {
    if (!groupId && channelId && wizard) {
      let statistic = null;
      try {
        statistic = (await retryGetStatistic(channelId)).statistic;
      } catch (error) {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error.message));
        return;
      }
      if (Object.keys(statistic).length) {
        const breakpoint = 720 * 567;
        const resolutionAlternative = getResolution(
          { statistic },
          'AlternativeStream'
        );
        const resolutionMain = getResolution({ statistic }, 'MainStream');
        const altResolutionIsLow = resolveResolution(
          resolutionAlternative,
          breakpoint
        );
        const mainResolutionIsLow = resolveResolution(
          resolutionMain,
          breakpoint
        );
        groupId = altResolutionIsLow || mainResolutionIsLow ? 1 : 2;
      }
    }

    tariffService.getTariffs(groupId).then(
      (data) => {
        dispatch({ type: tariffConstants.GET_SUCCESS, data });
      },
      (error) => {
        dispatch(loaderActions.hide('pageLoader'));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}
