import axios from 'axios';
import { orderService } from 'services';
import { baseReactAPIUrl } from '../constants';
import { authHeader, handleResponse } from 'helpers';

const serverUrl = baseReactAPIUrl;

export const invoiceService = {
  createInvoice,
  getInvoice,
  getTotalPriceAllChannelsUnpaid,
  getInvoiceItems,
  paidInvoices,
  createInvoiceOfInvoices,
  startTest,
  getPaymentLogs,
  getInvoiceFromOrder,
  viewInvoicelink,
  getInvoices,
  cancelInvoice,
  applyInvoice,
};

async function applyInvoice(invoiceid) {
  return axios
    .get(`/invoice/applyInvoice/${invoiceid}`)
    .catch((error) => handleResponse(error.response));
}

async function viewInvoicelink(invoiceid) {
  return axios
    .get(`/invoice/view/${invoiceid}`)
    .catch((error) => handleResponse(error.response));
}

async function createInvoice(data) {
  return orderService.updateOrder(data).then(() => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify(data),
    };

    return fetch(`${serverUrl}/invoice`, requestOptions).then(handleResponse);
  });
}

function getInvoice(Id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${serverUrl}/invoice/${Id}`, requestOptions).then(
    handleResponse
  );
}
function getInvoiceFromOrder(orderId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(
    `${serverUrl}/invoice/fromorder/${orderId}`,
    requestOptions
  ).then(handleResponse);
}

function getPaymentLogs() {
  return axios.get('/invoice/payment-logs', {
    // params: { pageNumber, nPerPage }
  });
}

function getInvoices(limitstart, limitnum, status) {
  return axios.get('/invoice/getall', {
    params: {
      limitstart,
      limitnum,
      status,
    },
  });
}

function cancelInvoice(invoiceId) {
  return axios.delete(`/invoice/${invoiceId}`);
}

function getTotalPriceAllChannelsUnpaid() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${serverUrl}/invoice/getAllUnpaidInvoice`, requestOptions).then(
    handleResponse
  );
}

function getInvoiceItems(status) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(
    `${serverUrl}/invoice/invocieitems/${status}`,
    requestOptions
  ).then(handleResponse);
}

async function paidInvoices(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify(data),
  };

  return fetch(`${serverUrl}/invoice/paidinvoices`, requestOptions).then(
    handleResponse
  );
}

async function createInvoiceOfInvoices(data) {
  return axios.post('/invoice/createinvoiceofinvoices', { invoicesids: data });
}

async function startTest(orderId) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify(orderId),
  };

  return fetch(`${serverUrl}/order/starttest`, requestOptions).then(
    handleResponse
  );
}
