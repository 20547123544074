import styles from './styles';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import React, { Component } from 'react';
import { alertActions } from '../../actions';
import { cameraLoadingStatus } from './utils';
import { SocketStreamService } from 'services';
import withStyles from '@material-ui/core/styles/withStyles';
import CameraCircularProgress from './CameraCircularProgress';
import VideoStatistics from '../VideoStatistics/VideoStatistics';

class VideoImageStream extends Component {
  constructor(props) {
    super(props);
    this.imgRef = React.createRef();
    this.imageVisible = {
      visible: false,
    };
  }

  componentDidMount() {
    this.stream = new SocketStreamService();
    this.stream.onOpen(
      this.props.channelId,
      {
        name: this.props.user.email,
        password: this.props.user.macroscopPassword,
      },
      this.props.statisticStreamType
    );
    this.stream.onMessage(
      this.imgRef,
      this.imageVisible,
      this.props.handleLoad,
      this.props.handleError
    );
    this.stream.onError(this.props.handleError);
  }

  componentDidUpdate(prevProps) {
    const { loading, cameraError } = this.props;
    if (loading && prevProps.cameraError) this.props.handleError();
    if (
      this.props.channelSessionFailure !== prevProps.channelSessionFailure ||
      this.props.channelAddError !== prevProps.channelAddError
    ) {
      this.props.handleError();
    }
    if (cameraError !== prevProps.cameraError) {
      this.props.dispatch(alertActions.error('Error add channel'));
    }
  }

  componentWillUnmount() {
    this.stream.close();
  }

  render() {
    const { classes, cameraLoading } = this.props;
    return (
      <div className={classes.container} key={Math.random()}>
        <img
          ref={this.imgRef}
          onError={this.props.handleError}
          className={`${classes.filler} ${classes.stream}`}
          alt="videoImage"
        />
        {!cameraLoading && (
          <VideoStatistics
            channelId={this.props.channelId}
            statisticStreamType={this.props.statisticStreamType}
            activateVideoLoading={this.props.activateVideoLoading}
          />
        )}
        {cameraLoading && !this.imageVisible.visible && (
          <CameraCircularProgress message={cameraLoadingStatus} />
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  channel: state.channels.edit,
  user: state.authentication.user,
  channelAddError: state.channels.error,
  channelSessionFailure: state.channels.channelSessionFailure,
}))(withStyles(styles)(injectIntl(VideoImageStream)));
