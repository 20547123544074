import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';

import Table from 'components/Table/Table.jsx';
import Switch from 'components/ReduxInputs/Switch';

import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import Warning from 'components/Typography/Warning';

const styles = {
  ...extendedTablesStyle,
  tableCol: { width: '20%' },
  tableHead: {
    fontSize: '1em !important',
    fontWeight: 'bold',
    color: '#000000a8',
  },
};

const renderSwitch = (props) => (
  <Switch checked={props.input.value} {...props} {...props.input} />
);

const ChannelsFields = (props) => {
  const { channels, classes, intl, disabledParams } = props;

  const commonFieldsParams = [
    {
      text: 'viewers.settings.table.soundTransmitting',
      field: 'channels.params.WorkplaceOptions.TransmitSoundEnabled',
    },
    {
      text: 'viewers.settings.table.soundReceiving',
      field: 'channels.params.WorkplaceOptions.ReceiveSoundEnabled',
    },
    {
      text: 'viewers.settings.table.ptz',
      field: 'channels.params.WorkplaceOptions.PtzEnabled',
    },
    {
      text: 'viewers.settings.table.push',
      field: 'channels.params.WebAndMobileOptions.MobilePushReceivingEnabled',
    },
  ];

  // const commonFields = commonFieldsParams.map(i => [
  //   <FormattedMessage key={1} id={i.text} />,
  //   <Field
  //     key={2}
  //     name={i.field}
  //     component={renderSwitch}
  //     disabled={disabledParams}
  //   />
  // ]);

  const tableData = channels
    // .filter(el => el.enabled)
    .map((item) => {
      let viewersServiceActive = false;
      item.configoptions.configoption.map((item) => {
        if (item.optionName === 'CustomRows.Viewers') {
          viewersServiceActive = !!item.value;
        }
      });

      return {
        id: item.channel.data.Id,
        name: `${item.channel.data.Name} ${
          !viewersServiceActive ? '( Функция пользователя недоступна. )' : ''
        }`,
        enabled: item.status === 'Active',
        viewersServiceActive,
      };
    })
    .filter((el) => el.enabled)
    .map((channel, idx) => {
      return [
        channel.name,
        <Field
          key={idx}
          disabled={!channel.viewersServiceActive}
          name={`channels.${channel.id}.ChannelsRealtimeAllowed`}
          component={renderSwitch}
        />,
        <Field
          key={idx}
          disabled={!channel.viewersServiceActive}
          name={`channels.${channel.id}.ChannelsArchiveAllowed`}
          component={renderSwitch}
        />,
      ];
    });

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <h4>
            <FormattedMessage id="viewers.settings.table.title" />
          </h4>
          <Warning>
            <FormattedMessage id="viewers.settings.table.title.warning" />
          </Warning>
        </CardHeader>
        <CardBody>
          <GridContainer>
            {commonFieldsParams.map((el, idx) => (
              <GridItem key={idx} xs={12} sm={6} md={3}>
                <Field
                  label={intl.formatMessage({ id: el.text })}
                  name={el.field}
                  component={renderSwitch}
                  disabled={disabledParams}
                />
              </GridItem>
            ))}
          </GridContainer>
          {/* <Table
            tableHead={[
              intl.formatMessage({
                id: "viewers.settings.table.nameOption"
              })
            ]}
            tableData={commonFields}
            customCellClasses={[`${classes.tableCol} ${classes.center}`]}
            customClassesForCells={[1]}
            customHeadCellClasses={[`${classes.tableHead}`]}
            customHeadClassesForCells={[0]}
          /> */}
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h4>
            <FormattedMessage id="viewers.settings.table.title2" />
          </h4>
        </CardHeader>
        <CardBody>
          <Table
            tableHead={[
              intl.formatMessage({ id: 'viewers.settings.table.name' }),
              intl.formatMessage({ id: 'viewers.settings.table.live' }),
              intl.formatMessage({ id: 'viewers.settings.table.archive' }),
            ]}
            tableData={tableData}
            customCellClasses={[
              `${classes.tableCol} ${classes.center}`,
              `${classes.tableCol} ${classes.center}`,
            ]}
            customClassesForCells={[1, 2]}
            customHeadCellClasses={[
              `${classes.tableHead}`,
              `${classes.tableHead} ${classes.tableCol} ${classes.center}`,
              `${classes.tableHead} ${classes.tableCol} ${classes.center}`,
            ]}
            customHeadClassesForCells={[0, 1, 2]}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default injectIntl(connect()(withStyles(styles)(ChannelsFields)));
