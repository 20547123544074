import styles from '../styles';
import { connect } from 'react-redux';
import { getPrice } from './helpers';
import React, { Component } from 'react';
import { Switch } from 'components/ReduxInputs';
import Danger from 'components/Typography/Danger';
import TableRow from '@material-ui/core/TableRow';
import { Field, getFormValues } from 'redux-form';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';

class OrderAddons extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.selectedTariff !== nextProps.selectedTariff ||
      this.props.dataForm.billingCycle !== nextProps.dataForm.billingCycle ||
      (Boolean(this.props.wizard) &&
        this.props.tariff.products !== nextProps.tariff.products)
    );
  }

  getPropsDisabled = (option) => {
    const nameArr = option.name.split('.');
    if (nameArr[0] !== 'CustomRows') {
      return !this.props.modelParams[
        nameArr[1].replace('Enabled', 'Supported')
      ];
    }
    return false;
  };

  getAddons = () => {
    const {
      classes,
      channels,
      currency,
      dataForm,
      selectedTariff,
      deviceManufactures,
    } = this.props;
    if (
      !(
        deviceManufactures.length &&
        channels.edit.data.Id &&
        Object.keys(selectedTariff).length
      )
    ) {
      return;
    }
    return selectedTariff.configoptions.configoption.map((item, index) => {
      const option = item.options.option[0];
      const disabled = this.getPropsDisabled(option);
      return (
        <TableRow key={index}>
          <TableCell className={classes.col1}>
            <div className={classes.title}>
              <FormattedMessage id={option.name} />
            </div>
            {disabled && (
              <Danger>
                <small>
                  <FormattedMessage id="order.form.warning" />
                </small>
              </Danger>
            )}
          </TableCell>
          <TableCell className={`${classes.col2} ${classes.center}`}>
            <Field
              disabled={disabled}
              name={`data.${item.options.option[0].name}`}
              component={Switch}
            />
          </TableCell>
          <TableCell className={`${classes.col3} ${classes.right}`}>
            <span className={classes.price}>
              {`${currency.prefix || ''}
              ${getPrice(option.pricing, currency.code, dataForm.billingCycle)}
              ${currency.suffix || ''}`}
            </span>
          </TableCell>
        </TableRow>
      );
    });
  };

  render() {
    return this.getAddons() || null;
  }
}

const mapStateToProps = (state) => ({
  tariff: state.tariff,
  channels: state.channels,
  dataForm: getFormValues('EditChannel')(state),
  deviceManufactures: state.deviceManufactures,
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(withStyles(styles)(OrderAddons))
);
