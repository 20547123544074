import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { baseReactMACROSCOPUrl } from '../../../constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { channelsActions, loaderActions } from 'actions';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import GridOn from '@material-ui/icons/GridOn';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import RangeSlider from 'components/RangeSlider';
import LoaderAnimation from 'components/Loader';
import ZonesCanvas from 'components/ZonesCanvas';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

class Zone {
  constructor(title, data = undefined) {
    this.Name = title;
    this.MinObjWidth = 0.1;
    this.MinObjHeight = 0.1;
    this.DetectionMap =
      'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA';
    this.DetectionMapWidth = 40;
    this.DetectionMapHeight = 30;
    if (data) Object.keys(data).forEach((key) => (this[key] = data[key]));
  }
}

class ZoneEdit extends Component {
  state = {
    view: 'grid',
    firstZone: null,
    zones: [],
    currentZone: 0,
    title: '',
    titleValid: true,
    cursorSize: 1,
  };

  componentDidMount = () => {
    const { dispatch, match } = this.props;
    if (match.params) {
      dispatch(loaderActions.show('pageLoader'));
      dispatch(channelsActions.getOneChannel(match.params.channelId));
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.channel !== prevProps.channel &&
      Object.keys(this.props.channel.data).length
    ) {
      const [firstZone, ...zones] =
        this.props.channel.data.AnalyzeSettings.MotionDetectorSettings.Zones;
      this.setState({ firstZone, zones });
    }
  }

  getActions = (actions) => (this.actions = actions);

  addZone = (title) => {
    const { zones } = this.state;
    if (zones.length) this.updateZone();
    zones.push(new Zone(title, zones.length ? null : this.actions.getData()));
    this.setState({ zones, currentZone: zones.length - 1 });
  };

  deleteZone = (index) => {
    this.updateZone();
    const { zones } = this.state;
    let { currentZone } = this.state;
    zones.splice(index, 1);
    if (!zones.length) this.actions.reset();
    if (currentZone >= zones.length) currentZone = zones.length - 1;
    this.setState({ zones, currentZone });
  };

  selectZone = (index) => {
    this.updateZone();
    this.setState({ currentZone: index });
  };

  updateZone = () => {
    const { zones, currentZone } = this.state;
    const data = this.actions.getData();
    Object.assign(zones[currentZone], data);
    this.setState({ zones });
  };

  fillGrid = () => this.actions && this.actions.fillGrid();

  clearGrid = () => this.actions && this.actions.clearGrid();

  setCursorSize = (size) => {
    this.setState({ cursorSize: size });
    if (this.actions) {
      this.actions.setCursorSize(parseInt(size[0], 10));
    }
  };

  addHandler = (e) => {
    e.preventDefault();
    const title = this.state.title.trim();
    if (!title || title.length > 100) {
      this.setState({ titleValid: false });
      return;
    }
    this.addZone(title);
    this.setState({ title: '' });
  };

  titleHandler = (e) => {
    const title = e.target.value;
    this.setState({ title, titleValid: true });
  };

  changeView = (e, view) => {
    this.setState({ view });
    this.actions.setView(view);
  };

  getVideoUrl = () => {
    const { user, channel } = this.props;
    const params = {
      serverURL: baseReactMACROSCOPUrl,
      login: user.email,
      password: user.macroscopPassword,
      // login: "root",
      // password: "827ccb0eea8a706c4c34a16891f84e7b",
      width: 1920,
      height: 1080,
      fps: 25,
    };
    return `${params.serverURL}/mobile?channelId=${channel.data.Id}&resolutionx=${params.width}&resolutiony=${params.height}&fps=${params.fps}&is_ajax=true&whoami=webclient&withcontenttype=true&login=${params.login}&password=${params.password}&usertype=Internal&streamtype=MainVideo`;
  };

  updateChannelsZone = () => {
    if (this.state.zones.length) this.updateZone();
    const { channel, dispatch } = this.props;
    const zones = [this.state.firstZone, ...this.state.zones];
    channel.data.AnalyzeSettings.MotionDetectorEnabled = true;
    channel.data.AnalyzeSettings.MotionDetectorSettings.Zones = zones;
    if (channel.data.ConnectionSettings.AltVideoStreamEnabled) {
      // Alternative / Main;
      channel.data.AnalyzeSettings.AnalyzeStreamType = 'Alternative';
    }
    const query = { zone: true };
    dispatch(channelsActions.updateChannel(channel, 'update', query));
  };

  render = () => {
    const { intl, classes, loader, btnLoader } = this.props;

    if (loader) return <LoaderAnimation />;

    const { zones, currentZone } = this.state;
    return (
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <GridOn />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            <FormattedMessage id="layout.menu.zones.edit" />
          </h4>
        </CardHeader>
        <CardBody>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={8} lg={9}>
              <Paper square>
                <Tabs
                  value={this.state.view}
                  onChange={this.changeView}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  centered
                >
                  <Tab
                    label={intl.formatMessage({ id: 'zone.size' })}
                    value="size"
                  />
                  <Tab
                    label={intl.formatMessage({ id: 'zone.grid' })}
                    value="grid"
                  />
                </Tabs>
              </Paper>
              <ZonesCanvas
                image={this.getVideoUrl()}
                data={zones[currentZone]}
                getActions={this.getActions}
              />
              {this.state.view === 'grid' && (
                <Fragment>
                  <Grid container spacing={8}>
                    <Grid item xs={12} sm={6}>
                      <div style={{ margin: '18px 0 5px' }}>
                        <RangeSlider
                          start={1}
                          connect
                          step={1}
                          range={{ min: 1, max: 8 }}
                          value={this.state.cursorSize}
                          onChange={this.setCursorSize}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Button
                        fullWidth
                        simple
                        color="primary"
                        size="sm"
                        onClick={this.fillGrid}
                      >
                        <FormattedMessage id="zone.fill" />
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Button
                        fullWidth
                        simple
                        color="primary"
                        size="sm"
                        onClick={this.clearGrid}
                      >
                        <FormattedMessage id="zone.clear" />
                      </Button>
                    </Grid>
                  </Grid>
                  <p>
                    <FormattedMessage id="zone.description" />
                  </p>
                </Fragment>
              )}
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              {Boolean(zones.length) && (
                <Fragment>
                  <h4>
                    <FormattedMessage id="zone.list" />
                  </h4>
                  <List dense>
                    {zones.map((item, idx) => (
                      <ListItem
                        button
                        selected={currentZone === idx}
                        onClick={() => this.selectZone(idx)}
                        key={idx}
                      >
                        <ListItemText primary={item.Name} />
                        <ListItemSecondaryAction>
                          <IconButton onClick={() => this.deleteZone(idx)}>
                            <CloseIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                  <hr />
                </Fragment>
              )}
              <h4>
                <FormattedMessage id="zone.add" />
              </h4>
              <form onSubmit={this.addHandler}>
                <TextField
                  fullWidth
                  name="title"
                  label={intl.formatMessage({ id: 'zone.name' })}
                  value={this.state.title}
                  error={!this.state.titleValid}
                  onChange={this.titleHandler}
                  helperText={
                    !this.state.titleValid
                      ? this.state.title.length > 100
                        ? intl.formatMessage(
                            { id: 'validation.length' },
                            { count: 100 }
                          )
                        : intl.formatMessage({ id: 'validation.required' })
                      : ''
                  }
                  inputProps={{ maxLength: 100 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton type="submit">
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
              <hr />
              <Button
                fullWidth
                loading={btnLoader}
                color="primary"
                onClick={this.updateChannelsZone}
              >
                <FormattedMessage id="zone.save" />
              </Button>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    );
  };
}

export default connect((state) => ({
  loader: state.loader.pageLoader,
  btnLoader: state.loader.channelsForm,
  user: state.authentication.user,
  channel: state.channels.edit,
}))(withStyles(regularFormsStyle)(injectIntl(ZoneEdit)));
