import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import CameraNameComponent from './CameraNameComponent';
import CameraPortsComponent from './CameraPortsComponent';
import CameraModelComponent from './CameraModelComponent';
import CameraTestPortsComponent from './CameraTestPortsComponent';
import CameraCredentialsComponent from './CameraCredentialsComponent';

const CameraConfigComponent = (props) => {
  return (
    <React.Fragment>
      <Grid item xs={12} lg={12}>
        <Grid container spacing={16}>
          <Grid item xs={12} lg={6}>
            <h3>
              <FormattedMessage id="channels.form.group.camera" />
            </h3>
          </Grid>
          <Grid item xs={12} lg={6}>
            <h3>
              <FormattedMessage id="channels.form.group.connection" />
            </h3>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container spacing={16} style={{ marginBottom: '5px' }}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={16}>
                <CameraNameComponent />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={16}>
                <CameraCredentialsComponent />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={16}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={16}>
                <CameraModelComponent change={props.change} />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={16}>
                <CameraPortsComponent />
                <CameraTestPortsComponent
                  onSubmit={props.onSubmit}
                  portsModalOpen={props.portsModalOpen}
                  handleTestPortsBtnDisabled={props.handleTestPortsBtnDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CameraConfigComponent;
