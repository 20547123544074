import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import countrieslist from 'countries-list';
import Card from 'components/Card/Card.jsx';
import LockIcon from '@material-ui/icons/Lock';
import BlockIcon from '@material-ui/icons/Block';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import PartnerDeleteForm from './PartnerDeleteForm';
import PartnerStatistics from './PartnerStatistics';
import { partnerActions, userActions } from 'actions';
import PartnerProfileForm from './PartnerProfileForm';
import CardHeader from 'components/Card/CardHeader.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import { injectIntl, FormattedMessage } from 'react-intl';
import PermIdentity from '@material-ui/icons/PermIdentity';
import PartnerAuthorization from './PartnerAuthorization';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import withStyles from '@material-ui/core/styles/withStyles';
import UserPasswordForm from '../UserProfile/UserPasswordForm';
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';

class PartnerProfile extends React.Component {
  componentWillMount() {
    const {
      dispatch,
      authentication: { user },
    } = this.props;
    if (Object.keys(user).length) {
      dispatch(partnerActions.getStatus());
    }
  }

  componentDidMount() {
    this.props.dispatch(partnerActions.getRegions());
  }

  handleProfileForm = (values) => {
    delete values.token;
    const { dispatch } = this.props;
    const countryCode = countrieslist.countries[values.country].phone;
    dispatch(
      userActions.update({
        ...values,
        phonenumber: values.phonenumber.slice(
          values.phonenumber.indexOf(`${countryCode}`) + countryCode.length
        ),
      })
    );
  };

  handlePasswordForm = (values) => {
    const { dispatch } = this.props;
    dispatch(userActions.changePassword(values));
  };

  handleDeleteForm = (values) => {
    const { dispatch } = this.props;
    const id = this.props.authentication.user._id;
    dispatch(userActions.remove(id, values.password));
  };

  handleAuthorization = (ticketid) => () => {
    const { dispatch } = this.props;

    if (ticketid) {
      dispatch(partnerActions.verificationReopen(ticketid));
    } else {
      dispatch(partnerActions.verificationRequest());
    }
  };

  render() {
    const { classes, formValues, verification, authentication } = this.props;

    return (
      <div>
        <PartnerStatistics
          data={{
            cams: authentication.user.stats.activeAbonents,
            rating: authentication.user.stats.rating,
            profit: authentication.user.stats.profit,
            id: authentication.user.partnercode,
          }}
        />
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <VerifiedUser />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="profile.form.authorization.title" />
            </h4>
          </CardHeader>
          <CardBody>
            <PartnerAuthorization
              onClick={this.handleAuthorization}
              classes={classes}
              user={authentication.user}
              verification={verification}
            />
          </CardBody>
        </Card>
        <Clearfix />
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <PermIdentity />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="profile.form.partnerdata.title" />
            </h4>
          </CardHeader>
          <CardBody>
            <PartnerProfileForm
              onSubmit={this.handleProfileForm}
              classes={classes}
              formValues={formValues}
            />
          </CardBody>
        </Card>
        <Clearfix />
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <LockIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="profile.form.password.title" />
            </h4>
          </CardHeader>
          <CardBody>
            <UserPasswordForm
              onSubmit={this.handlePasswordForm}
              classes={classes}
            />
          </CardBody>
        </Card>
        <Clearfix />
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <BlockIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="profile.form.delete.title" />
            </h4>
          </CardHeader>
          <CardBody>
            <PartnerDeleteForm
              onSubmit={this.handleDeleteForm}
              classes={classes}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

PartnerProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectIntl(
  connect((state) => ({
    authentication: state.authentication,
    formValues: getFormValues('UserProfileForm')(state),
    verification: state.partner.verification,
  }))(withStyles(userProfileStyles)(PartnerProfile))
);
