import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import Table from 'components/Table/Table.jsx';
import Assignment from '@material-ui/icons/Assignment';

import { ewalletActions, userActions } from 'actions';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';

const style = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class PaymentsSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payOrder: {},
    };
    if (props.match.params) {
      props.dispatch(ewalletActions.getPayOrder(props.match.params.orderId));
    }

    props.dispatch(userActions.refreshToken());
  }

  componentWillUpdate(nextProps) {
    if (
      Object.keys(nextProps.payOrder).length &&
      nextProps.payOrder !== this.state.payOrder
    ) {
      this.setState({ payOrder: nextProps.payOrder });
    }
  }

  render() {
    const { classes, payOrder, intl } = this.props;
    return (
      <GridContainer>
        <GridItem xs={5}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="payment.success.table.title" />
              </h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  intl.formatMessage({ id: 'payment.success.table.status' }),
                  payOrder.status,
                ]}
                tableData={[
                  // ["Статус", payOrder.status],
                  [
                    intl.formatMessage({
                      id: 'payment.success.table.description',
                    }),
                    payOrder.description,
                  ],
                  [
                    intl.formatMessage({ id: 'payment.success.table.paytype' }),
                    payOrder.paytype,
                  ],
                  [
                    intl.formatMessage({ id: 'payment.success.table.amount' }),
                    payOrder.amount,
                  ],
                  [
                    intl.formatMessage({
                      id: 'payment.success.table.currency',
                    }),
                    payOrder.currency,
                  ],
                  [
                    intl.formatMessage({
                      id: 'payment.success.table.createdAt',
                    }),
                    payOrder.createdAt,
                  ],
                  [
                    intl.formatMessage({
                      id: 'payment.success.table.updatedA',
                    }),
                    payOrder.updatedAt,
                  ],
                ]}
                coloredColls={[3]}
                colorsColls={['primary']}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const {
    eWallet: { payOrder },
  } = state;
  return {
    payOrder,
  };
}

export default injectIntl(
  connect(mapStateToProps)(withStyles(style)(PaymentsSuccess))
);
