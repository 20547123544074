const cameraNameRegex = new RegExp(
  /\s{2}|(\d+){5}|([+!@#$%^&*\\(){}|+";?№])|[-_="[\]:";',/]{2}/
);
const cameraLoginRegex = new RegExp(/\s/);
const portCameraRegex = new RegExp(/^\D+$/);
const firstIpCameraRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.)?){1}$/;
const secondIpCameraRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){1}(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.)?)$/;
const thirdIpCameraRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){2}(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.)?)$/;
const fourthIpCameraRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

const basicNormalizerWithRegex = (value, previousValue, size, regex) => {
  return value.length > size || regex.test(value) ? previousValue : value;
};

const ipCameraNormalizer = (
  value,
  previousValue,
  size,
  firstIpCameraRegex,
  secondIpCameraRegex,
  thirdIpCameraRegex,
  fourthIpCameraRegex
) => {
  if (value === '') {
    return value;
  }
  return value.length > size ||
    !(
      firstIpCameraRegex.test(value) ||
      secondIpCameraRegex.test(value) ||
      thirdIpCameraRegex.test(value) ||
      fourthIpCameraRegex.test(value)
    )
    ? previousValue
    : value;
};

export const basicNormalizer = (value, previousValue, size) => {
  return value.length > size ? previousValue : value;
};

export const normalizeCameraLogin = (value, previousValue, size) => {
  return basicNormalizerWithRegex(value, previousValue, size, cameraLoginRegex);
};

export const normalizeCameraName = (value, previousValue, size) => {
  return basicNormalizerWithRegex(value, previousValue, size, cameraNameRegex);
};

export const normalizeIpCamera = (value, previousValue, size) => {
  return ipCameraNormalizer(
    value,
    previousValue,
    size,
    firstIpCameraRegex,
    secondIpCameraRegex,
    thirdIpCameraRegex,
    fourthIpCameraRegex
  );
};

export const normalizeCameraPorts = (value, previousValue, size) => {
  return basicNormalizerWithRegex(value, previousValue, size, portCameraRegex);
};

export const normalizeAuthForms = (value) => {
  return value.trim();
};

export const handleAutocomplete = ({ target }) => {
  const { value } = target;
  if (value.length > 2) {
    target.autocomplete = 'on';
  }
};
