import Layer from '../../classes/Layer';
import Circle from '../../classes/Circle';

export class Marker extends Layer {
  constructor() {
    super();
    this.outer = new Circle();
    this.outer.setColor('#3f51b5');
    this.inner = new Circle();
    this.inner.setColor('#FFFFFF');
    this.hover = false;
    this.setSize();
    this.setPosition();
  }

  setSize(size = 10) {
    this.outer.setSize(size);
    this.inner.setSize(size / 2);
  }

  setPosition(position = { x: 20, y: 20 }) {
    this.outer.setPosition(position);
    this.inner.setPosition(position);
  }

  getBounds() {
    return this.outer.getBounds();
  }

  draw(ctx = undefined) {
    if (!ctx) throw 'No canvas';
    if (!this.visible) return;
    this.outer.draw(ctx);
    if (this.hover) this.inner.draw(ctx);
  }
}

export default Marker;
