export default {
  button: {
    height: 'auto',
    margin: 0,
    padding: 10,
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '30px',
    textTransform: 'none',
  },
  menuitem: {
    fontSize: 14,
    fontWeight: 300,
  },
  '@media (max-width: 959px)': {
    container: {
      padding: '10px 15px 0 15px',
      marginBottom: -10,
    },
    icon: {
      marginLeft: 2,
      marginRight: '20px !important',
      width: '22px !important',
      height: '22px !important',
    },
    button: {
      width: '100%',
      justifyContent: 'left',
    },
  },
  '@media (min-width: 960px)': {
    container: {
      marginRight: 15,
    },
    button: {
      '& svg': {
        height: 30,
        marginRight: 0,
      },
    },
    text: { marginLeft: 4 },
  },
  pooperResponsive: {
    zIndex: '1200',
    '@media (max-width: 600px)': {
      zIndex: '1640',
      position: 'static',
      float: 'none',
      width: 'auto',
      marginTop: '0',
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
      color: 'black',
    },
  },
  pooperNav: {
    '@media (max-width: 600px)': {
      position: 'static !important',
      left: 'unset !important',
      top: 'unset !important',
      transform: 'none !important',
      willChange: 'none !important',
      '& > div': {
        boxShadow: 'none !important',
        transition: 'none !important',
        marginTop: '0px !important',
        marginBottom: '5px !important',
        padding: '0px !important',
      },
    },
  },
  notifications: {
    zIndex: '4',
    '@media (min-width: 960px)': {
      position: 'absolute',
      top: '5px',
      right: '5px',
      fontSize: '10px',
      background: 'red',
      color: '#FFFFFF',
      minWidth: '16px',
      height: '16px',
      borderRadius: '100%',
      textAlign: 'center',
      lineHeight: '16px',
      verticalAlign: 'middle',
      display: 'block',
      fontWeight: 600,
    },
    '@media (max-width: 959px)': {
      fontSize: '14px',
      marginLeft: '5px',
    },
  },
};
