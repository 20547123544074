import { connect } from 'react-redux';
import React, { Component } from 'react';
import Card from 'components/Card/Card.jsx';
import Payment from '@material-ui/icons/Payment';
import CardIcon from 'components/Card/CardIcon.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import TextField from '@material-ui/core/TextField';
import { ewalletActions, userActions } from 'actions';
import CardHeader from 'components/Card/CardHeader.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Button from 'components/CustomButtons/ButtonWithLoader';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const styles = {
  cardTitle,
  regularFormsStyle,
  balanceText: {
    paddingTop: '38px',
    fontWeight: 500,
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
};

class Withdraw extends Component {
  state = {
    amount: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      amount: '',
    };

    props.dispatch(userActions.getBalance());
  }

  componentDidMount() {
    if (Object.keys(this.props.user).length) {
      this.setState({ amount: this.props.user.credit });
    }
  }

  handleChange = (event) => {
    const { value } = event.target;
    const regex =
      /^(?:\d+(?:,\d{3})*(?:\.\d{2})?|\d+(?:\.\d{0,2})*(?:,\d{2})?)$/;
    if (value !== '' && !regex.test(value)) return false;
    this.setState({ amount: value });
  };

  handleSubmit = () => {
    if (this.state.amount > 0) {
      // const description = this.props.intl.formatMessage({
      //   id: "bill.event.refill"
      // });
      this.props.dispatch(ewalletActions.withdraw({ sum: this.state.amount }));
    }
  };

  render() {
    const { classes, user, loader, withdrawRequest, intl } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Payment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="withdraw.title" />
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" className={classes.row}>
                <GridItem xs={12} sm={6}>
                  <GridContainer>
                    <GridItem item xs={12} sm={6}>
                      <div className={classes.label}>
                        <FormattedMessage id="withdraw.form.balance" />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <b>{user.credit}</b>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>

              <GridContainer justify="center" className={classes.row}>
                <GridItem xs={12} sm={6}>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <div className={classes.label}>
                        <FormattedMessage id="withdraw.form.payout" />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <TextField
                        type="text"
                        name="amount"
                        onChange={this.handleChange}
                        value={this.state.amount}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <br />

              <GridContainer justify="center" className={classes.row}>
                <GridItem xs={12} sm={6}>
                  {!withdrawRequest ? (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={6}>
                        <Button
                          loading={loader}
                          color="success"
                          onClick={this.handleSubmit}
                        >
                          <FormattedMessage id="withdraw.form.button" />
                        </Button>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12}>
                        <SnackbarContent
                          message={intl.formatMessage({
                            id: 'withdraw.form.requestSend',
                          })} // {'SUCCESS - This is a regular notification made with color="success"'}
                          color="success"
                        />
                      </GridItem>
                    </GridContainer>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, eWallet, loader } = state;

  return {
    user: authentication.user,
    loader: loader.withdrawRequest,
    withdrawRequest: eWallet.withdrawRequest,
  };
}

export default injectIntl(
  connect(mapStateToProps)(withStyles(styles)(Withdraw))
);
