import axios from 'axios';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import { store } from './helpers';
import App from './containers/App';
import React, { Component } from 'react';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import uk from 'react-intl/locale-data/uk';
import { Provider, connect } from 'react-redux';
import LoaderAnimation from 'components/Loader';
import { userActions, loaderActions } from 'actions';
import { addLocaleData, IntlProvider } from 'react-intl';
import { langCodes, browserLocale } from './helpers/language.js';
import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0';

addLocaleData([en[0], ru[0], uk[0]]);

class AppContainer extends Component {
  state = { messages: null };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loaderActions.appLoad());
    axios({
      baseURL: '',
      method: 'get',
      url: '/localization.json',
    }).then(({ data }) => this.setState({ messages: data }));

    const urlParams = window.location.search
      .slice(1)
      .split('&')
      .reduce((obj, param) => {
        const [key, value] = param.split('=');
        obj[key] = value;
        return obj;
      }, {});
    const urlLocale = langCodes[urlParams.lang];
    if (urlLocale) dispatch(userActions.changeLang(urlLocale));
    ReactGA.pageview(window.location.pathname + window.location.search);
    // this.setState({ messages });
  }

  render() {
    const { locale, loader } = this.props;
    const { messages } = this.state;
    if (!loader && messages)
      return (
        <IntlProvider locale={locale} messages={messages[locale]}>
          <App />
        </IntlProvider>
      );

    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoaderAnimation />
      </div>
    );
  }
}

const AppWrapper = connect((state) => ({
  loader: state.loader.app,
  locale:
    (state.authentication.user &&
      langCodes[state.authentication.user.language]) ||
    state.authentication.language ||
    browserLocale,
}))(AppContainer);

ReactDOM.render(
  <Provider store={store}>
    <AppWrapper />
  </Provider>,
  document.getElementById('root')
);
