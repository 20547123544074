import React from 'react';
import GridItem from '../Grid/GridItem';
import { winManuals } from './manuals';
import Grid from '@material-ui/core/Grid';
import WInManualsItem from './WInManualsItem';
import { winManualsX64Url, winManualsX86Url } from '../../constants';

const WinManuals = () => {
  return (
    <GridItem items xs={12} lg={12} style={{ marginTop: '5rem' }}>
      <Grid container justify="center" alignItems="center">
        <WInManualsItem download={winManuals(winManualsX64Url)} arch={64} />
        <WInManualsItem download={winManuals(winManualsX86Url)} arch={86} />
      </Grid>
    </GridItem>
  );
};

export default WinManuals;
