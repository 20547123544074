import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { channels } from './channels.reducer';
import { deviceManufactures } from './deviceManufactures.reducer';
import { tariff } from './tariff.reducer';
import { invoice } from './invoice.reducer';
import { eWallet } from './ewallet.reducer';
import { system } from './system.reducer';
import { loader } from './loader.reducer';
import { places } from './places.reducer';
import { partner } from './partner.reducer';
import { transfer } from './transfer.reducer';
import { viewers } from './viewers.reducer';
import { pushRules } from './pushRules.reducer';
// import { recoveryPassword } from "./recoveryPassword.reducer";
// import { statusChannels } from "./statusChannels.reducer";
import { alert } from './alert.reducer';
import { paymentLogs } from './paymentLogs.reducer.js';
// import languageProviderReducer from "./lenguageProvider.reducer";

const rootReducer = combineReducers({
  form: formReducer,
  // language: languageProviderReducer,
  authentication,
  registration,
  users,
  // user,
  channels,
  // order,
  tariff,
  invoice,
  // statusChannels,
  deviceManufactures,
  alert,
  paymentLogs,
  eWallet,
  system,
  loader,
  places,
  partner,
  transfer,
  viewers,
  pushRules,
});

export default rootReducer;
