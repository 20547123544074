const emailVaildation = (values, props) => {
  const errors = {};
  if (!values.email) {
    errors.email = props.intl.formatMessage({ id: 'validation.required' });
  }
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = props.intl.formatMessage({ id: 'validation.email' });
  }
  return errors;
};

const passwordValidate = (values, { intl }) => {
  const errors = {};
  const requiredFields = ['rePassword', 'password2'];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = intl.formatMessage({ id: 'validation.required' });
    }
    if (values[field] && values[field].trim().length < 6) {
      errors[field] = intl.formatMessage(
        { id: 'validation.password' },
        { count: 6 }
      );
    }
  });
  if (values.password2 !== values.rePassword) {
    errors.rePassword = intl.formatMessage({
      id: 'validation.confirmpassword',
    });
  }
  return errors;
};

export { emailVaildation, passwordValidate };
