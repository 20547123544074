import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions, loaderActions } from '.';

import { history, errNormalizer } from '../helpers';
import { reset } from 'redux-form';
import { transformLangCodes } from '../helpers/language';

export const userActions = {
  login,
  refreshToken,
  logout,
  register,
  update,
  getAll,
  delete: _delete,
  getBalance,
  verifyEmail,
  changePassword,
  restorePassword,
  validateRecoveryCode,
  clearRecovery,
  resendEmail,
  remove,
  changeLang,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));
    dispatch(loaderActions.show('authLoginForm'));

    userService.login(username, password).then(
      (data) => {
        dispatch(success(data.user));
        dispatch(loaderActions.hide('authLoginForm'));

        history.push('/');
      },
      (error) => {
        dispatch(reset('AuthForm')); // clear form auth

        dispatch(loaderActions.hide('authLoginForm'));

        if (error.status === 403) {
          dispatch({
            type: userConstants.LOGIN_NOT_CONFIRM,
            data: { message: error.message, email: username },
          });
        } else {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function refreshToken() {
  return (dispatch) => {
    dispatch(request());

    userService.refreshToken().then(
      (data) => {
        dispatch(success(data.user));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.REFRESH_REQUEST };
  }

  function success(user) {
    return { type: userConstants.REFRESH_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.REFRESH_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));
    dispatch(loaderActions.show('authRegForm'));

    user.language = transformLangCodes(user.language);
    userService.register(user).then(
      (data) => {
        dispatch(success(data));
        // history.push("/login");
        dispatch(alertActions.success('alert.registration.success'));
        dispatch(loaderActions.hide('authRegForm'));
      },
      (error) => {
        const errMsg = errNormalizer(error);
        dispatch(failure(errMsg));
        dispatch(alertActions.error(errMsg));
        dispatch(loaderActions.hide('authRegForm'));
      }
    );
  };

  function request(data) {
    return { type: userConstants.REGISTER_REQUEST, data };
  }

  function success(data) {
    return { type: userConstants.REGISTER_SUCCESS, data };
  }

  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function update(user) {
  return (dispatch) => {
    dispatch(request(user));
    dispatch(loaderActions.show('userProfileForm'));

    userService.update(user).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success('alert.profile.updated'));
        dispatch(loaderActions.hide('userProfileForm'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loaderActions.hide('userProfileForm'));
      }
    );
  };

  function request(user) {
    return { type: userConstants.UPDATE_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.UPDATE_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }

  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }

  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      () => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }

  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }

  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function getBalance() {
  return (dispatch) => {
    dispatch(request());

    userService.getBalance().then(
      ({ data }) => dispatch({ type: userConstants.GET_BALANCE_SUCCESS, data }),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GET_BALANCE_REQUEST };
  }

  // function success(data) {
  //   return { type: userConstants.GET_BALANCE_SUCCESS, data };
  // }

  function failure(error) {
    return { type: userConstants.GET_BALANCE_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function verifyEmail(token) {
  return (dispatch) => {
    dispatch(request());

    userService.verifyEmail(token).then(
      (data) => dispatch(success(data.data)),
      (error) => {
        dispatch(failure(error));

        // dispatch(alertActions.error(error.message));
      }
    );
  };

  function request() {
    return { type: userConstants.CONFIRM_REQUEST };
  }

  function success(data) {
    return { type: userConstants.CONFIRM_SUCCESS, balance: data };
  }

  function failure(error) {
    return { type: userConstants.CONFIRM_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function resendEmail(email) {
  return (dispatch) => {
    // dispatch(request());

    userService.resendEmail(email).then(
      () => {
        dispatch({ type: userConstants.RESEND_SUCCESS, data: { email } });
        history.push('/auth/register');
      },
      (error) => {
        dispatch({ type: userConstants.CONFIRM_FAILURE, error });

        dispatch(alertActions.error(error.message));
      }
    );
  };
}

function changePassword(values) {
  return (dispatch) => {
    // dispatch(request());

    dispatch(loaderActions.show('userPasswordForm'));
    userService.changePassword(values).then(
      () => {
        dispatch(reset('UserPasswordForm'));
        dispatch({ type: userConstants.CHANGE_PASSWORD_SUCCESS });
        dispatch(alertActions.success('alert.profile.updatedPssword'));
        dispatch(loaderActions.hide('userPasswordForm'));
        dispatch(userActions.refreshToken());
      },
      (error) => {
        dispatch(alertActions.error(error.message));
        dispatch(loaderActions.hide('userPasswordForm'));
      }
    );
  };
}

function restorePassword(values) {
  return (dispatch) => {
    // dispatch(request());
    dispatch(loaderActions.show('authRecoveryForm'));

    userService.restorePassword(values).then(
      (data) => {
        dispatch(reset('RestoreForm'));
        dispatch({
          type: userConstants.SEND_EMAIL_RECOVERY_SUCCESS,
          data: data.data,
        });
        dispatch(loaderActions.hide('authRecoveryForm'));

        // dispatch(alertActions.success("alert.profile.updatedPssword"));
      },
      (error) => {
        dispatch({ type: userConstants.SEND_EMAIL_RECOVERY_FAILURE });
        dispatch(alertActions.error(error.message));
        dispatch(loaderActions.hide('authRecoveryForm'));
      }
    );
  };
}

function validateRecoveryCode(values) {
  return (dispatch) => {
    userService.validateRecoveryCode(values).then(
      (data) => {
        dispatch(reset('RestoreNewPasswordForm'));
        dispatch({
          type: userConstants.VALIDATE_CODE_RECOVERY_SUCCESS,
          data: data.data,
        });

        if (values.type === 'recovery' && data.data.result === 'success') {
          history.push('/auth/recover');
        }

        // dispatch(alertActions.success("alert.profile.updatedPssword"));
      },
      (error) => {
        dispatch({ type: userConstants.VALIDATE_CODE_RECOVERY_FAILURE });
        dispatch(alertActions.error(error.message));
      }
    );
  };
}

function clearRecovery() {
  return (dispatch) => {
    dispatch({ type: userConstants.CLEAR_RECOVERY });
  };
}

function remove(id, password) {
  return (dispatch) => {
    dispatch({ type: userConstants.DELETE_FAILURE, id, error: null });
    dispatch(loaderActions.show('userDeleteForm'));
    userService._delete(id, password).then(
      () => {
        dispatch(loaderActions.hide('userDeleteForm'));
        setTimeout(() => {
          userService.logout();
          document.location.reload(true);
        }, 3000);
      },
      (error) => {
        dispatch({
          type: userConstants.DELETE_FAILURE,
          id,
          error,
        });
        dispatch(loaderActions.hide('userDeleteForm'));
        dispatch(alertActions.error(error.message));
      }
    );
  };
}

function changeLang(language) {
  return { type: userConstants.CHANGE_LANG, language };
}
