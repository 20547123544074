import {
  getErrorStatus,
  cameraCreationStatus,
  cameraConnectionStatus,
} from './utils';
import styles from './styles';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { alertActions } from 'actions';
import React, { Component } from 'react';
import VideoImageStream from './VideoImageStream';
import CameraStatusError from './CameraStatusError';
import VideocamIcon from '@material-ui/icons/Videocam';
import withStyles from '@material-ui/core/styles/withStyles';
import CameraCircularProgress from './CameraCircularProgress';

class VideoImage extends Component {
  state = {
    error: false,
    loading: true,
  };

  componentDidUpdate(prevProps, prevState) {
    const { loading, dispatch, activateVideoLoading } = this.props;
    if (loading && prevState.error) this.setState({ error: false });
    if (this.state.error !== prevState.error) {
      dispatch(alertActions.error('Error add channel'));
      activateVideoLoading({
        mainVideoStreamActive: false,
        altVideoStreamActive: false,
        videoStreamError: true,
      });
      this.setState({
        error: false,
        loading: true,
      });
    }
    if (
      this.props.channelSessionFailure !== prevProps.channelSessionFailure ||
      this.props.channelAddError !== prevProps.channelAddError
    ) {
      this.handleError();
      activateVideoLoading({
        mainVideoStreamActive: false,
        altVideoStreamActive: false,
        videoStreamError: true,
      });
    }
  }

  handleLoad = () => {
    this.setState({
      error: false,
      loading: false,
    });
    if (this.props.onLoad) this.props.onLoad();
  };

  handleError = () => {
    this.setState({
      error: true,
      loading: false,
    });
    if (this.props.onError) this.props.onError();
  };

  render = () => {
    const { classes, active, creatingCamera, statusChannel } = this.props;

    if (active.videoStreamError) {
      const status = getErrorStatus({ error: true });
      return <CameraStatusError status={status} />;
    }

    if (active.mainVideoStreamActive && !creatingCamera) {
      return <CameraCircularProgress message={cameraConnectionStatus} />;
    }

    if (active.mainVideoStreamActive && creatingCamera) {
      return <CameraCircularProgress message={cameraCreationStatus} />;
    }

    if (statusChannel === 'Active') {
      return (
        <VideoImageStream
          handleLoad={this.handleLoad}
          handleError={this.handleError}
          channelId={this.props.channelId}
          cameraLoading={this.state.loading}
          cameraError={active.videoStreamError}
          loading={active.mainVideoStreamActive}
          statisticStreamType={this.props.statisticStreamType}
          activateVideoLoading={this.props.activateVideoLoading}
        />
      );
    }
    if (statusChannel === 'NoConnection') {
      const status = getErrorStatus({ error: true });
      return <CameraStatusError status={status} />;
    }
    if (!statusChannel) {
      return (
        <div className={classes.container}>
          <div className={`${classes.filler} ${classes.info}`}>
            <VideocamIcon className={classes.icon} />
          </div>
        </div>
      );
    }
    return <CameraCircularProgress message={cameraCreationStatus} />;
  };
}

export default connect((state) => ({
  channel: state.channels.edit,
  status: state.channels.edit.testMode,
  channelAddError: state.channels.error,
  creatingCamera: state.channels.creatingCamera,
  channelSessionFailure: state.channels.channelSessionFailure,
}))(withStyles(styles)(injectIntl(VideoImage)));
