export default {
  TextNotification: {
    paddingTop: '30px',
    label: {
      top: '12px',
    },
  },
  PushButtonStyle: {
    margin: '50px 0 10px 20px',
  },
  cardIconTitle: {
    color: '#3C4858',
    marginTop: '15px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '0px',
    textDecoration: 'none',
  },
  selectLabel: {
    fontSize: '14px',
    textTransform: 'none',
    color: '#AAAAAA !important',
    top: '5px',
  },
  selectLabelError: {
    fontSize: '14px',
    textTransform: 'none',
    color: '#f44336!important',
    top: '5px',
  },

  formError: {
    margin: '10px 1px 10px 0px !important',
    '& div': {
      '&:before': {
        borderBottomWidth: '1px !important',
        borderBottomColor: '#D2D2D2 !important',
      },
      '&:after': {
        borderBottomColor: '#f44336 !important',
        transform: 'scaleX(0) !important',
      },
    },
  },
  select: {
    padding: '12px 0 7px',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    textDecoration: 'none',
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&[aria-owns] + input + svg': {
      transform: 'rotate(180deg)',
    },
    '& + input + svg': {
      transition: 'all 300ms linear',
    },
  },
};
