import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';

import Profile from './Profile';
import Rating from './Rating';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { partnerActions } from 'actions';

const style = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 16,
    '& hr': {
      margin: '10px 0',
    },
  },
  cardBody: {
    textAlign: 'center',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  camera: {
    lineHeight: 1.2,
    '& .title': {
      fontWeight: 400,
      margin: 0,
    },
    '& .model': {
      fontSize: 12,
      color: 'gray',
    },
  },
  date: {
    lineHeight: 1.2,
    '& .title': {
      fontSize: 12,
      color: 'gray',
    },
    '& .time': {},
    '@media (min-width: 600px)': {
      textAlign: 'right',
    },
  },
  center: { textAlign: 'center' },
  small: { fontSize: 12 },
};

class List extends React.Component {
  getCameraDetails = (modelId) => {
    const { deviceManufactures } = this.props;
    if (!deviceManufactures.length) return;
    const matchModel = (model) => model.Id === modelId;
    const brand = deviceManufactures.find((brand) =>
      brand.DeviceModels.some(matchModel)
    );
    const model = brand.DeviceModels.find(matchModel);
    return `${brand.Name} ${model.Name}`;
  };

  getPartnerDetails = (partnerid) => {
    return this.props.partners.find((partner) => partner.id === partnerid);
  };

  handleRating = (id) => (rating) => {
    this.props.dispatch(partnerActions.setRating(id, rating));
  };

  render() {
    const { intl, classes, channel, rated } = this.props;
    return (
      <Paper className={classes.card}>
        <div className={classes.camera}>
          <h4 className="title">{channel.name}</h4>
          <div className="model">{this.getCameraDetails(channel.modelid)}</div>
        </div>
        <hr />
        <div className={classes.cardBody}>
          {rated ? (
            <Fragment>
              <Rating
                starsOnly
                rating={channel.rating}
                affiliateChannelId={channel.id}
              />
              {channel.autorating && (
                <Tooltip
                  title={intl.formatMessage({
                    id: 'rating.autorating.description',
                  })}
                  placement="top"
                >
                  <div className={classes.small}>
                    <FormattedMessage id="rating.autorating" />
                  </div>
                </Tooltip>
              )}
            </Fragment>
          ) : (
            <Rating onClick={this.handleRating(channel.id)} />
          )}
        </div>
        <hr />
        <Grid container spacing={8} alignItems="center">
          <Grid item xs={12} sm={6}>
            {Boolean(this.props.partners.length) && (
              <Profile data={this.getPartnerDetails(channel.partnerid)} />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.date}>
              <div className="title">
                <FormattedMessage id="rating.installTime" />
              </div>
              <div className="time">
                {moment(channel.created_at).format('YYYY.MM.DD HH:mm:ss')}
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default connect((state) => ({
  deviceManufactures: state.deviceManufactures,
  partners: state.partner.partners,
}))(withStyles(style)(injectIntl(List)));
