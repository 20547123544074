import React, { useState, useEffect, useMemo } from 'react';
import Pagination from 'components/Pagination';
import PartnerProfile from './PartnerProfile';
import { withStyles } from '@material-ui/core';

// style settings
const itemToShow = 4;
const getResponsiveItems = (count) => (count > 1 ? count : 1);
const style = (theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(${getResponsiveItems(itemToShow)}, 1fr)`,
    gap: '30px',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: `repeat(${getResponsiveItems(itemToShow - 1)}, 1fr)`,
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: `repeat(${getResponsiveItems(itemToShow - 2)}, 1fr)`,
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: `repeat(${getResponsiveItems(itemToShow - 3)}, 1fr)`,
    },
  },
});

// items count to show per page
const ITEMS_ON_PAGE = 12;

const PartnerList = ({ data, classes }) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    // take the data for the first page by default
    applyPage(1);
  }, []);

  const applyPage = (_page) => {
    const _items = data.slice(
      (_page - 1) * ITEMS_ON_PAGE,
      _page * ITEMS_ON_PAGE
    );
    // save the page and the data to the local state
    setPage(_page);
    setItems(_items);

    // go to the top if the page was changed
    window.scrollTo(0, 0);
  };

  // recalculate if the data will change
  const totalPages = useMemo(
    () => Math.ceil(data.length / ITEMS_ON_PAGE),
    [data]
  );

  return (
    <div>
      <div className={classes.grid}>
        {items.map((i, key) => (
          <PartnerProfile data={i} key={key} />
        ))}
      </div>
      {totalPages > 1 && (
        <Pagination
          style={{ marginTop: 30 }}
          current={page}
          total={totalPages}
          visible="5"
          change={applyPage}
        />
      )}
    </div>
  );
};

export default withStyles(style)(PartnerList);
