import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, reduxForm } from 'redux-form';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import Visibility from '@material-ui/icons/Visibility';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import ChannelsFields from './ChannelsFields';
import { channelsActions, loaderActions, viewersActions } from 'actions';
import LoaderAnimation from 'components/Loader';
import GridItem from 'components/Grid/GridItem';
import RenderTextField from 'components/CustomInput/RenderTextField';
import GridContainer from 'components/Grid/GridContainer';

class ViewersEdit extends React.Component {
  UNSAFE_componentWillMount() {
    const { dispatch, match } = this.props;
    dispatch(loaderActions.show('pageLoader'));
    dispatch(channelsActions.getChannels());
    dispatch(viewersActions.getOne(match.params.viewerId));
  }

  onSubmit(values) {
    const { dispatch, match } = this.props;
    dispatch(viewersActions.editViewer(match.params.viewerId, values));
  }

  render() {
    const {
      classes,
      channels,
      handleSubmit,
      pageLoader,
      viewerEditLoader,
      intl,
    } = this.props;

    // if (pageLoader) return <LoaderAnimation />;

    return (
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <Visibility />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            <FormattedMessage id="viewers.settings.table.title" />
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Field
                name="email"
                disabled
                component={RenderTextField}
                label={intl.formatMessage({ id: 'form.email' })}
                formControlProps={{ fullWidth: true }}
              />
            </GridItem>
            {/* <GridItem xs={12} sm={6}>
              <Field
                name="password2"
                type="password"
                component={RenderTextField}
                label={intl.formatMessage({ id: "form.password" })}
                formControlProps={{ fullWidth: true }}
              />
            </GridItem> */}
          </GridContainer>
          <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
            {pageLoader ? (
              <LoaderAnimation />
            ) : (
              <ChannelsFields channels={channels} />
            )}
            <div align="right">
              <Button
                loading={viewerEditLoader}
                round
                type="submit"
                color="rose"
              >
                <FormattedMessage id="viewers.settings.form.submit" />
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: state.viewers.edit,
  channels: state.channels.all,
  loader: state.loader.viewerAddForm,
  pageLoader: state.loader.pageLoader,
  viewerEditLoader: state.loader.viewerEdit,
});

export default injectIntl(
  connect(mapStateToProps)(
    withStyles(regularFormsStyle)(
      reduxForm({
        form: 'ViewersEdit',
        enableReinitialize: true,

        touchOnBlur: false,
      })(ViewersEdit)
    )
  )
);
