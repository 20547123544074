import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { history } from 'helpers/history.js';
import { deleteValidate } from './validate.js';
import GridItem from 'components/Grid/GridItem.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import { injectIntl, FormattedMessage } from 'react-intl';
import GridContainer from 'components/Grid/GridContainer.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import RenderTextField from 'components/CustomInput/RenderTextField';
import CheckboxWithLabelAndError from 'components/CustomCheckbox/CustomCheckbox';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

let PartnerDeleteForm = (props) => {
  const { classes, handleSubmit, intl, loader } = props;

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <p>
          <FormattedMessage id="profile.form.delete.description.partner" />
        </p>
        <GridContainer>
          <GridItem xs={12} sm={6} lg={12}>
            <Field
              color="primary"
              name="agreeToDelete"
              className={classes.checkbox}
              classes={{
                checked: classes.checked,
                checkboxError: classes.checkboxAgreeError,
              }}
              label={intl.formatMessage({
                id: 'profile.form.delete.agreeToDelete.partner',
              })}
              component={CheckboxWithLabelAndError}
            />
          </GridItem>
          <GridItem xs={12} sm={6} lg={12}>
            <Field
              color="primary"
              name="agreeToBalanceLoss"
              className={classes.checkbox}
              classes={{
                checked: classes.checked,
                checkboxError: classes.checkboxAgreeError,
              }}
              label={intl.formatMessage({
                id: 'profile.form.delete.agreeToBalanceLoss',
              })}
              component={CheckboxWithLabelAndError}
            />
          </GridItem>
        </GridContainer>
        <Field
          name="password"
          type="password"
          component={RenderTextField}
          label={`${intl.formatMessage({ id: 'form.password' })}*`}
          formControlProps={{
            fullWidth: true,
          }}
        />
        <Button
          loading={loader}
          type="submit"
          color="rose"
          className={classes.updateProfileButton}
        >
          <FormattedMessage id="profile.form.delete.submit" />
        </Button>
        <Clearfix />
      </form>
      <SweetAlert
        show={props.submitSucceeded && !loader && !props.deleteError}
        success
        title={intl.formatMessage({ id: 'profile.form.delete.success' })}
        confirmBtnCssClass={`${classes.button} ${classes.success}`}
        onConfirm={() => history.push('/auth/logout')}
      >
        <FormattedMessage id="profile.form.delete.success.description" />
      </SweetAlert>
    </Fragment>
  );
};

PartnerDeleteForm = reduxForm({
  form: 'PartnerDeleteForm',
  validate: deleteValidate,
  touchOnBlur: false,
})(PartnerDeleteForm);

PartnerDeleteForm = injectIntl(
  connect((state) => ({
    loader: state.loader.userDeleteForm,
    deleteError: state.users.deleteError,
  }))(PartnerDeleteForm)
);

export default withStyles(sweetAlertStyle)(PartnerDeleteForm);
