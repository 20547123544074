import { viewersService } from '../services';
import { viewersConstant } from '../constants';
import { alertActions } from 'actions/alert.actions';
import { loaderActions } from 'actions/loader.actions';
import { history } from '../helpers';

export const viewersActions = {
  addViewers,
  getViewers,
  removeViewers,
  getOne,
  editViewer,
  toggleViewer,
};

function addViewers(params) {
  return (dispatch) => {
    dispatch({ type: 'SHOW_LOADER', place: 'viewerAddForm' });
    viewersService.addViewers(params).then(
      (response) => {
        dispatch(loaderActions.hide('viewerAddForm'));
        history.push('/viewers');
        dispatch({
          type: viewersConstant.ADD_VIEWERS_SUCCESS,
          data: response.data,
        });
      },
      (error) => {
        dispatch(loaderActions.hide('viewerAddForm'));
        dispatch(alertActions.error(error.message));
        dispatch({
          type: viewersConstant.ADD_VIEWERS_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}

function getViewers() {
  return (dispatch) => {
    dispatch(loaderActions.show('pageLoader'));
    viewersService
      .getViewers()
      .then(
        (response) => {
          dispatch({
            type: viewersConstant.GET_VIEWERS_SUCCESS,
            data: response.data,
          });
        },
        (error) => {
          dispatch(alertActions.error(error.message));
          dispatch({
            type: viewersConstant.GET_VIEWERS_FAILURE,
            error: error.toString(),
          });
        }
      )
      .finally(() => {
        dispatch(loaderActions.hide('pageLoader'));
      });
  };
}

function getOne(viewerId) {
  return (dispatch) => {
    dispatch(loaderActions.show('viewerGet'));
    viewersService.getOne(viewerId).then(
      ({ data: response }) => {
        dispatch(loaderActions.hide('viewerGet'));

        dispatch({
          type: viewersConstant.GET_ONE_VIEWERS_SUCCESS,
          data: response.data,
        });
      },
      (error) => {
        dispatch(loaderActions.hide('viewerGet'));
        dispatch(alertActions.error(error.message));
        dispatch({
          type: viewersConstant.GET_ONE_VIEWERS_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}
function removeViewers(id) {
  return (dispatch) => {
    dispatch(loaderActions.show('viewerRemove'));
    dispatch({ type: 'SHOW_LOADER', place: 'viewerRemove' });
    viewersService.removeViewers(id).then(
      () => {
        dispatch(loaderActions.hide('viewerRemove'));
        dispatch({
          type: viewersConstant.REMOVE_VIEWERS_SUCCESS,
          data: { id },
        });
      },
      (error) => {
        dispatch(loaderActions.hide('viewerRemove'));
        dispatch(alertActions.error(error.message));
        dispatch({
          type: viewersConstant.REMOVE_VIEWERS_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}

function editViewer(id, params) {
  return (dispatch) => {
    dispatch({ type: 'SHOW_LOADER', place: 'viewerEdit' });
    viewersService.editViewer(id, params).then(
      () => {
        dispatch({ type: 'HIDE_LOADER', place: 'viewerEdit' });
        dispatch(alertActions.success('alert.profile.updated'));
        dispatch({ type: viewersConstant.EDIT_VIEWERS_SUCCESS });
      },
      (error) => {
        dispatch({ type: 'HIDE_LOADER', place: 'viewerEdit' });
        dispatch(alertActions.error(error.message));
        dispatch({
          type: viewersConstant.EDIT_VIEWERS_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}

function toggleViewer(id, state) {
  return (dispatch) => {
    dispatch({ type: 'SHOW_LOADER', place: 'viewerToggle' });
    viewersService.toggleViewer(id, state).then(
      () => {
        dispatch({ type: 'HIDE_LOADER', place: 'viewerToggle' });
        dispatch(alertActions.success('alert.profile.updated'));
        dispatch({
          type: viewersConstant.TOGGLE_VIEWERS_SUCCESS,
          data: { id, state },
        });
      },
      (error) => {
        dispatch({ type: 'HIDE_LOADER', place: 'viewerToggle' });
        dispatch(alertActions.error(error.message));
        dispatch({
          type: viewersConstant.TOGGLE_VIEWERS_FAILURE,
          error: error.toString(),
        });
      }
    );
  };
}
