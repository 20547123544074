import { connect } from 'react-redux';
import Card from 'components/Card/Card';
import { unpaidInvoiceStyles } from './utils';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import React, { Fragment } from 'react';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.jsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';

const UnpaidInvoices = (props) => {
  const { classes, invoiceData, currency, refillBalance, paidAll } = props;

  let total = 0;
  let sum = 0;

  if (invoiceData && invoiceData.length) {
    invoiceData.forEach((item) => {
      if (item.type !== 'Invoice' && item.status === 'Unpaid') {
        total++;
        sum += +item.total;
      }
    });
  }

  return (
    <Card>
      <CardHeader color="primary" icon>
        <h4 className={classes.cardIconTitle}>
          <FormattedMessage
            id="invoice.unpaid.total.title"
            values={{ total }}
          />
        </h4>
      </CardHeader>
      <CardBody style={{ textAlign: 'left' }}>
        {total ? (
          <Fragment>
            <FormattedMessage id="invoice.unpaid.total" values={{ total }} />
            <br />
            <FormattedMessage
              id="invoice.unpaid.totalsum"
              values={{
                sum: sum.toFixed(2),
                currencyPrefix: currency && currency.prefix,
                currencySuffix: currency && currency.suffix,
              }}
            />
          </Fragment>
        ) : (
          <FormattedMessage id="invoice.unpaid.not" />
        )}
      </CardBody>
      <CardFooter>
        {total ? (
          <GridContainer>
            {total > 1 ? (
              <GridItem md={6} style={{ textAlign: 'right' }}>
                <Button color="rose" onClick={paidAll} size="sm">
                  <FormattedMessage id="payment.form.pay.all" />
                </Button>
              </GridItem>
            ) : null}
            <GridItem md={6} style={{ textAlign: 'left' }}>
              <Button color="rose" onClick={refillBalance} size="sm">
                <FormattedMessage id="payment.form.refill" />
              </Button>
            </GridItem>
          </GridContainer>
        ) : (
          ''
        )}
      </CardFooter>
    </Card>
  );
};

function mapStateToProps(state) {
  const { invoice } = state;
  return {
    invoice,
  };
}

export default injectIntl(
  connect(mapStateToProps)(withStyles(unpaidInvoiceStyles)(UnpaidInvoices))
);
