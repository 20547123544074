import LoginPage from 'containers/auth/LoginPage';
import RegisterPage from 'containers/auth/RegisterPage';
import RegisterPartner from 'containers/auth/RegisterPartner';
import ConfirmEmail from 'containers/auth/ConfirmEmail';
import RestorePassword from 'containers/auth/RestorePassword';

// Icons
import PersonAdd from '@material-ui/icons/PersonAdd';
import Fingerprint from '@material-ui/icons/Fingerprint';

const pagesRoutes = [
  {
    path: '/auth/register/partner',
    component: RegisterPartner,
  },
  {
    path: '/auth/register',
    name: 'layout.menu.registration',
    icon: PersonAdd,
    component: RegisterPage,
    menu: true,
  },
  {
    path: '/auth/confirm/:verificationId',
    component: ConfirmEmail,
  },
  {
    path: '/auth/confirm',
    component: ConfirmEmail,
  },
  {
    path: '/auth/login',
    name: 'layout.menu.login',
    icon: Fingerprint,
    component: LoginPage,
    menu: true,
  },
  {
    path: '/auth/recover',
    component: RestorePassword,
  },
  {
    redirect: true,
    path: '/auth',
    pathTo: '/auth/login',
  },
];

export default pagesRoutes;
