export const channelsConstant = {
  ADD_CHANNELS: 'CHANNELS_ADD_REQUEST',

  CREATING_CAMERA: 'CREATING_CAMERA',
  CHANNEL_SESSION_FAILURE: 'CHANNEL_SESSION_FAILURE',
  GET_REQUEST: 'CHANNELS_GET_REQUEST',
  GET_SUCCESS: 'CHANNELS_GET_SUCCESS',
  GET_FAILURE: 'CHANNELS_GET_FAILURE',

  GET_ONE_REQUEST: 'CHANNELS_GET_ONE_REQUEST',
  GET_ONE_SUCCESS: 'CHANNELS_GET_ONE_SUCCESS',
  GET_ONE_FAILURE: 'CHANNELS_GET_ONE_FAILURE',

  GET_STATUS_REQUEST: 'CHANNELS_GET_STATUS_REQUEST',
  GET_STATUS_SUCCESS: 'CHANNELS_GET_STATUS_SUCCESS',
  GET_STATUS_FAILURE: 'CHANNELS_GET_STATUS_FAILURE',

  ADD_REQUEST: 'CHANNELS_ADD_REQUEST',
  ADD_SUCCESS: 'CHANNELS_ADD_SUCCESS',
  ADD_FAILURE: 'CHANNELS_ADD_FAILURE',

  UPDATE_REQUEST: 'CHANNELS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CHANNELS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CHANNELS_UPDATE_FAILURE',

  TOGGLE_ON_OFF_CHANNEL: 'TOGGLE_ON_OFF_CHANNEL',

  DELETE_REQUEST: 'CHANNELS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CHANNELS_DELETE_SUCCESS',
  DELETE_FAILURE: 'CHANNELS_DELETE_FAILURE',

  REACHABLE_REQUEST: 'CHANNELS_REACHABLE_PORT_REQUEST',
  REACHABLE_SUCCESS: 'CHANNELS_REACHABLE_PORT_SUCCESS',
  REACHABLE_FAILURE: 'CHANNELS_REACHABLE_PORT_FAILURE',

  PRIORITY_REQUEST: 'CHANNELS_CHANGE_PRIORITY_REQUEST',
  PRIORITY_SUCCESS: 'CHANNELS_CHANGE_PRIORITY_SUCCESS',
  PRIORITY_FAILURE: 'CHANNELS_CHANGE_PRIORITY_FAILURE',
  CHANGE_PRIORITY: 'CHANNELS_CHANGE_PRIORITY',

  CLEAR_EDIT: 'CHANNELS_CLEAR_EDIT',
};
