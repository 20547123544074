import React, { Fragment } from 'react';
import Avatar from 'components/Avatar';
import Grid from '@material-ui/core/Grid';
import { partnerActions } from 'actions';
import { FormattedMessage } from 'react-intl';
import Place from '@material-ui/icons/Place';
import Phone from '@material-ui/icons/Phone';
import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import Success from 'components/Typography/Success.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import { faViber } from '@fortawesome/free-brands-svg-icons/faViber';
import { faSkype } from '@fortawesome/free-brands-svg-icons/faSkype';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';

const style = {
  profile: {
    display: 'flex',
    alignItems: 'center',
  },
  profileAvatar: {
    width: 110,
    marginRight: 20,
    minWidth: 110,
  },
  profileAvatarWide: {
    width: 180,
    marginRight: 20,
    minWidth: 180,
  },
  profileInfo: {
    flexGrow: 1,
    alignSelf: 'start',
    maxWidth: 'calc(100% - 130px)',
  },
  profileInfoWide: {
    flexGrow: 1,
    alignSelf: 'start',
    maxWidth: 'calc(100% - 290px)',
  },
  profileCheck: { marginLeft: 20 },
  avatar: { boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14)' },
  name: {
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  contacts: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    verticalAlign: 'middle',
    '& svg': {
      color: '#999',
      marginRight: 5,
      marginBottom: -2,
    },
  },
  contactsInline: {
    display: 'inline-block',
    '& li': {
      display: 'inline-block',
      marginRight: 20,
    },
  },
  blockTitle: {
    color: '#999',
    fontSize: 12,
    lineHeight: 1,
  },
  blockData: {
    marginBottom: 5,
    fontSize: 20,
  },
  center: { textAlign: 'center' },
  '@media  (max-width: 560px)': {
    name: { margin: '10px 0' },
    profile: { display: 'block' },
    profileAvatar: { width: '100%' },
    profileAvatarWide: { width: '100%' },
    contactsInline: {
      display: 'block',
      '& li': {
        display: 'block',
        margin: 0,
      },
    },
  },
};

class PartnerProfile extends React.Component {
  clearPartner = () => {
    const { dispatch, change, partnerHandleCheck } = this.props;
    dispatch(partnerActions.clearPartner());
    change('data.customParam.installer', '');
    partnerHandleCheck(false);
  };

  render() {
    const { classes, data, wide } = this.props;
    const blocks = (
      <Fragment>
        {wide && <hr />}
        <Grid
          container
          spacing={8}
          justify="center"
          style={!wide ? { marginTop: '12px' } : null}
        >
          <Grid item xs={4} className={classes.center}>
            <div className={classes.blockData}>{data.rating || 0}</div>
            <div className={classes.blockTitle}>
              <FormattedMessage id="profile.partner.statistics.rating" />
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
    return (
      <Fragment>
        <div className={classes.profile}>
          <div
            className={wide ? classes.profileAvatarWide : classes.profileAvatar}
          >
            <div className={classes.avatar}>
              <Avatar image={data.profilephoto} />
            </div>
          </div>
          <div className={wide ? classes.profileInfoWide : classes.profileInfo}>
            <h3 className={classes.name}>{data.nickname}</h3>
            <ul
              className={`${classes.contacts} ${
                wide && classes.contactsInline
              }`}
            >
              <li>
                <Place fontSize="inherit" />
                <FormattedMessage id={`country.${data.country}`} />
              </li>
              <li>
                <Phone fontSize="inherit" />
                {data.phonenumber}
              </li>
            </ul>
            {this.props.contacts && (
              <ul
                className={`${classes.contacts} ${
                  wide && classes.contactsInline
                }`}
              >
                {data.telegram && (
                  <li>
                    <Tooltip title="Telegram" placement="top">
                      <FontAwesomeIcon icon={faTelegram} />
                    </Tooltip>
                    {data.telegram}
                  </li>
                )}
                {data.viber && (
                  <li>
                    <Tooltip title="Viber" placement="top">
                      <FontAwesomeIcon icon={faViber} />
                    </Tooltip>
                    {data.viber}
                  </li>
                )}
                {data.skype && (
                  <li>
                    <Tooltip title="Skype" placement="top">
                      <FontAwesomeIcon icon={faSkype} />
                    </Tooltip>
                    {data.skype}
                  </li>
                )}
                {data.whatsapp && (
                  <li>
                    <Tooltip title="WhatsApp" placement="top">
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </Tooltip>
                    {data.whatsapp}
                  </li>
                )}
              </ul>
            )}
            {wide && blocks}
          </div>
          <div>
            {this.props.check && (
              <div className={classes.profileCheck}>
                <Success>
                  <Check fontSize="large" />
                </Success>
              </div>
            )}
            {this.props.dispatch && this.props.check && (
              <Button
                simple
                color="danger"
                size="sm"
                onClick={this.clearPartner}
              >
                <FormattedMessage id="channels.form.partner.cancel" />
              </Button>
            )}
          </div>
        </div>
        {!wide && blocks}
      </Fragment>
    );
  }
}

export default withStyles(style)(PartnerProfile);
