import { invoiceConstants } from '../constants/invoice.constants';

const initialState = {
  logs: [],
};

export function paymentLogs(state = initialState, action) {
  switch (action.type) {
    case invoiceConstants.GET_LOGS_SUCCESS:
      return {
        ...state,
        logs: action.data,
      };
    default:
      return state;
  }
}
