import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import Collapse from '@material-ui/core/Collapse';

const style = {
  buttonGroup: {
    whiteSpace: 'nowrap',
    height: 22,
  },
  button: {
    padding: 0,
    border: 'none',
    color: 'orange',
    background: 'transparent',
    fontSize: 22,
    height: 22,
  },
  active: {
    cursor: 'pointer',
    '&:hover': { transform: 'scale(1.2)' },
  },
  successTitle: {
    lineHeight: '22px',
    margin: 0,
  },
  stars: {
    whiteSpace: 'nowrap',
    color: 'orange',
    fontSize: 22,
    height: 22,
  },
};

export const RatingStars = ({ rating, classes }) => (
  <div className={classes.stars}>
    {Array.from({ length: 10 }, (el, idx) =>
      rating - idx > 0 ? (
        <Star key={idx} fontSize="inherit" />
      ) : (
        <StarBorder key={idx} fontSize="inherit" />
      )
    )}
  </div>
);

export class Rating extends Component {
  state = {
    hover: null,
  };

  setRating = (e) => {
    const { rating } = e.currentTarget.dataset;
    this.setState({ hover: null });
    if (this.props.onClick) {
      this.props.onClick(Number(rating));
    }
  };

  onMouseEnter = (e) => {
    if (this.props.disabled) return;
    const { rating } = e.currentTarget.dataset;
    this.setState({ hover: rating });
  };

  onMouseLeave = () => this.setState({ hover: null });

  render() {
    const { classes, disabled, rating } = this.props;
    return (
      <div className={classes.buttonGroup} onMouseLeave={this.onMouseLeave}>
        {Array.from({ length: 10 }, (el, idx) => (
          <button
            key={idx}
            disabled={disabled}
            className={`${classes.button} ${!disabled && classes.active}`}
            data-rating={idx + 1}
            onClick={this.setRating}
            onMouseEnter={this.onMouseEnter}
          >
            {(this.state.hover || rating) - idx > 0 ? (
              <Star fontSize="inherit" />
            ) : (
              <StarBorder fontSize="inherit" />
            )}
          </button>
        ))}
      </div>
    );
  }
}

export class RatingContainer extends Component {
  state = {
    rating: 0,
    rated: false,
    submited: false,
  };

  handleRating = (rating) => {
    this.setState({ rating, rated: true });
  };

  handleSubmit = () => {
    this.setState({ submited: true });
    this.props.onClick(this.state.rating);
  };

  cancelRating = () => {
    this.setState({ rating: 0, rated: false });
  };

  render() {
    const { classes, starsOnly } = this.props;
    if (starsOnly) {
      return <RatingStars rating={this.props.rating} classes={classes} />;
    }
    if (this.state.submited) {
      return (
        <h4 className={classes.successTitle}>
          <FormattedMessage id="rating.success" />
        </h4>
      );
    }
    return (
      <div>
        <Rating
          disabled={this.state.rated}
          rating={this.state.rating}
          classes={classes}
          onClick={this.handleRating}
        />
        <Collapse in={this.state.rated}>
          <div style={{ marginTop: 5 }}>
            <div>
              <FormattedMessage id="rating.confirm.title" />
            </div>
            <Button simple size="sm" color="danger" onClick={this.cancelRating}>
              <FormattedMessage id="rating.confirm.cancel" />
            </Button>
            <Button size="sm" color="success" onClick={this.handleSubmit}>
              <FormattedMessage id="rating.confirm.yes" />
            </Button>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default injectIntl(withStyles(style)(RatingContainer));
