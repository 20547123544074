export const sortPartnersByRating = (list) => {
  const activePartners = list.reduce(
    (obj, partner) => {
      if (partner.status === 'Active') {
        obj[partner.installCams >= 5 ? 'rating' : 'middle'].push(partner);
      }
      return obj;
    },
    {
      rating: [],
      middle: [],
    }
  );
  return [
    ...activePartners.rating.sort((a, b) => a.rating - b.rating),
    ...activePartners.middle.sort((a, b) => a.middleRating - b.middleRating),
  ];
};

export const searchedPlaceTitle = (place, format) => {
  const taxonomy = ['country', 'region', 'city', 'district', 'microdistrict'];
  const places = taxonomy.map((key) => place[key]).filter((i) => i);
  switch (format) {
    case 'last':
      return places.pop();
    case 'penult':
      return places.splice(-2, 1)[0];
    case 'string':
    default:
      return places.join(', ');
  }
};

export const buildPlaceForEnlarge = (place) => {
  // make sure you won't send an undefined value because the place object has a null value for the field by default
  let newPlace = { ...place };
  if (newPlace.microdistrict) {
    newPlace.microdistrict = null;
  } else if (newPlace.district) {
    newPlace.district = null;
  } else if (newPlace.city) {
    newPlace.city = null;
    // } else if (newPlace.region) {
    //   newPlace.region = null;
  } else {
    newPlace = null;
  }
  return newPlace;
};
