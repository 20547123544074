import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { Field, getFormValues } from 'redux-form';
import { TextField } from 'components/ReduxInputs';
import { normalizeCameraPorts } from '../formsNormalization';
import { getPortsFields, getPortStatusIcon } from './getPortsFields';

const CameraPortsComponent = (props) => {
  const { intl, portReachable, deviceManufactures, cameraConfigValues } = props;
  const portsFields = getPortsFields(
    intl,
    deviceManufactures,
    portReachable,
    cameraConfigValues
  );
  const isRtspPort = portsFields.find((i) => i.isRtsp);
  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          fullWidth
          label={intl.formatMessage({ id: 'channels.form.ip' })}
          name="data.ConnectionSettings.Hostname"
          // normalize={(value, previousValue) =>
          //   normalizeIpCamera(value, previousValue, 15)
          // }
        />
      </Grid>
      <Grid item xs={6} sm={isRtspPort ? 3 : 6}>
        <Field
          component={TextField}
          fullWidth
          type="number"
          label={intl.formatMessage(
            { id: 'channels.form.port' },
            { portName: 'HTTP' }
          )}
          name="customParam.Port"
          normalize={(value, previousValue) =>
            normalizeCameraPorts(value, previousValue, 5)
          }
          InputProps={{
            inputProps: { min: 1, max: 65535 },
            endAdornment: getPortStatusIcon(
              portReachable,
              cameraConfigValues,
              'customParam.Port'
            ),
          }}
        />
      </Grid>
      {Boolean(isRtspPort) && (
        <Grid item xs={6} sm={3}>
          {portsFields.find((i) => i.isRtsp).field}
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  deviceManufactures: state.deviceManufactures,
  portReachable: state.channels.portReachable,
  cameraConfigValues: getFormValues('CameraConfigForm')(state),
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(CameraPortsComponent)
);
