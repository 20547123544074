const emailRegexp = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
const phoneRegexp = new RegExp(/^\+\d{10,12}$/);
const nickRegexp = new RegExp(/^[a-zA-Z0-9_]+$/);

export default (values, props) => {
  const errors = {};

  const requiredFields = [
    'firstname',
    'lastname',
    'nickname',
    'email',
    'password',
    'confirmpassword',
    'country',
    'phone',
    'recaptcha',
  ];
  requiredFields.forEach((field) => {
    if (
      !values[field] ||
      (typeof values[field] === 'string' && !values[field].trim())
    ) {
      errors[field] = props.intl.formatMessage({
        id: 'validation.required',
      });
    }
  });

  const nameFields = ['firstname', 'lastname', 'nickname'];
  nameFields.forEach((field) => {
    if (values[field] && values[field].trim().length > 30) {
      errors[field] = props.intl.formatMessage(
        {
          id: `validation.${field}`,
        },
        { count: 30 }
      );
    }
  });

  if (values.nickname && !nickRegexp.test(values.nickname.trim())) {
    errors.nickname = props.intl.formatMessage({
      id: `validation.nickname.lang`,
    });
  }

  if (values.email && !emailRegexp.test(values.email.trim())) {
    errors.email = props.intl.formatMessage({
      id: 'validation.email',
    });
  }

  const passwordLength = { min: 6, max: 128 };
  if (
    values.password &&
    (values.password.trim().length < passwordLength.min ||
      values.password.trim().length > passwordLength.max)
  ) {
    errors.password = props.intl.formatMessage(
      { id: 'validation.password2' },
      passwordLength
    );
  }

  if (values.confirmpassword !== values.password) {
    errors.confirmpassword = props.intl.formatMessage({
      id: 'validation.confirmpassword',
    });
  }

  if (values.phone && !phoneRegexp.test(values.phone.trim())) {
    errors.phone = props.intl.formatMessage({ id: 'validation.phone' });
  }

  if (!values.agreeToTerms) {
    errors.agreeToTerms = props.intl.formatMessage({
      id: 'validation.agreeToTerms',
    });
  }

  return errors;
};
