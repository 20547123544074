import moment from 'moment';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import Card from 'components/Card/Card';
import React, { Component } from 'react';
import GridItem from 'components/Grid/GridItem';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import LoaderAnimation from 'components/Loader';
import { invoiceTransactionStyles } from './utils';
import CardHeader from 'components/Card/CardHeader';
import Assignment from '@material-ui/icons/Assignment';
import { invoiceActions, loaderActions } from 'actions';
import { injectIntl, FormattedMessage } from 'react-intl';
import GridContainer from 'components/Grid/GridContainer';
import withStyles from '@material-ui/core/styles/withStyles';

class InvoiceTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      nPerPage: 10,
      data: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      invoiceActions.getPaymentLogs(this.state.pageNumber, this.state.nPerPage)
    );
    dispatch(loaderActions.show('pageLoader'));
  }

  componentWillUpdate(nextProps) {
    const { intl } = this.props;
    if (nextProps.paymentLogs.logs.length && !this.state.data.length) {
      const data = nextProps.paymentLogs.logs.map((item) => {
        return {
          date: moment(item.date).format('YYYY.MM.DD HH:mm:ss'),
          amountin:
            item.amountin > 0
              ? `${item.amountin} ${
                  item.currency
                    ? item.currency
                    : intl.formatMessage({ id: 'currency.uah' })
                }`
              : '',
          amountout:
            item.amountout > 0
              ? `${item.amountout} ${
                  item.currency
                    ? item.currency
                    : intl.formatMessage({ id: 'currency.uah' })
                }`
              : '',
          description: item.description, // <NavLink to={href}>{item.description}</NavLink>
        };
      });
      this.setState({ data });
    }
  }

  render() {
    const { classes, intl, loader } = this.props;

    if (loader) return <LoaderAnimation />;

    const tableTranslate = {
      previousText: intl.formatMessage({ id: 'layout.table.previousText' }),
      nextText: intl.formatMessage({ id: 'layout.table.nextText' }),
      loadingText: intl.formatMessage({ id: 'layout.table.loadingText' }),
      noDataText: intl.formatMessage({ id: 'layout.table.noDataText' }),
      pageText: intl.formatMessage({ id: 'layout.table.pageText' }),
      ofText: intl.formatMessage({ id: 'layout.table.ofText' }),
      rowsText: intl.formatMessage({ id: 'layout.table.rowsText' }),
    };
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="invoice.historyPayments.title" />
                <br />
                <small>
                  <FormattedMessage id="invoice.history.description" />
                </small>
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.state.data}
                {...tableTranslate}
                filterable
                columns={[
                  {
                    Header: intl.formatMessage({
                      id: 'invoice.history.table.date',
                    }),
                    accessor: 'date',
                    filterable: false,
                  },
                  {
                    Header: intl.formatMessage({
                      id: 'invoice.history.table.amountin',
                    }),
                    accessor: 'amountin',
                    filterable: false,
                  },
                  {
                    Header: intl.formatMessage({
                      id: 'invoice.history.table.amountout',
                    }),
                    accessor: 'amountout',
                    filterable: false,
                  },
                  {
                    Header: intl.formatMessage({
                      id: 'invoice.history.table.description',
                    }),
                    accessor: 'description',
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { paymentLogs } = state;
  const loader = state.loader.pageLoader;
  return {
    paymentLogs,
    loader,
  };
}

export default injectIntl(
  connect(mapStateToProps)(
    withStyles(invoiceTransactionStyles)(InvoiceTransactions)
  )
);
