import axios from 'axios';
import { authHeader } from '../helpers';
import { baseReactAPIUrl } from '../constants';
import { apiCall } from '../helpers/request';

export const userService = {
  login,
  refreshToken,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  getBalance,
  verifyEmail,
  changePassword,
  restorePassword,
  validateRecoveryCode,
  resendEmail,
  _delete,
};

const serverUrl = baseReactAPIUrl;

function verifyEmail(token) {
  return axios.get(`/auth/verifyemail/${token}`);
}

function resendEmail(email) {
  return axios.post(`/auth/resendemail`, { email });
}

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${serverUrl}/auth/login`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // login successful if there's a jwt token in the response

      if (data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', JSON.stringify(data.token));
        localStorage.setItem('user', JSON.stringify(data.user));
      }

      return data;
    });
}

async function refreshToken() {
  try {
    const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && token) {
      const { refreshToken } = token;
      const response = await apiCall({
        url: '/auth/refresh-token',
        method: 'POST',
        data: JSON.stringify({ email: user.email, refreshToken }),
      });
      localStorage.setItem('token', JSON.stringify(response.data.token));
      localStorage.setItem('user', JSON.stringify(response.data.user));
      return response.data;
    }
    return Promise.reject('');
  } catch (err) {
    return errorCatcher(err);
  }
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${serverUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${serverUrl}/users/${id}`, requestOptions).then(handleResponse);
}

// function getProfile() {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader()
//   };
//   return fetch(`${config.serverUrl}/user`, requestOptions).then(handleResponse);
// }

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(`${serverUrl}/auth/register`, requestOptions).then(
    handleResponse
  );
}

function update(data) {
  const formData = new FormData();
  for (const name in data) {
    formData.append(name, data[name]);
  }

  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader() },
    body: formData,
  };

  return fetch(`${serverUrl}/users/${data._id}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data) {
        localStorage.setItem('user', JSON.stringify(data));
      }
      return data;
    });
}

function changePassword(values) {
  return axios.patch(`/users/changepassword`, {
    ...values,
  });
}

function restorePassword(values) {
  return axios.patch(`/auth/restorepassword`, values);
}

function validateRecoveryCode(values) {
  return axios.post(`/auth/validaterestorepassword`, values);
}

function getBalance() {
  return axios.get(`/users/balance`);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id, password) {
  return axios.post(`/users/${id}`, { pwd: password });
  /* const requestOptions = {
    method: "delete",
    headers: authHeader(),
    body: JSON.stringify({ pwd: password })
  };

  return fetch(`${config.serverUrl}/users/${id}`, requestOptions).then(
    handleResponse
  ); */
}

function errorCatcher(err) {
  const { response } = err;
  if (response) {
    if (response.status === 401) {
      logout(response.status === 401);
      document.location.reload();
      return Promise.reject('');
    }
    if (response.status === 403) {
      return Promise.reject({
        status: 403,
        message: response.data.message || response.statusText,
      });
    }
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout(response.status === 401);
        document.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      if (response.status === 403) {
        return Promise.reject({ status: 403, message: error });
      }
      return Promise.reject(error);
    }

    return data;
  });
}
