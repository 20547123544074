import React from 'react';
import { authHeader } from 'helpers';
import { baseReactAPIUrl } from '../../constants';

const DownloadInvoiceButton = (props) => {
  const downloadInvoice = () => {
    const { id } = props;
    const url = `${baseReactAPIUrl}/invoice/downloadInvoice/${id}`;
    fetch(url, {
      method: 'GET',
      headers: authHeader(),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `invoice-${id}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <a className="btn btn-default" onClick={downloadInvoice}>
      <i className="fas fa-download" /> Download
    </a>
  );
};

export default DownloadInvoiceButton;
