import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Notifications from '@material-ui/icons/Notifications';
import Button from 'components/CustomButtons/Button.jsx';
import { history } from '../../../helpers/history.js';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import Hidden from '@material-ui/core/Hidden';

class Notification extends React.Component {
  state = { open: false };

  popperToggle = () => this.setState({ open: !this.state.open });

  popperClose = () => this.setState({ open: false });

  popperMenu = (link) => {
    this.popperClose();
    history.push(link);
  };

  render() {
    const { classes, stats } = this.props;
    return (
      <Fragment>
        <Button
          className={classes.button}
          color="transparent"
          buttonRef={(node) => (this.popperBtn = node)}
          onClick={this.popperToggle}
        >
          <Notifications className={classes.icon} />
          <Hidden mdUp>
            <span className={classes.text}>
              <FormattedMessage id="layout.notification" />:{' '}
            </span>
          </Hidden>
          {stats.allnatification ? (
            <span className={classes.notifications}>
              {stats.allnatification}
            </span>
          ) : null}
        </Button>
        <Popper
          open={this.state.open}
          anchorEl={this.popperBtn}
          transition
          disablePortal
          placement="bottom"
          className={`${classes.pooperResponsive} ${classes.pooperNav}`}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={this.popperClose}>
                  <MenuList>
                    {stats.numsetuprequest ? (
                      <MenuItem
                        className={classes.menuitem}
                        onClick={() => this.popperMenu('/subscribers')}
                      >
                        <FormattedMessage id="layout.notification.setuprequest" />
                      </MenuItem>
                    ) : null}

                    {stats.numratepartner ? (
                      <MenuItem
                        className={classes.menuitem}
                        onClick={() => this.popperMenu('/channels/rate')}
                      >
                        <FormattedMessage id="layout.notification.ratepartner" />
                      </MenuItem>
                    ) : null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Fragment>
    );
  }
}

export default Notification;
