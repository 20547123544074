import Cursor from '../../classes/Cursor';

export class GridCursor extends Cursor {
  constructor(size, grid) {
    super(size);
    this.setGrid(grid);
  }

  setGrid(grid = null) {
    this.grid = grid || {
      width: this.width,
      height: this.height,
    };
  }

  moveHandler(e) {
    const canvas = e.target;
    const canvasPos = this.getPosition(e);
    const gridPos = {
      x: Math.floor(canvasPos.x / this.grid.width) * this.grid.width,
      y: Math.floor(canvasPos.y / this.grid.height) * this.grid.height,
    };
    const boundsPos = {
      x:
        gridPos.x > 0
          ? gridPos.x < canvas.width - this.width
            ? gridPos.x
            : canvas.width - this.width
          : 0,
      y:
        gridPos.y > 0
          ? gridPos.y < canvas.height - this.height
            ? gridPos.y
            : canvas.height - this.height
          : 0,
    };
    this.setPosition(boundsPos);
  }
}

export default GridCursor;
