import React from 'react';
import { Field } from 'redux-form';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'components/ReduxInputs';
import { basicNormalizer, normalizeCameraLogin } from '../formsNormalization';

const CameraCredentialsComponent = (props) => {
  const { intl } = props;
  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          fullWidth
          label={intl.formatMessage({ id: 'form.login' })}
          name="data.ConnectionSettings.Login"
          normalize={(value, previousValue) =>
            normalizeCameraLogin(value, previousValue, 24)
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          fullWidth
          type="password"
          label={intl.formatMessage({ id: 'form.password' })}
          name="data.ConnectionSettings.Password"
          normalize={(value, previousValue) =>
            basicNormalizer(value, previousValue, 32)
          }
        />
      </Grid>
    </React.Fragment>
  );
};

export default injectIntl(CameraCredentialsComponent);
