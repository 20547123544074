import { invoiceService } from 'services';
import { alertActions, loaderActions } from '.';
import { invoiceConstants } from 'constants/invoice.constants';
import { history } from 'helpers';

export const invoiceActions = {
  createInvoice,
  getInvoice,
  getTotalPriceAllUnpaidChannels,
  getInvoiceItems,
  paidInvoices,
  createInvoiceOfInvoices,
  startTest,
  getPaymentLogs,
  clearWizard,
  cancelInvoice,
  getInvoiceFromOrder,
  getInvoices,
};

function clearWizard() {
  return (dispatch) => {
    dispatch({ type: invoiceConstants.CLEAR_WIZARD });
  };
}

function createInvoice(data) {
  return (dispatch) => {
    // dispatch(request({ username }));

    invoiceService.createInvoice(data).then(
      (data) => {
        dispatch(success(data));
        // dispatch(alertActions.success("Ордер обновлен"));
        // history.push("/refill");
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  // function request(user) {
  //   return { type: invoiceConstants.CREATE_REQUEST, user };
  // }

  function success(data) {
    return { type: invoiceConstants.CREATE_SUCCESS, data };
  }

  function failure(error) {
    return { type: invoiceConstants.CREATE_FAILURE, error };
  }
}

function getInvoice(id) {
  return (dispatch) => {
    // dispatch({ type: invoiceConstants.GET_REQUEST, user }));

    invoiceService.getInvoice(id).then(
      (data) => {
        dispatch({ type: invoiceConstants.GET_SUCCESS, data });
      },
      (error) => {
        error = error.toString();
        dispatch({ type: invoiceConstants.GET_FAILURE, error });
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function getInvoiceFromOrder(orderId) {
  return (dispatch) => {
    // dispatch({ type: invoiceConstants.GET_REQUEST, user });

    invoiceService.getInvoiceFromOrder(orderId).then(
      (invoice) => {
        dispatch({ type: invoiceConstants.GET_SUCCESS, invoice });
      },
      (error) => {
        error = error.toString();
        dispatch({ type: invoiceConstants.GET_FAILURE, error });
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function getTotalPriceAllUnpaidChannels(id) {
  return (dispatch) => {
    // dispatch({ type: invoiceConstants.GET_TOTAL_PRICE_REQUEST, data });

    invoiceService.getTotalPriceAllChannelsUnpaid(id).then(
      (data) => {
        dispatch({ type: invoiceConstants.GET_TOTAL_PRICE_SUCCESS, data });
      },
      (error) => {
        error = error.toString();
        dispatch({ type: invoiceConstants.GET_TOTAL_PRICE_FAILURE, error });
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function getInvoiceItems(status) {
  return (dispatch) => {
    // dispatch({ type: invoiceConstants.GET_TOTAL_PRICE_REQUEST, data });

    invoiceService.getInvoiceItems(status).then(
      (data) => {
        data.status = status;
        dispatch({ type: invoiceConstants.GET_INVOICE_ITEMS_SUCCESS, data });
        dispatch(loaderActions.hide('pageLoader'));
      },
      (error) => {
        error = error.toString();
        dispatch({ type: invoiceConstants.GET_INVOICE_ITEMS_FAILURE, error });
        dispatch(alertActions.error(error.toString()));
        dispatch(loaderActions.hide('pageLoader'));
      }
    );
  };
}

function paidInvoices(orderDate) {
  return (dispatch) => {
    // dispatch(request({ username }));

    invoiceService.paidInvoices(orderDate).then(
      (allInvoices) => {
        dispatch({
          type: invoiceConstants.GET_TOTAL_PRICE_SUCCESS,
          allInvoices,
        });
        history.push('/payment/success');
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  // function request(data) {
  //   return { type: invoiceConstants.GET_TOTAL_PRICE_REQUEST, data };
  // }

  // function success(allInvoices) {
  //   return { type: invoiceConstants.GET_TOTAL_PRICE_SUCCESS, allInvoices };
  // }

  function failure(error) {
    return { type: invoiceConstants.GET_TOTAL_PRICE_FAILURE, error };
  }
}

function createInvoiceOfInvoices(invoices) {
  return (dispatch) => {
    // dispatch(request({ username }));

    invoiceService.createInvoiceOfInvoices(invoices).then(
      ({ data }) => {
        // dispatch({ type: invoiceConstants.GET_TOTAL_PRICE_SUCCESS, allInvoices });
        history.push(`/invoice/${data.invoiceid}`);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function failure(error) {
    return { type: invoiceConstants.GET_TOTAL_PRICE_FAILURE, error };
  }
}

function getPaymentLogs(pageNumber, nPerPage) {
  return (dispatch) => {
    // dispatch(request({ username }));

    invoiceService.getPaymentLogs(pageNumber, nPerPage).then(
      ({ data }) => {
        let transactions = [];
        if (data.totalresults > 0) {
          transactions = data.transactions;
        }
        dispatch({
          type: invoiceConstants.GET_LOGS_SUCCESS,
          data: transactions,
        });
        dispatch(loaderActions.hide('pageLoader'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loaderActions.hide('pageLoader'));
      }
    );
  };

  // function request(data) {
  //   return { type: invoiceConstants.GET_LOGS_REQUEST, data };
  // }

  // function success(paymentLogs) {
  //   return { type: invoiceConstants.GET_LOGS_SUCCESS, paymentLogs };
  // }

  function failure(error) {
    return { type: invoiceConstants.GET_LOGS_FAILURE, error };
  }
}

function startTest(orderId) {
  return (dispatch) => {
    // dispatch(request({ username }));

    invoiceService.startTest(orderId).then(
      (data) => {
        dispatch(success(data));
        history.push('/channels/list');
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  // function request(data) {
  //   return { type: invoiceConstants.GET_TOTAL_PRICE_REQUEST, data };
  // }

  function success(allInvoices) {
    return { type: invoiceConstants.GET_TOTAL_PRICE_SUCCESS, allInvoices };
  }

  function failure(error) {
    return { type: invoiceConstants.GET_TOTAL_PRICE_FAILURE, error };
  }
}

function getInvoices(limitstart, limitnum, status) {
  return (dispatch) => {
    // dispatch(request({ username }));

    invoiceService.getInvoices(limitstart, limitnum, status).then(
      ({ data }) => {
        const invoices = data.totalresults ? data.invoices.invoice : [];
        dispatch({
          type: invoiceConstants.GET_INVOICES_SUCCESS,
          data: invoices,
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function failure(error) {
    return { type: invoiceConstants.GET_INVOICES_FAILURE, error };
  }
}

function cancelInvoice(invoiceId, callback = null) {
  return (dispatch) => {
    dispatch(loaderActions.show('refillCancelInvoice'));
    // dispatch(request({ username }));
    invoiceService.cancelInvoice(invoiceId).then(
      (data) => {
        dispatch(loaderActions.hide('refillCancelInvoice'));
        dispatch(invoiceActions.getTotalPriceAllUnpaidChannels());
        dispatch({ type: invoiceConstants.GET_TOTAL_PRICE_SUCCESS, data });
        if (callback) callback();
      },
      (error) => {
        error = error.toString();
        dispatch(loaderActions.hide('refillCancelInvoice'));
        dispatch({ type: invoiceConstants.GET_TOTAL_PRICE_FAILURE, error });
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}
