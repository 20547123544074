import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Button from 'components/CustomButtons/Button.jsx';
import AddIcon from '@material-ui/icons/Add';

const EmptyPlaceholder = (props) => (
  <div className={props.classes.center}>
    <h3>
      <FormattedMessage id="viewers.list.empty" />
    </h3>
    <Button color="primary" onClick={props.addViewer}>
      <AddIcon className={props.classes.icons} />
      <FormattedMessage id="viewers.list.add" />
    </Button>
  </div>
);

export default injectIntl(EmptyPlaceholder);
