import { loaderConstants } from '../constants';
import set from 'lodash/set';

const initialState = {
  app: false,
  authLoginForm: false,
  authRegForm: false,
  authRecoveryForm: false,
  userProfileForm: false,
  userPasswordForm: false,
  userDeleteForm: false,
  partnerAuthorization: false,
  viewerAddForm: false,
  channelsTestPort: false,
  channelsTestVideo: false,
  channelsForm: false,
  orderForm: false,
  refillForm: false,
  refillBalanceForm: false,
  regionsAddForm: false,
  pageLoader: false,
  approveChannel: {},
};

export function loader(state = initialState, action) {
  if (!action.place) return state;
  switch (action.type) {
    case loaderConstants.show:
      return set({ ...state }, action.place, true);
    case loaderConstants.hide:
      return set({ ...state }, action.place, false);
    default:
      return state;
  }
}
