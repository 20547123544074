import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/ButtonWithLoader.js';

import { ewalletActions } from 'actions';
import { history } from 'helpers/history';

const styles = {
  row: {
    marginBottom: 20,
  },
  label: {},
  '@media (min-width: 600px)': {
    label: {
      textAlign: 'right',
    },
  },
};

class RefillForm extends Component {
  state = {
    amount: '',
    eWallet: {},
    submited: false,
    setInitialAmount: false,
  };

  componentDidUpdate() {
    if (this.props.totalAmount && !this.state.setInitialAmount) {
      this.setState({ setInitialAmount: true });
      this.setState({ amount: this.props.totalAmount });
    }

    if (
      Object.keys(this.props.eWallet).length &&
      this.props.eWallet !== this.state.eWallet
    ) {
      this.setState({ eWallet: this.props.eWallet });
      if (
        this.state.submited &&
        Object.keys(this.props.eWallet.invoice).length
      ) {
        history.push(`/invoice/${this.props.eWallet.invoice.invoiceid}`);
      }
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const regex =
      /^(?:\d+(?:,\d{3})*(?:\.\d{2})?|\d+(?:\.\d{0,2})*(?:,\d{2})?)$/;
    if (value !== '' && !regex.test(value)) return false;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    if (this.state.amount > 0) {
      const description = this.props.intl.formatMessage({
        id: 'bill.event.refill',
      });
      this.props.dispatch(
        ewalletActions.createBill(this.state.amount, description, 'UAH')
      );
      this.setState({ submited: true });
    }
  };

  render() {
    const { classes, balance, totalAmount, totalInvoices, loader } = this.props;
    return (
      <div>
        <Grid
          container
          spacing={16}
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={12} sm={6}>
            <div className={classes.label}>
              <FormattedMessage id="payment.form.balance" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <b>{balance}</b>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={16}
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={12} sm={6}>
            <div className={classes.label}>
              <FormattedMessage id="payment.form.notPaidInvoices" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <b>{totalInvoices}</b>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={16}
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={12} sm={6}>
            <div className={classes.label}>
              <FormattedMessage id="payment.form.total" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <b>{totalAmount || '0.00'}</b>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={16}
          alignItems="center"
          className={classes.row}
        >
          <Grid item xs={12} sm={6}>
            <div className={classes.label}>
              <FormattedMessage id="payment.form.recomend" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="text"
              name="amount"
              onChange={this.handleChange}
              value={this.state.amount}
            />
          </Grid>
        </Grid>

        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <Button
              loading={loader}
              color="success"
              onClick={this.handleSubmit}
            >
              <FormattedMessage id="payment.form.refill" />
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, invoice, channels, eWallet } = state;

  return {
    user: authentication.user,
    loader: state.loader.refillBalanceForm,
    invoice,
    channels,
    eWallet,
  };
}

export default injectIntl(
  connect(mapStateToProps)(withStyles(styles)(RefillForm))
);
