import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  invoiceActions,
  userActions,
  ewalletActions,
  tariffActions,
} from 'actions';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';

import Payment from '@material-ui/icons/Payment';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import RefillForm from './RefillBalanceForm';
import IsAddFoundExists from './IsAddFoundExists';

import LoaderAnimation from 'components/Loader';

import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const customStyle = {
  balanceText: {
    paddingTop: '38px',
    fontWeight: 500,
  },
};

const styles = { ...regularFormsStyle, ...customStyle };

class RefillPaid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [24, 22],
      selectedValue: null,
      selectedEnabled: 'b',
    };

    props.dispatch(invoiceActions.getTotalPriceAllUnpaidChannels());
    props.dispatch(userActions.getBalance());
    props.dispatch(tariffActions.getTariffs());

    this.paidInvoices = this.paidInvoices.bind(this);
    this.refillBalance = this.refillBalance.bind(this);
    this.cancelInvoice = this.cancelInvoice.bind(this);
  }

  paidInvoices() {
    const { dispatch } = this.props;
    dispatch(invoiceActions.paidInvoices());
  }

  cancelInvoice(id) {
    const { dispatch } = this.props;
    dispatch(invoiceActions.cancelInvoice(id));
  }

  refillBalance() {
    const { dispatch } = this.props;
    dispatch(ewalletActions.refillBalance());
  }

  render() {
    const { classes, user, invoice, loaderCancelInvoice } = this.props;

    if (!Object.keys(invoice.unpaidInvoices).length) {
      return <LoaderAnimation />;
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Payment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="payment.title" />
              </h4>
            </CardHeader>
            <CardBody>
              {invoice.unpaidInvoices.result === 'isexists' ? (
                <IsAddFoundExists
                  invoice={invoice.unpaidInvoices.invoices}
                  cancelInvoice={this.cancelInvoice}
                  loader={loaderCancelInvoice}
                />
              ) : (
                <RefillForm
                  paidInvoices={this.paidInvoices}
                  balance={user.credit}
                  totalAmount={invoice.unpaidInvoices.totalSum}
                  totalInvoices={invoice.unpaidInvoices.numreturned || 0}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, invoice, loader } = state;

  return {
    user: authentication.user,
    loaderCancelInvoice: loader.refillCancelInvoice,
    invoice,
  };
}

export default injectIntl(
  connect(mapStateToProps)(withStyles(styles)(RefillPaid))
);
