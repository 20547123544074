import {
  getChannelId,
  getVideoParams,
  displayImageStream,
  altStreamStatusChannel,
} from 'helpers';
import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { getFormValues } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import VideoImage from 'components/VideoImage/VideoImage';

const AlternativeVideoImageStream = (props) => {
  const {
    user,
    wizard,
    active,
    channel,
    portReachable,
    handleVideoLoad,
    videoStreamValues,
    activateVideoLoading,
  } = props;
  const videoParams = getVideoParams(user);
  const portsValues = Object.values(portReachable);
  return (
    <React.Fragment>
      {displayImageStream(wizard, videoStreamValues, portsValues) && (
        <Grid item xs={12} sm={6}>
          <div align="center" style={{ marginBottom: 10 }}>
            <h4>
              <FormattedMessage id="channels.form.stream.alt" />
            </h4>
            <VideoImage
              id="alternativeStream"
              wizard={wizard}
              active={active}
              {...videoParams}
              statisticStreamType="AlternativeVideo"
              statusChannel={altStreamStatusChannel(videoStreamValues)}
              channelId={getChannelId(wizard, videoStreamValues, channel)}
              activateVideoLoading={activateVideoLoading}
              onLoad={() => handleVideoLoad(true)}
              onError={() => handleVideoLoad(false)}
            />
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  channel: state.channels.edit,
  portReachable: state.channels.portReachable,
  videoStreamValues: getFormValues('VideoStreamTestForm')(state),
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(AlternativeVideoImageStream)
);
