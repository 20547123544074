import { systemConstants } from '../constants';

const initialState = {
  currencies: [],
};

export function system(state = initialState, action) {
  switch (action.type) {
    case systemConstants.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.data,
      };
    default:
      return state;
  }
}
