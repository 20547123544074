import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import Danger from 'components/Typography/Danger';
import { injectIntl, FormattedMessage } from 'react-intl';
import Button from 'components/CustomButtons/ButtonWithLoader.js';

const PartnerAuthorization = (props) => {
  const { classes, user, onClick, loader, regions, verification } = props;
  let waiting = false;
  let authorized = false;
  let waitModeration = false;
  let message = '';
  let ticketid = '';
  const userHaveAllData =
    user.profilephoto &&
    user.firstname &&
    user.lastname &&
    user.nickname &&
    user.phonenumber &&
    user.city &&
    user.email &&
    Object.keys(regions).length;
  if (Object.keys(verification).length) {
    if (verification.status === 'Open') {
      waitModeration = true;
    } else if (verification.status === 'Closed') {
      authorized = true;
    } else if (verification.status === 'Answered') {
      ticketid = verification.ticketid;
      message =
        verification.replies.reply[verification.replies.reply.length - 1]
          .message;
    }
  } else {
    waiting = true;
  }

  if (waiting) {
    return (
      <h3 style={{ margin: '0 0 10px' }}>
        <FormattedMessage id="profile.form.authorization.status.title" />:{' '}
        <FormattedMessage id="profile.form.authorization.status.waiting" />
      </h3>
    );
  }

  if (authorized) {
    return (
      <h3 style={{ margin: '0 0 10px' }}>
        <FormattedMessage id="profile.form.authorization.status.title" />:{' '}
        <FormattedMessage id="profile.form.authorization.status.authorized" />
      </h3>
    );
  }

  if (waitModeration) {
    return (
      <Fragment>
        <h3 style={{ margin: '0 0 10px' }}>
          <FormattedMessage id="profile.form.authorization.status.title" />:{' '}
          <FormattedMessage id="profile.form.authorization.status.moderated" />
        </h3>
        <p>
          <FormattedMessage id="profile.form.authorization.descrition.moderated" />
        </p>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <h3 style={{ margin: '0 0 10px' }}>
        <FormattedMessage id="profile.form.authorization.status.title" />:{' '}
        <FormattedMessage id="profile.form.authorization.status.notauthorized" />
      </h3>
      <p>
        {message ? (
          <Danger> {message} </Danger>
        ) : (
          <FormattedMessage id="profile.form.authorization.descrition.nodata" />
        )}
      </p>
      <Button
        disabled={!userHaveAllData || loader}
        loading={loader}
        type="submit"
        color="rose"
        className={classes.updateProfileButton}
        onClick={onClick(ticketid)}
      >
        <FormattedMessage id="profile.form.authorization.getAuthorized" />
      </Button>
    </Fragment>
  );
};

export default injectIntl(
  connect((state) => ({
    regions: state.places,
    user: state.authentication.user,
    loader: state.loader.partnerAuthorization,
  }))(PartnerAuthorization)
);
