import React from 'react';

import PersonPin from '@material-ui/icons/PersonPin';
import Clear from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import { getLangCode } from 'helpers/language';

import { FormattedMessage, injectIntl } from 'react-intl';

import GeoAutocomplete from 'components/GeoAutocomplete';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import { connect } from 'react-redux';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';

const Search = ({ classes, inputRef, lang, onPlaceChange, clearPlace }) => {
  const setInputRef = (node) => (inputRef.current = node);

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <PersonPin />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          <FormattedMessage id="layout.menu.searchpartner" />
        </h4>
      </CardHeader>
      <CardBody>
        <Grid container spacing={16}>
          <Grid item xs={9} sm={10} md={11}>
            <GeoAutocomplete
              inputRef={setInputRef}
              lang={lang}
              onChange={onPlaceChange}
            />
          </Grid>
          <Grid item xs={3} sm={2} md={1}>
            <Button fullWidth justIcon block size="sm" onClick={clearPlace}>
              <Clear />
            </Button>
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

export const SearchCard = injectIntl(
  connect((state) => ({
    lang: getLangCode(state.authentication.user.language),
  }))(withStyles(extendedTablesStyle)(Search))
);
