import { get } from 'lodash';
import { tariffConstants } from '../constants';

export const getResolution = (value, streamType) => {
  return (
    get(value, `statistic.${streamType}.Resolution.Width`, 0) *
    get(value, `statistic.${streamType}.Resolution.Height`, 0)
  );
};
export const resolveResolution = (resolution, breakpoint) => {
  return resolution > 0 && resolution < breakpoint;
};
export const failure = (error) => {
  return { type: tariffConstants.GET_FAILURE, error };
};
