export const pushRulesConstants = {
  GET_PUSH_RULES_SUCCESS: 'GET_PUSH_RULES_SUCCESS',
  GET_PUSH_RULES_FAILURE: 'GET_PUSH_RULES_FAILURE',

  GET_ONE_PUSH_RULES_SUCCESS: 'GET_ONE_PUSH_RULES_SUCCESS',
  GET_ONE_PUSH_RULES_FAILURE: 'GET_ONE_PUSH_RULES_FAILURE',

  REMOVE_PUSH_RULES_SUCCESS: 'REMOVE_PUSH_RULES_SUCCESS',
  REMOVE_PUSH_RULES_FAILURE: 'REMOVE_PUSH_RULES_FAILURE',

  ADD_PUSH_RULES_SUCCESS: 'ADD_PUSH_RULES_SUCCESS',
  ADD_PUSH_RULES_FAILURE: 'ADD_PUSH_RULES_FAILURE',

  EDIT_PUSH_RULES_SUCCESS: 'EDIT_PUSH_RULES_SUCCESS',
  EDIT_PUSH_RULES_FAILURE: 'EDIT_PUSH_RULES_FAILURE',

  CLEAN_PUSH_RULES_EDIT: 'CLEAN_PUSH_RULES_EDIT',
  CLEAN_PUSH_RULES_SUCCESS: 'CLEAN_PUSH_RULES_SUCCESS',
};
