import { connect } from 'react-redux';
import React, { Component } from 'react';
import LoaderAnimation from 'components/Loader';
import RefillFormPaidPeriod from './RefillFormPaidPeriod';
import RefillFormTestPeriod from './RefillFormTestPeriod';

class RefillForm extends Component {
  state = {
    currency: {},
    promocode: '',
  };

  componentDidUpdate() {
    if (
      this.props.currencies.length &&
      Object.keys(this.props.user).length &&
      !Object.keys(this.state.currency).length
    ) {
      this.props.currencies.map((item) => {
        if (item.id === this.props.user.currency) {
          this.setState({ currency: item });
        }
      });
    }
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    this.props.paid(this.state.promocode, true);
  };

  applyPromocode = () => {
    this.props.paid(this.state.promocode);
  };

  render() {
    const { balance, loaderAdd, orderReview, channelName, channelsData } =
      this.props;

    if (loaderAdd) return <LoaderAnimation />;

    const { cartData } = channelsData;

    if (orderReview.pid === 1 || orderReview.pid === 5) {
      return (
        <RefillFormTestPeriod
          channelName={channelName}
          handleSubmit={this.handleSubmit}
        />
      );
    }

    return (
      <RefillFormPaidPeriod
        paid={this.props.paid}
        balance={balance}
        cartData={cartData}
        channelName={channelName}
        promocode={this.state.promocode}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        applyPromocode={this.applyPromocode}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { channels, eWallet, tariff } = state;
  return {
    currencies: state.system.currencies,
    user: state.authentication.user,
    loader: state.loader.refillForm,
    loaderAdd: state.loader.orderFormAdd,
    channelsData: channels.edit,
    channels,
    tariff,
    eWallet,
  };
};

export default connect(mapStateToProps)(RefillForm);
