import Square from '../../classes/Square';

export class Grid {
  constructor({ size, grid }) {
    this.setSize(size);
    this.setupGrid();
    this.set(grid);
  }

  setSize(size = { x: 40, y: 30 }) {
    this.size = size;
  }

  setupGrid() {
    this.grid = Array.from({ length: this.size.y }).map((row, y) =>
      Array.from({ length: this.size.x }).map((el, x) => ({
        x,
        y,
        filled: 0,
        square: new Square(),
      }))
    );
  }

  get() {
    return this.grid.map((row) => row.map((el) => el.filled));
  }

  set(grid = []) {
    if (!grid || !grid.length) return;
    if (grid.length !== this.size.y && grid[0].length !== this.size.x) {
      throw 'Wrong map size';
    }
    grid.forEach((row, y) =>
      row.forEach((el, x) => (this.grid[y][x].filled = Number(el)))
    );
  }

  fill() {
    this.grid.forEach((row) => row.forEach((el) => (el.filled = 1)));
  }

  clear() {
    this.grid.forEach((row) => row.forEach((el) => (el.filled = 0)));
  }

  draw(ctx = undefined) {
    if (!ctx) throw 'No canvas';
    const squareSize = {
      width: ctx.canvas.width / this.size.x,
      height: ctx.canvas.height / this.size.y,
    };
    this.grid
      .flat()
      .map((i) => {
        i.square.setSize(squareSize);
        i.square.setPosition({
          x: i.x * squareSize.width,
          y: i.y * squareSize.height,
        });
        return i;
      })
      .filter((i) => i.filled === 1)
      .forEach((i) => i.square.draw(ctx));
  }
}

export default Grid;
