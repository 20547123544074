import { partnerConstants } from '../constants';
import { prepareUserDataFromResponse } from '../helpers/userNormalizer';

const initialState = {
  verification: {},
  partners: [],
  abonents: [],
};

export function partner(state = initialState, action) {
  switch (action.type) {
    case partnerConstants.REQUEST_VERIFICATION_SUCCESS:
    case partnerConstants.GET_STATUS_SUCCESS:
      return {
        ...state,
        verification: { ...action.data },
      };
    case partnerConstants.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: [...action.data].map((item) =>
          prepareUserDataFromResponse(item)
        ),
      };
    case partnerConstants.GET_PARTNERS_ABONENTS_SUCCESS:
    case partnerConstants.GET_PARTNERS_CONFIRM_SUCCESS:
      return {
        ...state,
        abonents: [...action.data],
      };
    default:
      return state;
  }
}
