import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import ButtonUI from 'components/CustomButtons/Button.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';

const style = {
  wrapper: {
    position: 'relative',
  },
  iblock: {
    display: 'inline-block',
  },
  block: {
    display: 'block',
  },
  invisible: {
    visibility: 'hidden',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: 'white',
  },
};

const Button = ({
  disabled,
  loading,
  children,
  classes,
  className,
  fullWidth,
  ...other
}) => (
  <div
    className={`${classes.wrapper} ${className} ${
      fullWidth ? classes.block : classes.iblock
    }`}
  >
    <ButtonUI
      disabled={Boolean(disabled || loading)}
      fullWidth={fullWidth}
      {...other}
    >
      <span className={loading ? classes.invisible : ''}>{children}</span>
    </ButtonUI>
    {loading && <CircularProgress size={24} className={classes.spinner} />}
  </div>
);

export default withStyles(style)(Button);
