import ContactUs from 'components/ContactUs';
import Dashboard from 'containers/Dashboard/Dashboard';
import PartnerDashboard from 'containers/Dashboard/PartnerDashboard';

import UserProfile from 'containers/UserProfile';
import PartnerProfile from 'containers/PartnerProfile';

import Regions from 'containers/Regions';
import SearchPartner from 'containers/SearchPartner';

import Subscribers from 'containers/Subscribers';
import RateCamera from 'containers/RateCamera';

import Channels from 'containers/Channels/List';
import ChannelWizard from 'containers/InstallWizard/ChannelWizard';
import EditChannel from 'containers/Channels/EditChannel';
import OrderView from 'containers/Order';

import Refill from 'containers/Refill/RefillPaid';
import InvoiceView from 'containers/Invoice/InvoiceView';
import InvoicesList from 'containers/Invoice/InvoicesList';
import InvoiceTransactions from 'containers/Invoice/InvoiceTransactions';
import PaidAllInvoices from 'containers/Invoice/PaidAllInvoices';
import PaymentsSuccess from 'containers/Payments/PaymentsSuccess';

import Viewers from 'containers/Viewers/List';
import ViewersAdd from 'containers/Viewers/Add/';
import ViewersEdit from 'containers/Viewers/Edit';

import Withdraw from 'containers/Withdraw/Withdraw';
import PartnerTransfer from 'containers/TransferCredit/PartnerTransfer';
import AbonentTransfer from 'containers/TransferCredit/AbonentTransfer';

import ZonesList from 'containers/Zones/List';
import ZonesEdit from 'containers/Zones/Edit';
import Instructions from 'components/Instructions';
import PushReceiving from '../containers/PushReceiving/List';
import PushAdd from '../containers/PushReceiving/Add';

// Icons
import Group from '@material-ui/icons/Group';
import Place from '@material-ui/icons/Place';
import Alarm from '@material-ui/icons/Alarm';
import GridOn from '@material-ui/icons/GridOn';
import People from '@material-ui/icons/People';
import ListAlt from '@material-ui/icons/ListAlt';
import Videocam from '@material-ui/icons/Videocam';
import PersonPin from '@material-ui/icons/PersonPin';
import ContactsIcon from '@material-ui/icons/Contacts';
import DashboardIcon from '@material-ui/icons/Dashboard';
import WinManuals from 'components/Instructions/WinManuals';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';

const channels = [
  {
    name: 'layout.menu.channels',
    show: true,
    icon: Videocam,
    state: 'openChannels',
    collapse: true,
    views: [
      {
        path: '/channels/list',
        name: 'layout.menu.channels.list',
        component: Channels,
      },
      {
        path: '/channels/add',
        name: 'layout.menu.channels.add',
        component: ChannelWizard,
      },
    ],
  },
  {
    path: '/channels/edit/:channelId',
    name: 'layout.menu.channels.edit',
    show: false,
    component: EditChannel,
  },
  {
    path: '/channels/rate',
    show: false,
    component: RateCamera,
  },
  {
    redirect: true,
    path: '/channels',
    pathTo: '/',
    name: 'layout.menu.homepage',
  },
];

const viewers = [
  {
    name: 'layout.menu.viewers',
    show: true,
    icon: Group,
    state: 'openViewers',
    collapse: true,
    views: [
      {
        path: '/viewers/list',
        name: 'layout.menu.viewers.list',
        component: Viewers,
      },
      {
        path: '/viewers/add',
        name: 'layout.menu.viewers.add',
        component: ViewersAdd,
      },
    ],
  },
  {
    path: '/viewers/edit/:viewerId',
    name: 'layout.menu.viewers.edit',
    show: false,
    component: ViewersEdit,
  },
  {
    redirect: true,
    path: '/viewers/edit',
    pathTo: '/viewers/list',
    name: 'layout.menu.homepage',
  },
  {
    redirect: true,
    path: '/viewers',
    pathTo: '/viewers/list',
    name: 'layout.menu.homepage',
  },
];

const zones = [
  {
    path: '/zones/list',
    name: 'layout.menu.zones',
    show: true,
    icon: GridOn,
    component: ZonesList,
  },
  {
    path: '/zones/edit/:channelId',
    name: 'layout.menu.zones.edit',
    show: false,
    component: ZonesEdit,
  },
  {
    redirect: true,
    path: '/zones/edit',
    pathTo: '/zones/list',
  },
  {
    redirect: true,
    path: '/zones',
    pathTo: '/zones/list',
  },
];

const pushReceiving = [
  {
    path: '/push/add',
    name: 'layout.menu.push.add',
    show: false,
    icon: Alarm,
    component: PushAdd,
  },
  {
    path: '/push/edit/:zoneIndex',
    name: 'layout.menu.push.edit',
    show: false,
    icon: Alarm,
    component: PushAdd,
  },
  {
    path: '/push',
    name: 'layout.menu.push',
    show: true,
    icon: Alarm,
    component: PushReceiving,
  },
];

const ewallet = (role) => {
  const route = [
    {
      name: 'layout.menu.billing',
      show: true,
      icon: AccountBalanceWallet,
      state: 'openEwallet',
      collapse: true,
      views: [
        {
          path: '/invoices',
          name: 'layout.menu.ewallet.logs',
          component: InvoicesList,
        },
        {
          path: '/invoice/transaction',
          name: 'layout.menu.invoice.transactions',
          component: InvoiceTransactions,
        },
      ],
    },
    {
      path: '/invoice/paidall',
      name: 'layout.menu.ewallet.refill',
      show: false,
      component: PaidAllInvoices,
    },
    {
      path: '/invoice/:invoiceid',
      name: '',
      show: false,
      icon: ListAlt,
      component: InvoiceView,
    },
    {
      path: '/order/edit/:channelId',
      name: '',
      show: false,
      icon: Videocam,
      component: OrderView,
    },
    {
      path: '/payment/success/:orderId',
      name: '',
      show: false,
      icon: Videocam,
      component: PaymentsSuccess,
    },
    {
      redirect: true,
      path: '/ewallet',
      pathTo: '/',
      name: 'layout.menu.homepage',
    },
  ];

  if (role === 'abonent') {
    route[0].views.push({
      path: '/gettransfer',
      name: 'layout.menu.getTransaction',
      component: AbonentTransfer,
    });
    route[0].views.push({
      path: '/refill',
      name: 'layout.menu.ewallet.refill',
      component: Refill,
    });
  } else if (role === 'partner') {
    route[0].views.push({
      path: '/transfer',
      name: 'layout.menu.transfer.list',
      component: PartnerTransfer,
    });
    route[0].views.push({
      path: '/withdraw',
      name: 'layout.menu.withdraw',
      component: Withdraw,
    });
  }

  return route;
};

export const abonentRoutes = [
  {
    path: '/profile',
    name: 'layout.menu.profile',
    show: false,
    component: UserProfile,
  },
  {
    path: '/instructions',
    name: 'layout.menu.instructions',
    icon: QuestionAnswerIcon,
    state: 'openInstructions',
    collapse: true,
    views: [
      {
        path: '/instructions/ios',
        name: 'layout.menu.instructions.iphone',
        show: false,
        component: Instructions,
      },
      {
        path: '/instructions/win',
        name: 'layout.menu.instructions.windows',
        show: false,
        component: WinManuals,
      },
    ],
  },
  {
    path: '/searchpartner',
    name: 'layout.menu.searchpartner',
    show: true,
    icon: PersonPin,
    component: SearchPartner,
  },
  ...channels,
  ...zones,
  ...viewers,
  ...pushReceiving,
  ...ewallet('abonent'),
  {
    path: '/help',
    name: 'layout.menu.contacts',
    show: true,
    icon: ContactsIcon,
    component: ContactUs,
  },
  {
    path: '/',
    name: 'layout.menu.homepage',
    show: false,
    icon: DashboardIcon,
    component: Dashboard,
  },
];

export const partnerRoutes = [
  {
    path: '/profile',
    name: 'layout.menu.profile',
    show: false,
    component: PartnerProfile,
  },
  {
    path: '/regions',
    name: 'layout.menu.regions',
    show: true,
    icon: Place,
    component: Regions,
  },
  {
    path: '/subscribers',
    name: 'layout.menu.subscribers',
    show: true,
    icon: People,
    component: Subscribers,
  },
  ...ewallet('partner'),
  {
    path: '/help',
    name: 'layout.menu.contacts',
    show: true,
    icon: ContactsIcon,
    component: ContactUs,
  },
  {
    path: '/',
    name: 'layout.menu.homepage',
    show: false,
    icon: DashboardIcon,
    component: PartnerDashboard,
  },
];

export default {
  abonent: abonentRoutes,
  partner: partnerRoutes,
};
