export default {
  container: {
    position: 'relative',
    width: '100%',
    paddingBottom: '75%',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  filler: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  stream: {
    margin: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  info: {
    background: '#e7e7e7',
    color: '#9c9c9c',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '5rem',
  },
  title: {
    fontSize: '2rem',
    margin: 0,
  },
  statistic: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: '5px 15px',
    borderRadius: '0 4px 0 0',
    background: 'rgba(0, 0, 0, 0.8)',
    color: '#FFF',
  },
};
