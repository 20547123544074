import get from 'lodash/get';
import set from 'lodash/set';

// const ipRegex = new RegExp(
//   /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
// );

export default (values, props) => {
  const errors = {
    Brand: undefined,
    data: {
      Name: undefined,
      ConnectionSettings: {
        ModelId: undefined,
        Hostname: undefined,
        ExternalNetworkPorts: [
          {
            PortValue: undefined,
          },
        ],
        Login: undefined,
        Password: undefined,
        MainVideoStreamFormat: undefined,
        AltVideoStreamFormat: undefined,
        AltVideoStreamEnabled: undefined,
        SoundReceivingEnabled: undefined,
        SoundTransmittingEnabled: undefined,
        PtzEnabled: undefined,
      },
      ArchiveSettings: {
        DeviceArchiveEnabled: undefined,
      },
      customParam: {
        installer: undefined,
      },
    },
    customParam: {
      Port: undefined,
    },
  };

  const requiredFields = [
    'Brand',
    'data.Name',
    'data.ConnectionSettings.ModelId',
    'data.ConnectionSettings.Hostname',
    // "data.ConnectionSettings.ExternalNetworkPorts.0.PortValue",
    // "customParam.Port",
    'data.ConnectionSettings.Login',
    'data.ConnectionSettings.Password',
    'data.ConnectionSettings.MainVideoStreamFormat',
  ];
  requiredFields.forEach((field) => {
    if (!get(values, field)) {
      set(
        errors,
        field,
        props.intl.formatMessage({ id: 'validation.required' })
      );
    }
  });

  const lengthCheck = [
    'data.Name',
    // "data.customParam.installer"
    // "data.ConnectionSettings.Login"
  ];
  lengthCheck.forEach((field) => {
    const val = get(values, field);
    if (val && (val.length < 3 || val.length > 30)) {
      set(
        errors,
        field,
        props.intl.formatMessage({ id: 'channels.form.validation.length' })
      );
    }
  });

  if (
    get(values, 'data.ConnectionSettings.AltVideoStreamEnabled') &&
    !get(values, 'data.ConnectionSettings.AltVideoStreamFormat')
  ) {
    set(
      errors,
      'data.ConnectionSettings.AltVideoStreamFormat',
      props.intl.formatMessage({ id: 'validation.required' })
    );
  }

  const hostname = get(values, 'data.ConnectionSettings.Hostname');
  // if (hostname && hostname.trim() && !ipRegex.test(hostname.trim())) {
  //   set(
  //     errors,
  //     "data.ConnectionSettings.Hostname",
  //     props.intl.formatMessage({ id: "channels.form.validation.ip" })
  //   );
  // }

  const httpPort = get(values, 'customParam.Port');
  if (httpPort && (httpPort < 1 || httpPort > 65535)) {
    set(
      errors,
      'customParam.Port',
      props.intl.formatMessage({ id: 'channels.form.validation.port.correct' })
    );
  }

  const externalPorts = get(
    values,
    'data.ConnectionSettings.ExternalNetworkPorts'
  );
  if (externalPorts) {
    externalPorts.forEach((port, index) => {
      if (port) {
        if (port.PortValue < 1 || port.PortValue > 65535) {
          set(
            errors,
            `data.ConnectionSettings.ExternalNetworkPorts[${index}].PortValue`,
            props.intl.formatMessage({
              id: 'channels.form.validation.port.correct',
            })
          );
        }
      }
    });
  }

  // const password = objectLens(values, "data.ConnectionSettings.Password");
  // if (password && password.trim().length < 6) {
  //   objectLens(
  //     errors,
  //     "data.ConnectionSettings.Password",
  //     props.intl.formatMessage({ id: "validation.password" }, { count: 6 })
  //   );
  // }
  if (hostname && !values.isPortsTested) {
    errors.isPortsTested = props.intl.formatMessage({
      id: 'channels.form.validation.test.ports',
    });
  }
  if (
    hostname &&
    values.isPortsTested &&
    externalPorts &&
    externalPorts.map((i) => i.PortValue).some((i) => !values.isPortsTested[i])
  ) {
    if (Object.keys(values.isPortsTested).length === 1) return;
    errors.isPortsTested = props.intl.formatMessage({
      id: 'channels.form.validation.test.portsNotAvaliable',
    });
  }

  // if (values.isPortsTested) {
  //   if (
  //     httpPort &&
  //     get(values.isPortsTested, httpPort) !== undefined &&
  //     !get(values.isPortsTested, httpPort)
  //   ) {
  //     set(
  //       errors,
  //       "customParam.Port",
  //       props.intl.formatMessage({ id: "channels.form.validation.port.closed" })
  //     );
  //   }
  //   if (externalPorts) {
  //     externalPorts.forEach((port, index) => {
  //       if (
  //         get(values.isPortsTested, port.PortValue) !== undefined &&
  //         !get(values.isPortsTested, port.PortValue)
  //       ) {
  //         set(
  //           errors,
  //           `data.ConnectionSettings.ExternalNetworkPorts[${index}].PortValue`,
  //           props.intl.formatMessage({
  //             id: "channels.form.validation.port.closed"
  //           })
  //         );
  //       }
  //     });
  //   }
  // }

  if (!values.isVideoTested) {
    errors.isVideoTested = props.intl.formatMessage({
      id: 'channels.form.validation.test.video',
    });
  }

  const installer = get(values, 'data.customParam.installer');
  if (
    installer &&
    installer.trim() &&
    (installer.trim().length !== 6 || /\D/g.test(installer))
  ) {
    set(
      errors,
      'data.customParam.installer',
      props.intl.formatMessage({ id: 'channels.form.validation.partnercode' })
    );
  }

  return errors;
};
