import { connect } from 'react-redux';
import { alertActions } from 'actions';
import { injectIntl } from 'react-intl';
import React, { Component } from 'react';
import VideoStreamControls from './VideoStreamControls';
import VideoImageStreamContainer from './VideoImageStreamContainer';

class VideoStreamTest extends Component {
  state = {
    active: {
      mainVideoStreamActive: false,
      altVideoStreamActive: false,
      videoStreamError: false,
    },
    tested: false,
    showTestTimeCameraWarning: true,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.active.mainVideoStreamActive !==
        prevState.active.mainVideoStreamActive &&
      this.state.showTestTimeCameraWarning &&
      this.props.wizard
    ) {
      this.setState({ showTestTimeCameraWarning: false });
      this.props.dispatch(alertActions.info('channels.form.warning.testTime'));
    }
  }

  handleVideoLoad = (status) => {
    this.setState({
      tested: status,
    });
  };

  activateVideoLoading = (status) => {
    this.setState({
      active: status,
    });
  };

  render() {
    const {
      wizard,
      sendForm,
      innerRef,
      editChannel,
      currentStep,
      orderOptionsEnabled,
    } = this.props;
    return (
      <React.Fragment>
        <VideoImageStreamContainer
          wizard={wizard}
          active={this.state.active}
          tested={this.state.tested}
          handleVideoLoad={this.handleVideoLoad}
          activateVideoLoading={this.activateVideoLoading}
        />
        <VideoStreamControls
          wizard={wizard}
          sendForm={sendForm}
          innerRef={innerRef}
          currentStep={currentStep}
          editChannel={editChannel}
          active={this.state.active}
          tested={this.state.tested}
          orderOptionsEnabled={orderOptionsEnabled}
          activateVideoLoading={this.activateVideoLoading}
        />
      </React.Fragment>
    );
  }
}

export default injectIntl(connect()(VideoStreamTest));
