import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from './Card';
import Pagination from 'components/Pagination';

class List extends React.Component {
  state = { page: 1, data: [] };

  itemsOnPage = 12;

  componentDidMount() {
    this.setPage(1);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.channels !== this.props.channels) this.setPage(1);
    if (prevState.page !== this.state.page) {
      window.scrollTo(0, this.wrapper.offsetTop - document.body.scrollTop);
    }
  }

  setPage = (page) => {
    const data = this.props.channels.slice(
      (page - 1) * this.itemsOnPage,
      page * this.itemsOnPage
    );
    this.setState({ page, data });
  };

  render() {
    const total = Math.ceil(this.props.channels.length / this.itemsOnPage);
    return (
      <div ref={(node) => (this.wrapper = node)}>
        <Grid container spacing={16}>
          {this.state.data.map((el) => (
            <Grid item key={el.id} xs={12} sm={6} lg={4} xl={3}>
              <Card channel={el} rated={this.props.rated} />
            </Grid>
          ))}
        </Grid>
        {total > 1 && (
          <Pagination
            style={{ marginTop: 30 }}
            current={this.state.page}
            total={total}
            visible="5"
            change={this.setPage}
          />
        )}
      </div>
    );
  }
}

export default List;
