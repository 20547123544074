import appStoreIcon from '../../assets/img/manuals/appStoreIcon.png';
import installManualImg from '../../assets/img/manuals/01_install-1.png';
import firstSetupManualImg from '../../assets/img/manuals/02_setup-1.png';
import secondSetupManualImg from '../../assets/img/manuals/02_setup-2.png';
import thirdSetupManualImg from '../../assets/img/manuals/02_setup-3.png';
import fourthSetupManualImg from '../../assets/img/manuals/02_setup-4.png';
import use1ManualImg from '../../assets/img/manuals/03_use-1.png';
import use2ManualImg from '../../assets/img/manuals/03_use-2.png';
import use3ManualImg from '../../assets/img/manuals/03_use-3.png';
import use4ManualImg from '../../assets/img/manuals/03_use-4.png';
import qualitySwitchManualImg from '../../assets/img/manuals/quality_switch.png';
import use5ManualImg from '../../assets/img/manuals/03_use-5.png';
import use6ManualImg from '../../assets/img/manuals/03_use-6.png';
import playbacksManualImg from '../../assets/img/manuals/playback.png';
import use7ManualImg from '../../assets/img/manuals/03_use-7.png';
import notificationsManualImg from '../../assets/img/manuals/notifications.png';
import use8ManualImg from '../../assets/img/manuals/03_use-8.png';
import settingsManualImg from '../../assets/img/manuals/Settings.png';
import settings1ManualImg from '../../assets/img/manuals/04_settings-1.png';
import settings2ManualImg from '../../assets/img/manuals/04_settings-2.png';
import settings6ManualImg from '../../assets/img/manuals/04_settings-6.png';
import settings3ManualImg from '../../assets/img/manuals/04_settings-3.png';
import settings4ManualImg from '../../assets/img/manuals/04_settings-4.png';
import settings5ManualImg from '../../assets/img/manuals/04_settings-5.png';
import settings7ManualImg from '../../assets/img/manuals/04_settings-7.png';
import settings8ManualImg from '../../assets/img/manuals/04_settings-8.png';

export const appStoreUrl =
  'https://apps.apple.com/ua/app/macroscop/id590215695?l=ru';

export const appStore = appStoreIcon;

export const ruImages = {
  installationSection: {
    installation: installManualImg,
  },
  setupCameraSection: {
    firstSetup: firstSetupManualImg,
    secondSetup: secondSetupManualImg,
    thirdSetup: thirdSetupManualImg,
    fourthSetup: fourthSetupManualImg,
  },
  utilizationCameraSection: {
    use1Manual: use1ManualImg,
    use2Manual: use2ManualImg,
    use3Manual: use3ManualImg,
    use4Manual: use4ManualImg,
    use5Manual: use5ManualImg,
    use6Manual: use6ManualImg,
    use7Manual: use7ManualImg,
    use8Manual: use8ManualImg,
  },
  settingsCameraSection: {
    settingsManual: settingsManualImg,
    settings1Manual: settings1ManualImg,
    settings2Manual: settings2ManualImg,
    settings3Manual: settings3ManualImg,
    settings4Manual: settings4ManualImg,
    settings5Manual: settings5ManualImg,
    settings6Manual: settings6ManualImg,
    settings7Manual: settings7ManualImg,
    settings8Manual: settings8ManualImg,
  },
  other: {
    qualitySwitch: qualitySwitchManualImg,
    playbacks: playbacksManualImg,
    notifications: notificationsManualImg,
  },
};

export const uaHeaders = [
  'Встановлення',
  'Налаштування підключення до IPTeamCloud',
  'Користування',
  'Налаштування',
];

export const uaBoldSections = [
  'Мобільний iOS-застосунок Macroscop - ',
  ' iOS',
  'Macroscop',
  'Назва системи:',
  'IP адрес або доменне ім’я сервера:',
  'Порт сервера:',
  '«Безпечне підключення»',
  '«Отримувати push-повідомлення»',
  '«Підключатися під час запуску програми»',
  'Им’я користувача:',
  'Пароль:',
  '«ЗБЕРЕГТИ»',
];
export const uaParagraphs = [
  'Мобільний iOS-застосунок Macroscop',
  'це застосунок для',
  `для перегляду відео та аудіо з камер, підключених до серверів`,
  `: як в режимі реального часу, так й з архіву записів. Для доступу до відео необхідно встановити з'єднання з віддаленим сервером IPTeamCloud. При цьому з'єднання повинно мати достатню для отримання відео пропускну здатність (швидкість Інтернет-з'єднання).`,
  `Встановлення програми Мобільний iOS-застосунок Macroscop на пристрій під управлінням iOS здійснюється з App Store стандартним способом. При цьому рекомендується здійснювати пошук за ключовим словом Macroscop.`,
  `Застосунок працює під управлінням iOS версії 8.0 і вище.`,
  `За замовчуванням в перелік систем включено демо-сервер. Користувачі не мають доступу до параметрів демо-сервера.`,
  `Демо-сервер можна видалити із переліку систем.`,
  `Для підключення до IPTeamCloud натисніть “+”. Відкриється вікно «Додати систему»:`,
  `Реквізити підключення потрібно заповнити як на малюнку нижче:`,
  `8080 или 18080 (при безопасном подключении)`,
  'За бажанням включити опції:',
  `- для шифрування каналу зв'язку;`,
  `- для отримування повідомлень;`,
  `- для автоматичного підключення`,
  `Ваш e-mail (від входу в Особистий Кабінет Абонента).`,
  `Ваш пароль (від входу в Особистий Кабінет Абонента).`,
  'Натисніть',
  'Натисніть на нову систему для підключення:',
  'Це вікно з Вашими камерами в реальному часі:',
  'Піктограма з камерами надає лист доступних Вам камер:',
  'Натисніть бажану камеру для відображення:',
  `Ваш смартфон можна повернути горизонтально для збільшення зображення з камери:`,
  `Ви можете перемикати якість трансляції`,
  `Вища якість трансляції потребує більшу швидкість Інтернет-з'єднання, та більшу обчислювальну потужність процесора Вашого смартфону для плавного відображення відео.`,
  `Натисніть піктограму архіву для відтворення записів з камери:`,
  `Натисніть піктограму`,
  `для вибору часу і дати для відтворення записів`,
  `У розділі`,
  `міститься перелік отриманих push-повідомлень (якщо в Вашому тарифі активована ця послуга, та включений прийом push-повідомлень в налаштуваннях застосунку).`,
  'Розділ',
  'містить налаштування застосунку:',
  `Налаштування розділу «Відео» задають параметри трансляції, що безпосередньо впливають на потрібну швидкість Інтернет-з'єднання, ресурси процесора та як наслідок, інтенсивність використання заряду акумулятора. Чим вище частота кадрів, тим більше потрібно ресурсів смартфона.`,
  `У розділі «Види» можна створювати власні види:`,
  `Для перемикання видів в режимі відображення відкрийте панель «Види» для вибору:`,
];

export const ruHeaders = [
  'Установка',
  'Настройка подключения к IPTeamCloud',
  'Использование',
  'Настройки',
];
export const ruBoldSections = [
  'Мобильный iOS-клиент Macroscop - ',
  ' iOS',
  'Macroscop.',
  'Название системы:',
  'IP адрес или доменное имя сервера:',
  'Порт сервера:',
  '«Безопасное подключение»',
  '«Получать push-уведомления»',
  '«Подключаться при запуске приложения»',
  'Имя пользователя:',
  'Пароль:',
  '«СОХРАНИТЬ»',
];
export const ruParagraphs = [
  'Мобильный iOS-клиент Macroscop',
  'это приложение, работающее под управлением',
  `как в реальном времени, так и из архива`,
  'Для доступа к видео необходимо установить соединение с удаленным сервером IPTeamCloud. При этом соединение должно обладать достаточной для получения видео пропускной способностью (скоростью Интернет-соединения).',
  'Установка приложения Мобильный iOS-клиент Macroscop на устройство под управлением iOS осуществляется из App Store стандартным способом. При этом рекомендуется осуществлять поиск по ключевому слову Macroscop.',
  'Приложение работает под управлением iOS версии 8.0 и выше.',
  `По умолчанию в список систем включен демо-сервер. У пользователя отсутствует доступ к параметрам подключения к демо-серверу. `,
  `Демо-сервер можно удалить из списка систем.`,
  `Для подключения к IPTeamCloud нажмите “+”. Откроется «Добавление системы»:`,
  `Реквизиты подключения нужно заполнить как показано ниже:`,
  `8080 или 18080 (при безопасном подключении)`,
  `По желанию установить флажки:`,
  `- для использования шифрования канала связи;`,
  `- для получения уведомлений;`,
  `- для автоматического подключения.`,
  `Ваш e-mail (используемый для входа в Личный Кабинет Абонента).`,
  `Ваш пароль (используемый для входа в Личный Кабинет Абонента).`,
  'Нажмите',
  'Кликните на новую систему для подключения:',
  'Это окно с Вашими камерами в реальном времени:',
  'Пиктограмма с камерами вызывает окно выбора доступных Вам камер:',
  'Нажмите на интересующую Вас камеру для отображения:',
  `Ваш смартфон можно повернуть горизонтально для увеличения изображения с камеры:`,
  `Вы можете переключать качество трансляции`,
  `Чем выше качество трансляции, тем больше скорость Интернет-соединения и вычислительная мощность процессора требуется Вашему смартфону для плавного отображения видео.`,
  `Нажмите пиктограмму архива для воспроизведения записей с камеры:`,
  `Нажмите пиктограмму`,
  ` календаря для выбора времени и даты для воспроизведения записей:`,
  `В разделе`,
  `содержится список полученных push уведомлений (если у Вас активирована эта услуга в тарифе и включен прием push-уведомлений в настройках приложения).`,
  'В разделе',
  'содержатся настройки приложения:',
  `Настройки раздела «Видео» задают параметры трансляции, которые напрямую влияют на необходимую скорость Интернет-соединения, ресурсы процессора и как следствие, интенсивность использования заряда аккумулятора. Чем выше частота кадров, тем больше потребуется ресурсов смартфона.`,
  `В разделе «Виды» можно создавать собственные виды:`,
  `Для переключения видов в режиме отображения откройте панель «Виды» для выбора:`,
];

export const winManuals = (uri) => () => {
  return window.open(uri, '_blank').focus();
};
