import React from 'react';
import ReactTable from 'react-table';
import { injectIntl } from 'react-intl';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

export const InvoiceTable = (props) => {
  const { data, intl } = props;

  const tableTranslate = {
    previousText: intl.formatMessage({ id: 'layout.table.previousText' }),
    nextText: intl.formatMessage({ id: 'layout.table.nextText' }),
    loadingText: intl.formatMessage({ id: 'layout.table.loadingText' }),
    noDataText: intl.formatMessage({ id: 'layout.table.noDataText' }),
    pageText: intl.formatMessage({ id: 'layout.table.pageText' }),
    ofText: intl.formatMessage({ id: 'layout.table.ofText' }),
    rowsText: intl.formatMessage({ id: 'layout.table.rowsText' }),
  };

  return (
    <Card>
      <CardBody>
        <ReactTable
          data={data}
          {...tableTranslate}
          filterable
          columns={[
            {
              Header: '#ID',
              accessor: 'id',
              filterable: false,
            },
            {
              Header: intl.formatMessage({ id: 'invoice.history.table.type' }),
              accessor: 'typeDescription',
              filterable: false,
            },
            {
              Header: intl.formatMessage({
                id: 'invoice.history.table.datepaid',
              }),
              accessor: 'datepaid',
              filterable: false,
            },
            {
              Header: intl.formatMessage({
                id: 'invoice.history.table.duedate',
              }),
              accessor: 'duedate',
              filterable: false,
            },
            {
              Header: intl.formatMessage({
                id: 'invoice.history.table.amount',
              }),
              accessor: 'amount',
              filterable: false,
            },
            {
              Header: intl.formatMessage({
                id: 'invoice.history.table.status',
              }),
              accessor: 'statusIntl',
              sortable: false,
              filterable: false,
            },
          ]}
          defaultPageSize={10}
          showPaginationBottom
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
};

export default injectIntl(InvoiceTable);
