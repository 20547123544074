import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import formatGeoData from 'helpers/formatGeoData';

import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import PartnerList from './PartnerList';
import { partnerActions, loaderActions } from 'actions';
import LoaderAnimation from 'components/Loader';
import {
  WelcomePlaceholder,
  AreaToLargePlaceholder,
  NoResultsPlaceholder,
} from './Placeholders';

import {
  buildPlaceForEnlarge,
  searchedPlaceTitle,
  sortPartnersByRating,
} from './service';
import { SearchCard } from './SearchCard';

const SearchPartner = ({ data, dispatch, loader }) => {
  const [place, setPlace] = useState(null);
  const [partners, setPartners] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    // sort partners by rating when the component will get a new response from the BE
    setPartners(sortPartnersByRating(data));
  }, [data]);

  useEffect(() => {
    // send the request with new place data only if the place isn't null
    if (place) {
      dispatch(loaderActions.show('pageLoader'));
      dispatch(partnerActions.getPartners({ place }));
    }
  }, [place]);

  const onPlaceChange = (place) => {
    const placeData = formatGeoData(place);
    setPlace(placeData);
  };

  const clearPlace = () => {
    setPlace(null);
    inputRef.current.value = '';
  };

  const enlargeAreaOfSearch = () => {
    // build new place object based on the selected one
    // it will trigger the new request for getting partners
    const newPlace = buildPlaceForEnlarge(place);
    if (!newPlace) inputRef.current.value = '';
    setPlace(newPlace);
  };

  const enlargeArreaButton = place && place.city && (
    <div style={{ textAlign: 'center', marginTop: 30 }}>
      <Button color="primary" onClick={enlargeAreaOfSearch}>
        <FormattedMessage id="searchpartner.enlargeArea" />
      </Button>
      <p style={{ marginTop: 10, fontSize: 12 }}>
        <FormattedMessage id="searchpartner.enlargeArea.next" />:{' '}
        {searchedPlaceTitle(place, 'penult')}
      </p>
    </div>
  );

  return (
    <div>
      <SearchCard
        inputRef={inputRef}
        onPlaceChange={onPlaceChange}
        clearPlace={clearPlace}
      />
      <Clearfix />
      {loader ? (
        <LoaderAnimation />
      ) : (
        <Fragment>
          {place ? (
            place.region ? (
              partners.length ? (
                <Fragment>
                  <h3 style={{ margin: '30px 0', textAlign: 'center' }}>
                    <FormattedMessage id="searchpartner.area" />:{' '}
                    {searchedPlaceTitle(place)}
                  </h3>
                  <PartnerList data={partners} />
                  {enlargeArreaButton}
                </Fragment>
              ) : (
                <Fragment>
                  <NoResultsPlaceholder
                    region={searchedPlaceTitle(place, 'last')}
                  />
                  {enlargeArreaButton}
                </Fragment>
              )
            ) : (
              <AreaToLargePlaceholder />
            )
          ) : (
            <WelcomePlaceholder />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default injectIntl(
  connect((state) => ({
    data: state.partner.partners,
    loader: state.loader.pageLoader,
  }))(SearchPartner)
);
