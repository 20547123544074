import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { getFormValues } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import VideoImage from 'components/VideoImage/VideoImage';
import { mainStreamStatusChannel, getVideoParams, getChannelId } from 'helpers';

const MainVideoImageStream = (props) => {
  const {
    user,
    tested,
    wizard,
    active,
    channel,
    handleVideoLoad,
    videoStreamValues,
    activateVideoLoading,
  } = props;
  const videoParams = getVideoParams(user);
  return (
    <Grid item xs={12} sm={6}>
      <div align="center" style={{ marginBottom: 10 }}>
        <h4>
          <FormattedMessage id="channels.form.stream.main" />
        </h4>
        <VideoImage
          id="mainStream"
          wizard={wizard}
          tested={tested}
          active={active}
          {...videoParams}
          statisticStreamType="MainVideo"
          statusChannel={mainStreamStatusChannel(videoStreamValues)}
          channelId={getChannelId(wizard, videoStreamValues, channel)}
          activateVideoLoading={activateVideoLoading}
          onLoad={() => handleVideoLoad(true)}
          onError={() => handleVideoLoad(false)}
        />
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  channel: state.channels.edit,
  videoStreamValues: getFormValues('VideoStreamTestForm')(state),
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(MainVideoImageStream)
);
