/**
 * Class ValidatorFields
 *
 * @author Valerii Manchenko <vsvalera@gmail.com>
 */

const errorMessages = {
  required: 'push.form.required.field',
  minLength: 'push.form.error.min.length',
  maxLength: 'push.form.error.max.length',
};

export function checkAll(validateRules, data, intl) {
  const fields = Object.keys(validateRules);
  fields.forEach((item) => {
    validateRules = validateField(item, validateRules, data, intl);
  });
  return validateRules;
}

export function validateField(fieldName, validate, data, intl) {
  const validateRules = { ...validate };
  if (Object.keys(validateRules).length && validateRules[fieldName]) {
    for (const property in validateRules[fieldName].rules) {
      let error = false;
      if (validateRules[fieldName].rules[property] !== false) {
        switch (property) {
          case 'required':
            if (Array.isArray(data[fieldName])) {
              if (!data[fieldName].length) {
                error = intl.formatMessage({ id: errorMessages[property] });
              }
            } else if (!data[fieldName]) {
              error = intl.formatMessage({ id: errorMessages[property] });
            }

            break;
          case 'minLength':
            if (
              data[fieldName].trim().length <
              validateRules[fieldName].rules[property]
            ) {
              error = intl.formatMessage(
                { id: errorMessages[property] },
                { count: validateRules[fieldName].rules[property] }
              );
            }
            break;
          case 'maxLength':
            if (
              data[fieldName].trim().length >
              validateRules[fieldName].rules[property]
            )
              error = intl.formatMessage(
                { id: errorMessages[property] },
                { count: validateRules[fieldName].rules[property] }
              );
            break;
          // no default
        }

        if (error) {
          validateRules[fieldName].error = true;
          validateRules[fieldName].errorMessage = error;
          break;
        } else {
          validateRules[fieldName].error = false;
          validateRules[fieldName].errorMessage = null;
        }
      }
    }
  }
  return validateRules;
}
