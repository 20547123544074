import React from 'react';
import { get } from 'lodash';
import { styles } from '../styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Field, getFormValues } from 'redux-form';
import { getPortsFields } from './getPortsFields';
import { HiddenField } from 'components/ReduxInputs';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from 'components/CustomButtons/ButtonWithLoader.js';

const CameraTestPortsComponent = (props) => {
  const {
    intl,
    onSubmit,
    portReachable,
    portsModalOpen,
    deviceManufactures,
    portReachableLoader,
    cameraConfigValues,
    handleTestPortsBtnDisabled,
  } = props;
  const portsFields = getPortsFields(
    intl,
    deviceManufactures,
    portReachable,
    cameraConfigValues
  );
  const isAdditionalPorts = portsFields.filter((i) => !i.isRtsp).length;
  return (
    <Grid container spacing={32}>
      <Grid item xs={false} sm={6} />
      <Grid item xs={12} sm={6} style={styles.cameraConfigGrid}>
        {Boolean(
          get(cameraConfigValues, 'data.ConnectionSettings.Hostname')
        ) && (
          <Button
            style={styles.basicBtnAlignments}
            loading={portReachableLoader}
            onClick={onSubmit}
            disabled={handleTestPortsBtnDisabled()}
            color="success"
            size="sm"
            disableFocusRipple
          >
            <FormattedMessage id="channels.form.test.port" />
          </Button>
        )}
        {Boolean(isAdditionalPorts) && (
          <Button
            style={styles.basicBtnAlignments}
            onClick={portsModalOpen}
            disabled={handleTestPortsBtnDisabled()}
            size="sm"
          >
            <FormattedMessage id="channels.form.portssetings.button" />
          </Button>
        )}
        <div>
          <Field component={HiddenField} name="isPortsTested" />
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  portReachable: state.channels.portReachable,
  deviceManufactures: state.deviceManufactures,
  portReachableLoader: state.loader.channelsTestPort,
  cameraConfigValues: getFormValues('CameraConfigForm')(state),
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(CameraTestPortsComponent)
);
