import React from 'react';
import { get } from 'lodash';
import { Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { getBrandModels, getSupportedSettings } from 'helpers';
import { TextField } from 'components/ReduxInputs';

const CameraModelComponent = (props) => {
  const { intl, deviceManufactures, cameraConfigValues } = props;
  const handleBrandChange = () => {
    props.change('data.ConnectionSettings.ModelId', '');
  };
  const handleModelChange = (e) => {
    const { deviceManufactures, cameraConfigValues, change } = props;
    const deviceSupportedSettings = getSupportedSettings(
      deviceManufactures,
      cameraConfigValues,
      e.target.value
    );
    if (!deviceSupportedSettings.AltStreamSupported) {
      change('data.ConnectionSettings.AltVideoStreamEnabled', false);
    }
    if (
      deviceSupportedSettings.AltStreamSupported &&
      get(props.channels, 'edit.data.ConnectionSettings.AltVideoStreamEnabled')
    ) {
      change('data.ConnectionSettings.AltVideoStreamEnabled', true);
    }
  };
  const models = getBrandModels(deviceManufactures, cameraConfigValues);
  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          fullWidth
          select
          SelectProps={{ native: true }}
          label={intl.formatMessage({
            id: 'channels.form.brand.title',
          })}
          name="Brand"
          onChange={handleBrandChange}
        >
          <option disabled value="" />
          <option disabled>
            {intl.formatMessage({ id: 'channels.form.brand.select' })}
          </option>
          {deviceManufactures.map((item) => (
            <option key={item.Name} value={item.Name}>
              {item.Name}
            </option>
          ))}
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          fullWidth
          select
          SelectProps={{ native: true }}
          label={intl.formatMessage({
            id: 'channels.form.model.title',
          })}
          name="data.ConnectionSettings.ModelId"
          onChange={handleModelChange}
        >
          <option disabled value="" />
          <option disabled>
            {models.length
              ? intl.formatMessage({ id: 'channels.form.model.select' })
              : intl.formatMessage({
                  id: 'channels.form.brand.select',
                })}
          </option>
          {models.map((item) => (
            <option key={item.Id} value={item.Id}>
              {item.Name}
            </option>
          ))}
        </Field>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  deviceManufactures: state.deviceManufactures,
  cameraConfigValues: getFormValues('CameraConfigForm')(state),
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(CameraModelComponent)
);
