import { authHeader } from '../helpers';
import { userService } from './user.service';
import { baseReactAPIUrl } from '../constants';

const serverUrl = baseReactAPIUrl;

export const deviceManufacturesService = {
  getDeviceManufactures,
};

function getDeviceManufactures() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${serverUrl}/channels/devicemanufactures`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  // return response.data;
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        document.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
