import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = [thunkMiddleware];

const isProduction = process.env.REACT_APP_ENV === 'production';
const composeEnhancers = isProduction ? compose : composeWithDevTools;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
