import React from 'react';
import { injectIntl } from 'react-intl';
import Button from 'components/CustomButtons/Button.jsx';
import AddIcon from '@material-ui/icons/Add';

const EmptyPlaceholder = (props) => (
  <div className={props.classes.center}>
    <h3>{props.message}</h3>
    <Button color="primary" onClick={props.addRule}>
      <AddIcon className={props.classes.icons} />
      {props.btnName}
    </Button>
  </div>
);

export default injectIntl(EmptyPlaceholder);
