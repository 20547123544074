import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { loaderActions, channelsActions } from 'actions';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Videocam from '@material-ui/icons/Videocam';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';

import { history } from 'helpers';

import RemoveAlert from 'components/RemoveAlert';
import ChannelsTable from './ChannelsTable.js';
import EmptyPlaceholder from './EmptyPlaceholder.js';
import LoaderAnimation from 'components/Loader';
import { NavLink } from 'react-router-dom';

class ChannelsList extends React.Component {
  state = { confirm: null };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loaderActions.show('pageLoader'));
    dispatch(channelsActions.getChannels());
  }

  onEditOrder = (Id) => history.push(`/order/edit/${Id}`);

  onEditChannel = (Id) => history.push(`/channels/edit/${Id}`);

  onDeleteChannel = (id) => {
    this.setState({
      confirm: (confirmation) => {
        if (confirmation) {
          const { dispatch } = this.props;
          dispatch(channelsActions.deleteChannel(id));
        }
        this.setState({ confirm: null });
      },
    });
  };

  changePriority = (priority, direction) => {
    const { dispatch } = this.props;
    dispatch(
      channelsActions.changePriority(
        priority,
        direction,
        this.props.channels.all
      )
    );
  };

  handleChecked = (params) => {
    this.props.dispatch(channelsActions.toggleChannel(params));
  };

  render() {
    const { classes, channels, intl, loader } = this.props;

    if (loader) return <LoaderAnimation />;

    if (!channels.all.length)
      return <EmptyPlaceholder classes={classes} addViewer={this.addViewer} />;

    const mediaQueryUpXs = isWidthUp('sm', this.props.width);
    return (
      <Fragment>
        <Card>
          <CardHeader color="info" icon>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <CardIcon color="info">
                  <Videocam />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  <FormattedMessage id="channels.list.table.title" />
                </h4>
              </GridItem>
              <GridItem xs={12} sm={6} className={classes.right}>
                <NavLink to="/channels/add">
                  <Button
                    color="primary"
                    size="sm"
                    fullWidth={!mediaQueryUpXs}
                    style={{ marginTop: mediaQueryUpXs ? 15 : 30 }}
                  >
                    <FormattedMessage id="channels.list.add" />
                  </Button>
                </NavLink>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <ChannelsTable
              channels={channels}
              onEditChannel={this.onEditChannel}
              onEditOrder={this.onEditOrder}
              onDeleteChannel={this.onDeleteChannel}
              handleChecked={this.handleChecked}
              changePriority={this.changePriority}
            />
          </CardBody>
        </Card>
        <RemoveAlert
          confirm={this.state.confirm}
          title={intl.formatMessage({ id: 'channels.list.remove.title' })}
          description={intl.formatMessage({
            id: 'channels.list.remove.description',
          })}
          successTitle={intl.formatMessage({
            id: 'channels.list.remove.success',
          })}
          confirmBtnText={intl.formatMessage({
            id: 'channels.list.remove.confirm',
          })}
          cancelBtnText={intl.formatMessage({
            id: 'channels.list.remove.cancel',
          })}
        />
      </Fragment>
    );
  }
}

ChannelsList.propTypes = {
  classes: PropTypes.object.isRequired,
  channels: PropTypes.object.isRequired,
  // dispatch: PropTypes.function
};

const mapStateToProps = (state) => ({
  channels: state.channels,
  user: state.authentication.user,
  loader: state.loader.pageLoader,
});

export default injectIntl(
  connect(mapStateToProps)(
    withWidth()(withStyles(extendedTablesStyle)(ChannelsList))
  )
);
