import moment from 'moment';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { partnerActions } from 'actions';
import Table from '@material-ui/core/Table';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import SweetAlert from 'react-bootstrap-sweetalert';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';

const style = {
  ...extendedTablesStyle,
  ...sweetAlertStyle,
  profile: {
    margin: '10px 0 30px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  title: {
    fontWeight: 400,
  },

  tableResponsive: {
    minHeight: '0.1%',
    overflowX: 'auto',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  table: {
    '& tr:last-child td': {
      borderBottom: 'none',
    },
    '& td:first-child, & th:first-child': {
      paddingLeft: 0,
    },
    '& td:last-child, & th:last-child': {
      paddingRight: 0,
    },
    '& tr': {
      height: 34,
    },
  },
};

class Profile extends React.Component {
  state = { alert: null };

  getCameraDetails = (modelId) => {
    const { deviceManufactures } = this.props;
    if (!deviceManufactures.length) return;
    const matchModel = (model) => model.Id === modelId;
    const brand = deviceManufactures.find((brand) =>
      brand.DeviceModels.some(matchModel)
    );
    const model = brand.DeviceModels.find(matchModel);
    return `${brand.Name} ${model.Name}`;
  };

  showAlert = (type, title, decription, confirm) => {
    const { classes, intl } = this.props;
    const alert = (
      <SweetAlert
        danger={!type}
        success={type}
        title={title}
        onConfirm={() => {
          confirm();
          this.setState({ alert: null });
        }}
        onCancel={() => this.setState({ alert: null })}
        confirmBtnCssClass={`${classes.button} ${classes.success}`}
        cancelBtnCssClass={`${classes.button} ${classes.danger}`}
        confirmBtnText={intl.formatMessage({ id: 'subscribers.confirm.ok' })}
        cancelBtnText={intl.formatMessage({ id: 'subscribers.confirm.cancel' })}
        showCancel
      >
        {decription}
      </SweetAlert>
    );
    this.setState({ alert });
  };

  approve = (channelid) => {
    const { intl } = this.props;
    this.showAlert(
      true,
      intl.formatMessage({ id: 'subscribers.confirm.title' }),
      '', // intl.formatMessage({ id: "subscribers.confirm.description.approve" }),
      () => {
        this.props.dispatch(partnerActions.confirmConnected(channelid, 1));
        // On approve function
      }
    );
  };

  decline = (channelid) => {
    const { intl } = this.props;
    this.showAlert(
      false,
      intl.formatMessage({ id: 'subscribers.confirm.title' }),
      intl.formatMessage({ id: 'subscribers.confirm.description.decline' }),
      () => {
        this.props.dispatch(partnerActions.confirmConnected(channelid, 0));
        // On decline function
      }
    );
  };

  render() {
    const { classes, data, loader } = this.props;
    return (
      <div className={classes.profile}>
        <div className={classes.header}>
          <h4 className={classes.title}>{data.fullname}</h4>
          <Clearfix />
        </div>
        <div className={classes.tableResponsive}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width="30%">
                  <FormattedMessage id="subscribers.table.title" />
                </TableCell>
                <TableCell width="30%">
                  <FormattedMessage id="subscribers.table.model" />
                </TableCell>

                {this.props.new ? (
                  <Fragment>
                    <TableCell width="30%">
                      <FormattedMessage id="subscribers.table.date" />
                    </TableCell>
                    <TableCell width="10%" className={classes.right}>
                      <FormattedMessage id="subscribers.table.approvement" />
                    </TableCell>
                  </Fragment>
                ) : (
                  <Fragment>
                    <TableCell width="30%">
                      <FormattedMessage id="subscribers.table.payday" />
                    </TableCell>
                    <TableCell width="30%">
                      <FormattedMessage id="subscribers.table.status" />
                    </TableCell>
                  </Fragment>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.channels.map((el) =>
                loader[`approveChannel.${el.confirmid}`] ? (
                  <TableRow key={el.channelid}>
                    <TableCell colSpan={5}>
                      <div style={{ textAlign: 'center' }}>
                        <CircularProgress size="28px" />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={el.channelid}>
                    <TableCell>{el.Name}</TableCell>
                    <TableCell>{this.getCameraDetails(el.ModelId)}</TableCell>
                    <TableCell>
                      {moment(el.date).format('YYYY.MM.DD HH:mm:ss')}
                    </TableCell>
                    {this.props.new ? (
                      <TableCell className={classes.right}>
                        <div className={classes.nowrap}>
                          <Button
                            color="success"
                            className={classes.actionButton}
                            onClick={() => this.approve(el.confirmid)}
                          >
                            <Check />
                          </Button>
                          <Button
                            color="danger"
                            className={classes.actionButton}
                            onClick={() => this.decline(el.confirmid)}
                          >
                            <Close />
                          </Button>
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell>
                        {el.servicestatus && (
                          <FormattedMessage
                            id={`channels.list.status.${el.servicestatus}`}
                          />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </div>
        {this.state.alert}
      </div>
    );
  }
}

export default connect((state) => ({
  deviceManufactures: state.deviceManufactures,
  loader: state.loader.approveChannel,
}))(withStyles(style)(injectIntl(Profile)));
