import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { getLangCode } from 'helpers/language';
import Avatar from 'components/Avatar';
import PartnerProfileModal from '../SearchPartner/PartnerProfileModal';

const style = {
  card: {
    display: 'flex',
    cursor: 'pointer',
  },
  avatar: {
    width: 30,
    minWidth: 30,
    marginRight: 10,
  },
  name: {
    margin: 0,
    flexGrow: 1,
    alignSelf: 'center',
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

class PartnerProfile extends React.Component {
  state = { modal: false };

  openModal = () => this.setState({ modal: true });

  closeModal = () => this.setState({ modal: false });

  render() {
    const { classes, data, lang } = this.props;
    return (
      <Fragment>
        <div className={classes.card} onClick={this.openModal}>
          <div className={classes.avatar}>
            <Avatar image={data.profilephoto} />
          </div>
          <div className={classes.name}>{data.nickname}</div>
        </div>
        {this.state.modal && (
          <PartnerProfileModal
            data={data}
            openState={this.state.modal}
            close={this.closeModal}
            lang={lang}
          />
        )}
      </Fragment>
    );
  }
}

export default connect((state) => ({
  lang: getLangCode(state.authentication.user.language),
}))(withStyles(style)(PartnerProfile));
