import React from 'react';
import { connect } from 'react-redux';
import { getFormData } from 'helpers';
import { injectIntl } from 'react-intl';
import { get, set, merge } from 'lodash';
import LoaderAnimation from 'components/Loader';
import VideoStreamTest from './VideoStreamTest';
import PartnerSelected from './PartnerSelected';
import CameraConfigForm from './CameraConfigForm';
import { getFormValues, reset } from 'redux-form';
import SubmitEditChannel from './SubmitEditChannel';
import ChannelSessionFailure from './ChannelSessionFailure';
import { channelsActions, deviceManufacturesActions } from 'actions';

class EditChannel extends React.Component {
  state = {
    tariff: {},
    editChannel: {},
    orderOptionsEnabled: {},
  };

  constructor(props) {
    super(props);
    if (props.wizard) {
      props.innerRef(this);
    }
  }

  componentWillMount() {
    this.props.dispatch(reset('PartnerSelectedForm'));
    this.props.dispatch(reset('DeviceSettingsForm'));
    this.props.dispatch(reset('VideoStreamTestForm'));
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(deviceManufacturesActions.getDeviceManufactures());
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { tariff, channels, deviceManufactures } = this.props;
    if (
      deviceManufactures.length &&
      Object.keys(channels.edit.data).length &&
      channels.edit !== prevState.editChannel &&
      tariff &&
      tariff.products &&
      tariff.products.length
    ) {
      this.setState({ editChannel: this.props.channels.edit });
      tariff.products.forEach((item) => {
        if (item.pid === channels.edit.product.pid) {
          this.setState({ tariff: item });
        }
      });
      const orderOptionsEnabled = {};
      if (
        channels.edit.product.configoptions &&
        this.state.tariff.configoptions
      ) {
        channels.edit.product.configoptions.configoption.forEach((item) => {
          this.state.tariff.configoptions.configoption.map((itemConf) => {
            if (itemConf.id === item.id) {
              set(
                orderOptionsEnabled,
                itemConf.options.option[0].name
                  .split('.')[1]
                  .replace('Enabled', 'Supported'),
                !!item.value
              );
            }
          });
        });
      }
      this.setState({ orderOptionsEnabled });
    }
  };

  isValid = () => {
    const camConfigValid = this.camConfigRef.isValid();
    const videoStreamTestValid = this.videoStreamTestRef.isValid();
    const partnerSelectedValid = this.partnerSelectedRef.isValid();
    return camConfigValid && videoStreamTestValid && partnerSelectedValid;
  };

  displayPageCondition = () => {
    const { channels, deviceManufactures } = this.props;
    const channelData = get(channels, 'edit.data');
    if (deviceManufactures && channelData) {
      return (
        Object.keys(deviceManufactures).length &&
        Object.keys(channelData).length
      );
    }
  };

  updateChannel = (data = {}) => {
    const { dispatch, channels } = this.props;
    const params = getFormData(this.props);
    params.data.testMode = false;
    if (params.data.Id) {
      dispatch(
        channelsActions.updateChannel(
          merge(
            channels.edit,
            params,
            { data },
            {
              data: {
                ArchiveSettings: {
                  ArchivingDisabled: false,
                  DeviceArchiveEnabled:
                    channels.edit.data.ArchiveSettings.DeviceArchiveEnabled,
                  DeviceArchiveAutoSynchronizationEnabled:
                    channels.edit.data.ArchiveSettings
                      .DeviceArchiveAutoSynchronizationEnabled,
                },
              },
            }
          ),
          'update'
        )
      );
    }
  };

  render = () => {
    const { wizard, sendForm, formLoader, currentStep } = this.props;
    return this.displayPageCondition() || wizard ? (
      <React.Fragment>
        <CameraConfigForm
          wizard={!!wizard}
          innerRef={(node) => (this.camConfigRef = node)}
        />
        <VideoStreamTest
          wizard={!!wizard}
          sendForm={sendForm}
          currentStep={currentStep}
          editChannel={this.state.editChannel}
          innerRef={(node) => (this.videoStreamTestRef = node)}
          orderOptionsEnabled={this.state.orderOptionsEnabled}
        />
        <PartnerSelected
          innerRef={(node) => (this.partnerSelectedRef = node)}
        />
        <SubmitEditChannel
          wizard={!!wizard}
          formLoader={formLoader}
          submitEditChannel={this.updateChannel}
        />
        <ChannelSessionFailure />
      </React.Fragment>
    ) : (
      <LoaderAnimation />
    );
  };
}

const mapStateToProps = (state) => ({
  tariff: state.tariff,
  channels: state.channels,
  formLoader: state.loader.channelsForm,
  deviceManufactures: state.deviceManufactures,
  cameraConfigValues: getFormValues('CameraConfigForm')(state),
  partnerSelectedValues: getFormValues('PartnerSelectedForm')(state),
  deviceSettingsValues: getFormValues('DeviceSettingsForm')(state),
  videoStreamValues: getFormValues('VideoStreamTestForm')(state),
  user: state.authentication.user,
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(EditChannel)
);
