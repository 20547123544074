import { get } from 'lodash';

export const getModelParams = (deviceManufactures, modelId) => {
  if (!modelId) return {};
  const isModelId = (model) => model.Id === modelId;
  return deviceManufactures
    .find((brand) => brand.DeviceModels.some(isModelId))
    .DeviceModels.find(isModelId);
};

export const getPrice = (prices = {}, currencyCode, billingcycle) => {
  if (
    Object.keys(prices).length &&
    prices[currencyCode] &&
    prices[currencyCode][billingcycle] > 0
  ) {
    return prices[currencyCode][billingcycle];
  }
  return (0).toFixed(2);
};

const baseTotalSum = (
  tariff,
  dataForm,
  billingCycle,
  currencyCode,
  accumulator = 0
) => {
  return tariff.configoptions.configoption.reduce((prev, curr) => {
    if (get(dataForm, `data.${curr.options.option[0].name}`)) {
      return (
        prev +
        +getPrice(curr.options.option[0].pricing, currencyCode, billingCycle)
      );
    }
    return prev;
  }, accumulator);
};

export const totalSum = (
  dataForm,
  selectedTariff,
  billingCycle,
  currencyCode
) => {
  let totalSum = 0;
  if (dataForm) {
    if (Object.keys(selectedTariff).length) {
      totalSum = baseTotalSum(
        selectedTariff,
        dataForm,
        billingCycle,
        currencyCode,
        +getPrice(selectedTariff.pricing, currencyCode, billingCycle)
      );
    }
  }
  return totalSum.toFixed(2);
};

export const totalAddonsSum = (
  dataForm,
  selectedTariff,
  editChannel,
  billingCycle,
  currencyCode
) => {
  let totalSum = 0;
  if (dataForm) {
    if (Object.keys(selectedTariff).length) {
      totalSum = selectedTariff.configoptions.configoption.reduce(
        (prev, curr) => {
          if (get(dataForm, `data.${curr.options.option[0].name}`)) {
            const paidConfigOptions = get(editChannel, 'product.configoptions');
            if (paidConfigOptions) {
              const paidOption = paidConfigOptions.configoption[curr.id - 2];
              if (paidOption) {
                if (paidOption.value > 0) {
                  return prev;
                }
              }
            }
            return (
              prev +
              +getPrice(
                curr.options.option[0].pricing,
                currencyCode,
                billingCycle
              )
            );
          }
          return prev;
        },
        0
      );
    }
  }
  return totalSum.toFixed(2);
};
