import moment from 'moment';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { channelsActions } from 'actions';
import { withRouter } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import Table from 'components/Table/Table.jsx';
import Close from '@material-ui/icons/Close';
import Card from '@material-ui/icons/CreditCard';
import Tooltip from '@material-ui/core/Tooltip';
import Settings from '@material-ui/icons/Settings';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { injectIntl, FormattedMessage } from 'react-intl';
import Button from 'components/CustomButtons/Button.jsx';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';

class ChannelsTable extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(channelsActions.getChannels());
  }

  isDisabled = (id) => {
    const item = this.props.channels.all.find((item) => item.id === id);
    return item.status !== 'Active' || item.channel.data.Disabled;
  };

  getButtons = (id) => {
    const { classes, onEditChannel, onEditOrder, onDeleteChannel, intl } =
      this.props;

    const disabled = this.isDisabled(id);
    const activeButtons = [
      {
        color: 'info',
        icon: Settings,
        action: () => onEditChannel(id),
        tooltipMessage: intl.formatMessage({ id: 'tooltip.message.settings' }),
        disabled,
      },
      {
        color: 'success',
        icon: AttachMoney,
        action: () => onEditOrder(id),
        tooltipMessage: intl.formatMessage({ id: 'tooltip.message.rate' }),
        disabled,
      },
    ];
    const suspendedButtons = [
      {
        color: 'warning',
        icon: Card,
        action: () => this.props.history.push('/invoices'),
        tooltipMessage: intl.formatMessage({ id: 'tooltip.message.invoices' }),
      },
      {
        color: 'success',
        icon: AttachMoney,
        action: () => onEditOrder(id),
        tooltipMessage: intl.formatMessage({ id: 'tooltip.message.rate' }),
      },
    ];
    const additionalButtons = disabled ? suspendedButtons : activeButtons;
    return [
      ...additionalButtons,
      {
        color: 'danger',
        icon: Close,
        action: () => onDeleteChannel(id),
        tooltipMessage: intl.formatMessage({ id: 'tooltip.message.delete' }),
      },
    ].map((prop, key) => {
      return (
        <Tooltip title={prop.tooltipMessage} key={key}>
          <Button
            color={prop.color}
            className={classes.actionButton}
            onClick={prop.action}
            disabled={prop.disabled}
          >
            <prop.icon className={classes.icon} />
          </Button>
        </Tooltip>
      );
    });
  };

  render() {
    const { classes, channels, intl, changePriority, handleChecked } =
      this.props;

    const tableData = channels.all.map((item, index) => {
      return [
        index + 1,
        item.channel.name,
        <div key={index}>
          {item.status === 'Pending'
            ? item.firstpaymentamount
            : item.recurringamount}
          <br />
          <FormattedMessage id={`billing.cycle.${item.billingcycle}`} />
        </div>,
        [
          <Button
            key={`1_${index}`}
            simple
            color="success"
            disabled={
              item.channel.priority === channels.all.length ||
              channels.all.length === 1 ||
              item.channel.data.Disabled ||
              item.status === 'Pending'
            }
            style={channels.all.length === 1 ? { opacity: 0 } : {}}
            className={classes.actionButton}
            onClick={() => changePriority(item.channel.priority, 1)}
          >
            <ArrowUpward className={classes.icon} />
          </Button>,
          <span key={`priotiy_${index}`}>
            {channels.all.length === 1 ? 1 : item.channel.priority}
          </span>,
          <Button
            key={`2_${index}`}
            simple
            color="danger"
            disabled={
              item.channel.priority === 1 ||
              item.channel.data.Disabled ||
              item.status === 'Pending'
            }
            style={
              item.channel.priority === 1 || channels.all.length === 1
                ? { opacity: 0 }
                : {}
            }
            className={classes.actionButton}
            onClick={() => changePriority(item.channel.priority, -1)}
          >
            <ArrowDownward className={classes.icon} />
          </Button>,
        ],
        <Switch
          key={index}
          checked={!item.channel.data.Disabled}
          onChange={() => handleChecked(item)}
          disabled={item.status !== 'Active'}
        />,
        <div key={index}>
          {item.billingcycle === 'Free Account'
            ? moment(item.regdate).add(3, 'days').format('YYYY-MM-DD')
            : item.nextduedate}
          <br />
          <FormattedMessage id={`channels.list.status.${item.status}`} />
        </div>,
        <div key={index} style={{ whiteSpace: 'nowrap' }}>
          {this.getButtons(item.id)}
        </div>,
      ];
    });
    return (
      <Table
        tableHead={[
          '#',
          intl.formatMessage({ id: 'channels.list.table.name' }),
          intl.formatMessage({ id: 'channels.list.table.cost' }),
          intl.formatMessage({ id: 'channels.list.table.priority' }),
          intl.formatMessage({ id: 'channels.list.table.status' }),
          intl.formatMessage({ id: 'channels.list.table.paidTo' }),
          intl.formatMessage({ id: 'channels.list.table.actions' }),
        ]}
        tableData={tableData}
        customCellClasses={[
          classes.center,
          classes.center,
          classes.center,
          classes.right,
        ]}
        customClassesForCells={[0, 3, 4, 6]}
        customHeadCellClasses={[
          classes.center,
          classes.center,
          classes.center,
          classes.right,
        ]}
        customHeadClassesForCells={[0, 3, 4, 6]}
      />
    );
  }
}

export default withRouter(
  injectIntl(connect()(withStyles(extendedTablesStyle)(ChannelsTable)))
);
