import React from 'react';

export const AgendaUa = () => (
  <div style={{ textAlign: 'justify' }}>
    <p style={{ textAlign: 'center' }}>
      <b>
        ПУБЛІЧНИЙ ДОГОВІР ПРО НАДАННЯ ПОСЛУГИ ТЕРИТОРІАЛЬНО-РОЗПОДІЛЕНОГО
        ВІДЕОСПОСТЕРЕЖЕННЯ <br />
        (на умовах публічної оферти)
      </b>
    </p>
    <p>
      Сервіс територіально-розподіленого відеоспостереження ipteamcloud, в
      подальшому &laquo;Виконавець&raquo;, цим документом пропонує будь-яким
      юридичним особам та/або дієздатним фізичним особам (надалі за текстом
      &mdash; &laquo;Користувач&raquo;) укласти Договір про надання послуги
      ТЕРИТОРІАЛЬНО-РОЗПОДІЛЕНОГО ВІДЕОСПОСТЕРЕЖЕННЯ (надалі за текстом&mdash;
      Послуга) на наступних умовах:
    </p>
    <p>
      <b>1. ТЕРМІНИ ТА ВИЗНАЧЕННЯ</b>
    </p>
    <p>
      <b>Публічна оферта</b> &mdash; пропозиція Виконавця (викладена на Сайті
      Виконавця), адресована невизначеному колу фізичних осіб, укласти договір
      на визначених умовах.
    </p>
    <p>
      <b>Договір</b> &mdash; договір про надання послуги
      територіально-розподіленого відеоспостереження, укладений між Виконавцем
      та Користувачем на умовах Публічної оферти в момент Акцепту Користувачем
      її умов.
    </p>
    <p>
      <b>Акцепт</b> &mdash; повне та безумовне, беззастережне прийняття
      Користувачем умов Публічної оферти та Правил. Порядок Акцепту визначений
      статтею 4 цього Договору.
    </p>
    <p>
      <b>Користувач</b> &mdash; особа, що здійснила акцепт (прийняття) умов
      даного Договору та користується Послугою.
    </p>
    <p>
      <b>Кінцеве обладнання</b> &mdash; встановлене у Користувача обладнання
      (маршрутизатор, IP відеокамера, відеорегістратор, комп&rsquo;ютер тощо),
      необхідне для отримання Послуги.
    </p>
    <p>
      <b>Логін</b> &mdash; унікальний алфавітно-цифровий набір символів, що
      ідентифікує Користувача Послуги і разом із паролем використовується для
      надання Користувачеві дозволу доступу до особистого кабінету. Логін має
      бути унікальним в межах даної системи.
    </p>
    <p>
      <b>E-mail</b> &mdash; унікальний алфавітно-цифровий набір символів, що
      ідентифікує адресу електронної пошти Користувача, яка використовується
      Виконавцем задля інформування Користувача щодо деяких дій в Особистому
      Кабінеті, а саме: авторизації Користувача, змін налаштувань, оплати
      Послуг, та інше.
    </p>
    <p>
      <b>IP адреса</b> &mdash; інтернет-протокол-адреса Користувача, що
      використовується для передавання даних Користувача через Телекомунікаційну
      мережу Провайдера.
    </p>
    <p>
      <b>Провайдер</b> &mdash; стороння організація, що надає на комерційній або
      будь-якій іншій основі Користувачу доступ до мережі Інтернет.
    </p>
    <p>
      <b>Cайт Виконавця</b> &mdash; веб-сторінка Виконавця в Інтернет, яка
      знаходиться за адресою{' '}
      <a
        href="http://www.ipteamcloud.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.ipteamcloud.com
      </a>
      , та є основним джерелом інформування Користувачів та доступу до
      Особистого Кабінету.
    </p>
    <p>
      <b>Особистий Кабінет</b> &mdash; індивідуальний розділ на сайті Виконавця
      для кожного Користувача, використовуючи який, Користувач може самостійно
      змінювати Тарифні плани та параметри Послуги, отримувати інформацію щодо
      обсягів наданих Послуг та самостійно керувати Послугою (умови користування
      особистим кабінетом вказані на сайті Виконавця).
    </p>
    <p>
      <b>Тарифний план або Тариф</b>
      &mdash; сукупність пропозицій, запропонованих Користувачу Виконавцем, що
      встановлюють обсяг та вартість Послуг, способи розрахунків, особливості
      тарифікації, та інша інформація, яка затверджується Виконавцем самостійно
      та оприлюднюється на Сайті Виконавця.
    </p>
    <p>
      <b>Додаткові послуги</b> &mdash; набір додаткових, не включених до
      Тарифних планів, опційних Послуг, які Користувач може замовити за своїм
      бажанням додатково.
    </p>
    <p>
      <b>INTERNET або Інтернет</b> &mdash; всесвітня інформаційна система
      загального доступу, яка логічно зв&apos;язана глобальним адресним
      простором та базується на Інтернет-протоколі, визначеному міжнародними
      стандартами.
    </p>
    <p>
      <b>Розрахунковий період</b> &mdash; період, який дорівнює 30 (тридцяти)
      календарним дням, протягом якого Сторони здійснюють виконання цього
      Договору.
    </p>
    <p>
      <b>Контент</b> &mdash; потокове відео в режимі реального часу чи архіву,
      що створюється Користувачем (обладнанням Користувача) та зберігається на
      серверах Виконавця.
    </p>
    <p>
      <b>Особовий рахунок</b> &mdash; рахунок у Біллінговій системі Виконавця,
      на якому фіксуються авансові платежі Користувача та суми коштів, що
      утримані (списані) з даних платежів як оплата за надані Послуги.
    </p>
    <p>
      Всі інші терміни вживаються у значенні визначеному згідно чинного
      законодавства України.
    </p>
    <p>
      <b>2. ЗАГАЛЬНІ ПОЛОЖЕННЯ</b>
    </p>
    <p>
      2.1. Відповідно до ст. 633 Цивільного кодексу України цей Договір є
      публічним договором, укладеним на умовах публічної оферти.
    </p>
    <p>
      2.2. Всі умови цього Договору є обов&rsquo;язковим для Виконавця та
      Користувача. Перед початком отримання послуг кожний Користувач
      зобов&rsquo;язаний ознайомитися з умовами цього Договору. Якщо Користувач
      не згодний з будь-якими умовами Договору, він не вправі користуватися
      послугами Виконавця.
    </p>
    <p>
      2.3. Користувачем, який Акцептує публічну оферту на укладання цього
      Договору, може бути фізична особа, яка має повну цивільну дієздатність,
      або юридична особа.
    </p>
    <p>
      2.4. Сторони погодились, що сайт Виконавця{' '}
      <a
        href="http://www.ipteamcloud.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        www.ipteamcloud.com
      </a>{' '}
      є доступним та максимально прийнятним для Користувача джерелом інформації
      стосовно послуг та правил їх надання, діючих тарифів Виконавця.
    </p>
    <p>
      2.5. Послуга відеоспостереження через Інтернет, яка надається Виконавцем
      не є охоронною системою.
    </p>
    <p>
      <b>3. ПРЕДМЕТ ДОГОВОРУ</b>
    </p>
    <p>
      3.1. Предметом даного Договору є регулювання відносин між Виконавцем та
      Користувачем, при використанні останнім Послуги
      територіально-розподіленоговідеоспостереження через мережу Інтернет
      (надалі за текстом - Послуга).
    </p>
    <p>
      3.2. Виконавець зобов&rsquo;язується надати Користувачу Послугу,
      відповідно до обраного Тарифного Плану та (у разі наявності) додаткових
      опцій, інформація про який розміщена на сайті Виконавця, а Користувач
      зобов&rsquo;язується сплачувати вартість Послуг відповідно до умов цього
      Договору, в розмірі вартості обраного Тарифного плану та обраних
      Користувачем додаткових опцій, визначених у тарифних опціях.
    </p>
    <p>
      <b>4. ПОРЯДОК УКЛАДЕННЯ ДОГОВОРУ</b>
    </p>
    <p>
      4.1. Цей Договір вважається укладеним без його підписання з дати Акцепту
      (прийняття) умов даного Договору, яка визначена відповідно до п. 4.2 - п.
      4.3 цього Договору.
    </p>
    <p>
      4.2. Для фізичних осіб, свідоцтвом повного й беззастережного Акцепту
      (прийняття) умов даного Договору є здійснення фізичною особою процедури
      реєстрації на Сайті Виконавця, протягом якої фізична особа приймає умови
      даного Договору, і Договір вважається укладеним без підписання в кожному
      окремому випадку. Датою укладання даного Договору в цьому випадку є дата
      реєстрації/перереєстрації на Сайті Виконавця.
    </p>
    <p>
      4.3. Для юридичних осіб прийняття умов даного Договору вважається
      здійсненним з моменту першої оплати юридичної особи за виставленим
      Виконавцем рахунком-фактурою. Або шляхом підписання письмового договору
      (за бажанням Користувача-юридичної особи).
    </p>
    <p>
      <b>5. УМОВИ, ПОРЯДОК НАДАННЯ ТА ВИКОРИСТАННЯ ПОСЛУГИ</b>
    </p>
    <p>5.1. Необхідні умови для надання Послуги:</p>
    <p>
      5.1.1. Наявність доступу до мережі Інтернет із статичною (незмінною)
      IP-адресою;
    </p>
    <p>
      5.1.2. Вихідна швидкість зєданння з мережою Інтернет, на місці
      встановлення IP відеокамер, не менше 2Мб/сек на кожен відеопоток (на 1
      IP-відеокамеру, або 1 канала відеосервера, або 1 канал відеореєстратора, в
      напрямку від Користувача &mdash; UPLOAD);
    </p>
    <p>5.2. Порядок надання Послуги:</p>
    <p>5.2.1. Користувач реєструється на сайті Виконавця;</p>
    <p>
      5.2.2. Надалі Користувач за допомогою Особистого Кабінету може додати своє
      обладнання, обрати* Тарифний план, додаткові опціїї за бажанням;
    </p>
    <p>
      5.2.3. Користувач сплачує обраний обсяг Послуг та починає користуватися
      Послугою;
    </p>
    <p>
      5.2.4. У рамках Послуги Користувачу з Особистого Кабінету доступний набір
      дій* по керуванню, підключенню/видаленню Кінцевого Обладнання, керуванню
      Контентом, надання/вилучення доступу до власного Контенту іншим
      користувачам, та інше.
    </p>
    <p>
      *Опис доступних Тарифів, сервісів, опцій та дій по керуванню Послугою, що
      в сукупності складають Послугу, та порядок користування ними відображено у
      документі &laquo;Інструкція Користувача&raquo;, який знаходиться в
      Особистому Кабінеті у розділі &laquo;Допомога&raquo;.
    </p>
    <p>
      <b>6. ПРАВА ТА ОБОВ&rsquo;ЯЗКИ СТОРІН</b>
    </p>
    <p>6.1. Виконавець зобов&apos;язаний:</p>
    <p>
      6.1.1. Надавати Користувачу Послугу відповідно до умов цього Договору та
      згідно з чинним законодавством України.
    </p>
    <p>
      6.1.2. Попереджати Користувача про скорочення переліку або припинення
      надання Послуг у випадках і порядку, передбачених цим Договором та
      законодавством України, у тому числі у разі наявності заборгованості у
      Користувача.
    </p>
    <p>6.1.3. Вести облік обсягу та вартості наданих Користувачу Послуг.</p>
    <p>
      6.1.4. Інформувати Користувача про нові види Послуг, які Виконавець може
      надавати Користувачу.
    </p>
    <p>
      6.1.5. Інформувати Користувача про зміну переліку та/або вартості Послуг
      шляхом розміщення повідомлень на Сайті Виконавця.
    </p>
    <p>6.2. Користувач зобов&apos;язується:</p>
    <p>
      6.2.1. Ознайомитися з правилами та умовами надання Послуги, та виконувати
      умови, передбачені цим Договором.
    </p>
    <p>
      6.2.2. Використовувати Послугу виключно для цілей та в спосіб визначений
      Виконавцем в межах та у відповідності до норм чинного Законодавства.
    </p>
    <p>
      6.2.3. Не використовувати Послугу для розповсюдження будь-якого іншого
      Контенту, в тому числі того, що порушує норми чинного законодавства
      України.
    </p>
    <p>
      6.2.4. Не розміщувати та не транслювати Контент, який містить інформацію
      порнографічного та еротичного характеру, або іншу інформацію, щодо
      розміщення (оприлюднення) якої встановлені обмеження.
    </p>
    <p>
      6.2.5. При використанні Послуги у місцях загального користування,
      повідомляти третіх осіб про відеоспостереження (в тому числі шляхом
      розміщення відповідних письмових повідомлень).
    </p>
    <p>
      6.2.6. Не здійснювати та не намагатися отримати доступ до Послуги іншим
      способом крім як через інтерфейс(и) надані Виконавцем.
    </p>
    <p>
      6.2.7. Не здійснювати будь-які дії, які перешкоджають наданню Послуги або
      порушують її функціонування, або іншим чином порушують інтереси Виконавця.
    </p>
    <p>
      6.2.8. Контролювати наявність на своєму Особовому рахунку достатньої
      мінімальної суми коштів для можливості отримання Послуги та проводити
      звірку з метою уточнення сум, внесених авансовим платежем.
    </p>
    <p>
      6.2.9. Своєчасно приймати та в повному обсязі здійснювати оплату наданої
      Виконавцем Послуги в строки і порядку, передбачені цим Договором.
    </p>
    <p>
      6.2.10. Негайно (але в будь-якому випадку не пізніше ніж через 24 години)
      повідомити Виконавця, в разі отримання відомостей про будь-яке
      несанкціоноване використання пароля і Особистого Кабінету.
    </p>
    <p>
      6.2.11. Не використовувати Послугу в цілях, заборонених чинним
      законодавством України, тому числі не допускати збирання, зберігання,
      використання та поширення конфіденційної інформації про особу (як
      юридичну, так і фізичну) або зображення фізичної особи без її згоди, а
      також не допускати збирання та розповсюдження інформації юридичної особи,
      яка містить/може містити комерційну таємницю.
    </p>
    <p>
      6.2.12. Користуватись Послугою лише з метою відеоспостереження за
      приміщенням, які Користувач експлуатує на законних підставах.
    </p>
    <p>
      6.2.13. Належним чином виконувати інші обов&apos;язки, передбачені цим
      Договором та чинним законодавством України.
    </p>
    <p>6.3. Виконавець має право:</p>
    <p>
      6.3.1. Своєчасно отримувати оплату за надану Користувачу Послугу згідно до
      умов цього Договору.
    </p>
    <p>
      6.3.2. Призупинити виконання цього Договору у разі нестачі грошових коштів
      на Особовому рахунку Користувача. При такому призупиненні виконання цього
      Договору, Виконавець не несе відповідальності за невиконання або неналежне
      виконання зобов&rsquo;язань за Договором. Поновлення дії цього Договору
      відбувається виключно після отримання Виконавцем від Користувача оплати за
      надані Послуги в повному обсязі та авансової оплати за надання Послуги в
      наступному розрахунковому періоді.
    </p>
    <p>
      6.3.3. У порядку, передбаченому цим Договором, скорочувати або припиняти
      надання Послуги Користувачу у випадку порушення ним умов цього Договору.
    </p>
    <p>
      6.3.4. Скорочувати обсяг, тимчасово чи повністю припиняти надання Послуг
      або розірвати Договір в односторонньому порядку у разі надходження скарг
      чи інших повідомлень про:
    </p>
    <ul>
      <li>
        <p>
          розміщення у Користувача інформації, що ображає гідність інших людей;
        </p>
      </li>
      <li>
        <p>
          розміщення у Користувача інформації еротичного або порнографічного
          характеру (згідно статті 1 Закону України &laquo;Про захист суспільної
          моралі&raquo;);
        </p>
      </li>
      <li>
        <p>
          розміщення у Користувача матеріалів, що суперечать чинному
          законодавству України.
        </p>
      </li>
    </ul>
    <p>
      У разі надходженні такої скарги чи повідомлення, Виконавець може видалити
      весь Контент Користувача, заблокувати все Кінцеве обладнання повідомивши
      про це Користувача в будь-який спосіб (в тому числі, але не виключно
      електронним листом, SMS повідомленням тощо).
    </p>
    <p>6.4. Користувач має право:</p>
    <p>
      6.4.1. Отримувати від Виконавця інформацію про Послугу та умови її
      надання.
    </p>
    <p>
      6.4.2. Змінювати відповідно до своїх інтересів перелік та параметри
      сервісів, які складають Послугу, відповідно до порядку та умовами цього
      Договору.
    </p>
    <p>
      6.4.3. Звертатися до Виконавця з пропозиціями щодо поліпшення якості
      Послуги.
    </p>
    <p>6.4.4. Змінювати/відновлювати пароль свого Особистого Кабінету.</p>
    <p>6.4.5. Змінювати тарифні плани в порядку визначеному Виконавцем.</p>
    <p>
      6.4.6. Користувач має також інші права, передбачені чинним законодавством
      України та цим Договором.
    </p>
    <p>
      <b>7. ВАРТІСТЬ ПОСЛУГ ТА ПОРЯДОК РОЗРАХУНКІВ</b>
    </p>
    <p>
      7.1. Для отримання Послуги за цим Договором Користувач оплачує вартість
      Послуги в розмірі та в порядку, визначеними обраним Тарифним планом.
    </p>
    <p>
      7.2. Вартість Послуг за даним Договором визначається Тарифними Планами та
      вказана на Сайті Виконавця.
    </p>
    <p>
      7.3. Оплата Послуг здійснюється шляхом внесення авансового платежу у
      безготівковій формі за допомогою банківського переказу чи з використанням
      платіжних засобів, які вказані на Сайті Виконавця.
    </p>
    <p>
      7.4. Повернення залишку коштів з Особового рахунку Користувача у випадку
      розірвання договірних відносин не відбувається.
    </p>
    <p>
      7.5. Зміна тарифного плану здійснюється з моменту переходу Користувачем в
      Особовому Кабінеті на інший тарифний план та після опрацювання Виконавцем
      такого переходу.
    </p>
    <p>
      7.6. Виконавець має право в односторонньому порядку змінювати вартість
      Послуг за Тарифними планами та/або Тарифні плани, при цьому всі зміни
      публікуються на Сайті. Не розірвання Користувачем Договору в зазначений
      строк та продовження користування Послугою свідчать про згоду Користувача
      зі змінами вартості Послуг та/або Тарифних планів.
    </p>
    <p>
      <b>8. ГАРАНТІЙНІ ЗОБОВ&rsquo;ЯЗАННЯ ТА ВІДПОВІДАЛЬНІСТЬ СТОРІН</b>
    </p>
    <p>
      8.1. За невиконання або неналежне виконання зобов&apos;язань за цим
      Договором Виконавець та Користувач несуть відповідальність відповідно до
      діючого законодавства України та умов цього Договору.
    </p>
    <p>
      8.2. Виконавець гарантує зберігання записів відеопотоків з Кінцевого
      Обладання Користувача, відповідно до обраного Тарифного плану, в режимі
      запису по детекції руху в кадрі. Тобто, якщо зображення з камери статичне
      (в кадрі нічого не відбувається), запис не ведеться.
    </p>
    <p>
      8.3. Виконавець гарантує роботу сервісу в режимі 24/7, за винятком
      зазначених перерв у роботі сервісу ipteamcloud:
    </p>
    <ul>
      <li>
        <p>
          не більше 12 годин на місяць сумарно з профілактичних причин та/або
          аварійних зупинок в роботі сервісу ipteamcloud на стороні Виконавця;
        </p>
      </li>
      <li>
        <p>
          збої в роботі сервісу ipteamcloud з технічних причин на стороні
          Користувача не враховуються (відсутність електроенергії, або
          з&rsquo;єднання з мережею Інтернет на стороні Користувача, або
          навмисне вимикання/пошкодження Кінцевого Обладнання Користувача та
          таке інше);
        </p>
      </li>
      <li>
        <p>
          якщо перерва (вимушена чи профілактична) в роботі сервісу ipteamcloud
          за вини Виконавця сумарно набуде більш ніж 12 годин на місяць,
          Виконавець робить знижку у розмірі 50% Користувачу на наступний місяць
          користування сервісом ipteamcloud;
        </p>
      </li>
      <li>
        <p>
          якщо перерва (вимушена чи профілактична) в роботі сервісу ipteamcloud
          за вини Виконавця сумарно набуде більш ніж 72 години на місяць,
          ВИКОНАВЕЦЬ робить знижку у розмірі 100% Користувачу на наступний
          місяць користування сервісу ipteamcloud.
        </p>
      </li>
    </ul>
    <p>
      8.4. В будь-якому випадку, відповідальність Виконавця перед Користувачем
      обмежується єквівалентом розміру оплати Користувача за поточний
      рохрахунковий період (грошовим розміром сплаченного Таріфного плану за
      поточний період).
    </p>
    <p>
      8.5. Виконавець не несе відповідальність за зміст та якість отриманої
      Користувачем з мережі Інтернет інформації, за її подальше використання та
      зберігання Користувачем.
    </p>
    <p>8.6. Виконавець не несе відповідальність перед Користувачем за:</p>
    <p>
      8.6.1. Ненадання або неналежне надання Послуг за умови настання будь-яких
      обставин, які виникли не з вини Виконавця.
    </p>
    <p>8.6.2. Технічний стан каналів, орендованих Виконавцем у Провайдера.</p>
    <p>
      8.6.3. Погіршення якості Послуги, перебої в наданні Послуги, будь-які
      ушкодження, які були завдані Контенту Користувача в результаті
      використання ним мережі Інтернет, тому числі ушкодження або втрату даних в
      результаті затримок, неотримання, помилкової адресації або переривання
      Послуги, яке було викликане неналежним технічним станом орендованих
      каналів та перебоями в роботі, які виникли прямо або опосередковано з
      причин, що знаходяться поза межами контролю та впливу Виконавця.
    </p>
    <p>
      8.6.4. Якість функціонування окремих сегментів і вузлів мережі Інтернет,
      які не належать Виконавцю.
    </p>
    <p>8.6.5. За якість та функціонування Кінцевого Обладнання Користувача.</p>
    <p>
      8.7. Виконавець не несе будь-якої матеріальної, фінансової або іншої
      відповідальності за можливі наслідки користування Користувачем сервісу
      ipteamcloud та/або претензій, що виникли або можуть виникнути з боку
      Користувача або з боку третіх осіб до Користувача та/або Виконавця
      внаслідок користування Користувачем сервісом ipteamcloud.
    </p>
    <p>
      8.8. Користувач несе відповідальність за використання Послуги в цілях,
      заборонених чинним законодавством України, в тому числі за збирання,
      зберігання, використання та поширення конфіденційної інформації про особу
      (як юридичну, так і фізичну) або зображення фізичної особи без її згоди, а
      також збирання та розповсюдження інформації юридичної особи, яка
      містить/може містити комерційну таємницю.
    </p>
    <p>
      8.9. Користувач несе відповідальність перед третіми особами за
      розголошення інформації, що стала відома при користуванні Послугою.
    </p>
    <p>
      8.10. Користувач несе відповідальність перед Виконавцем за шкоду завдану
      діловій репутації останнього та зобов&rsquo;язується відшкодувати завдані
      збитки в повному обсязі. Сторони погодили, що шкодою завданою діловій
      репутації вважається:
    </p>
    <p>
      8.10.1. Задоволення судом позовів третіх осіб до Виконавця, підставою для
      яких стало неналежне виконання Користувачем своїх обов&rsquo;язків за цим
      Договором.
    </p>
    <p>
      8.10.2. Застосування до Виконавця контролюючими органами заходів впливу
      та/або фінансових санкцій, підставою для яких стало неналежне виконання
      Користувачем своїх обов&rsquo;язків за цим Договором.
    </p>
    <p>
      8.11. Сторони погодили, що за порушення умов цього Договору будь-якими
      особами, яким Користувач надав доступ до Контенту, несе відповідальність
      Користувач.
    </p>
    <p>
      <b>9. КОНФЕДЕНЦІЙНІСТЬ ТА ЗАХИСТ ІНФОРМАЦІЇ</b>
    </p>
    <p>
      9.1. Задля користування Послугою, Користувач не надає свої персональні
      дані, такі як: прізвище, імя, по-батькові, номер телефону, паспортні
      та/або інші дані, що ідентифікують особу. Необхідні лише технічні дані
      щодо обладання, (IP адреса, порт(и), логін, та інше від кінцевого
      Обладнання Користувача), які вводяться Користувачем самостійно в
      Особистому кабінеті Користувача.
    </p>
    <p>
      При реєстрації, Користувач зазначає &laquo;ім&rsquo;я&raquo; (псевдонім)
      &mdash; це може бути вигадане ім&rsquo;я/призвісько/псевдонім, та
      використується Виконавцем виключно з метою звернення до Користувача при
      листуванні електронною поштою та в Особистому Кабінеті.
    </p>
    <p>
      Таким чином, Виконавець взагалі не має персональних даних Користувачів.
    </p>
    <p>
      9.2. Виконавець вважає, що вся технічна інформація по використанню Послуги
      Користувачем рівно, як і зміст всього Контенту Користувача є
      конфіденційною. Виконавець не веде перегляд Контенту Користувача та не
      веде спостереження за відео трансляціями Користувача. Виконавець не
      передає будь-які відомості Користувача третім особам, окрім випадків,
      передбачених законодавством.
    </p>
    <p>
      9.3. Користувач погоджується з тим, що він несе відповідальність за
      збереження конфіденційності даних (логін/пароль), що дають доступ до
      Особистого Кабінету, та інших паролів, які надають доступ до обладнання
      Користувача, та пов&rsquo;язані з Особистим Кабінетом Користувача. Згідно
      з вище викладеним Користувач погоджується, що він несе виключну
      відповідальність перед Виконавцем за всі дії, які здійснені при
      використанні свого Особистого Кабінету.
    </p>
    <p>
      9.4. Всі дані, надані Користувачем на Сайті Виконавця та/або в Особистому
      Кабінеті, шифруються за допомогою SSL (http протокол). Контент Користувача
      шифрується на серверах Виконавця, алгоритм шифрування є комерційною
      таємницею.
    </p>
    <p>
      9.5. Згідно з цього розділу Користувач несе виключну відповідальність за
      надання доступу іншим Користувачам до свого Контенту.
    </p>
    <p>
      <b>10. КОНТЕНТ ПОСЛУГИ</b>
    </p>
    <p>
      10.1. Користувач визнає, що відповідальність за будь-який Контент (такий
      як відео файли, звукові файли, он-лайн відео та аудіо трансляція, фото
      зображення з відеокамер та іншого Кінцевого Обладнання Користувача), до
      якої Користувач може мати доступ, як до частини Послуги, або через
      використання Послуги, несе Користувач - власник Контенту, який надав таку
      Інформацію.
    </p>
    <p>
      10.2. Користувач визнає, що він несе виняткову відповідальність за
      будь-який Контент, який створюється, передається, зберігається або
      відтворюється на екрані в процесі користування Послугою, а також за всі
      наслідки таких дій.
    </p>
    <p>
      10.3. Виконавець не несе відповідальність за використання Користувачем
      Контенту, створеного процесі користування Послугою зокрема: передачі
      збереженого Контенту третім особам та надання доступу до свого Особистого
      Кабінету третім особам, а також за надання доступу іншим Користувачам до
      перегляду.
    </p>
    <p>
      10.4. Користувач визнає, що він, як власник свого Контенту, несе повну
      відповідальність за надання доступу до Контенту свого Особового Кабінету
      іншим Користувачам, в ході Користування Послугою, як частина сервісу
      Послуги.
    </p>
    <p>
      10.5. Користувач несе відповідальність за порушення недоторканності
      приватного життя третіх осіб при користуванні Послугою, та гарантує, що до
      моменту запису і зберігання Контенту отримав згоду третіх осіб, якщо така
      потрібна згідно законодавства, на запис та зберігання їх зображення, або
      зображення об&rsquo;єктів правами на які Користувач не володіє.
    </p>
    <p>
      <b>11. СТРОК ДІЇ ДОГОВОРУ, ПРИПИНЕННЯ ТА ЗМІНА УМОВ ДОГОВОРУ</b>
    </p>
    <p>
      11.1. Цей Договір набуває чинності з дати акцепту, визначеної відповідної
      умовами цього Договору і діє безстроково.
    </p>
    <p>
      11.2. Цей Договір припиняє дію достроково, у передбачених ним, або діючим
      законодавством України випадках.
    </p>
    <p>
      11.3. Виконавець залишає за собою право внесення в будь-який час змін у
      даний Договір, які набирають чинності не раніше ніж через 10 (десять)
      календарних днів з дня їх публікації на Сайті Виконавця. При внесенні
      таких змін, Виконавець може розмістити нову редакцію Договору шляхом
      викладення її на Сайті Виконавця.
    </p>
    <p>
      11.4. У разі незгоди Користувача зі змінами умов Договору, Користувач
      зобов&apos;язаний припинити використовувати Послугу. Продовження
      використання Послуги означає надання згоди Користувача на використання
      Послуги на умовах зміненого Договору.
    </p>
    <p>
      11.5. Користувач може в односторонньому порядку припинити дію Договору з
      Виконавцем, повідомивши Виконавця про це в будь-який час. Розірвання
      договору з ініціативи Користувача здійснюється з урахуванням умов цього
      Договору.
    </p>
    <p>
      <b>12. ІНШІ УМОВИ ДОГОВОРУ</b>
    </p>
    <p>
      12.1. Цей Договір і відносини між Користувачем і Виконавцем регулюються
      чинним законодавством України. Користувач і Виконавець погоджуються
      використовувати при виникненні будь-яких спорів, що виникають у
      зв&apos;язку з цими Умовами, юрисдикцію судів України.
    </p>
    <p>
      12.2. Сторони зобов&apos;язуються забезпечити актуальність і
      конфіденційність всієї інформації за цим Договором, включаючи атрибути
      авторизованого доступу, банківські реквізити, адреси Сторін і інші дані,
      крім випадків передбачених чинним законодавством України.
    </p>
    <p>
      12.3. Визнання недійсним окремих положень цього Договору не тягне за собою
      недійсність всього Договору. У такому випадку Договір продовжує діяти без
      урахування недійсних положень, а Сторони вносять необхідні зміни до умов
      цього Договору.
    </p>
    <p>
      12.4. Акцептом (прийняттям) цього Договору Користувач, відповідно до
      Закону України &laquo;Про захист персональних даних&raquo;,
      проінформований про свої права шляхом отримання відповідної інформації з
      Сайту Виконавця, та надає згоду протягом всього строку існування
      Виконавця:
    </p>
    <ul>
      <li>
        <p>
          на обробку його персональних даних Виконавцем, в обсязі та цілях,
          необхідних для забезпечення реалізації податкових відносин та відносин
          у сфері бухгалтерського обліку, виконання Сторонами умов цього
          Договору, або інших умов, що мають пряме чи опосередковане відношення
          щодо його укладення, дії, виконання та припинення, відносин у сфері
          збору персональних даних у комерційних цілях (для інформування про
          послуги, рекламні акції, звернення з офертою тощо), згідно
          встановленої Виконавцем процедури їх обробки, без встановлення
          будь-яких обмежень права Виконавця на їх обробку;
        </p>
      </li>
      <li>
        <p>
          на поширення Виконавцем (без обов&rsquo;язку повідомлення Користувача)
          будь-якими каналами зв&rsquo;язку персональних даних Виконавця третім
          особам, які прямо чи опосередковано мають відношення щодо укладення,
          дії, виконання або припинення цього Договору; особам, право доступу до
          персональних даних (їх джерел) яким надане відповідно до законодавства
          України; розпорядникам персональних даних.
        </p>
      </li>
      <li>
        <p>
          Виконавець гарантує Користувачу дотримання норм Закону України
          &laquo;Про захист персональних даних&raquo;, під час обробки ним
          персональних даних Користувача.
        </p>
      </li>
    </ul>
    <p>
      12.5. Замовник погоджується, що Виконавець має право направляти
      повідомлення, включаючи повідомлення про внесення змін в умови
      використання Послуги, за допомогою e-mail, push-повідомлень або шляхом
      розміщення інформації на сайті Виконавця.
    </p>
    <p>
      12.6. У всьому іншому, не врегульованому в цьому Договорі, Сторони
      керуються чинним законодавством України.
    </p>
    <p>
      <b>13. ФОРС МАЖОР</b>
    </p>
    <p>
      13.1. Сторони звільняються від відповідальності за повне або часткове
      невиконання своїх обов&rsquo;язків за цим Договором, якщо таке невиконання
      стало наслідком обставин форс-мажору, які виникли після укладення цього
      Договору та які Сторони не могли ані передбачити, ані відвернути розумними
      заходами.
    </p>
    <p>
      13.2. У разі виникнення форс-мажорних обставин дія цього Договору
      призупиняється до закінчення дії форс-мажорних обставин.
    </p>
    <p>
      13.3. До обставин форс-мажору відносяться: повінь, пожежа, землетрус, інші
      стихійні лиха, а також війна, воєнні дії, незаконні дії органів державної
      влади та управління і будь-які інші обставини поза розумним контролем
      Сторін, які мають безпосередній суттєвий негативний вплив на виконання
      обов&apos;язків за цим Договором, однак, за умови, що такі обставини не
      виникли внаслідок недотримання Стороною чинного законодавства, невиконання
      Стороною будь-яких зобов&apos;язань, які стосуються будь-яких укладених
      між Сторонами угод.
    </p>
    <p>
      13.4. Сторона, яка посилається на настання форс-мажорних обставин, має
      підтвердити його документально відповідними довідками компетентних
      органів.
    </p>
    <p style={{ textAlign: 'center' }}>
      <b>
        АКЦЕПТ ПУБЛІЧНОГО ДОГОВОРА ПРО НАДАННЯ ПОСЛУГИ ВІДЕОСПОСТЕРЕЖЕННЯ ЧЕРЕЗ
        МЕРЕЖУ ІНТЕРНЕТ
        <br />
        (на умовах публічної оферти)
      </b>
    </p>
    <p>
      Користувач, керуючись нормами статей 638 Цивільного кодексу України,
      приймаю публічну пропозицію (оферту) сервісу ipteamcloud (надалі -
      Виконавець), опубліковану на сайті Виконавця{' '}
      <a
        href="http://www.ipteamcloud.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.ipteamcloud.com
      </a>
      , щодо укладання договору про використання Послуги
      територіально-розподіленого відеоспостереження через мережу Інтернет
      (надалі &mdash; Оферта), наступним чином:
    </p>
    <p>
      при укладенні зі мною договору Послуги відеоспостереження через мережу
      Інтернет
    </p>
    <p>
      1. Ставлячи відмітку &laquo;Пташка&raquo; в формі Реєстрації навпроти
      твердження &laquo;Я згоден та приймаю умови користування&raquo;
      підтверджую, що:
    </p>
    <ul>
      <li>
        <p>
          приймаю умови Договору про надання послуги територіально-розподіленого
          відеоспостереження через мережу Інтернет;
        </p>
      </li>
      <li>
        <p>
          приймання цього Акцепту є моїм вільним волевиявленням, відповідає моїй
          волі і інтересам, та ніяким чином не порушує мої права;
        </p>
      </li>
      <li>
        <p>як Замовник цілком усвідомлюю природу правочину, що вчиняється.</p>
      </li>
    </ul>
    <p>2. Оферта та цей Акцепт разом складають єдиний документ.</p>
    <p>
      3. Оферта вважається прийнятою з моменту позначення &laquo;Пташки&raquo; в
      формі Реєстрації навпроти твердження &laquo;Я згоден та приймаю умови
      користування&raquo; та Реєстрації на Сайті Виконавця.
    </p>
  </div>
);

export default AgendaUa;
