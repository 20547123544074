import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'components/CustomButtons/ButtonWithLoader.js';

const DevicePortsModal = (props) => {
  const {
    openState,
    close,
    ports,
    loader,
    testPorts,
    isTestPortBtn,
    handleTestPortsBtnDisabled,
  } = props;
  return (
    <Dialog open={openState} onClose={close} maxWidth="sm" fullWidth>
      <IconButton
        style={{ position: 'absolute', float: 'left', right: 0, zIndex: 100 }}
        onClick={close}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <FormattedMessage id="channels.form.portssetings.title" />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={16} style={{ marginBottom: 15 }}>
          {ports &&
            ports.map((i, idx) => (
              <Grid key={idx} item xs={12} sm={6}>
                {i.field}
              </Grid>
            ))}
        </Grid>
        {Boolean(isTestPortBtn) && (
          <Button
            style={{
              textAlign: 'center',
              padding: '0.45rem 1.25rem',
            }}
            loading={loader}
            onClick={testPorts}
            disabled={handleTestPortsBtnDisabled()}
            color="success"
            size="sm"
          >
            <FormattedMessage id="channels.form.test.port" />
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DevicePortsModal;
