import React from 'react';
import cx from 'classnames';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import image from 'assets/img/sidebar_bg.jpg';
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import dashboardRoutes from 'routes/dashboard.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute';
import withStyles from '@material-ui/core/styles/withStyles';
import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';

const switchRoutesFn = (routes) => (
  <Switch>
    {routes.map((prop, index) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={index} />;

      if (prop.collapse)
        return prop.views.map((prop, index) => {
          return (
            <Route path={prop.path} component={prop.component} key={index} />
          );
        });

      if (prop.private === false)
        return (
          <Route path={prop.path} component={prop.component} key={index} />
        );

      return (
        <PrivateRoute path={prop.path} component={prop.component} key={index} />
      );
    })}
  </Switch>
);

ReactGA.initialize(process.env.REACT_APP_UA_ANALYTICS_TRACKER);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
    };
    this.routes = dashboardRoutes[this.props.authentication.user.role];
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      if (this.mainPanel) this.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  sidebarMinimize = () => {
    this.setState({ miniActive: !this.state.miniActive });
  };

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    const switchRoutes = switchRoutesFn(this.routes);
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: this.state.miniActive,
    })}`;
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={this.routes}
          logoText="IPTeam Cloud"
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          bgColor="black"
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref={(node) => (this.mainPanel = node)}>
          <Header
            sidebarMinimize={this.sidebarMinimize}
            miniActive={this.state.miniActive}
            routes={this.routes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { authentication } = state;
  return { authentication };
};

export default connect(mapStateToProps)(withStyles(appStyle)(Dashboard));
