export const viewersConstant = {
  ADD_VIEWERS_REQUEST: 'ADD_VIEWERS_REQUEST',
  ADD_VIEWERS_SUCCESS: 'ADD_VIEWERS_SUCCESS',
  ADD_VIEWERS_FAILURE: 'ADD_VIEWERS_FAILURE',

  GET_VIEWERS_SUCCESS: 'GET_VIEWERS_SUCCESS',
  GET_VIEWERS_FAILURE: 'GET_VIEWERS_FAILURE',

  REMOVE_VIEWERS_SUCCESS: 'REMOVE_VIEWERS_SUCCESS',
  REMOVE_VIEWERS_FAILURE: 'REMOVE_VIEWERS_FAILURE',

  GET_ONE_VIEWERS_SUCCESS: 'GET_ONE_VIEWERS_SUCCESS',
  GET_ONE_VIEWERS_FAILURE: 'GET_ONE_VIEWERS_FAILURE',

  EDIT_VIEWERS_SUCCESS: 'EDIT_VIEWERS_SUCCESS',
  EDIT_VIEWERS_FAILURE: 'EDIT_VIEWERS_FAILURE',

  TOGGLE_VIEWERS_SUCCESS: 'TOGGLE_VIEWERS_SUCCESS',
  TOGGLE_VIEWERS_FAILURE: 'TOGGLE_VIEWERS_FAILURE',
};
