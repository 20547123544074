import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const SubmitInvoiceButton = () => {
  return (
    <button className="submit-invoice" type="submit">
      <FormattedMessage id="invoice.submitInvoiceButton" />
    </button>
  );
};

export default injectIntl(SubmitInvoiceButton);
