import Square from './Square';

export class Cursor extends Square {
  constructor(size) {
    super(undefined, size);
    this.visible = true;
  }

  setVisibility(val) {
    this.visible = val;
  }

  checkCollision(el) {
    const elBounds = el.getBounds();
    const cursorBounds = this.getBounds();
    if (
      // if cursor biger that element
      (elBounds.top >= cursorBounds.top &&
        elBounds.left >= cursorBounds.left &&
        elBounds.right <= cursorBounds.right &&
        elBounds.bottom <= cursorBounds.bottom) ||
      // if cursor smaller that element
      (elBounds.top <= cursorBounds.top &&
        elBounds.left <= cursorBounds.left &&
        elBounds.right >= cursorBounds.right &&
        elBounds.bottom >= cursorBounds.bottom)
    ) {
      return true;
    }
    return false;
  }

  getPosition = (e) => {
    // bounds.width !== canvas.width
    // bounds size = element size
    // canvas size = image resolution
    const bounds = e.target.getBoundingClientRect();
    const canvas = {
      width: e.target.width,
      height: e.target.height,
    };
    const pos = {
      x: e.clientX - bounds.x,
      y: e.clientY - bounds.y,
    };
    const canvasPos = {
      x: (pos.x / bounds.width) * canvas.width,
      y: (pos.y / bounds.height) * canvas.height,
    };
    return canvasPos;
  };

  moveHandler(e) {
    const canvasPos = this.getPosition(e);
    this.setPosition(canvasPos);
  }

  init(ctx = undefined) {
    if (!ctx) throw 'No canvas';
    const events = {
      mousemove: this.moveHandler.bind(this),
      mouseout: () => this.setVisibility(false),
      mouseenter: () => this.setVisibility(true),
    };
    Object.keys(events).forEach((e) =>
      ctx.canvas.addEventListener(e, events[e])
    );
    return this;
  }

  draw(ctx) {
    if (!this.visible) return;
    super.draw(ctx);
  }
}

export default Cursor;
