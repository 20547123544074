import React from 'react';
import { connect } from 'react-redux';
import GridContainer from '../Grid/GridContainer';
import withWidth from '@material-ui/core/withWidth';
import { injectIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';
import {
  uaHeaders,
  uaParagraphs,
  uaBoldSections,
  ruImages,
  ruHeaders,
  ruParagraphs,
  ruBoldSections,
} from './manuals';
import Manual from './Manual';

const Instructions = (props) => {
  return (
    <GridContainer
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      {props.user.language === 'ukraine' ? (
        <Manual
          images={ruImages}
          headers={uaHeaders}
          paragraphs={uaParagraphs}
          boldSections={uaBoldSections}
        />
      ) : (
        <Manual
          images={ruImages}
          headers={ruHeaders}
          paragraphs={ruParagraphs}
          boldSections={ruBoldSections}
        />
      )}
      {/* <GridItem
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ marginTop: "5rem" }}
      >
        <Button color="primary" onClick={downloadManual}>
          <GetAppOutlinedIcon className={props.classes.icons} />
          <span style={{ verticalAlign: "bottom" }}>
            <FormattedMessage id="profile.form.avatar.upload" />
          </span>
        </Button>
      </GridItem> */}
    </GridContainer>
  );
};

const mapStateToProps = (state) => ({
  user: state.authentication.user,
});
export default injectIntl(
  connect(mapStateToProps)(
    withWidth()(withStyles(extendedTablesStyle)(Instructions))
  )
);
