import countrieslist from 'countries-list';

export const prepareUserDataFromResponse = (data) => {
  // just in case when data object doesn't contain the country field
  if (data && data.country) {
    const defaultCountry = 'UA';
    const regexp = /\/\//gim;
    const image = data.profilephoto.replace(regexp, '/').replace(':/', '://');
    const dialCountry = countrieslist.countries[data.country || defaultCountry];
    if (dialCountry.currency === 'KZT') {
      dialCountry.phone = '7';
    }
    return {
      ...data,
      phonenumber: `+${dialCountry.phone}${data.phonenumber}`,
      profilephoto: image,
    };
  }
  return data;
};
