import { transferConstants } from 'constants/transfer.constants';

const initialState = {
  data: [],
};

export function transfer(state = initialState, action) {
  switch (action.type) {
    case transferConstants.GET_SUCCESS:
      return {
        data: [...action.data],
      };
    case transferConstants.ADD_SUCCESS:
    case transferConstants.APPROVE_SUCCESS:
      return {
        data: [...state.data, action.data],
      };
    case transferConstants.DELETE_SUCCESS:
      return {
        data: [...state.data.filter((i) => i.id !== action.data.id)],
      };
    default:
      return state;
  }
}
