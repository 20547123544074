import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import { getLangCode } from 'helpers/language';

import Place from '@material-ui/icons/Place';
import AddLocation from '@material-ui/icons/AddLocation';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';

import RegionsAdd from './RegionsAdd.js';
import RegionsTable from './RegionsTable.js';
import RemoveAlert from 'components/RemoveAlert';

import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';
// import testData from "./testData.js";
import { partnerActions, loaderActions } from 'actions';
import LoaderAnimation from 'components/Loader';

class Regions extends React.Component {
  state = {
    data: [],
    confirm: null,
    edit: {},
  };

  componentDidMount() {
    this.setState({ data: this.props.data });
    const { dispatch } = this.props;
    dispatch(loaderActions.show('pageLoader'));
    dispatch(partnerActions.getRegions());
  }

  handleAddLocation = (data) => {
    // const array = this.state.data;
    // array.push(data);
    // this.setState({ data: array });
    this.props.dispatch(partnerActions.addRegion(data));
  };

  onEdit = (id) => {
    const filtered = this.props.geoData.find((i) => i._id !== id);
    this.setState({ edit: filtered });
  };

  handleEditLocation = (data) => {
    // const array = this.state.data;
    // array.push(data);
    // this.setState({ data: array });
    this.props.dispatch(partnerActions.editRegion(data));
  };

  handleRemoveLocation = (id) => {
    this.setState({
      confirm: (confirmation) => {
        if (confirmation) {
          this.props.dispatch(partnerActions.deleteRegion(id));
          // const filtered = this.state.data.filter(i => i.id !== id);
          this.setState({ confirm: null });
        }
        this.setState({ confirm: null });
      },
    });
  };

  render() {
    const { classes, intl, loader } = this.props;

    if (loader) return <LoaderAnimation />;

    return (
      <div>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <AddLocation />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="regions.add.title" />
            </h4>
          </CardHeader>
          <CardBody>
            <RegionsAdd
              lang={this.props.lang}
              onChange={this.handleAddLocation}
              edit={this.state.edit}
            />
          </CardBody>
        </Card>
        <Clearfix />
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Place />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <FormattedMessage id="layout.menu.regions" />
            </h4>
          </CardHeader>
          <CardBody>
            <RegionsTable
              data={this.props.geoData}
              remove={this.handleRemoveLocation}
              lang={this.props.lang}
              edit={this.onEdit}
            />
          </CardBody>
        </Card>
        <Clearfix />
        <RemoveAlert
          confirm={this.state.confirm}
          title={intl.formatMessage({
            id: 'regions.table.remove.title',
          })}
          description={intl.formatMessage({
            id: 'regions.table.remove.description',
          })}
          successTitle={intl.formatMessage({
            id: 'regions.table.remove.success',
          })}
          confirmBtnText={intl.formatMessage({
            id: 'regions.table.remove.confirm',
          })}
          cancelBtnText={intl.formatMessage({
            id: 'regions.table.remove.cancel',
          })}
        />
      </div>
    );
  }
}

export default injectIntl(
  connect((state) => ({
    lang: getLangCode(state.authentication.user.language),
    geoData: state.places,
    loader: state.loader.pageLoader,
    // data: testData
  }))(withStyles(extendedTablesStyle)(Regions))
);
