import axios from 'axios';
import { baseReactAPIUrl } from '../constants';

export const apiCall = axios.create({
  baseURL: baseReactAPIUrl,
});

apiCall.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

apiCall.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      config.headers.Authorization = `${token.tokenType} ${token.accessToken}`;
    }
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
