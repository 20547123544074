import React, { Component } from 'react';
import { connect } from 'react-redux';

import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';

import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import AddTransfer from './Get';
import TransferTable from './ListTransactions';

import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { transferActions } from 'actions/transfer.actions';

const style = {
  customCardContentClass: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

class AbonentTransfer extends Component {
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(transferActions.getTransfers());
  }

  getTransfer = (code) => {
    const { dispatch } = this.props;
    dispatch(transferActions.approveTransfer(code));
  };

  removeTransfer = (id) => {
    const { dispatch } = this.props;
    dispatch(transferActions.deleteTransfer(id));
  };

  render() {
    const { classes, transfer, currencies } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AccountBalanceWallet />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="layout.menu.getTransaction" />
                <br />
                <small>
                  <FormattedMessage id="transfer.title.getTransaction.description" />
                </small>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <GridContainer>
                    <GridItem md={3}>
                      <AddTransfer onSubmit={this.getTransfer} />
                    </GridItem>
                    <GridItem md={9}>
                      <TransferTable
                        data={transfer}
                        remove={this.removeTransfer}
                        currencies={currencies}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem md={9} />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    transfer: state.transfer.data,
    currencies: state.system.currencies,
  };
}

export default injectIntl(
  connect(mapStateToProps)(withStyles(style)(AbonentTransfer))
);
