import React from 'react';
import GridItem from '../Grid/GridItem';
import { injectIntl } from 'react-intl';

const supportMail = 'support@ipteamcloud.com';

const ContactUs = (props) => {
  return (
    <GridItem
      container
      justify="center"
      alignItems="center"
      style={{ marginTop: '5rem' }}
    >
      <a href={`mailto:${supportMail}`}>
        <h3 style={{ verticalAlign: 'bottom' }}>
          {`${props.intl.formatMessage({
            id: 'layout.menu.contacts.message',
          })} - ${supportMail}`}
        </h3>
      </a>
    </GridItem>
  );
};

export default injectIntl(ContactUs);
