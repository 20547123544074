import React from 'react';
import loadGoogleMapsApi from 'load-google-maps-api';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';

const loadApi = (lang) =>
  loadGoogleMapsApi({
    key: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places'],
    language: lang,
  });

export default class Autocomplete extends React.Component {
  api = null;

  input = null;

  autocomplete = null;

  componentDidMount() {
    loadApi(this.props.lang).then(this.init);
    if (this.props.inputRef) this.props.inputRef(this.input);
  }

  initAutocomplete = () => {
    if (!this.input) return;
    const autocomplete = new this.api.places.Autocomplete(this.input, {
      types: ['geocode'],
    });
    autocomplete.addListener('place_changed', this.handleChange);
    this.autocomplete = autocomplete;
  };

  handleChange = () => {
    const place = this.autocomplete.getPlace();
    if (!place.geometry) return;
    this.props.onChange(place);
  };

  init = (api) => {
    this.api = api;
    this.initAutocomplete();
  };

  render() {
    return (
      <FormControl fullWidth>
        <Input
          inputRef={(node) => (this.input = node)}
          value={this.props.value}
          onChange={this.onChange}
        />
      </FormControl>
    );
  }
}
