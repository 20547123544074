import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const HiddenField = ({ input, meta: { touched, invalid, error } }) => (
  <FormControl error={invalid}>
    <input type="hidden" {...input} />
    <FormHelperText>{touched && error}</FormHelperText>
  </FormControl>
);

export default HiddenField;
