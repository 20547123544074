export const invoiceConstants = {
  GET_REQUEST: 'GET_INVOICE_REQUEST',
  GET_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_FAILURE: 'GET_INVOICE_FAILURE',

  GET_TOTAL_PRICE_REQUEST: 'GET_INVOICE_TOTAL_PRICE_REQUEST',
  GET_TOTAL_PRICE_SUCCESS: 'GET_INVOICE_TOTAL_PRICE_SUCCESS',
  GET_TOTAL_PRICE_FAILURE: 'GET_INVOICE_TOTAL_PRICE_FAILURE',

  GET_INVOICE_ITEMS_REQUEST: 'GET_INVOICE_ITEMS_REQUEST',
  GET_INVOICE_ITEMS_SUCCESS: 'GET_INVOICE_ITEMS_SUCCESS',
  GET_INVOICE_ITEMS_FAILURE: 'GET_INVOICE_ITEMS_FAILURE',

  GET_LOGS_REQUEST: 'GET_PAYMENTS_LOGS_REQUEST',
  GET_LOGS_SUCCESS: 'GET_PAYMENTS_LOGS_SUCCESS',
  GET_LOGS_FAILURE: 'GET_PAYMENTS_LOGS_FAILURE',

  GET_INVOICES_REQUEST: 'GET_INVOICES_LOGS_REQUEST',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_LOGS_SUCCESS',
  GET_INVOICES_FAILURE: 'GET_INVOICES_LOGS_FAILURE',

  CREATE_REQUEST: 'CREATE_INVOICE_REQUEST',
  CREATE_SUCCESS: 'CREATE_INVOICE_SUCCESS',
  CREATE_FAILURE: 'CREATE_INVOICE_FAILURE',

  CLEAR_WIZARD: 'CLEAR_WIZARD_INVOICE',
};
