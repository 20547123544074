export const styles = {
  cameraConfigGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: '0.45rem',
  },
  basicBtnAlignments: {
    textAlign: 'center',
    padding: '0.45rem 1.25rem',
    width: '14.5rem',
  },
  btnPaddingWithMargin: {
    margin: '20px 0',
    textAlign: 'center',
    padding: '0.45rem 1.25rem',
  },
  btnPaddingWithMarginLeft: {
    margin: '20px 0',
    textAlign: 'center',
    padding: '0.45rem 1.25rem',
    marginLeft: '0.4rem',
  },
};
