import { alertConstants } from '../constants';

const initialState = {
  color: 'success',
  message: '',
};

export function alert(state = initialState, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        color: 'success',
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        color: 'danger',
        message: action.message,
      };
    case alertConstants.INFO: {
      return {
        color: 'info',
        message: action.message,
      };
    }
    case alertConstants.WARNING: {
      return {
        color: 'warning',
        message: action.message,
      };
    }
    case alertConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
