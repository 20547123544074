import React from 'react';
import { customStyle } from './styles';
import TextField from '@material-ui/core/TextField';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/ButtonWithLoader';

const RefillFormPaidPeriod = (props) => {
  const {
    intl,
    balance,
    classes,
    cartData,
    promocode,
    channelName,
    handleChange,
    handleSubmit,
    applyPromocode,
  } = props;
  return (
    <div className={classes.textCenter}>
      <h3 className={classes.header}>
        <FormattedMessage
          id="wizard.step3.description"
          values={{ channelName }}
        />
      </h3>
      <div>
        {balance > 0
          ? intl.formatMessage({ id: 'payment.form.warning.deactivate' })
          : intl.formatMessage({ id: 'payment.form.warning.activate' })}
        <br />
        <FormattedMessage id="payment.form.total.order" />
      </div>
      <h2 className={classes.header}>{cartData.total}</h2>

      <div style={{ marginBottom: 20 }}>
        <TextField
          type="text"
          name="promocode"
          label={intl.formatMessage({ id: 'order.form.promocode' })}
          value={promocode}
          onChange={handleChange}
        />
        <Button round color="rose" onClick={applyPromocode}>
          <FormattedMessage id="payment.form.paycode" />
        </Button>
      </div>
      <Button round color="rose" onClick={handleSubmit}>
        <FormattedMessage id="payment.form.pay" />
      </Button>
    </div>
  );
};

export default injectIntl(withStyles(customStyle)(RefillFormPaidPeriod));
