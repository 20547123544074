import { viewersConstant } from '../constants';

const initialState = {
  contacts: [],
  edit: {},
};

export function viewers(state = initialState, action) {
  switch (action.type) {
    case viewersConstant.ADD_VIEWERS_SUCCESS:
    case viewersConstant.GET_VIEWERS_SUCCESS:
      return {
        contacts: [...action.data.contacts.contact],
      };
    case viewersConstant.REMOVE_VIEWERS_SUCCESS:
      return {
        contacts: state.contacts.filter((item) => item.id !== action.data.id),
      };
    case viewersConstant.GET_ONE_VIEWERS_SUCCESS:
      return {
        ...state,
        edit: action.data,
      };
    case viewersConstant.TOGGLE_VIEWERS_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map((item) => {
          if (action.data.id === item.id) {
            item.UserBlocked = action.data.state;
          }
          return item;
        }),
      };

    default:
      return state;
  }
}
