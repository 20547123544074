import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltip } from 'assets/jss/material-dashboard-pro-react.jsx';

const TooltipMui = ({
  title = '',
  placement = 'top',
  children,
  classes,
  ...other
}) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      classes={{ tooltip: classes.tooltip }}
      {...other}
    >
      {children}
    </Tooltip>
  );
};

export default withStyles({ tooltip })(TooltipMui);
