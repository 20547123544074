import { invoiceConstants } from '../constants/invoice.constants';

const initialState = {
  showInvoice: {},
  all: [],
  unpaidInvoices: {},
  unpaid: {},
  wizardInvoice: {},
};

export function invoice(state = initialState, action) {
  switch (action.type) {
    case invoiceConstants.CREATE_SUCCESS:
    case invoiceConstants.GET_SUCCESS:
      return {
        ...state,
        showInvoice: action.data,
      };
    case invoiceConstants.GET_INVOICES_SUCCESS:
      return {
        ...state,
        all: action.data.invoices,
      };
    case invoiceConstants.GET_TOTAL_PRICE_SUCCESS:
      return {
        ...state,
        unpaidInvoices: action.data,
      };
    // case invoiceConstants.CREATE_SUCCESS:
    //   return {
    //     ...state,
    //     wizardInvoice: action.data || {}
    //   };
    case invoiceConstants.CLEAR_WIZARD:
      return {
        ...state,
        wizardInvoice: {},
      };
    case invoiceConstants.GET_INVOICE_ITEMS_SUCCESS:
      return {
        ...state,
        [action.data.status]: action.data.invoices,
      };
    default:
      return state;
  }
}
