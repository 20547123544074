import { authHeader } from '../helpers';
import { baseReactAPIUrl } from '../constants';

export const tariffService = {
  getTariffs,
};

const serverUrl = baseReactAPIUrl;

function getTariffs(gid) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${serverUrl}/tariffs?gid=${gid}`, requestOptions).then(
    handleResponse
  );
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        document.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
