import React from 'react';
import { connect } from 'react-redux';
import RenderTextField from 'components/CustomInput/RenderTextField';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import Clearfix from 'components/Clearfix/Clearfix.jsx';

import { passwordValidate } from './validate.js';

let UserPasswordForm = (props) => {
  const { classes, handleSubmit, intl, loader } = props;

  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Field
            name="password"
            type="password"
            component={RenderTextField}
            label={`${intl.formatMessage({
              id: 'profile.form.password.oldpass',
            })}*`}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Field
            name="password2"
            type="password"
            component={RenderTextField}
            label={`${intl.formatMessage({
              id: 'profile.form.password.newpass',
            })}*`}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} md={4}>
          <Field
            name="rePassword"
            type="password"
            component={RenderTextField}
            label={`${intl.formatMessage({
              id: 'profile.form.password.renewpass',
            })}*`}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>
      </GridContainer>
      <Button
        loading={loader}
        type="submit"
        color="rose"
        className={classes.updateProfileButton}
      >
        <FormattedMessage id="profile.form.password.submit" />
      </Button>
      <Clearfix />
    </form>
  );
};

UserPasswordForm = reduxForm({
  form: 'UserPasswordForm',
  validate: passwordValidate,
  touchOnBlur: false,
})(UserPasswordForm);

UserPasswordForm = injectIntl(
  connect((state) => ({
    loader: state.loader.userPasswordForm,
  }))(UserPasswordForm)
);

export default UserPasswordForm;
