import { tariffConstants } from 'constants/tariff.constants';

const initialState = {
  products: [],
};

export function tariff(state = initialState, action) {
  switch (action.type) {
    case tariffConstants.GET_SUCCESS:
      return {
        ...state,
        products: action.data.products,
        hideTestProduct: action.data.hideTestProduct,
      };
    default:
      return state;
  }
}
