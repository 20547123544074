import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { history } from 'helpers/history.js';

import withStyles from '@material-ui/core/styles/withStyles';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Place from '@material-ui/icons/Place';
import AccountBox from '@material-ui/icons/AccountBox';
import Assignment from '@material-ui/icons/Assignment';

import {
  cardTitle,
  roseColor,
} from 'assets/jss/material-dashboard-pro-react.jsx';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

const styles = {
  ...dashboardStyle,
  cardTitle: {
    ...cardTitle,
    margin: 0,
    padding: '20px 0',
  },
  icon: {
    color: '#333333',
    margin: '10px auto 0',
    width: '130px',
    height: '130px',
    border: '1px solid #E5E5E5',
    borderRadius: '50%',
    lineHeight: '174px',
    '& svg': {
      width: '55px',
      height: '55px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      width: '55px',
      fontSize: '55px',
    },
  },
  iconRose: {
    color: roseColor,
  },
  textCenter: {
    textAlign: 'center',
  },
  stretch: {
    display: 'flex',
    alignItems: 'stretch',
  },
  strechBody: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardDescription: {
    flex: '1 1 auto',
    paddingBottom: 20,
  },
};

class Dashboard extends React.Component {
  getName() {
    const { user } = this.props;
    let username = '';
    if (Object.keys(user).length) {
      if ('firstname' in user) {
        username = user.firstname;
      }
      if ('lastname' in user) {
        username = `${username} ${user.lastname}`;
      }
      if (!('firstname' in user) || !('lastname' in user)) {
        username = user.username;
      }
    }
    return username;
  }

  render() {
    const { classes, user } = this.props;
    const isAffiliate = user.stats && user.stats.isAffiliate;
    return (
      <div>
        <div className={classes.textCenter}>
          <h2>
            <FormattedMessage
              id="dashboard.title"
              values={{ username: this.getName() }}
            />
          </h2>
          {!isAffiliate && (
            <p>
              <FormattedMessage id="dashboard.begin.partner" />
            </p>
          )}
        </div>
        <GridContainer justify="center">
          <GridItem xs={12} lg={10} xl={8}>
            <GridContainer>
              <GridItem xs={12} sm={4} className={classes.stretch}>
                <Card pricing>
                  <CardBody pricing className={classes.strechBody}>
                    <NavLink to="/regions" className={classes.icon}>
                      <Place className={classes.iconRose} />
                    </NavLink>
                    <h3 className={classes.cardTitle}>
                      <FormattedMessage id="dashboard.card.region.title" />
                    </h3>
                    <p className={classes.cardDescription}>
                      <FormattedMessage id="dashboard.card.region.description" />
                    </p>
                    <Button
                      onClick={() => history.push('/regions')}
                      round
                      color="rose"
                    >
                      <FormattedMessage id="dashboard.card.goToBtn" />
                    </Button>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.stretch}>
                <Card pricing>
                  <CardBody pricing className={classes.strechBody}>
                    <NavLink to="/profile" className={classes.icon}>
                      <AccountBox className={classes.iconRose} />
                    </NavLink>
                    <h3 className={classes.cardTitle}>
                      <FormattedMessage id="dashboard.card.profile.title" />
                    </h3>
                    <p className={classes.cardDescription}>
                      <FormattedMessage id="dashboard.card.profile.description" />
                    </p>
                    <Button
                      onClick={() => history.push('/profile')}
                      round
                      color="rose"
                    >
                      <FormattedMessage id="dashboard.card.goToBtn" />
                    </Button>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.stretch}>
                <Card pricing>
                  <CardBody pricing className={classes.strechBody}>
                    <NavLink to="/invoices" className={classes.icon}>
                      <Assignment className={classes.iconRose} />
                    </NavLink>
                    <h3 className={classes.cardTitle}>
                      <FormattedMessage id="dashboard.card.ewallet.title" />
                    </h3>
                    <p className={classes.cardDescription}>
                      <FormattedMessage id="dashboard.card.ewallet.description" />
                    </p>
                    <Button
                      onClick={() => history.push('/invoices')}
                      round
                      color="rose"
                    >
                      <FormattedMessage id="dashboard.card.goToBtn" />
                    </Button>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (state) => {
  return { user: state.authentication.user };
};

export default connect(mapDispatchToProps)(withStyles(styles)(Dashboard));
