import React from 'react';
import { connect } from 'react-redux';
import { customStyle } from './styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from 'components/CustomButtons/ButtonWithLoader';
import withStyles from '@material-ui/core/styles/withStyles';

const RefillFormTestPeriod = (props) => {
  const { classes, loaderAdd, channelName, handleSubmit } = props;
  return (
    <div className={classes.textCenter}>
      <h3 className={classes.header}>
        <FormattedMessage
          id="wizard.step3.test.title"
          values={{ channelName }}
        />
      </h3>
      <div style={{ marginBottom: 10 }}>
        <FormattedMessage id="wizard.step3.test.description" />
      </div>
      <Button loading={loaderAdd} round color="rose" onClick={handleSubmit}>
        <FormattedMessage id="wizard.step3.test.button" />
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loaderAdd: state.loader.orderFormAdd,
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(
    withStyles(customStyle)(RefillFormTestPeriod)
  )
);
