import { get } from 'lodash';
import styles from './styles.css';
import { connect } from 'react-redux';
import JsxParser from 'react-jsx-parser';
import React, { Component } from 'react';
import { invoiceActions } from 'actions';
import LoaderAnimation from 'components/Loader';
import { withStyles } from '@material-ui/core';
import SubmitInvoiceButton from './SubmitInvoiceButton';
import DownloadInvoiceButton from './DownloadInvoiceButton';
import ApplyInvoiceFromCreditButton from './ApplyInvoiceFromCreditButton';

class InvoiceView extends Component {
  constructor(props) {
    super(props);
    if (props.match.params) {
      props.dispatch(invoiceActions.getInvoice(props.match.params.invoiceid));
    }
  }

  injectOptionsBindings = (html) => {
    let r = '';
    const b = {};
    let i = 0;

    while (true) {
      const closeOptionIndex = html.indexOf('</option>', i);

      if (closeOptionIndex < 0) break;

      const openEndOptionIndex = html
        .slice(i, closeOptionIndex)
        .lastIndexOf('>');
      const value = html.slice(openEndOptionIndex + 1, closeOptionIndex);

      const bKey = `optionValue${openEndOptionIndex}`;
      b[bKey] = value;

      r += html.slice(i, openEndOptionIndex + 1);
      r += `{${bKey}}`;
      i = closeOptionIndex + 1;
    }

    r += html.slice(i > 0 ? i - 1 : 0);

    return { template: r, bindings: b };
  };

  render() {
    const { showInvoice } = this.props.invoice;
    if (!showInvoice || !Object.keys(showInvoice).length) {
      return <LoaderAnimation />;
    }
    if (Object.keys(showInvoice).length && !get(showInvoice, 'body')) {
      return <LoaderAnimation />;
    }
    const { template, bindings } = this.injectOptionsBindings(
      this.props.invoice.showInvoice.body
    );
    return (
      <JsxParser
        jsx={template}
        bindings={bindings}
        components={{
          SubmitInvoiceButton,
          DownloadInvoiceButton,
          ApplyInvoiceFromCreditButton,
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { invoice } = state;
  return {
    invoice,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(InvoiceView));
