import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import SelectField from '@material-ui/core/Select/Select';
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

const styles = {
  ...customSelectStyle,
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    margin: '10px 1px 10px 0px',
  },
};

const RenderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  classes,
  formControlProps,
  ...custom
}) => {
  return (
    <FormControl {...formControlProps} className={classes.selectFormControl}>
      {label && (
        <InputLabel className={classes.selectLabel}>{label}</InputLabel>
      )}
      <SelectField
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{ select: classes.select }}
        onChange={(event, index, value) => {
          input.onChange(value);
        }}
        // errorText={touched && error}
        {...input}
        {...custom}
      >
        {children}
      </SelectField>
      {Boolean(touched && error) && (
        <FormHelperText
          className={classes.checkboxError}
          error={Boolean(touched && error)}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

RenderSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RenderSelectField);
