import React, { useMemo } from 'react';
import { get } from 'lodash';

import { styles } from '../styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, getFormValues, isValid } from 'redux-form';
import { HiddenField, TextField } from 'components/ReduxInputs';
import Button from 'components/CustomButtons/ButtonWithLoader';
import { getVideoFormatsOption, intlOptionVideoFormat } from 'helpers';

let testStreamBtnActive = false;

const MainVideoStreamControls = (props) => {
  const {
    intl,
    active,
    tested,
    testVideoLoop,
    portReachable,
    deviceVideoFormat,
    cameraConfigFormValid,
    testStreamAfterTheVideoLoop,
  } = props;

  const onTestVideoStream = () => () => {
    const { wizard } = props;
    if (!wizard || tested) {
      return testStreamAfterTheVideoLoop();
    }
    return testVideoLoop();
  };

  const allPortsAreReachable = useMemo(() => {
    return Object.values(portReachable).every((el) => Boolean(el));
  }, [portReachable]);

  const isTestStreamBtnDisabled = () => {
    if (!portReachable || !cameraConfigFormValid) {
      testStreamBtnActive = false;
      return true;
    }
    if (active.videoStreamError) {
      return false;
    }
    if (
      active.mainVideoStreamActive &&
      allPortsAreReachable &&
      !testStreamBtnActive
    ) {
      testStreamBtnActive = true;
      return true;
    }
    if (allPortsAreReachable && !testStreamBtnActive) {
      return false;
    }
    if (!tested && !allPortsAreReachable) {
      return true;
    }
    if (!get(props.videoStreamTestValues, 'isVideoTested')) {
      return true;
    }
    if (get(props.videoStreamTestValues, 'isVideoTested')) {
      testStreamBtnActive = false;
    }
    return false;
  };

  const isTestStreamBtnLoading = () => {
    if (active.videoStreamError) {
      return false;
    }
    if (testStreamBtnActive) {
      return true;
    }
    if (portReachable && active.mainVideoStreamActive && !testStreamBtnActive) {
      return true;
    }
    if (!portReachable && !testStreamBtnActive) {
      return false;
    }
    if (portReachable && !testStreamBtnActive) {
      return false;
    }
    return false;
  };

  return (
    <Grid item xs={12} sm={6}>
      <Field
        component={TextField}
        fullWidth
        select
        SelectProps={{ native: true }}
        disabled={!deviceVideoFormat.main.length}
        label={intl.formatMessage({
          id: 'channels.form.codec.title',
        })}
        name="data.ConnectionSettings.MainVideoStreamFormat"
      >
        <option disabled value="" />
        <option disabled>
          {intlOptionVideoFormat(intl, deviceVideoFormat)}
        </option>
        {getVideoFormatsOption(deviceVideoFormat)}
      </Field>
      <Button
        style={styles.btnPaddingWithMargin}
        loading={isTestStreamBtnLoading()}
        color="success"
        onClick={onTestVideoStream()}
        disabled={isTestStreamBtnDisabled()}
        size="sm"
      >
        <FormattedMessage
          style={{ padding: '2px 5px 0 2px' }}
          id="channels.form.test.video"
        />
      </Button>
      <div>
        <Field component={HiddenField} name="isVideoTested" />
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  portReachable: state.channels.portReachable,
  cameraConfigFormValid: isValid('CameraConfigForm')(state),
  videoStreamTestValues: getFormValues('VideoStreamTestForm')(state),
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(MainVideoStreamControls)
);
