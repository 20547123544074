const addViewer = (values, { intl }) => {
  const errors = {};
  const requiredFields = [
    'email',
    'password2',
    'confirmpassword',
    // "cctv_login", "cctv_password"
  ];
  const passwordFields = [
    'password2',
    'confirmpassword',
    // "cctv_password"
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = intl.formatMessage({ id: 'validation.required' });
    }
  });
  const len = { min: 6, max: 128 };
  passwordFields.forEach((field) => {
    if (
      values[field] &&
      (values[field].trim().length < len.min ||
        values[field].trim().length > len.max)
    ) {
      errors[field] = intl.formatMessage({ id: 'validation.password2' }, len);
    }
  });
  if (values.confirmpassword !== values.password2) {
    errors.confirmpassword = intl.formatMessage({
      id: 'validation.confirmpassword',
    });
  }
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = intl.formatMessage({ id: 'validation.email' });
  }
  return errors;
};

export default addViewer;
