import Canvas from '../classes/Canvas';
import CanvasImage from '../classes/Image';
import ZoneLayer from './Zone';
import SizeLayer from './Size';
import { getMapFromString, getStringFromMap } from '../base64';

export class MacroscopCanvas extends Canvas {
  constructor({ selector = undefined, image = undefined } = {}) {
    super(selector);
    this.zoneLayer = new ZoneLayer();
    this.sizeLayer = new SizeLayer();
    this.image = new CanvasImage(image);
    this.addElement(this.image, this.zoneLayer, this.sizeLayer);
    this.sizeLayer.visible = false;
  }

  setData(data) {
    const map = getMapFromString(data.DetectionMap);
    const grid = {
      x: data.DetectionMapWidth,
      y: data.DetectionMapHeight,
    };
    const size = {
      x: data.MinObjWidth,
      y: data.MinObjHeight,
    };
    this.zoneLayer.grid.setSize(grid);
    this.zoneLayer.grid.setupGrid();
    this.zoneLayer.grid.set(map);
    this.sizeLayer.setSelected(size);
  }

  getData() {
    const map = getStringFromMap(this.zoneLayer.grid.get());
    const grid = this.zoneLayer.grid.size;
    const size = this.sizeLayer.getSelected();
    return {
      MinObjWidth: size.x,
      MinObjHeight: size.y,
      DetectionMap: map,
      DetectionMapWidth: grid.x,
      DetectionMapHeight: grid.y,
    };
  }

  resize() {
    super.resize();
    const gridSize = this.zoneLayer.grid.size;
    // adapt size of canvas to the grid
    const size = {
      width: Math.floor(this.width / gridSize.x) * gridSize.x,
      height: Math.floor(this.height / gridSize.y) * gridSize.y,
    };
    this.setSize(size);
    this.image.setSize(size);
    this.zoneLayer.setSize(size);
    this.sizeLayer.setSize(size);
  }

  init() {
    this.zoneLayer.init(this.ctx);
    this.sizeLayer.init(this.ctx);
    return super.init();
  }
}

export default MacroscopCanvas;
