import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { profileValidate } from './validate.js';
import GridItem from 'components/Grid/GridItem.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import { injectIntl, FormattedMessage } from 'react-intl';
import GridContainer from 'components/Grid/GridContainer.jsx';
import countriesListCodes from 'i18n-iso-countries/codes.json';
import Button from 'components/CustomButtons/ButtonWithLoader.js';
import RenderTextField from 'components/CustomInput/RenderTextField';
import RenderSelectField from 'components/CustomSelect/RenderSelectField';
import { normalizeCameraLogin } from 'containers/Channels/Form/formsNormalization';

let UserProfileForm = (props) => {
  const { classes, handleSubmit, intl, loader } = props;

  const getCountryList = countriesListCodes
    .map((i) => ({
      value: i[0],
      label: intl.formatMessage({ id: `country.${i[0]}` }),
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Field
                name="firstname"
                component={RenderTextField}
                label={`${intl.formatMessage({ id: 'form.firstname' })}*`}
                formControlProps={{
                  fullWidth: true,
                }}
                normalize={(value, previousValue) =>
                  normalizeCameraLogin(value, previousValue, 24)
                }
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="email"
                component={RenderTextField}
                label={`${intl.formatMessage({ id: 'form.email' })}*`}
                formControlProps={{
                  fullWidth: true,
                }}
                disabled
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="country"
                component={RenderSelectField}
                native
                label={`${intl.formatMessage({ id: 'form.country' })}*`}
                formControlProps={{ fullWidth: true }}
                inputProps={{ style: { textTransform: 'none' } }}
              >
                <option disabled value="" />
                <option disabled value="">
                  {intl.formatMessage({ id: 'form.country.select' })}
                </option>
                {getCountryList.map((el, idx) => (
                  <option key={idx} value={el.value}>
                    {el.label}
                  </option>
                ))}
              </Field>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Field
                name="language"
                component={RenderSelectField}
                native
                label={intl.formatMessage({ id: 'form.language' })}
                formControlProps={{ fullWidth: true }}
                inputProps={{ style: { textTransform: 'none' } }}
              >
                <option disabled value="" />
                <option disabled value="">
                  {intl.formatMessage({ id: 'language.select' })}
                </option>
                <option value="russian">
                  {intl.formatMessage({ id: 'language.ru' })}
                </option>
                <option value="ukraine">
                  {intl.formatMessage({ id: 'language.ua' })}
                </option>
                <option value="english">
                  {intl.formatMessage({ id: 'language.en' })}
                </option>
              </Field>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <Button
        loading={loader}
        type="submit"
        color="rose"
        className={classes.updateProfileButton}
      >
        <FormattedMessage id="profile.form.userdata.submit" />
      </Button>
      <Clearfix />
    </form>
  );
};

UserProfileForm = reduxForm({
  form: 'UserProfileForm',
  validate: profileValidate,
  touchOnBlur: false,
})(UserProfileForm);

UserProfileForm = injectIntl(
  connect((state) => ({
    initialValues: {
      ...state.authentication.user,
    },
    loader: state.loader.userProfileForm,
    enableReinitialize: true,
  }))(UserProfileForm)
);

export default UserProfileForm;
