import { loaderConstants } from '../constants';
import { userService } from '../services';

export const loaderActions = {
  show: (place) => ({ type: loaderConstants.show, place }),
  hide: (place) => ({ type: loaderConstants.hide, place }),
  appLoad: () => (dispatch) => {
    dispatch(loaderActions.show('app'));
    userService.refreshToken().then(
      () => dispatch(loaderActions.hide('app')),
      () => dispatch(loaderActions.hide('app'))
    );
  },
};
