import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

const NoservicePlaceholder = (props) => (
  <div className={props.classes.center}>
    <h3>
      <FormattedMessage id="viewers.list.noservice" />
    </h3>
  </div>
);

export default injectIntl(NoservicePlaceholder);
