import { pushRulesConstants } from '../constants';

const initialState = {
  list: [],
  edit: {
    serviceId: '',
    textNotification: '',
    clientIds: [],
    zoneIds: [],
  },
};

export function pushRules(state = initialState, action) {
  switch (action.type) {
    case pushRulesConstants.GET_PUSH_RULES_SUCCESS:
    case pushRulesConstants.REMOVE_PUSH_RULES_SUCCESS:
      return {
        ...initialState,
        list: action.data,
      };
    case pushRulesConstants.CLEAN_PUSH_RULES_SUCCESS:
      return {
        ...initialState,
      };
    case pushRulesConstants.GET_ONE_PUSH_RULES_SUCCESS:
      return {
        list: [],
        edit: action.data,
      };
    case pushRulesConstants.CLEAN_PUSH_RULES_EDIT:
      return {
        list: [],
        edit: initialState.edit,
      };
    default:
      return state;
  }
}
