import {
  userActions,
  partnerActions,
  deviceManufacturesActions,
} from 'actions';
import React from 'react';
import List from './List';
import { connect } from 'react-redux';
import Statistics from './Statisctics';
import Card from 'components/Card/Card.jsx';
import Videocam from '@material-ui/icons/Videocam';
import CardBody from 'components/Card/CardBody.jsx';
import VideoCall from '@material-ui/icons/VideoCall';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';

class Subscribers extends React.Component {
  componentDidMount() {
    this.props.dispatch(partnerActions.getPartnersAbonents());
    this.props.dispatch(deviceManufacturesActions.getDeviceManufactures());
    this.props.dispatch(userActions.refreshToken());
  }

  filterData = (data, filter = null) => {
    switch (filter) {
      case 'new':
        return data
          .filter((item) => item.channels.some((channel) => !channel.approved))
          .map((item) => {
            const channels = item.channels.filter(
              (channel) => !channel.approved
            );
            return { ...item, channels };
          });
      case 'approved':
        return data
          .filter((item) => item.channels.some((channel) => channel.approved))
          .map((item) => {
            const channels = item.channels.filter(
              (channel) => channel.approved
            );
            return { ...item, channels };
          });
      default:
        return data;
    }
  };

  render() {
    const { classes, data, stats } = this.props;

    if (!data || !data.length) {
      return (
        <div align="center">
          <Statistics
            active={stats.activeAbonents}
            paid={stats.totalPaidSetupFee}
            avaliable={stats.availableConnects}
          />
          <h2>
            <FormattedMessage id="subscribers.empty" />
          </h2>
        </div>
      );
    }

    const newCamera = this.filterData(data, 'new');
    const approvedCamera = this.filterData(data, 'approved');

    return (
      <div>
        <Statistics
          active={stats.activeAbonents}
          paid={stats.totalPaidSetupFee}
          avaliable={stats.availableConnects}
        />

        {newCamera.length ? (
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <VideoCall />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="subscribers.title.new" />
              </h4>
            </CardHeader>
            <CardBody>
              <List data={newCamera} new />
            </CardBody>
          </Card>
        ) : null}
        <Clearfix />

        {approvedCamera.length ? (
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Videocam />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <FormattedMessage id="subscribers.title.list" />
              </h4>
            </CardHeader>
            <CardBody>
              <List data={approvedCamera} />
            </CardBody>
          </Card>
        ) : null}
        <Clearfix />
      </div>
    );
  }
}

export default injectIntl(
  connect((state) => ({
    data: state.partner.abonents, // state.authentication.user.connected
    stats: state.authentication.user.stats,
  }))(withStyles(extendedTablesStyle)(Subscribers))
);
