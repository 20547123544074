import { get } from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { channelsService } from 'services';
import withStyles from '@material-ui/core/styles/withStyles';
import { channelsConstant } from 'constants/cannels.constants';

const styles = {
  statistic: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: '5px 15px',
    borderRadius: '0 4px 0 0',
    background: 'rgba(0, 0, 0, 0.8)',
    color: '#FFF',
  },
};

class VideoStatistics extends Component {
  state = {
    statistic: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.getStatistic();
    if (this.statInterval) clearInterval(this.statInterval);
    this.statInterval = setInterval(this.getStatistic, 5000);
  }

  componentDidUpdate() {
    if (get(this.state, 'statistic.status') === 404) {
      this.props.dispatch({
        type: channelsConstant.CHANNEL_SESSION_FAILURE,
        data: true,
      });
      this.props.dispatch({ type: channelsConstant.CLEAR_EDIT });
      this.props.activateVideoLoading({
        mainVideoStreamActive: false,
        altVideoStreamActive: false,
        videoStreamError: true,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.statInterval) clearInterval(this.statInterval);
  }

  getStatistic = () => {
    const { channelId } = this.props;
    channelsService.getStatistic(channelId).then(({ statistic }) => {
      if (!this.mounted) return;

      if (this.state.statistic) {
        const oldStat = this.state.statistic;
        const newStat = Object.keys(statistic)
          .filter((key) => !!statistic[key])
          .reduce((obj, key) => {
            obj[key] = statistic[key];
            return obj;
          }, {});
        this.setState({ statistic: Object.assign(oldStat, newStat) });
      } else {
        this.setState({ statistic });
      }
    });
  };

  render() {
    const { classes, statisticStreamType } = this.props;
    const statistic = this.state.statistic
      ? this.state.statistic[statisticStreamType.replace('Video', 'Stream')]
      : null;

    return statistic ? (
      <div className={classes.statistic}>
        {statistic.Codec} {statistic.Resolution.Width}x
        {statistic.Resolution.Height}{' '}
        {
          // statistic.Fps > 0 &&
          `${statistic.Fps}FPS`
        }{' '}
        {
          // statistic.Bitrate > 0 &&
          statistic.Bitrate > 1000
            ? `${(statistic.Bitrate / 1000).toFixed(2)}Mbps`
            : `${statistic.Bitrate}Kbps`
        }
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  channelSessionFailure: state.channels.channelSessionFailure,
});

export default connect((state) => mapStateToProps(state))(
  withStyles(styles)(VideoStatistics)
);
