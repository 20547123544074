import { merge } from 'lodash';
import { channelsService } from '../services';
import { channelsConstant } from '../constants';
import { alertActions } from './alert.actions';
import { loaderActions, partnerActions, tariffActions } from '.';

const timer = null;

const cameraCreating = async (dispatch, data) => {
  dispatch({ type: channelsConstant.CREATING_CAMERA, data });
};

function changePriority(position, direction, channels) {
  if (timer) clearTimeout(timer);

  if (position + direction > 0 && position + direction <= channels.length) {
    channels = channels.map((item) => {
      if (item.channel.priority === position + direction) {
        item.channel.priority = item.channel.priority - direction;
      } else if (item.channel.priority === position) {
        item.channel.priority = position + direction;
      }
      return item;
    });
    channels.sort((a, b) => {
      return a.channel.priority - b.channel.priority;
    });
  }

  return (dispatch) => {
    const ch = channels.map((item) => {
      return { Id: item.channel.channelid, priority: item.channel.priority };
    });

    channelsService.changePriority(ch).then(
      () => {
        dispatch({
          type: channelsConstant.CHANGE_PRIORITY,
          data: channels,
        });
        dispatch(success({ position, direction }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: channelsConstant.PRIORITY_SUCCESS, data };
  }
  function failure(error) {
    return { type: channelsConstant.PRIORITY_FAILURE, error };
  }
}

function clear() {
  intervalController.rejectInterval();
  intervalController.rejectTimeout();
  return (dispatch) => {
    dispatch({ type: channelsConstant.CLEAR_EDIT });
  };
}

const rejectAfter = (intervalController, totalSecondsRun) =>
  new Promise(
    (res, rej) =>
      (intervalController.timeOutDesc = setTimeout(() => {
        intervalController.rejectInterval();
        rej(Error('Error add channel'));
      }, totalSecondsRun * 1000))
  );

const tryInfinitely = (intervalController, callback, timeout) => {
  return new Promise((resolve, reject) => {
    intervalController.intervalDesc = setInterval(async () => {
      try {
        const response = await callback();
        if (response) {
          intervalController.rejectInterval();
          resolve(response);
        }
      } catch (err) {
        intervalController.rejectInterval();
        intervalController.rejectTimeout();
        reject();
      }
    }, timeout * 1000);
  });
};

const intervalController = {
  intervalDesc: null,
  timeOutDesc: null,
  rejectInterval() {
    clearInterval(this.intervalDesc);
  },
  rejectTimeout() {
    clearTimeout(this.timeOutDesc);
  },
};

const runRequestWithIntervals = async (
  callback,
  timeout,
  totalSecondsRun = 90
) => {
  return await Promise.race([
    rejectAfter(intervalController, totalSecondsRun),
    tryInfinitely(intervalController, callback, timeout),
  ]);
};

const mainStreamStateActive = (res, state = 'Active') => {
  return res.Id && res.StreamsStates[0].State === state ? res : false;
};

const mainAndAlternativeStreamStateActive = (res, state = 'Active') => {
  return res.Id &&
    res.StreamsStates[0].State === state &&
    res.StreamsStates[1].State === state
    ? res
    : false;
};

const requestStatusCam = async (id, altStreamEnabled, timeOut = 2) => {
  return await runRequestWithIntervals(async () => {
    const res = await channelsService.getStatusCam(id);
    if (!altStreamEnabled) {
      return mainStreamStateActive(res);
    }
    return mainAndAlternativeStreamStateActive(res);
  }, timeOut);
};

const getStatusCam = (Id, altStreamEnabled, onChange, onSuccess) => {
  return async (dispatch) => {
    try {
      const data = await requestStatusCam(Id, altStreamEnabled);
      await cameraCreating(dispatch, true);
      if (onChange && onSuccess) {
        await onChange('channel', data);
        await onSuccess();
        intervalController.rejectTimeout();
      }
      await cameraCreating(dispatch, false);
    } catch (error) {
      dispatch({ type: channelsConstant.ADD_FAILURE, error });
      dispatch(alertActions.error(error));
      dispatch(loaderActions.hide('channelsTestVideo'));
    }
  };
};

function getChannels() {
  return (dispatch) => {
    channelsService
      .getChannels()
      .then(
        (data) => {
          data.sort((a, b) => {
            return a.channel.priority - b.channel.priority;
          });
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      )
      .finally(() => {
        dispatch(loaderActions.hide('pageLoader'));
      });
  };
  function success(data) {
    return { type: channelsConstant.GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: channelsConstant.GET_FAILURE, error };
  }
}

function getOneChannel(Id, wizard = true) {
  return (dispatch) => {
    channelsService.getOneChannel(Id).then(
      (data) => {
        if (data.channel.partnercode) {
          dispatch(partnerActions.getSelectedPartner(data.channel.partnercode));
        }
        if (!wizard) {
          dispatch(tariffActions.getTariffs(data.gid, data.domain, wizard));
        }
        dispatch({ type: channelsConstant.GET_ONE_SUCCESS, data });
        dispatch(loaderActions.hide('pageLoader'));
      },
      (error) => {
        dispatch({
          type: channelsConstant.GET_ONE_FAILURE,
          error: error.toString(),
        });
        dispatch(alertActions.error(error.toString()));
        dispatch(loaderActions.hide('pageLoader'));
      }
    );
  };
}

function addChannel(prams = {}) {
  return (dispatch) => {
    channelsService.addChannel(prams).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: channelsConstant.ADD_SUCCESS, data };
  }

  function failure(error) {
    return { type: channelsConstant.ADD_FAILURE, error };
  }
}

function addAlternativeChannelTest(
  prams,
  altVideoStreamEnabled,
  onChange,
  onSuccess
) {
  return async (dispatch) => {
    try {
      dispatch(loaderActions.show('channelsTestVideo'));
      dispatch(
        getStatusCam(prams.data.Id, altVideoStreamEnabled, onChange, onSuccess)
      );
      await channelsService.updateChannel(prams);
    } catch (e) {
      throw e;
    }
  };
}

function addChannelTest(
  prams,
  altVideoStreamEnabled,
  onChange,
  onSuccess,
  onUpdateChannelTestSuccess
) {
  return async (dispatch) => {
    try {
      dispatch(loaderActions.show('channelsTestVideo'));
      let data = await channelsService.addChannel(prams.data);
      data = { ...prams, data: data.channel };
      dispatch({ type: channelsConstant.ADD_SUCCESS, data });
      dispatch(
        getStatusCam(data.data.Id, altVideoStreamEnabled, onChange, onSuccess)
      );
      dispatch(
        updateChannelTest(merge(prams, data), onUpdateChannelTestSuccess)
      );
      dispatch(loaderActions.hide('channelsTestVideo'));
    } catch (error) {
      dispatch({ type: channelsConstant.ADD_FAILURE, error });
      dispatch(alertActions.error(error.message));
      dispatch(loaderActions.hide('channelsTestVideo'));
    }
  };
}

function testVideoAfterTestLoop(
  id,
  altVideoStreamEnabled,
  onChange,
  onSuccess
) {
  return async (dispatch) => {
    try {
      dispatch(loaderActions.show('channelsTestVideo'));
      dispatch(getStatusCam(id, altVideoStreamEnabled, onChange, onSuccess));
      dispatch(loaderActions.hide('channelsTestVideo'));
    } catch (error) {
      dispatch({ type: channelsConstant.ADD_FAILURE, error });
      dispatch(alertActions.error(error.message));
      dispatch(loaderActions.hide('channelsTestVideo'));
    }
  };
}

function updateChannel(channel = {}, typeValue, query) {
  return (dispatch) => {
    dispatch(loaderActions.show('channelsForm'));
    channelsService.updateChannel(channel, query).then(
      (data) => {
        data = { ...channel, data: data.channel };
        dispatch({ type: channelsConstant.ADD_SUCCESS, data });
        if (typeValue === 'update') {
          dispatch(alertActions.success('alert.channel.updated'));
        }
        dispatch(loaderActions.hide('channelsForm'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loaderActions.hide('channelsForm'));
      }
    );
  };

  function failure(error) {
    return { type: channelsConstant.UPDATE_FAILURE, error };
  }
}

function updateChannelTest(prams, onSuccess) {
  return (dispatch) => {
    channelsService.updateChannel(prams).then(
      (data) => {
        data = { ...prams, data: data.channel };
        const updateSuccess = () =>
          dispatch({ type: channelsConstant.UPDATE_SUCCESS, data });
        if (typeof onSuccess === 'function') {
          onSuccess(updateSuccess);
        } else {
          updateSuccess();
        }
      },
      (error) => {
        dispatch({ type: channelsConstant.UPDATE_FAILURE, error });
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
}

function toggleChannel(prams) {
  return (dispatch) => {
    channelsService.toggleChannel(prams).then(
      () => {
        dispatch(toggle({ Id: prams.channel.data.Id }));
        if (!prams.channel.data.Disabled) {
          dispatch(alertActions.success('alert.channel.activated'));
        } else {
          dispatch(alertActions.warning('alert.channel.deactivated'));
        }
      },
      (error) => {
        prams.Disabled = !prams.channel.data.Disabled;
        dispatch(toggle(prams));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function toggle(data) {
    return { type: channelsConstant.TOGGLE_ON_OFF_CHANNEL, data };
  }
}

function deleteChannel(Id) {
  return (dispatch) => {
    channelsService.deleteChannel(Id).then(
      () => {
        dispatch(success({ Id }));
        dispatch(alertActions.success('alert.channel.deleted'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success(data) {
    return { type: channelsConstant.DELETE_SUCCESS, data };
  }

  function failure(error) {
    return { type: channelsConstant.DELETE_FAILURE, error };
  }
}

function reachablePort(host, ports) {
  return (dispatch) => {
    dispatch(loaderActions.show('channelsTestPort'));
    let index = 0;
    const portsStatus = {};
    const loop = () =>
      channelsService.reachablePort(host, ports[index]).then(
        (data) => {
          portsStatus[ports[index]] = data.reachable;
          if (index === ports.length - 1) {
            dispatch(loaderActions.hide('channelsTestPort'));
            dispatch(success({ data: portsStatus }));
          } else {
            index++;
            loop();
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
          dispatch(loaderActions.hide('channelsTestPort'));
        }
      );
    loop();
  };

  function success(data) {
    return { type: channelsConstant.REACHABLE_SUCCESS, data };
  }

  function failure(error) {
    return { type: channelsConstant.REACHABLE_FAILURE, error };
  }
}

export const channelsActions = {
  getChannels,
  addChannel,
  updateChannel,
  addChannelTest,
  updateChannelTest,
  getOneChannel,
  deleteChannel,
  getStatusCam,
  clear,
  toggleChannel,
  reachablePort,
  changePriority,
  cameraCreating,
  testVideoAfterTestLoop,
  addAlternativeChannelTest,
};
