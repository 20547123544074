import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import { deviceManufacturesActions, partnerActions } from 'actions';
import List from './List';
import EmptyPlaceholder from 'containers/Channels/List/EmptyPlaceholder';

// TEST
import testData from '../Subscribers/testData';

const style = {
  center: { textAlign: 'center' },
  title: { margin: 0 },
};

export class RateCamera extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(deviceManufacturesActions.getDeviceManufactures());
    dispatch(partnerActions.getPartners());
  }

  render() {
    const { classes, rated } = this.props;
    const preRating = [];
    const setRating = [];

    if (!rated.length) {
      return <EmptyPlaceholder classes={classes} />;
    }

    if (rated) {
      rated.forEach((item) => {
        if (item.rating) {
          preRating.push(item);
        } else {
          setRating.push(item);
        }
      });
    }

    return (
      <Fragment>
        {Boolean(setRating.length) && (
          <Fragment>
            <div className={classes.center}>
              <h2 className={classes.title}>
                <FormattedMessage id="rating.title" />
              </h2>
              <p>
                <FormattedMessage id="rating.description" />
              </p>
            </div>
            <br />
            <List channels={setRating} />
          </Fragment>
        )}

        {Boolean(preRating.length) && (
          <Fragment>
            <h2 className={classes.center}>
              <FormattedMessage id="rating.previous" />
            </h2>
            <List channels={preRating} rated />
          </Fragment>
        )}
      </Fragment>
    );
  }
}
const channels = testData.reduce((arr, cur) => {
  const autorating = Math.random() > 0.5;
  const temp = cur.channels.map((i) => ({
    ...i,
    rating: autorating ? 10 : Math.floor(Math.random() * 10 + 1),
    autorating,
  }));
  return [...arr, ...temp];
}, []);
// /TEST

export default injectIntl(
  connect((state) => ({
    channels: channels.slice(0, 3),
    // rated: channels,
    rated: state.authentication.user.setRating,
  }))(withStyles(style)(RateCamera))
);
