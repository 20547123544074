import React from 'react';
import InputMask from 'react-input-mask';
import countriesList from 'countries-list';
import RenderTextField from 'components/CustomInput/RenderTextField';

const PhoneInput = ({ country, value, onChange, input, ...other }) => {
  const defaultCountry = 'UA';
  const maskLength = 12;
  const dialCountry = countriesList.countries[country || defaultCountry];
  if (dialCountry.currency === 'KZT') {
    dialCountry.phone = '7';
  }
  const mask = `+${dialCountry.phone
    .split('')
    .map((i) => `\\${i}`)
    .join('')}${Array.from({ length: maskLength - dialCountry.phone.length })
    .fill(9)
    .join('')}`;
  return (
    <InputMask
      mask={mask}
      maskChar=" "
      value={value}
      onChange={onChange}
      {...input}
    >
      {(inputProps) => <RenderTextField {...inputProps} {...other} />}
    </InputMask>
  );
};

export default PhoneInput;
