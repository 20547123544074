const initConf = {
  serviceId: {
    rules: {
      required: true,
    },
    messages: {
      required: 'push.form.required.channelids',
    },
    error: null,
  },
  textNotification: {
    rules: {
      required: true,
      minLength: 5,
      maxLength: 160,
    },
    error: null,
    errorMessage: null,
  },
  clientIds: {
    rules: {
      required: true,
    },
    error: null,
  },
  zoneIds: {
    rules: {
      required: true,
    },
    error: null,
  },
};

export default initConf;
