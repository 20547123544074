import styles from '../styles';
import { getPrice } from './helpers';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import TableRow from '@material-ui/core/TableRow';
import { Field, getFormValues } from 'redux-form';
import React, { Fragment, Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage, injectIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';

class SelectedTariffRows extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.dataForm.formTariff !== nextProps.dataForm.formTariff ||
      this.props.dataForm.billingCycle !== nextProps.dataForm.billingCycle ||
      this.props.selectedTariff !== nextProps.selectedTariff ||
      this.props.tariff.products !== nextProps.tariff.products
    );
  }

  selectField = ({ input, value, children, ...custom }) => (
    <Select
      {...input}
      {...custom}
      {...value}
      onChange={(event) => input.onChange(event)}
    >
      {children}
    </Select>
  );

  isTariffDisabled = (item) =>
    this.props.tariff.hideTestProduct && (item.pid === 1 || item.pid === 5);

  getProductPrice = () => {
    const { currency, selectedTariff, dataForm } = this.props;
    return `${currency.prefix || ''}
            ${getPrice(
              selectedTariff.pricing,
              currency.code,
              dataForm.billingCycle
            )}
            ${currency.suffix || ''}`;
  };

  getTariffsDescription = (html) => {
    if (!html) return '';
    const textarea = document.createElement('textarea');
    textarea.innerHTML = html;
    html = textarea.value;
    const list = html
      .replace(/\s+/g, ' ')
      .replace(/<\/?li?>/g, '\n')
      .replace(/<\/?.*?>/g, '')
      .split('\n')
      .map((str) => str.trim())
      .filter((str) => str.length)
      .map((str, idx) => (
        <li key={idx}>
          <FormattedMessage id={str} />
        </li>
      ));
    return <ul>{list}</ul>;
  };

  render() {
    const { intl, tariff, classes, dataForm, tariffState, selectedTariff } =
      this.props;

    return (
      <Fragment>
        <TableRow>
          <TableCell colSpan={2} padding="dense">
            <Grid container alignItems="center" spacing={16}>
              <Grid item xs={12} lg={7}>
                <Grid
                  container
                  alignItems="center"
                  style={{ margin: '20px 0' }}
                >
                  <Grid item xs={12} sm={6}>
                    <span className={classes.title}>
                      <FormattedMessage id="order.form.base.title" />
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      fullWidth
                      success={(tariffState === 'success').toString()}
                      error={tariffState === 'error'}
                      value={dataForm.formTariff}
                      component={this.selectField}
                      name="formTariff"
                    >
                      <MenuItem value="Choose a tariff" disabled>
                        {intl.formatMessage({ id: 'choice.tariff' })}
                      </MenuItem>
                      {tariff.products
                        ? tariff.products.map((item, index) => (
                            <MenuItem
                              key={index}
                              label="sdfasdf"
                              disabled={this.isTariffDisabled(item)}
                              value={item.pid}
                            >
                              <FormattedMessage id={item.name} />
                            </MenuItem>
                          ))
                        : null}
                    </Field>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  style={{ margin: '20px 0' }}
                >
                  <Grid item xs={12} sm={6}>
                    <span className={classes.title}>
                      <FormattedMessage id="billing.cycle" />
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      fullWidth
                      success={(tariffState === 'success').toString()}
                      error={tariffState === 'error'}
                      value={dataForm.billingCycle}
                      disabled={
                        selectedTariff.pid === 1 ||
                        selectedTariff.pid === 5 ||
                        !Object.keys(selectedTariff).length
                      }
                      component={this.selectField}
                      name="billingCycle"
                    >
                      <MenuItem value="free account" disabled>
                        {intl.formatMessage({ id: 'choice.billingcycle' })}
                      </MenuItem>
                      {['monthly', 'annually'].map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {intl.formatMessage({ id: item })}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={5}>
                {selectedTariff
                  ? this.getTariffsDescription(selectedTariff.description)
                  : ''}
              </Grid>
            </Grid>
          </TableCell>
          <TableCell className={`${classes.col2} ${classes.right}`}>
            <span className={classes.price}>{this.getProductPrice()}</span>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
}

const mapStatToProps = (state) => ({
  tariff: state.tariff,
  channels: state.channels,
  dataForm: getFormValues('EditChannel')(state),
});

export default injectIntl(
  connect((state) => mapStatToProps(state))(
    withStyles(styles)(SelectedTariffRows)
  )
);
