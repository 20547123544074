export class Layer {
  constructor() {
    this.elements = [];
    this.visible = true;
  }

  addElement(...elements) {
    this.elements.push(...elements);
  }

  draw(ctx) {
    if (!ctx) throw 'No canvas';
    if (!this.visible) return;
    this.elements.forEach((el) => el.draw(ctx));
  }
}

export default Layer;
