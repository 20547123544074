export class Square {
  constructor(position, size, color) {
    this.setPosition(position);
    this.setSize(size);
    this.setColor(color);
    this.setPath();
  }

  setPath() {
    this.path = new Path2D();
    this.path.rect(this.x, this.y, this.width, this.height);
  }

  setPosition({ x = 0, y = 0 } = {}) {
    this.x = x;
    this.y = y;
  }

  setSize({ width = 100, height = 100 } = {}) {
    this.width = width;
    this.height = height;
  }

  setColor(color = 'rgba(100, 150, 200, 0.5)') {
    this.fillStyle = color;
  }

  getBounds() {
    return {
      top: this.y,
      left: this.x,
      right: this.x + this.width,
      bottom: this.y + this.height,
    };
  }

  draw(ctx = undefined) {
    if (!ctx) throw 'No canvas';
    this.setPath();
    ctx.fillStyle = this.fillStyle;
    ctx.fill(this.path);
  }
}

export default Square;
