import React from 'react';
import GridItem from '../Grid/GridItem';
import withWidth from '@material-ui/core/withWidth';
import Button from '../CustomButtons/ButtonWithLoader';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';

const WInManualsItem = (props) => {
  return (
    <GridItem container direction="column" item xs={12} sm={3}>
      <span style={{ marginBottom: '10px', fontWeight: 400 }}>
        <FormattedMessage
          id={`layout.menu.instructions.macroscop${props.arch}`}
        />
      </span>
      <Button color="primary" onClick={props.download}>
        <GetAppOutlinedIcon className={props.classes.icons} />
        <span style={{ verticalAlign: 'bottom' }}>
          <FormattedMessage id="profile.form.avatar.upload" />
        </span>
      </Button>
    </GridItem>
  );
};

export default injectIntl(
  withWidth()(withStyles(extendedTablesStyle)(WInManualsItem))
);
