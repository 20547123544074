import axios from 'axios';

export const viewersService = {
  addViewers,
  getViewers,
  removeViewers,
  getOne,
  editViewer,
  toggleViewer,
};

function addViewers(params) {
  return axios.post(`/viewers`, { ...params });
}

function getViewers() {
  return axios.get(`/viewers`);
}

function removeViewers(id) {
  return axios.delete(`/viewers/${id}`);
}

function getOne(id) {
  return axios.get(`/viewers/${id}`);
}

function editViewer(id, params) {
  return axios.put(`/viewers/${id}`, params);
}

function toggleViewer(id, state) {
  return axios.patch(`/viewers/${id}`, { state });
}
