import React from 'react';
import FormChannel from './Form';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from 'components/Card/Card.jsx';
import { FormattedMessage } from 'react-intl';
import LoaderAnimation from 'components/Loader';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import VideocamIcon from '@material-ui/icons/Videocam';
import CardHeader from 'components/Card/CardHeader.jsx';
import { channelsActions, loaderActions } from 'actions';
import withStyles from '@material-ui/core/styles/withStyles';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

class EditChannel extends React.Component {
  componentDidMount = () => {
    const { dispatch, match } = this.props;
    if (match.params) {
      dispatch(loaderActions.show('pageLoader'));
      dispatch(channelsActions.getOneChannel(match.params.channelId));
    }
  };

  render = () => {
    const { classes, match, loader } = this.props;

    if (loader) return <LoaderAnimation />;

    return (
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <VideocamIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>
            <FormattedMessage id="channels.editChanel" />
          </h4>
        </CardHeader>
        <CardBody>
          <Grid container justify="center">
            <Grid item xs={12} lg={window.innerWidth > 1600 ? 8 : 10}>
              <FormChannel formAction={match.params ? 'update' : 'add'} />
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    );
  };
}

export default connect((state) => ({
  loader: state.loader.pageLoader,
}))(withStyles(regularFormsStyle)(EditChannel));
