import React from 'react';
import Grid from '@material-ui/core/Grid';
import { injectIntl, FormattedMessage } from 'react-intl';
import MainVideoImageStream from './MainVideoImageStream';
import AlternativeVideoImageStream from './AlternativeVideoImageStream';

const VideoImageStreamContainer = (props) => {
  const { wizard, tested, active, handleVideoLoad, activateVideoLoading } =
    props;
  return (
    <React.Fragment>
      <h3 style={{ margin: '40px 0 10px' }}>
        <FormattedMessage id="channels.form.group.video" />
      </h3>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={8}>
          <p>
            <FormattedMessage id="channels.form.warning" />
          </p>
          <h6 style={{ fontWeight: 'bold' }}>
            <FormattedMessage id="channels.form.warning.altStream" />
          </h6>
        </Grid>
      </Grid>
      <Grid container spacing={16}>
        <MainVideoImageStream
          wizard={wizard}
          active={active}
          tested={tested}
          handleVideoLoad={handleVideoLoad}
          activateVideoLoading={activateVideoLoading}
        />
        <AlternativeVideoImageStream
          wizard={wizard}
          active={active}
          handleVideoLoad={handleVideoLoad}
          activateVideoLoading={activateVideoLoading}
        />
      </Grid>
    </React.Fragment>
  );
};

export default injectIntl(VideoImageStreamContainer);
