import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import React, { Fragment } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

class ReCaptcha extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.lang !== prevProps.lang) {
      this.changeCaptchaLang(window.document.body, this.props.lang);
    }
  }

  changeCaptchaLang = (recaptchaContainer, lang) => {
    const iframeGoogleCaptcha = recaptchaContainer.querySelector('iframe');
    iframeGoogleCaptcha.setAttribute(
      'src',
      iframeGoogleCaptcha
        .getAttribute('src')
        .replace(/hl=(.*?)&/, `hl=${lang}&`)
    );
  };

  onChangeCaptcha = (value) => {
    this.props.input.onChange(value);
  };

  render() {
    return (
      <FormControl
        error={Boolean(this.props.meta.touched && this.props.meta.error)}
      >
        <Fragment>
          <ReCAPTCHA
            key="recaptcha"
            hl={this.props.lang}
            sitekey={process.env.REACT_APP_SITEKEY}
            onChange={this.onChangeCaptcha}
          />
        </Fragment>
        {Boolean(this.props.meta.touched && this.props.meta.error) && (
          <FormHelperText>{this.props.meta.error}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.authentication.language,
});

export default injectIntl(
  connect((state) => mapStateToProps(state))(ReCaptcha)
);
