/* eslint-disable react/no-string-refs */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/Button.jsx';
import Avatar from 'components/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx';

const styles = {
  ...customInputStyle,
  w100: {
    width: '100%',
  },
  helperText: {
    margin: '10px 0',
    textAlign: 'center !important',
  },
};

class AvatarUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: null,
      imageUrl: null,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  };

  handleImageChange(e) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
      if (this.props.input) {
        this.props.input.onChange(file);
      }
    }
  }

  handleClick() {
    this.refs.fileInput.click();
  }

  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: null,
      // imageUrl: null
    });
    this.refs.fileInput.value = null;
    if (this.props.input) {
      this.props.input.onChange(null);
    }
  }

  render() {
    const {
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      success,
      meta: { touched, error },
      classes,
    } = this.props;

    if (
      this.props.image &&
      !this.state
        .imagePreviewUrl /* || this.props.image !== this.state.imageUrl */
    ) {
      this.setState({
        imagePreviewUrl: this.props.image,
        file: null,
        imageUrl: this.props.image,
      });
    }
    if (this.props.image !== this.state.imageUrl) {
      this.setState({ file: null, imageUrl: this.props.image });
    }

    const helpText = touched && error ? error : undefined;
    const helpTextClasses = classNames({
      [classes.labelRootError]: !!(touched && error),
      [classes.labelRootSuccess]: success && !(touched && error),
    });
    return (
      <div className={`${classes.w100} fileinput text-center`}>
        <input
          type="file"
          onChange={this.handleImageChange}
          accept="image/jpeg,image/png,image/gif"
          ref="fileInput"
        />
        <Avatar image={this.state.imagePreviewUrl} avatar={this.props.avatar} />
        {helpText && (
          <FormHelperText
            className={`${helpTextClasses} ${classes.helperText}`}
          >
            {helpText}
          </FormHelperText>
        )}
        <div>
          {this.state.imagePreviewUrl === null ? (
            <Button {...addButtonProps} onClick={() => this.handleClick()}>
              {addButtonProps.text || 'Select image'}
            </Button>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                {changeButtonProps.text || 'Change'}
              </Button>
              {this.state.file ? (
                <Button
                  {...removeButtonProps}
                  onClick={() => this.handleRemove()}
                >
                  {removeButtonProps.text || 'Cancel'}
                </Button>
              ) : (
                ''
              )}
            </span>
          )}
        </div>
      </div>
    );
  }
}

AvatarUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

export default withStyles(styles)(AvatarUpload);
