export const partnerConstants = {
  GET_SELECTED_PARTNER_SUCCESS: 'GET_SELECTED_PARTNER_SUCCESS',
  GET_STATUS_REQUEST: 'GET_STATUS_VERIFICATION_REQUEST',
  GET_STATUS_SUCCESS: 'GET_STATUS_VERIFICATION_SUCCESS',
  GET_STATUS_FAILURE: 'GET_STATUS_VERIFICATION_FAILURE',

  REQUEST_VERIFICATION_REQUEST: 'REQUEST_VERIFICATION_REQUEST',
  REQUEST_VERIFICATION_SUCCESS: 'REQUEST_VERIFICATION_SUCCESS',
  REQUEST_VERIFICATION_FAILURE: 'REQUEST_VERIFICATION_FAILURE',

  REQUEST_GET_REGIONS_REQUEST: 'REQUEST_GET_REGIONS_REQUEST',
  REQUEST_GET_REGIONS_SUCCESS: 'REQUEST_GET_REGIONS_SUCCESS',
  REQUEST_GET_REGIONS_FAILURE: 'REQUEST_GET_REGIONS_FAILURE',

  REQUEST_ADD_REGIONS_REQUEST: 'REQUEST_ADD_REGIONS_REQUEST',
  REQUEST_ADD_REGIONS_SUCCESS: 'REQUEST_ADD_REGIONS_SUCCESS',
  REQUEST_ADD_REGIONS_FAILURE: 'REQUEST_ADD_REGIONS_FAILURE',

  REQUEST_EDIT_REGIONS_REQUEST: 'REQUEST_EDIT_REGIONS_REQUEST',
  REQUEST_EDIT_REGIONS_SUCCESS: 'REQUEST_EDIT_REGIONS_SUCCESS',
  REQUEST_EDIT_REGIONS_FAILURE: 'REQUEST_EDIT_REGIONS_FAILURE',

  REQUEST_DELETE_REGIONS_REQUEST: 'REQUEST_DELETE_REGIONS_REQUEST',
  REQUEST_DELETE_REGIONS_SUCCESS: 'REQUEST_DELETE_REGIONS_SUCCESS',
  REQUEST_DELETE_REGIONS_FAILURE: 'REQUEST_DELETE_REGIONS_FAILURE',

  GET_PARTNER_SUCCESS: 'GET_PARTNER_SUCCESS',
  GET_PARTNER_FAILURE: 'GET_PARTNER_FAILURE',
  CLEAR_PARTNER: 'CLEAR_PARTNER',

  GET_PARTNERS_SUCCESS: 'GET_PARTNERS_SUCCESS',
  GET_PARTNERS_FAILURE: 'GET_PARTNERS_FAILURE',

  GET_PARTNERS_ABONENTS_SUCCESS: 'GET_PARTNERS_ABONENTS_SUCCESS',
  GET_PARTNERS_ABONENTS_FAILURE: 'GET_PARTNERS_ABONENTS_FAILURE',

  GET_PARTNERS_CONFIRM_SUCCESS: 'GET_PARTNERS_CONFIRM_SUCCESS',
  GET_PARTNERS_CONFIRM_FAILURE: 'GET_PARTNERS_CONFIRM_FAILURE',

  SET_RATING_SUCCESS: 'SET_RATING_SUCCESS',
  SET_RATING_FAILURE: 'SET_RATING_FAILURE',
};
