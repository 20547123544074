import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { getLangCode } from 'helpers/language';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PartnerProfileData from './PartnerProfileData';
import PartnerProfileModal from './PartnerProfileModal';

const style = {
  card: {
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.01)',
      transition: 'transform 0.1s',
    },
  },
  content: { padding: '12px !important' },
};

class PartnerProfile extends React.Component {
  state = { modal: false };

  openModal = () => this.setState({ modal: true });

  closeModal = () => this.setState({ modal: false });

  render() {
    const { classes, data, lang } = this.props;
    return (
      <Fragment>
        <Card className={classes.card} onClick={this.openModal}>
          <CardContent className={classes.content}>
            <PartnerProfileData
              data={data}
              check={this.props.check}
              wide={this.props.wide}
              dispatch={this.props.dispatch}
              change={this.props.change}
              partnerHandleCheck={this.props.partnerHandleCheck}
            />
          </CardContent>
        </Card>
        {this.state.modal && (
          <PartnerProfileModal
            data={data}
            openState={this.state.modal}
            close={this.closeModal}
            lang={lang}
          />
        )}
      </Fragment>
    );
  }
}

export default connect((state) => ({
  lang: getLangCode(state.authentication.user.language),
}))(withStyles(style)(PartnerProfile));
