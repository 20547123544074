import React from 'react';
import { get } from 'lodash';
import { Field } from 'redux-form';
import { getDefaultPort } from 'helpers';
import { TextField } from 'components/ReduxInputs';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Success from 'components/Typography/Success.jsx';
import Danger from 'components/Typography/Danger.jsx';
import { normalizeCameraPorts } from '../formsNormalization';

export const getPortStatusIcon = (portReachable, formValues, name) => {
  const port = get(formValues, name);
  if (!portReachable || !port || portReachable[port] === undefined) return;
  return (
    <InputAdornment position="end">
      {portReachable[port] ? (
        <Success>
          <CheckIcon />
        </Success>
      ) : (
        <Danger>
          <CloseIcon />
        </Danger>
      )}
    </InputAdornment>
  );
};

export const getPortsFields = (
  intl,
  deviceManufactures,
  portReachable,
  formValues
) => {
  return getDefaultPort(deviceManufactures, formValues).map((port, index) => {
    const name = `data.ConnectionSettings.ExternalNetworkPorts.${index}.PortValue`;
    const description = port.Description;
    const isRtsp = description === 'RTSP-порт';
    const field = (
      <Field
        component={TextField}
        fullWidth
        type="number"
        label={intl.formatMessage({ id: description })}
        name={name}
        normalize={(value, previousValue) =>
          normalizeCameraPorts(value, previousValue, 5)
        }
        InputProps={{
          inputProps: { min: 1, max: 65535 },
          endAdornment: getPortStatusIcon(portReachable, formValues, name),
        }}
      />
    );
    return { field, isRtsp };
  });
};
