import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PartnerProfileData from './PartnerProfileData';
import RegionsTable from '../Regions/RegionsTable';

const PartnerProfileModal = (props) => {
  const { data, lang } = props;
  return (
    <Dialog
      open={props.openState}
      onClose={props.close}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <PartnerProfileData data={data} wide contacts />
        {Boolean(data.regions.length) && (
          <Fragment>
            <h3>
              <FormattedMessage id="layout.menu.regions" />
            </h3>
            <RegionsTable
              data={data.regions}
              lang={lang}
              noEdit
              showPagination={false}
              minRows="0"
              sortable={false}
              resizable={false}
              filterable={false}
            />
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default injectIntl(PartnerProfileModal);
